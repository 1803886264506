import { useHistory } from 'react-router-dom';
import { ReactComponent as IconEmpty } from "assets/images/icons/project-icons/empty-img.svg";
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as InfoIcon } from "assets/images/icons/project-icons/information.svg";

const usePayrollEssentials = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const lettersRegex = /[a-zA-Z]/;
    const symbolRegex = /[!@#$%^&*(),.?":{}|<>]/;

    const AssignmentLabel = ({isAssigned}) => (  <p className={isAssigned ? "assigned-pill" : "unassigned-pill"}><span className={isAssigned ? "assigned-dot" : "unassigned-dot"} />{isAssigned ? "Assigned" : "Not Assigned"}</p>)

    const EmptyDataUI = ({header, subheader, btnText, onBtnClick}) =>  (
        <div className="flex flex-col items-center justify-center">
            <IconEmpty />
                <p className="mb-1 font-medium mt-8">{header || 'Oops! Nothing to see here'}</p>
                <p className="mb-6 font-medium mt-1 w-3/4 lg:w-3/5 text-center">{subheader}.</p>
                {btnText && <button onClick={onBtnClick} className="btn btn-md btn-ep-primary">
                    {btnText}
                </button>}
        </div>
    )

    const SubPageTooltip = ({text}) => (
        <div className='pl-2'>
            <span className="pt-1 text-xl">
                <InfoIcon width={17} data-tip data-for="title-info" />
            </span>

            <ReactTooltip id="title-info" className="tooptip-size">
                {text}
            </ReactTooltip>
        </div>
    )

    const truncator = (text, length = 25) => {
        if(text?.length > length) {
            return `${text.substring(0, length)}...`
        }

        return text
    }

    function removeNullProperties(obj) {
        if (typeof obj !== 'object' || obj === null) {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map(removeNullProperties);
        }

        const newObj = {};
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
            const value = removeNullProperties(obj[prop]);
            if (value !== null) {
                newObj[prop] = value;
            }
            }
        }
        return newObj;
        }
        
        const formatDeductionType = (type) => { 
            switch(type) {
                case "BASIC":
                    return "Basic";
                
                case "HOUSING":
                    return "Housing";
                
                case "TRANSPORT":
                    return "Transport";
                
                case "BASIC_AND_HOUSING":
                    return "Basic and Housing";
                
                case "BASIC_AND_TRANSPORT":
                    return "Basic and Transport";
                
                case "HOUSING_AND_TRANSPORT":
                    return "Housing and Transport";
                
                case "BASIC_HOUSING_AND_TRANSPORT":
                    return "Basic, Housing, and Transport";
                
                case "GROSS_SALARY":
                    return "Gross Salary";
                    
                default:
                    return ""
            }
        }
        
        function getPayrollStatusStyle(color) {
            let style;
            
            switch (color) {
            case "draft":
                style = "bg-[#344054] text-white";
                break;
            case "in-review":
            case "processing":
                style = "bg-[#FFFAEB] text-[#B54708]";
                break;
            case "approved":
                style = "bg-[#eff8ff] text-[#175cd3]";
            case "completed":
                style = "bg-[#ECFDF3] text-[#027A48]";
                break;
            case "rejected":
                style = "bg-[#FEF3F2] text-[#B42318]";
                break;
            default:
                return "";
            }
            
            return style;
        }

    return {EmptyDataUI, removeNullProperties, dispatch, useSelector, AssignmentLabel, history, SubPageTooltip, lettersRegex, truncator, symbolRegex, formatDeductionType, getPayrollStatusStyle };
}


export default usePayrollEssentials;
