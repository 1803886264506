/*
export const NumberFormat = (number, divider = ',', decimal = '.') => {
  const allowedInput = /^[0-9,.]*$/;
  if (number !== "") {
      if (allowedInput.test(number)) {
          let numberOnly = NumberFormatRemove(number);
          numberOnly += '';
          numberOnly = numberOnly.replace('.', '');
          numberOnly = numberOnly - 0;
          numberOnly = numberOnly.toString();

          if (numberOnly.length === 1) {
              return ("0" + decimal + "0" + numberOnly);
          }
          else if (numberOnly.length === 2) {
              return ("0" + decimal + numberOnly);
          }
          else {
              let x1 = numberOnly.substr(0, (numberOnly.length - 2));
              let x2 = numberOnly.substr(numberOnly.length - 2);

              let rgx = /(\d+)(\d{3})/;
              while (rgx.test(x1)) {
                  x1 = x1.replace(rgx, '$1' + divider + '$2');
              }
              return (x1 + decimal + x2);
          }
      }
      else {
          return ("");
      }
  }
  else {
      return ("0.00");
  }
};
*/




export const NumberFormat = (amount, kobo = '') => {

    if (!isNaN(amount)) {
  
      if (amount === "") {
        amount = 0;
      }
  
      let amount_rounded = 0;
      if (kobo === "no-kobo" || kobo === "nokobo" || kobo === "no kobo") {
        amount_rounded = parseFloat(amount);
      }
      else {
        amount_rounded = parseFloat(amount).toFixed(2);
      }
      return (amount_rounded).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
    }
    else {
      return (amount);
    }
  
  };
  
  
  
  export const NumberFormatRemove = (number) => {
    const numberOnly = number.toString().replace(/,/g, '');
  
    /*
    let numberOnly = "";
    if (number > Math.floor(number)) {
        numberOnly = number.toString().replace(/,/g, '');
    }
    else {
        numberOnly = parseFloat(number).toFixed(2).toString().replace(/,/g, '');
    }
    */
  
    // const numberOnly = parseFloat(number.toString().replace(/,/g, ''));
    // const numberOnly = parseFloat(number.toString().replace(/,/g, '')).toFixed(2);
    // const numberOnly = parseFloat(number).toFixed(2).toString().replace(/,/g, '');
    return (numberOnly);    //string
  };
  
  
  
  export const NumberChartFormat = (number, symbol='') => {
    if (number < 1000){
      return (symbol + number);
    }
    else if (number >= 1000 && number < 1000000){
      const truncateNumber = (number/1000);
      return (symbol + parseFloat((truncateNumber).toFixed(2)) + "k");
    }
    else{
      const truncateNumber = (number/1000000);
      return (symbol + parseFloat((truncateNumber).toFixed(2)) + "m");
    }
  }
  
  
  export default NumberFormat;