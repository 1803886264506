import errorReducer from "utils/errorReducer";
import { decryptPayload } from "utils/encrypt_decrypt";
import * as actions from "./actionTypes";
import { customInfoToast, customSuccessToast } from "utils/customToast";

const initialState = {
  beneficiariesLoading: false,
  beneficiaries: {},
  createBeneficiaryLoading: false,
  createBeneficiary: {},
  providersLoading: false,
  providers: {},
  serviceCodesLoading: false,
  serviceCodes: {},

  airtimePurchaseLoading: false,
  airtimePurchase: {},

  bulkAirtimeStatusLoading: false,
  bulkAirtimeStatusData: {},

  dataBundlesLoading: false,
  dataBundles: {},
  dataPurchaseLoading: false,
  dataPurchase: {},

  electricityValidationLoading: false,
  electricityValidation: {},
  electricityPurchaseLoading: false,
  electricityPurchase: {},

  multichoicePlanLoading: false,
  multichoicePlan: {},
  multichoiceCodesLoading: false,
  multichoiceCodes: {},
  multichoiceProductAddonsLoading: false,
  multichoiceProductAddons: {},
  cableTvBouquetsLoading: false,
  cableTvBouquets: {},
  cableTvValidationLoading: false,
  cableTvValidation: {},
  cableTvPurchaseLoading: false,
  cableTvPurchase: {},

  airtimeTransactionsLoading: false,
  airtimeTransactionsData: {},
  airtimePreviewItems: null,

  airtimeCsvData: null,
  airtimeCsvErrors: null,

  dataSubTransactionsLoading: false,
  dataSubTransactionsData: {},

  dataSubCsvData: null,
  dataSubCsvErrors: null,

  powerTransactionsLoading: false,
  powerTransactionsData: {},

  powerCsvData: null,
  powerCsvErrors: null,
  powerPreviewItems: null,

  cableTransactionsLoading: false,
  cableTransactionsData: {},

  cableCsvData: null,
  cableCsvErrors: null,
  cablePreviewItems: null,

  parseCsvLoading: false,
  parseCsvData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SAVE_CABLE_CSV_DATA:
      state = {
        ...state,
        cableCsvData: action.payload,
      };
      return state;

    case actions.RESET_PARSED_CSV_PROPS:
      state = {
        ...state,
        parseCsvData: null,
      };
      return state;

    case actions.PARSE_CSV_FILE_START:
      customInfoToast({ message: 'Extracting CSV data' });
      state = {
        ...state,
        parseCsvLoading: true,
        parseCsvData: null,
      };
      return state;

    case actions.PARSE_CSV_FILE_DONE:
      customSuccessToast({ message: action.payload.message });

      const formattedData = action.payload?.data?.map((obj) => {
        const newObj = {};
        Object.keys(obj).forEach((key) => {
          const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
          newObj[camelCaseKey] = obj[key];
        });
        return newObj;
      });

      state = {
        ...state,
        parseCsvLoading: false,
        parseCsvData: {
          ...action.payload,
          data: formattedData,
        },
      };
      return state;

    case actions.PARSE_CSV_FILE_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        parseCsvLoading: false,
        parseCsvData: {},
      };
      return state;

    case actions.CLEAR_CABLE_PREVIEW_ITEMS:
      state = {
        ...state,
        cablePreviewItems: null,
      };
      return state;

    case actions.SAVE_CABLE_PREVIEW_ITEMS:
      state = {
        ...state,
        cablePreviewItems: action.payload,
      };
      return state;

    case actions.GET_CABLE_TRANSACTIONS_START:
      state = {
        ...state,
        cableTransactionsLoading: true,
        cableTransactionsData: {},
      };
      return state;

    case actions.GET_CABLE_TRANSACTIONS_DONE:
      state = {
        ...state,
        cableTransactionsLoading: false,
        cableTransactionsData: action.payload,
      };
      return state;

    case actions.GET_CABLE_TRANSACTIONS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        cableTransactionsLoading: false,
        cableTransactionsData: {},
      };
      return state;

    case actions.SAVE_POWER_CSV_DATA:
      state = {
        ...state,
        powerCsvData: action.payload,
      };
      return state;

    case actions.CLEAR_POWER_PREVIEW_ITEMS:
      state = {
        ...state,
        powerPreviewItems: null,
      };
      return state;

    case actions.SAVE_POWER_PREVIEW_ITEMS:
      state = {
        ...state,
        powerPreviewItems: action.payload,
      };
      return state;

    case actions.GET_POWER_TRANSACTIONS_START:
      state = {
        ...state,
        powerTransactionsLoading: true,
        powerTransactionsData: {},
      };
      return state;

    case actions.GET_POWER_TRANSACTIONS_DONE:
      state = {
        ...state,
        powerTransactionsLoading: false,
        powerTransactionsData: action.payload,
      };
      return state;

    case actions.GET_POWER_TRANSACTIONS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        powerTransactionsLoading: false,
        powerTransactionsData: {},
      };
      return state;

    case actions.SAVE_DATASUB_CSV_ERRORS:
      state = {
        ...state,
        dataSubCsvData: action.payload,
      };
      return state;

    case actions.SAVE_DATASUB_CSV_DATA:
      state = {
        ...state,
        dataSubCsvData: action.payload,
      };
      return state;

    case actions.GET_DATASUB_TRANSACTIONS_START:
      state = {
        ...state,
        dataSubTransactionsLoading: true,
        dataSubTransactionsData: {},
      };
      return state;

    case actions.GET_DATASUB_TRANSACTIONS_DONE:
      state = {
        ...state,
        dataSubTransactionsLoading: false,
        dataSubTransactionsData: action.payload,
      };
      return state;

    case actions.GET_DATASUB_TRANSACTIONS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        dataSubTransactionsLoading: false,
        dataSubTransactionsData: {},
      };
      return state;

    case actions.SAVE_AIRTIME_CSV_ERRORS:
      state = {
        ...state,
        airtimeCsvData: action.payload,
      };
      return state;

    case actions.SAVE_AIRTIME_CSV_DATA:
      state = {
        ...state,
        airtimeCsvData: action.payload,
      };
      return state;

    case actions.CLEAR_AIRTIME_PREVIEW_ITEMS:
      state = {
        ...state,
        airtimePreviewItems: null,
      };
      return state;

    case actions.SAVE_AIRTIME_PREVIEW_ITEMS:
      state = {
        ...state,
        airtimePreviewItems: action.payload,
      };
      return state;

    case actions.GET_AIRTIME_TRANSACTIONS_START:
      state = {
        ...state,
        airtimeTransactionsLoading: true,
        airtimeTransactionsData: {},
      };
      return state;

    case actions.GET_AIRTIME_TRANSACTIONS_DONE:
      state = {
        ...state,
        airtimeTransactionsLoading: false,
        airtimeTransactionsData: action.payload,
      };
      return state;

    case actions.GET_AIRTIME_TRANSACTIONS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        airtimeTransactionsLoading: false,
        airtimeTransactionsData: {},
      };
      return state;

    case actions.GET_BENEFICIARIES:
      state = {
        ...state,
        beneficiariesLoading: false,
        beneficiaries: action.payload,
      };
      return state;

    case actions.GET_BENEFICIARIES_START:
      state = {
        ...state,
        beneficiariesLoading: true,
      };
      return state;

    case actions.GET_BENEFICIARIES_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        beneficiariesLoading: false,
        beneficiaries: action.payload,
      };
      return state;

    case actions.GET_ALL_BENEFICIARIES:
      state = {
        ...state,
        beneficiariesLoading: false,
        beneficiaries: action.payload,
      };
      return state;

    case actions.GET_ALL_BENEFICIARIES_START:
      state = {
        ...state,
        beneficiariesLoading: true,
      };
      return state;

    case actions.GET_ALL_BENEFICIARIES_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        beneficiariesLoading: false,
        beneficiaries: action.payload,
      };
      return state;

    case actions.CREATE_BENEFICIARY:
      state = {
        ...state,
        createBeneficiaryLoading: false,
        createBeneficiary: action.payload,
      };
      return state;

    case actions.CREATE_BENEFICIARY_START:
      state = {
        ...state,
        createBeneficiaryLoading: true,
      };
      return state;

    case actions.CREATE_BENEFICIARY_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        createBeneficiaryLoading: false,
        createBeneficiary: action.payload,
      };
      return state;

    case actions.EDIT_BENEFICIARY:
      state = {
        ...state,
        editBeneficiaryLoading: false,
        editBeneficiary: action.payload,
      };
      return state;

    case actions.EDIT_BENEFICIARY_START:
      state = {
        ...state,
        editBeneficiaryLoading: true,
      };
      return state;

    case actions.EDIT_BENEFICIARY_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        editBeneficiaryLoading: false,
        editBeneficiary: action.payload,
      };
      return state;

    case actions.DELETE_BENEFICIARY:
      state = {
        ...state,
        deleteBeneficiaryLoading: false,
        deleteBeneficiary: action.payload,
      };
      return state;

    case actions.DELETE_BENEFICIARY_START:
      state = {
        ...state,
        deleteBeneficiaryLoading: true,
      };
      return state;

    case actions.DELETE_BENEFICIARY_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        deleteBeneficiaryLoading: false,
        deleteBeneficiary: action.payload,
      };
      return state;

    case actions.GET_PROVIDERS:
      state = {
        ...state,
        providersLoading: false,
        providers: action.payload,
      };
      return state;

    case actions.GET_PROVIDERS_START:
      state = {
        ...state,
        providersLoading: true,
      };
      return state;

    case actions.GET_PROVIDERS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        providersLoading: false,
        providers: action.payload,
      };
      return state;

    case actions.GET_SERVICE_CODES:
      state = {
        ...state,
        serviceCodesLoading: false,
        serviceCodes: action.payload,
      };
      return state;

    case actions.GET_SERVICE_CODES_START:
      state = {
        ...state,
        serviceCodesLoading: true,
      };
      return state;

    case actions.GET_SERVICE_CODES_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        serviceCodesLoading: false,
        serviceCodes: action.payload,
      };
      return state;

    case actions.AIRTIME_PURCHASE:
      // if (action.payload.status === "failed"){
      //     errorReducer(action.payload);
      // }
      state = {
        ...state,
        airtimePurchaseLoading: false,
        airtimePurchase: action.payload,
        // airtimePurchase: JSON.parse(action.payload),
      };
      return state;

    case actions.AIRTIME_PURCHASE_START:
      state = {
        ...state,
        airtimePurchaseLoading: true,
      };
      return state;

    case actions.AIRTIME_PURCHASE_FAILED:
      state = {
        ...state,
        airtimePurchaseLoading: false,
        airtimePurchase: action.payload,
        // airtimePurchase: JSON.parse(action.payload),
      };
      return state;

    case actions.AIRTIME_PURCHASE_UPLOAD:
      // const responseAirtimeUpload1 = JSON.parse(decryptPayload(action.payload));
      const responseAirtimeUpload1 = JSON.parse(decryptPayload(action.payload));
      if (responseAirtimeUpload1.status === 'failed') {
        errorReducer(responseAirtimeUpload1);
      }
      state = {
        ...state,
        airtimePurchaseLoading: false,
        airtimePurchase: responseAirtimeUpload1,
      };
      return state;

    case actions.AIRTIME_PURCHASE_UPLOAD_FAILED:
      const responseAirtimeUpload2 = JSON.parse(decryptPayload(action.payload));
      errorReducer(responseAirtimeUpload2);
      state = {
        ...state,
        airtimePurchaseLoading: false,
        airtimePurchase: responseAirtimeUpload2,
      };
      return state;

    case actions.AIRTIME_PURCHASE_RESET:
      state = {
        ...state,
        airtimePurchaseLoading: false,
        airtimePurchase: {},
      };
      return state;

    case actions.GET_BULK_AIRTIME_STATUS_START:
      state = {
        ...state,
        bulkAirtimeStatusLoading: true,
      };
      return state;

    case actions.GET_BULK_AIRTIME_STATUS_DONE:
      state = {
        ...state,
        bulkAirtimeStatusLoading: false,
        bulkAirtimeStatusData: action.payload,
      };
      return state;

    case actions.GET_BULK_AIRTIME_STATUS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        bulkAirtimeStatusLoading: false,
        bulkAirtimeStatusData: {},
      };
      return state;

    case actions.GET_DATA_BUNDLES:
      state = {
        ...state,
        dataBundlesLoading: false,
        dataBundles: action.payload,
        // dataBundles: JSON.parse(action.payload),
      };
      return state;

    case actions.GET_DATA_BUNDLES_START:
      state = {
        ...state,
        dataBundlesLoading: true,
      };
      return state;

    case actions.GET_DATA_BUNDLES_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        dataBundlesLoading: false,
        dataBundles: action.payload,
        // dataBundles: JSON.parse(action.payload),
      };
      return state;

    case actions.DATA_PURCHASE:
      state = {
        ...state,
        dataPurchaseLoading: false,
        dataPurchase: action.payload,
        // dataPurchase: JSON.parse(action.payload),
      };
      return state;

    case actions.DATA_PURCHASE_START:
      state = {
        ...state,
        dataPurchaseLoading: true,
      };
      return state;

    case actions.DATA_PURCHASE_FAILED:
      //   if (action.payload.error === true) errorReducer(action.payload);
      state = {
        ...state,
        dataPurchaseLoading: false,
        dataPurchase: action.payload,
        // dataPurchase: JSON.parse(action.payload),
      };
      return state;

    case actions.DATA_PURCHASE_UPLOAD:
      const response1 = JSON.parse(decryptPayload(action.payload));
      if (response1.status === 'failed') {
        errorReducer(response1);
      }
      state = {
        ...state,
        dataPurchaseLoading: false,
        dataPurchase: response1,
      };
      return state;

    case actions.DATA_PURCHASE_UPLOAD_FAILED:
      const response2 = JSON.parse(decryptPayload(action.payload));
      errorReducer(response2);
      state = {
        ...state,
        dataPurchaseLoading: false,
        dataPurchase: response2,
      };
      return state;

    case actions.DATA_PURCHASE_RESET:
      state = {
        ...state,
        dataPurchaseLoading: false,
        dataPurchase: {},
      };
      return state;

    case actions.GET_ELECTRICITY_VALIDATION:
      state = {
        ...state,
        electricityValidationLoading: false,
        electricityValidation: action.payload,
      };
      return state;

    case actions.GET_ELECTRICITY_VALIDATION_START:
      state = {
        ...state,
        // electricityValidation: {},
        electricityValidationLoading: true,
      };
      return state;

    case actions.GET_ELECTRICITY_VALIDATION_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        electricityValidationLoading: false,
        electricityValidation: action.payload,
      };
      return state;

    case actions.RESET_ELECTRICITY_VALIDATION:
      state = {
        ...state,
        electricityValidationLoading: false,
        electricityValidation: {},
      };
      return state;

    case actions.ELECTRICITY_PURCHASE:
      if (action.payload.status === 'failed') {
        errorReducer(action.payload);
      }
      state = {
        ...state,
        electricityPurchaseLoading: false,
        electricityPurchase: action.payload,
      };
      return state;

    case actions.ELECTRICITY_PURCHASE_START:
      state = {
        ...state,
        electricityPurchaseLoading: true,
      };
      return state;

    case actions.ELECTRICITY_PURCHASE_FAILED:
      state = {
        ...state,
        electricityPurchaseLoading: false,
        electricityPurchase: action.payload,
      };
      return state;

    case actions.ELECTRICITY_PURCHASE_RESET:
      state = {
        ...state,
        electricityPurchaseLoading: false,
        electricityPurchase: {},
      };
      return state;

    case actions.RENEW_MULTICHOICE_PLAN:
      state = {
        ...state,
        multichoicePlanLoading: false,
        multichoicePlan: action.payload,
      };
      return state;

    case actions.RENEW_MULTICHOICE_PLAN_START:
      state = {
        ...state,
        multichoicePlanLoading: true,
      };
      return state;

    case actions.RENEW_MULTICHOICE_PLAN_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        multichoicePlanLoading: false,
        multichoicePlan: action.payload,
      };
      return state;

    case actions.GET_MULTICHOICE_CODES:
      state = {
        ...state,
        cableTvBouquetsLoading: false,
        cableTvBouquets: action.payload,
      };
      return state;

    case actions.GET_MULTICHOICE_CODES_START:
      state = {
        ...state,
        cableTvBouquetsLoading: true,
      };
      return state;

    case actions.GET_MULTICHOICE_CODES_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        cableTvBouquetsLoading: false,
        cableTvBouquets: action.payload,
      };
      return state;

    case actions.GET_MULTICHOICE_PRODUCT_ADDONS:
      state = {
        ...state,
        multichoiceProductAddonsLoading: false,
        multichoiceProductAddons: action.payload,
      };
      return state;

    case actions.GET_MULTICHOICE_PRODUCT_ADDONS_START:
      state = {
        ...state,
        multichoiceProductAddonsLoading: true,
      };
      return state;

    case actions.GET_MULTICHOICE_PRODUCT_ADDONS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        multichoiceProductAddonsLoading: false,
        multichoiceProductAddons: action.payload,
      };
      return state;

    case actions.GET_CABLETV_BOUQUETS:
      state = {
        ...state,
        cableTvBouquetsLoading: false,
        cableTvBouquets: action.payload,
      };
      return state;

    case actions.GET_CABLETV_BOUQUETS_START:
      state = {
        ...state,
        cableTvBouquetsLoading: true,
      };
      return state;

    case actions.GET_CABLETV_BOUQUETS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        cableTvBouquetsLoading: false,
        cableTvBouquets: action.payload,
      };
      return state;

    case actions.GET_CABLETV_VALIDATION:
      state = {
        ...state,
        cableTvValidationLoading: false,
        cableTvValidation: action.payload,
      };
      return state;

    case actions.GET_CABLETV_VALIDATION_START:
      state = {
        ...state,
        // cableTvValidation: {},
        cableTvValidationLoading: true,
      };
      return state;

    case actions.GET_CABLETV_VALIDATION_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        cableTvValidationLoading: false,
        cableTvValidation: action.payload,
      };
      return state;

    case actions.RESET_CABLETV_VALIDATION:
      state = {
        ...state,
        cableTvValidationLoading: false,
        cableTvValidation: {},
      };
      return state;

    case actions.CABLETV_PURCHASE:
      if (action.payload.error === true) {
        errorReducer(action.payload);
      }
      state = {
        ...state,
        cableTvPurchaseLoading: false,
        cableTvPurchase: action.payload,
      };
      return state;

    case actions.CABLETV_PURCHASE_START:
      state = {
        ...state,
        cableTvPurchaseLoading: true,
      };
      return state;

    case actions.CABLETV_PURCHASE_FAILED:
      state = {
        ...state,
        cableTvPurchaseLoading: false,
        cableTvPurchase: action.payload,
      };
      return state;

    case actions.CABLETV_PURCHASE_RESET:
      state = {
        ...state,
        cableTvPurchaseLoading: false,
        cableTvPurchase: {},
      };
      return state;

    default:
      return state;
  }
}