export const GET_ALL_PAYMENT_METHOD = "GET_ALL_PAYMENT_METHOD";
export const GET_ALL_PAYMENT_METHOD_START = "GET_ALL_PAYMENT_METHOD_START";
export const GET_ALL_PAYMENT_METHOD_FAILED = "GET_ALL_PAYMENT_METHOD_FAILED";

export const CREATE_PAYMENT_METHOD_CARD = "CREATE_PAYMENT_METHOD_CARD";
export const CREATE_PAYMENT_METHOD_CARD_START = "CREATE_PAYMENT_METHOD_CARD_START";
export const CREATE_PAYMENT_METHOD_CARD_FAILED = "CREATE_PAYMENT_METHOD_CARD_FAILED";
export const CREATE_PAYMENT_METHOD_CARD_RESET = "CREATE_PAYMENT_METHOD_CARD_RESET";

export const CREATE_PAYMENT_METHOD_BANK = "CREATE_PAYMENT_METHOD_BANK";
export const CREATE_PAYMENT_METHOD_BANK_START = "CREATE_PAYMENT_METHOD_BANK_START";
export const CREATE_PAYMENT_METHOD_BANK_FAILED = "CREATE_PAYMENT_METHOD_BANK_FAILED";
export const CREATE_PAYMENT_METHOD_BANK_RESET = "CREATE_PAYMENT_METHOD_BANK_RESET";

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_START = "UPDATE_PAYMENT_METHOD_START";
export const UPDATE_PAYMENT_METHOD_FAILED = "UPDATE_PAYMENT_METHOD_FAILED";
export const UPDATE_PAYMENT_METHOD_RESET = "UPDATE_PAYMENT_METHOD_RESET";

export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const DELETE_PAYMENT_METHOD_START = "DELETE_PAYMENT_METHOD_START";
export const DELETE_PAYMENT_METHOD_FAILED = "DELETE_PAYMENT_METHOD_FAILED";
export const DELETE_PAYMENT_METHOD_RESET = "DELETE_PAYMENT_METHOD_RESET";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";