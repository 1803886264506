import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import store, { persistor } from "store/store";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import UnsecurePageAlert from './pages/misc/UnsecurePageAlert';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css";
import 'assets/css/index.css';
import 'assets/css/earnipay.css';
import 'pages/compliance/new/compliance.css';


Sentry.init({
    dsn: "https://25944c08d8ac429e96a8e62819c02e23@o4504846732361728.ingest.sentry.io/4505177358008320",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});


ReactDOM.render(
    <React.StrictMode>
      <ToastContainer autoClose={5000} />
      
      {(window.self === window.top) &&
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
      }
  
      {(window.self !== window.top) && <UnsecurePageAlert /> }
  
    </React.StrictMode>,
    document.getElementById('root')
);