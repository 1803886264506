import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";

const initialState = {
    bvnValidationLoading: false,
    bvnValidation: {},
    
    bvnOtpLoading: false,
    bvnOtp: {},
    
    bvnWhatsAppOtpLoading: false,
    bvnWhatsAppOtp: {},
    
    bvnOtpValidationLoading: false,
    bvnOtpValidation: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.VALIDATE_BVN:
            state = {
                ...state,
                bvnValidationLoading: false,
                bvnValidation: action.payload,
            }
            return state;


        case actions.VALIDATE_BVN_START:
            state = {
                ...state,
                bvnValidationLoading: true,
            }
            return state;


        case actions.VALIDATE_BVN_FAILED:
            state = {
                ...state,
                bvnValidationLoading: false,
                bvnValidation: action.payload,
            }
            return state;


        case actions.VALIDATE_BVN_RESET:
            state = {
                ...state,
                bvnValidationLoading: false,
                bvnValidation: {},
            }
            return state;


        case actions.SEND_BVN_OTP:
            state = {
                ...state,
                bvnOtpLoading: false,
                bvnOtp: action.payload,
            }
            return state;


        case actions.SEND_BVN_OTP_START:
            state = {
                ...state,
                bvnOtpLoading: true,
            }
            return state;


        case actions.SEND_BVN_OTP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                bvnOtpLoading: false,
                bvnOtp: action.payload,
            }
            return state;


        case actions.SEND_BVN_OTP_RESET:
            state = {
                ...state,
                bvnOtpLoading: false,
                bvnOtp: {},
            }
            return state;
            
        case actions.SEND_BVN_OTP_WHATS_APP:
            state = {
                ...state,
                bvnWhatsAppOtpLoading: false,
                bvnWhatsAppOtp: action.payload,
            }
            return state;


        case actions.SEND_BVN_OTP_WHATS_APP_START:
            state = {
                ...state,
                bvnWhatsAppOtpLoading: true,
            }
            return state;


        case actions.SEND_BVN_OTP_WHATS_APP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                bvnWhatsAppOtpLoading: false,
                bvnWhatsAppOtp: action.payload,
            }
            return state;


        case actions.SEND_BVN_OTP_WHATS_APP_RESET:
            state = {
                ...state,
                bvnWhatsAppOtpLoading: false,
                bvnWhatsAppOtp: {},
            }
            return state;


        case actions.VALIDATE_BVN_OTP:
            state = {
                ...state,
                bvnOtpValidationLoading: false,
                bvnOtpValidation: action.payload,
            }
            return state;


        case actions.VALIDATE_BVN_OTP_START:
            state = {
                ...state,
                bvnOtpValidationLoading: true,
            }
            return state;


        case actions.VALIDATE_BVN_OTP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                bvnOtpValidationLoading: false,
                bvnOtpValidation: action.payload,
            }
            return state;


        case actions.VALIDATE_BVN_OTP_RESET:
            state = {
                ...state,
                bvnOtpValidationLoading: false,
            }
            return state;


        default:
            return state;
    }
}