import { useEffect, useRef, lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from 'react-router-dom';

import store from 'store/store';
import useRouteAccess from 'hooks/useRouteAccess';

import Error401 from './pages/misc/error401';
import Error404 from './pages/misc/error404';
import XFrameOptions from './pages/misc/x-frame-options';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ALLOWED_BUSINESS_NAMES_FOR_PAYROLL } from 'utils/constants';

import AuthLayout from './components/__new/layouts/authLayout';
import EmployeeAdminLayout from './components/__new/layouts/employeeAdminLayout';
import { useDispatch } from 'react-redux';
import { updateLoginProfile } from 'store/auth/account/action';
const NullLayout = lazy(() => import('./components/__new/layouts/nullLayout'));
const OnDemandCompliance = lazy(
  () => import('./components/__new/onDemand/compliance')
);

const GetStarted = lazy(() => import('pages/auth/signup/GetStarted'));
const GetStarted2 = lazy(() => import('./pages/auth/getStarted2'));
const GetStartedSuccess = lazy(() => import('./pages/auth/getStartedSuccess'));
const ReConfirmAccount = lazy(() => import('./pages/auth/reconfirmAccount'));
const ReConfirmAccountSuccess = lazy(
  () => import('./pages/auth/reconfirmAccountSuccess')
);
const VerifyAccountSuccess = lazy(
  () => import('./pages/auth/verifyAccountSuccess')
);
const Login = lazy(() => import('./pages/auth/login'));
const ForgotPassword = lazy(() => import('./pages/auth/forgotPassword'));
const ResetPassword = lazy(() => import('./pages/auth/resetPassword'));
const ResetPasswordSuccess = lazy(
  () => import('./pages/auth/resetPasswordSuccess')
);
const CreateYourPassword = lazy(
  () => import('./pages/auth/createYourPassword')
);
const CreateYourPasswordSuccess = lazy(
  () => import('./pages/auth/createYourPasswordSuccess')
);
const ResetYourPassword = lazy(() => import('./pages/auth/resetYourPassword'));
const ResetYourPasswordSuccess = lazy(
  () => import('./pages/auth/resetYourPasswordSuccess')
);
const VerifyEmail = lazy(() => import('pages/auth/VerifyEmail'));
const VerifyTwoFactorAuth = lazy(
  () => import('pages/auth/VerifyTwoFactorAuth')
);
const CreateBusinessPassword = lazy(
  () => import('pages/auth/signup/CreateBusinessPassword')
);
const setWithdrawalsLimit = lazy(
  () => import('./pages/general/setWithdrawalsLimit')
);
const earnipayAdminLogin = lazy(
  () => import('./pages/general/earnipayAdminLogin')
);
const FinancingOptions = lazy(() => import('pages/financingOptions'));

const GetStartedOverview = lazy(() => import('./pages/onboarding/overview'));
const GetStartedInfoGeneral = lazy(
  () => import('./pages/onboarding/getStartedInfoGeneral')
);
const GetStartedDemo = lazy(() => import('./pages/onboarding/getStartedDemo'));
const GetStartedInfoFinancials = lazy(
  () => import('./pages/onboarding/getStartedInfoFinancials')
);
const GetStartedInfoSuccess = lazy(
  () => import('./pages/onboarding/getStartedInfoSuccess')
);
const OnboardingComplianceIndex = lazy(
  () => import('./pages/onboarding/compliance/index')
);
const OnboardingComplianceBvn = lazy(
  () => import('./pages/onboarding/compliance/bvn')
);
const OnboardingComplianceBvnSuccess = lazy(
  () => import('./pages/onboarding/compliance/bvn-success')
);
const OnboardingComplianceCac = lazy(
  () => import('./pages/onboarding/compliance/cac')
);
const OnboardingComplianceCacSuccess = lazy(
  () => import('./pages/onboarding/compliance/cac-success')
);

const AddSubAccountCompanyInfo = lazy(
  () => import('./pages/account/addSubAccount/companyInfo')
);
const AddSubAccountUserInfo = lazy(
  () => import('./pages/account/addSubAccount/adminInfo')
);
const AddSubAccountPreview = lazy(
  () => import('./pages/account/addSubAccount/preview')
);

// New compliance - STARTS HERE

const ComplianceOnboarding = lazy(
  () => import('./pages/compliance/new/welcome')
);

const ComplianceVerification = lazy(
  () => import('./pages/compliance/new/verification-progress')
);

const BusinessInformation = lazy(
  () => import('./pages/compliance/new/business-information')
);

const BusinessDocuments = lazy(
  () => import('./pages/compliance/new/business-documents')
);

const DirectorsInformation = lazy(
  () => import('./pages/compliance/new/directors-information')
);

const ComplianceStatus = lazy(() => import('./pages/compliance/new/status'));

// New compliance - ENDS HERE

const ComplianceBusinessDetails = lazy(
  () => import('./pages/compliance/business-details')
);
const ComplianceVerifyBusiness = lazy(
  () => import('./pages/compliance/registered/verify-business')
);
const ComplianceVerifyDirector = lazy(
  () => import('./pages/compliance/registered/verify-director')
);
const ComplianceVerifyBusinessUnregistered = lazy(
  () => import('./pages/compliance/unregistered/verify-business')
);
const ComplianceVerifyBvn = lazy(
  () => import('./pages/compliance/unregistered/verify-bvn')
);
const ComplianceVerifyBvnOtp = lazy(
  () => import('./pages/compliance/unregistered/verify-bvn-otp')
);
const ComplianceApproval = lazy(
  () => import('./pages/compliance/approval-pending')
);
const ComplianceApprovalSuccess = lazy(
  () => import('./pages/compliance/approval-success')
);
const ComplianceApprovalRejected = lazy(
  () => import('./pages/compliance/approval-rejected')
);

const Dashboard = lazy(() => import('./pages/dashboard/index'));
const employeesList = lazy(() => import('./pages/employees/list'));
const employeesListERA = lazy(() => import('./pages/employees/listERA'));
const employeesListERATransactions = lazy(
  () => import('./pages/employees/eraTransactions')
);
const employeesListEnrolled = lazy(
  () => import('./pages/employees/listEnrolled')
);
const invitedEmployeesList = lazy(
  () => import('./pages/employees/invitedEmployeesList')
);
const employeesListCRM = lazy(() => import('./pages/employees/listCRM'));
const employeesCreate = lazy(() => import('./pages/employees/create'));
const employeesUploadResult = lazy(
  () => import('./pages/employees/uploadResult')
);
const employeesEdit = lazy(() => import('./pages/employees/edit'));
const withdrawalsList = lazy(() => import('./pages/withdrawals/list'));
// const savingsRequestList = lazy(() => import('./pages/savings/list'));
const reconciliationList = lazy(() => import('./pages/reconciliation/list'));
// const PaymentNotesList = lazy(() => import('./pages/payment-notes/list'));
const virtualAccount = lazy(() => import('./pages/virtualAccount/list'));
const paymentNote = lazy(() => import('./pages/payment-note/list'));
const paymentDetails = lazy(() => import('./pages/payment-note/details'));
const paymentHistory = lazy(() => import('./pages/payment-note/history'));

const BulkBeneficiaries = lazy(() => import('pages/wallet/BulkBeneficiaries'));

const PaymentsAccount = lazy(() => import('./pages/payments/account/index'));
const YieldList = lazy(() => import('./pages/payments/account/yield/list'));

const PayrollIndex = lazy(() => import('pages/payroll/run-payroll/index'));
const PayrollCreate = lazy(() => import('pages/payroll/run-payroll/create'));
const PayrollRun = lazy(() => import('pages/payroll/run-payroll/run'));
const PayrollFinalize = lazy(
  () => import('pages/payroll/run-payroll/finalize')
);
const PayrollPaySalary = lazy(
  () => import('pages/payroll/run-payroll/pay-salary')
);
const PayrollPayRemittance = lazy(
  () => import('pages/payroll/run-payroll/pay-remittance')
);
const PayrollHistory = lazy(
  () => import('pages/payroll/payroll-history/index')
);
const PayrollHistoryDetails = lazy(
  () => import('pages/payroll/payroll-history/details')
);

const PaySchedule = lazy(
  () => import('pages/payroll/payroll-setup/PaySchedule')
);
const PayStructure = lazy(
  () => import('pages/payroll/payroll-setup/PayStructure')
);
const GrossPayrollLayout = lazy(
  () => import('components/__new/grossPayroll/GrossPayrollLayout')
);
const BenefitsInKind = lazy(
  () => import('pages/payroll/payroll-setup/BenefitsInKind')
);
const BenefitAssignment = lazy(
  () => import('components/__new/grossPayroll/benefits/BenefitAssignment')
);
const AllowanceAssignment = lazy(
  () => import('components/__new/grossPayroll/allowances/AllowanceAssignment')
);
const Allowances = lazy(() => import('pages/payroll/payroll-setup/Allowances'));
const Remittances = lazy(
  () => import('pages/payroll/payroll-setup/Remittances')
);
const CreatePaySchedule = lazy(
  () => import('components/__new/grossPayroll/schedule/CreatePaySchedule')
);
const CreatePayStructure = lazy(
  () => import('components/__new/grossPayroll/structure/CreatePayStructure')
);
const EditPaySchedule = lazy(
  () => import('components/__new/grossPayroll/schedule/EditPaySchedule')
);
const PayStructureAssignment = lazy(
  () => import('components/__new/grossPayroll/structure/PayStructureAssignment')
);
const GroupsListing = lazy(() => import('pages/groupPolicy/GroupsListing'));
const EditGroupDetails = lazy(
  () => import('pages/groupPolicy/EditGroupDetails')
);
const CreateNewGroup = lazy(() => import('pages/groupPolicy/CreateNewGroup'));
const ViewGroupDetails = lazy(
  () => import('pages/groupPolicy/ViewGroupDetails')
);

const PayGroups = lazy(() => import('pages/payroll/payroll-setup/PayGroups'));
const CreatePayGroup = lazy(
  () => import('components/__new/grossPayroll/pay-groups/CreatePayGroup')
);
const PayGroupsAssignment = lazy(
  () => import('components/__new/grossPayroll/pay-groups/PayGroupsAssignment')
);
const ViewPayGroupDetails = lazy(
  () => import('components/__new/grossPayroll/pay-groups/ViewPayGroupDetails')
);
const ViewAllowanceDetails = lazy(
  () => import('components/__new/grossPayroll/allowances/ViewAllowanceDetails')
);
const ViewBenefitDetails = lazy(
  () => import('components/__new/grossPayroll/benefits/ViewBenefitDetails')
);
const ViewStructureDetails = lazy(
  () =>
    import('components/__new/grossPayroll/structure/view/ViewStructureDetails')
);

const auditTrail = lazy(() => import('./pages/audit-logs/list'));

const settingsProfile = lazy(() => import('./pages/settings/profile'));
const settingsCompanyDetails = lazy(() => import('./pages/settings/company'));
const settingsAdministrators = lazy(
  () => import('./pages/settings/admin/admin')
);
const settingsAdministratorsEdit = lazy(
  () => import('./pages/settings/admin/adminEdit')
);
const subAccountAdminOnboarding = lazy(
  () => import('./pages/settings/admin/subAccountAdminOnboard')
);
const settingsAdministratorsOnboarding = lazy(
  () => import('./pages/settings/admin/adminOnboard')
);
const settingsAdministratorsOnboardingSuccess = lazy(
  () => import('./pages/settings/admin/adminOnboardSuccess')
);
const settingsPreferences = lazy(() => import('./pages/settings/preferences'));
const settingsTeamManagement = lazy(
  () => import('./pages/settings/teamManagement/list')
);
const settingsTeamManagementRoles = lazy(
  () => import('./pages/settings/teamManagement/roles')
);
const settingsTeamManagementFormRoleDetails = lazy(
  () => import('./pages/settings/teamManagement/form-step-1')
);
const settingsTeamManagementFormRolePermissions = lazy(
  () => import('./pages/settings/teamManagement/form-step-2')
);
const settingsApprovalsList = lazy(
  () => import('./pages/settings/approvals/list')
);
const settingsApprovalsForm = lazy(
  () => import('./pages/settings/approvals/form')
);
const settingsLoginAndSecurity = lazy(
  () => import('pages/settings/login-security')
);
// const settingsNotifications = lazy(() => import('pages/settings/notifications'));
const settingsOnDemandPay = lazy(() => import('pages/settings/ondemand-pay'));
const settingsTransactionLimits = lazy(
  () => import('pages/settings/transaction-limits')
);

const integrationsBento = lazy(
  () => import('./pages/settings/integrations/bento')
);
const integrationsBentoManage = lazy(
  () => import('./pages/settings/integrations/bentoManage')
);
const integrationsFinch = lazy(
  () => import('./pages/settings/integrations/finch')
);
const settlements = lazy(() => import('./pages/settings/settlements'));

const ApprovalDashboardIndex = lazy(
  () => import('pages/approval/dashboard/index')
);
const ApprovalWorkflowIndex = lazy(
  () => import('pages/approval/workflow/index')
);

const AccountFundingStatus = lazy(
  () => import('pages/dashboard/AccountFundingStatus')
);
const ViewSingleAccount = lazy(
  () => import('components/__new/payments/ViewSingleAccount')
);
const CreditInfo = lazy(() => import('pages/credit/CreditInfo'));
const CreditApplication = lazy(() => import('pages/credit/CreditApplication'));
const CreditApplicationCompleted = lazy(
  () => import('pages/credit/CreditApplicationCompleted')
);
const CreditApplicationProcessing = lazy(
  () => import('pages/credit/CreditApplicationProcessing')
);
const CreditApplicationNotEligible = lazy(
  () => import('pages/credit/CreditApplicationNotEligible')
);
const CreditApplicationEligible = lazy(
  () => import('pages/credit/CreditApplicationEligible')
);

// Payments components (named exports)
const InstantTransfers = lazy(() =>
  import('./pages/payments').then((module) => ({
    default: module.InstantTransfers,
  }))
);
const RecurringTransfers = lazy(() =>
  import('./pages/payments').then((module) => ({
    default: module.RecurringTransfers,
  }))
);
const RecurringTransferHistory = lazy(() =>
  import('./pages/payments').then((module) => ({
    default: module.RecurringTransferHistory,
  }))
);
const TransactionHistory = lazy(() =>
  import('./pages/payments').then((module) => ({
    default: module.TransactionHistory,
  }))
);
const TransferBeneficiaries = lazy(() =>
  import('./pages/payments').then((module) => ({
    default: module.TransferBeneficiaries,
  }))
);
const AirtimeDataBeneficiaries = lazy(() =>
  import('./pages/payments').then((module) => ({
    default: module.AirtimeDataBeneficiaries,
  }))
);
const ElectricityBeneficiaries = lazy(() =>
  import('./pages/payments').then((module) => ({
    default: module.ElectricityBeneficiaries,
  }))
);
const CableBeneficiaries = lazy(() =>
  import('./pages/payments').then((module) => ({
    default: module.CableBeneficiaries,
  }))
);

// Wallet components (named exports)
const WalletBalance = lazy(() =>
  import('./pages/wallet').then((module) => ({ default: module.WalletBalance }))
);
const WalletTransactions = lazy(() =>
  import('./pages/wallet').then((module) => ({
    default: module.WalletTransactions,
  }))
);
const WalletFund = lazy(() =>
  import('./pages/wallet').then((module) => ({ default: module.WalletFund }))
);
const WalletBeneficiaryForm = lazy(() =>
  import('./pages/wallet').then((module) => ({
    default: module.WalletBeneficiaryForm,
  }))
);

// Bill Payment components (named exports)
const BillPaymentOverview = lazy(() =>
  import('./pages/billPayment').then((module) => ({
    default: module.BillPaymentOverview,
  }))
);
const BuyAirtime = lazy(() =>
  import('./pages/billPayment').then((module) => ({
    default: module.BuyAirtime,
  }))
);
const BuyData = lazy(() =>
  import('./pages/billPayment').then((module) => ({ default: module.BuyData }))
);
const BuyPower = lazy(() =>
  import('./pages/billPayment').then((module) => ({ default: module.BuyPower }))
);
const BuyTv = lazy(() =>
  import('./pages/billPayment').then((module) => ({ default: module.BuyTv }))
);
const AirtimeTransactions = lazy(() =>
  import('./pages/billPayment').then((module) => ({
    default: module.AirtimeTransactions,
  }))
);
const DataSubTransactions = lazy(() =>
  import('./pages/billPayment').then((module) => ({
    default: module.DataSubTransactions,
  }))
);
const PowerPurchaseTransactions = lazy(() =>
  import('./pages/billPayment').then((module) => ({
    default: module.PowerPurchaseTransactions,
  }))
);
const CablePurchaseTransactions = lazy(() =>
  import('./pages/billPayment').then((module) => ({
    default: module.CablePurchaseTransactions,
  }))
);
const ViewEmployeesGroupDetails = lazy(() =>
  import('components/__new/groups/sub-components').then((module) => ({
    default: module.ViewEmployeesGroupDetails,
  }))
);
const PayrollOnboarding = lazy(() =>
  import('pages/payroll/onboarding/PayrollOnboarding').then((module) => ({
    default: module.PayrollOnboarding,
  }))
);
const RestrictedFeature = lazy(() =>
  import('pages/misc/RestrictedFeature').then((module) => ({
    default: module.RestrictedFeature,
  }))
);
const CreditApplicationSubmitted = lazy(() =>
  import('pages/credit/CreditApplicationSubmitted').then((module) => ({
    default: module.CreditApplicationSubmitted,
  }))
);

const Trade = lazy(() =>
  import('pages/trade').then((module) => ({
    default: module.Trade,
  }))
);

library.add(fas);

const ScrollToTopComponent = () => {
  const location = useLocation();
  const disableScrollPaths = ['/dashboard'];
  const shouldScrollToTop = !disableScrollPaths.includes(location?.pathname);

  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (shouldScrollToTop) {
        window.scrollTo(0, 0);
      }
    }
  }, [location, shouldScrollToTop]);

  return null;
};
export const ScrollToTop = withRouter(ScrollToTopComponent);

const RouteLayout = ({
  component: Component,
  layout: Layout,
  authUser,
  authRole,
  allowUnverifiedUsers,
  isOnDemandRoute,
  isPayrollRoute,
  isCreditRoute,
  isTradeRoute,
  isOnboardingLandingPage,
  ...rest
}) => {
  const {
    isOnboardingRequired,
    isOnDemandOnboardingRequired,
    isPayrollOnboardingRequired,
    isPayrollOdpCreditRestricted,
  } = useRouteAccess();
  // useGetUserLocation();
  const dispatch = useDispatch();
  const user = store.getState().auth.userPersist;
  const employer = store.getState().auth.account.employerInfo;

  useEffect(() => {
    if (
      employer?.data?.employer?.adminVerify &&
      !user?.data?.employer?.adminVerify
    ) {
      dispatch(updateLoginProfile(employer?.data));
    }
  }, [employer]);

  return (
    <Suspense fallback={<></>}>
      <Route
        {...rest}
        render={(props) => {
          const user_role = user?.data?.employer?.userType;
          const businessName =
            user?.data?.company?.businessName?.toLowerCase() || '';
          const isAdminVerified =
            employer?.data?.employer?.adminVerify ||
            user?.data?.employer?.adminVerify;

          if (authUser) {
            if (authUser === 'loggedIn') {
              if (user.loggedIn === true) {
                if (isAdminVerified && isOnboardingRequired === false) {
                  if (
                    (isPayrollRoute ||
                      isOnDemandRoute ||
                      isCreditRoute ||
                      isTradeRoute) &&
                    isPayrollOdpCreditRestricted
                  ) {
                    return (
                      <Layout>
                        <RestrictedFeature />
                      </Layout>
                    );
                  } else if (isPayrollRoute && isPayrollOnboardingRequired) {
                    return (
                      <Layout>
                        <PayrollOnboarding />
                      </Layout>
                    );
                  } else if (
                    isPayrollRoute &&
                    !ALLOWED_BUSINESS_NAMES_FOR_PAYROLL.map(
                      (companyBusinessName) => companyBusinessName.toLowerCase()
                    ).includes(businessName)
                  ) {
                    return <Redirect to="/dashboard" />;
                  } else if (
                    !isOnDemandRoute ||
                    (isOnDemandRoute && !isOnDemandOnboardingRequired)
                  ) {
                    if (authRole) {
                      if (authRole.includes(user_role)) {
                        return (
                          <Layout>
                            <Component {...props} />
                          </Layout>
                        );
                      } else {
                        // alert("Access denied due to user role");
                        // return <Redirect to="/401" />

                        return (
                          <Layout>
                            <Component {...props} />
                          </Layout>
                        );
                      }
                    } else {
                      // alert("no roles required, you only need to be logged in");
                      return (
                        <Layout>
                          <Component {...props} />
                        </Layout>
                      );
                    }
                  } else {
                    // alert("ondemand onboarding required");
                    return (
                      <Layout>
                        {/* <GetStartedInfoGeneral /> */}
                        <OnDemandCompliance />
                      </Layout>
                    );
                  }
                } else {
                  if (allowUnverifiedUsers || isOnboardingLandingPage) {
                    // alert("allow inactive user");
                    return (
                      <Layout>
                        <Component {...props} />
                      </Layout>
                    );
                  } else {
                    // alert("not yet approved by admin");
                    // return <Redirect to="/compliance" />
                    return (
                      <Redirect to="/compliance/onboarding" />
                      // <Redirect to="/compliance/business-details?redirect" />
                    );
                  }
                }
              } else {
                // alert("need to be logged in");
                window.location.href = '/login';
                return <Redirect to="/login" />;
              }
            } else if (authUser === 'loggedOut') {
              if (user.loggedIn === false) {
                return (
                  <Layout>
                    <Component {...props} />
                  </Layout>
                );
              } else if (!isAdminVerified || isOnboardingRequired) {
                return <Redirect to="/compliance/onboarding" />;
              } else {
                // alert("already logged in; need to be logged out to access");
                return <Redirect to="/dashboard" />;
              }
            }
          } else {
            // public page, no auth-yes or auth-no required
            return (
              <Layout>
                <Component {...props} />
              </Layout>
            );
          }
        }}
      />
    </Suspense>
  );
};

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/onboarding/verify-email"
          layout={AuthLayout}
          component={VerifyEmail}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/onboarding/create-business-password"
          layout={AuthLayout}
          component={CreateBusinessPassword}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/login"
          layout={AuthLayout}
          component={Login}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/login/two-factor-auth"
          layout={AuthLayout}
          component={VerifyTwoFactorAuth}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/onboarding/get-started"
          layout={AuthLayout}
          component={GetStarted}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/get-started/complete"
          layout={AuthLayout}
          component={GetStarted2}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/get-started/success"
          layout={AuthLayout}
          component={GetStartedSuccess}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/account-confirmation"
          layout={AuthLayout}
          component={ReConfirmAccount}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/account-confirmation/success"
          layout={AuthLayout}
          component={ReConfirmAccountSuccess}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/forgot-password"
          layout={AuthLayout}
          component={ForgotPassword}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/reset-password"
          layout={AuthLayout}
          component={ResetPassword}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/reset-password/success"
          layout={AuthLayout}
          component={ResetPasswordSuccess}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/create-your-password/:token"
          layout={AuthLayout}
          component={CreateYourPassword}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/create-your-password/member/success"
          layout={AuthLayout}
          component={CreateYourPasswordSuccess}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/reset-your-password/:token"
          layout={AuthLayout}
          component={ResetYourPassword}
        />
        <RouteLayout
          exact
          authUser="loggedOut"
          path="/reset-your-password/member/success"
          layout={AuthLayout}
          component={ResetYourPasswordSuccess}
        />
        <RouteLayout
          exact
          path="/account/confirmation/:id/:activationToken"
          layout={AuthLayout}
          component={VerifyAccountSuccess}
        />
        <RouteLayout
          exact
          path="/setwithdrawals/:payload"
          layout={AuthLayout}
          component={setWithdrawalsLimit}
        />
        <RouteLayout
          exact
          path="/ep-admin/login/:payload"
          layout={AuthLayout}
          component={earnipayAdminLogin}
        />

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer']}
          allowUnverifiedUsers
          path="/get-started/welcome"
          layout={EmployeeAdminLayout}
          component={GetStartedOverview}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer']}
          allowUnverifiedUsers
          path="/get-started/info/general"
          layout={EmployeeAdminLayout}
          component={GetStartedInfoGeneral}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer']}
          allowUnverifiedUsers
          path="/get-started/info/demo"
          layout={EmployeeAdminLayout}
          component={GetStartedDemo}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer']}
          allowUnverifiedUsers
          path="/get-started/info/financials"
          layout={EmployeeAdminLayout}
          component={GetStartedInfoFinancials}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer']}
          allowUnverifiedUsers
          path="/get-started/info/complete"
          layout={EmployeeAdminLayout}
          component={GetStartedInfoSuccess}
        />

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/get-started/compliance"
          layout={EmployeeAdminLayout}
          component={OnboardingComplianceIndex}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/get-started/compliance/bvn"
          layout={EmployeeAdminLayout}
          component={OnboardingComplianceBvn}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/get-started/compliance/bvn/success"
          layout={EmployeeAdminLayout}
          component={OnboardingComplianceBvnSuccess}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/get-started/compliance/cac"
          layout={EmployeeAdminLayout}
          component={OnboardingComplianceCac}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/get-started/compliance/cac/success"
          layout={EmployeeAdminLayout}
          component={OnboardingComplianceCacSuccess}
        />

        {/* New compliance - STARTS */}
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/onboarding"
          layout={EmployeeAdminLayout}
          component={ComplianceOnboarding}
        />

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/verification"
          layout={EmployeeAdminLayout}
          component={ComplianceVerification}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/business-information"
          layout={EmployeeAdminLayout}
          component={BusinessInformation}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/business-documents"
          layout={EmployeeAdminLayout}
          component={BusinessDocuments}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/directors-information"
          layout={EmployeeAdminLayout}
          component={DirectorsInformation}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/status"
          layout={EmployeeAdminLayout}
          component={ComplianceStatus}
        />

        {/* New compliance - ENDS */}

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/business-details"
          layout={EmployeeAdminLayout}
          component={ComplianceBusinessDetails}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/registered/verify-business"
          layout={EmployeeAdminLayout}
          component={ComplianceVerifyBusiness}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/registered/verify-director"
          layout={EmployeeAdminLayout}
          component={ComplianceVerifyDirector}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/registered/approval"
          layout={EmployeeAdminLayout}
          component={ComplianceApproval}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/registered/approval/success"
          layout={EmployeeAdminLayout}
          component={ComplianceApprovalSuccess}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/unregistered/verify-business"
          layout={EmployeeAdminLayout}
          component={ComplianceVerifyBusinessUnregistered}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/unregistered/verify-bvn"
          layout={EmployeeAdminLayout}
          component={ComplianceVerifyBvn}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/unregistered/verify-bvn-otp"
          layout={EmployeeAdminLayout}
          component={ComplianceVerifyBvnOtp}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/unregistered/approval"
          layout={EmployeeAdminLayout}
          component={ComplianceApproval}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/unregistered/approval/success"
          layout={EmployeeAdminLayout}
          component={ComplianceApprovalSuccess}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/approval/success"
          layout={EmployeeAdminLayout}
          component={ComplianceApprovalSuccess}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/compliance/approval/rejected"
          layout={EmployeeAdminLayout}
          component={ComplianceApprovalRejected}
        />

        {/* <RouteLayout exact authUser="loggedIn" authRole={["employer", "employer-admin"]} path="/account/add" layout={EmployeeAdminLayout} component={AddSubAccountIntro} /> */}
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/account/add/company"
          layout={EmployeeAdminLayout}
          component={AddSubAccountCompanyInfo}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/account/add/user"
          layout={EmployeeAdminLayout}
          component={AddSubAccountUserInfo}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/account/add/preview"
          layout={EmployeeAdminLayout}
          component={AddSubAccountPreview}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/financing-options"
          layout={EmployeeAdminLayout}
          component={FinancingOptions}
        />

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/dashboard"
          layout={EmployeeAdminLayout}
          component={Dashboard}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/dashboard/verify-fund-status"
          layout={EmployeeAdminLayout}
          component={AccountFundingStatus}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          isOnboardingLandingPage
          path="/people/employees"
          layout={EmployeeAdminLayout}
          component={employeesList}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/people/employees/ERA"
          layout={EmployeeAdminLayout}
          component={employeesListERA}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/people/employees/era/:id/transactions"
          layout={EmployeeAdminLayout}
          component={employeesListERATransactions}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnDemandRoute={true}
          path="/people/employees/accepted"
          layout={EmployeeAdminLayout}
          component={employeesListEnrolled}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnDemandRoute={true}
          isOnboardingLandingPage
          allowUnverifiedUsers
          path="/invited-employees"
          layout={EmployeeAdminLayout}
          component={invitedEmployeesList}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/people/employees/crm"
          layout={EmployeeAdminLayout}
          component={employeesListCRM}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/people/employees/add"
          layout={EmployeeAdminLayout}
          component={employeesCreate}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/people/employees/add/upload-result/:jobId"
          layout={EmployeeAdminLayout}
          component={employeesUploadResult}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/people/employees/edit/:id/:slug?"
          layout={EmployeeAdminLayout}
          component={employeesEdit}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          allowUnverifiedUsers
          path="/people/groups"
          layout={EmployeeAdminLayout}
          component={GroupsListing}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/people/groups/create"
          layout={EmployeeAdminLayout}
          component={CreateNewGroup}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/people/groups/edit/:id"
          layout={EmployeeAdminLayout}
          component={EditGroupDetails}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/people/groups/details/:id"
          layout={EmployeeAdminLayout}
          component={ViewGroupDetails}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/people/groups/group-details/:id"
          layout={EmployeeAdminLayout}
          component={ViewEmployeesGroupDetails}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnboardingLandingPage={true}
          isOnDemandRoute={true}
          path="/withdrawals"
          layout={EmployeeAdminLayout}
          component={withdrawalsList}
        />
        {/* <RouteLayout exact authUser="loggedIn" authRole={["employer", "employer-admin"]} isOnDemandRoute={true} path="/savings" layout={EmployeeAdminLayout} component={savingsRequestList} /> */}
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnDemandRoute={true}
          isOnboardingLandingPage
          allowUnverifiedUsers
          path="/reconciliation"
          layout={EmployeeAdminLayout}
          component={reconciliationList}
        />
        {/* <RouteLayout exact authUser="loggedIn" authRole={["employer", "employer-admin"]} isOnDemandRoute={true} path="/payment-notes" layout={EmployeeAdminLayout} component={PaymentNotesList} /> */}
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/transaction-history"
          layout={EmployeeAdminLayout}
          component={virtualAccount}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnDemandRoute={true}
          path="/payment-note"
          layout={EmployeeAdminLayout}
          component={paymentNote}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnDemandRoute={true}
          path="/payment-note/details/:invoiceId"
          layout={EmployeeAdminLayout}
          component={paymentDetails}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payment-history"
          layout={EmployeeAdminLayout}
          component={paymentHistory}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isCreditRoute={true}
          path="/credit"
          isOnboardingLandingPage={true}
          layout={EmployeeAdminLayout}
          component={CreditInfo}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isCreditRoute={true}
          isOnboardingLandingPage={true}
          path="/credit/application"
          layout={EmployeeAdminLayout}
          component={CreditApplication}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isCreditRoute={true}
          path="/credit/application-completed"
          layout={EmployeeAdminLayout}
          component={CreditApplicationCompleted}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isCreditRoute={true}
          path="/credit/application-processing"
          layout={EmployeeAdminLayout}
          component={CreditApplicationProcessing}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isCreditRoute={true}
          path="/credit/application-submitted"
          layout={EmployeeAdminLayout}
          component={CreditApplicationSubmitted}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isCreditRoute={true}
          path="/credit/application-not-eligible"
          layout={EmployeeAdminLayout}
          component={CreditApplicationNotEligible}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isCreditRoute={true}
          path="/credit/application-eligible"
          layout={EmployeeAdminLayout}
          component={CreditApplicationEligible}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnboardingLandingPage={true}
          path="/trade"
          isTradeRoute={true}
          layout={EmployeeAdminLayout}
          component={Trade}
        />

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnboardingLandingPage={true}
          path="/account/all-accounts"
          layout={EmployeeAdminLayout}
          component={PaymentsAccount}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/account/all-accounts/:walletId"
          layout={EmployeeAdminLayout}
          component={ViewSingleAccount}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnboardingLandingPage={true}
          path="/account/yield"
          layout={EmployeeAdminLayout}
          component={YieldList}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/wallet/balance"
          layout={EmployeeAdminLayout}
          component={WalletBalance}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/transactions"
          layout={EmployeeAdminLayout}
          component={WalletTransactions}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/wallet/fund"
          layout={EmployeeAdminLayout}
          component={WalletFund}
        />

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/transaction-history"
          isOnboardingLandingPage={true}
          layout={EmployeeAdminLayout}
          component={TransactionHistory}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/transfers/instant-transfers"
          isOnboardingLandingPage={true}
          layout={EmployeeAdminLayout}
          component={InstantTransfers}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/transfers/recurring-transfers"
          layout={EmployeeAdminLayout}
          component={RecurringTransfers}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/transfers/recurring-transfers/:id/history"
          layout={EmployeeAdminLayout}
          component={RecurringTransferHistory}
        />

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnboardingLandingPage={true}
          path="/payments/bills/airtime"
          layout={EmployeeAdminLayout}
          component={AirtimeTransactions}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/bills/data"
          layout={EmployeeAdminLayout}
          component={DataSubTransactions}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/bills/electricity"
          layout={EmployeeAdminLayout}
          component={PowerPurchaseTransactions}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/bills/cable-tv"
          layout={EmployeeAdminLayout}
          component={CablePurchaseTransactions}
        />

        {/* <RouteLayout exact authUser="loggedIn" authRole={["employer", "employer-admin"]} path="/payments/transfer" layout={EmployeeAdminLayout} component={WalletTransfer} /> */}
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnboardingLandingPage={true}
          path="/payments/beneficiaries"
          layout={EmployeeAdminLayout}
          component={TransferBeneficiaries}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnboardingLandingPage={true}
          path="/payments/beneficiaries/airtime-and-data"
          layout={EmployeeAdminLayout}
          component={AirtimeDataBeneficiaries}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/beneficiaries/electricity"
          layout={EmployeeAdminLayout}
          component={ElectricityBeneficiaries}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/beneficiaries/cable-tv"
          layout={EmployeeAdminLayout}
          component={CableBeneficiaries}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/beneficiaries/add"
          layout={EmployeeAdminLayout}
          component={WalletBeneficiaryForm}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/beneficiaries/bulk-upload"
          layout={EmployeeAdminLayout}
          component={BulkBeneficiaries}
        />

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/bill-payment"
          isOnboardingLandingPage={true}
          layout={EmployeeAdminLayout}
          component={BillPaymentOverview}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnboardingLandingPage={true}
          path="/payments/bills/airtime"
          layout={EmployeeAdminLayout}
          component={BuyAirtime}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/bills/data"
          layout={EmployeeAdminLayout}
          component={BuyData}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/bills/power"
          layout={EmployeeAdminLayout}
          component={BuyPower}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/payments/bills/tv"
          layout={EmployeeAdminLayout}
          component={BuyTv}
        />

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll"
          layout={EmployeeAdminLayout}
          component={PayrollIndex}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll/create"
          layout={EmployeeAdminLayout}
          component={PayrollCreate}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll/:id/run"
          layout={EmployeeAdminLayout}
          component={PayrollRun}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll/:id/finalize"
          layout={EmployeeAdminLayout}
          component={PayrollFinalize}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll/:id/pay-salary"
          layout={EmployeeAdminLayout}
          component={PayrollPaySalary}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll/:id/pay-remittance/:remittanceId"
          layout={EmployeeAdminLayout}
          component={PayrollPayRemittance}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll/history"
          layout={EmployeeAdminLayout}
          component={PayrollHistory}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll/history/:id"
          layout={EmployeeAdminLayout}
          component={PayrollHistoryDetails}
        />

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isOnboardingLandingPage={true}
          path="/approvals/dashboard"
          layout={EmployeeAdminLayout}
          component={ApprovalDashboardIndex}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/approvals/workflow"
          layout={EmployeeAdminLayout}
          component={ApprovalWorkflowIndex}
        />

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/pay-groups"
          layout={GrossPayrollLayout}
          component={PayGroups}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/pay-groups/create"
          layout={GrossPayrollLayout}
          component={CreatePayGroup}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/pay-groups/assign"
          layout={GrossPayrollLayout}
          component={PayGroupsAssignment}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/pay-groups/:id/view"
          layout={GrossPayrollLayout}
          component={ViewPayGroupDetails}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/pay-schedule"
          layout={GrossPayrollLayout}
          component={PaySchedule}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/pay-schedule/create"
          layout={GrossPayrollLayout}
          component={CreatePaySchedule}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/pay-schedule/:id/edit"
          layout={GrossPayrollLayout}
          component={EditPaySchedule}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/pay-structure"
          layout={GrossPayrollLayout}
          component={PayStructure}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/pay-structure/:id/view"
          layout={GrossPayrollLayout}
          component={ViewStructureDetails}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/pay-structure/create"
          layout={GrossPayrollLayout}
          component={CreatePayStructure}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/pay-structure/assign"
          layout={GrossPayrollLayout}
          component={PayStructureAssignment}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/benefits"
          layout={GrossPayrollLayout}
          component={BenefitsInKind}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/benefits/assign"
          layout={GrossPayrollLayout}
          component={BenefitAssignment}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/benefits/:id/view"
          layout={GrossPayrollLayout}
          component={ViewBenefitDetails}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/allowances"
          layout={GrossPayrollLayout}
          component={Allowances}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/allowances/assign"
          layout={GrossPayrollLayout}
          component={AllowanceAssignment}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/allowances/:id/view"
          layout={GrossPayrollLayout}
          component={ViewAllowanceDetails}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          isPayrollRoute={true}
          path="/payroll-configuration/remittance-parties"
          layout={GrossPayrollLayout}
          component={Remittances}
        />

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/audit-logs"
          layout={EmployeeAdminLayout}
          component={auditTrail}
        />
        {/* <RouteLayout exact authUser="loggedIn" authRole={["employer", "employer-admin"]} path="/approvals" layout={EmployeeAdminLayout} component={ApprovalDashboard} /> */}
        {/* <RouteLayout exact authUser="loggedIn" authRole={["employer", "employer-admin"]} path="/approvals/:id" layout={EmployeeAdminLayout} component={ApprovalDetails} /> */}
        {/* <RouteLayout exact authUser="loggedIn" authRole={["employer", "employer-admin"]} path="/approvals/:id/activity" layout={EmployeeAdminLayout} component={ApprovalActivity} /> */}

        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          // allowUnverifiedUsers
          path="/settings/profile"
          layout={EmployeeAdminLayout}
          component={settingsProfile}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/info"
          layout={EmployeeAdminLayout}
          component={settingsCompanyDetails}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/administrators"
          layout={EmployeeAdminLayout}
          component={settingsAdministrators}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer']}
          path="/settings/administrators/edit/:id"
          layout={EmployeeAdminLayout}
          component={settingsAdministratorsEdit}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/preferences"
          layout={EmployeeAdminLayout}
          component={settingsPreferences}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/team-management"
          layout={EmployeeAdminLayout}
          component={settingsTeamManagement}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/team-management/roles"
          layout={EmployeeAdminLayout}
          component={settingsTeamManagementRoles}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/team-management/roles/create/details"
          layout={EmployeeAdminLayout}
          component={settingsTeamManagementFormRoleDetails}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/team-management/roles/create/permissions"
          layout={EmployeeAdminLayout}
          component={settingsTeamManagementFormRolePermissions}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/team-management/roles/edit/:id/details"
          layout={EmployeeAdminLayout}
          component={settingsTeamManagementFormRoleDetails}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/team-management/roles/edit/:id/permissions"
          layout={EmployeeAdminLayout}
          component={settingsTeamManagementFormRolePermissions}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/approvals"
          layout={EmployeeAdminLayout}
          component={settingsApprovalsList}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/approvals/create"
          layout={EmployeeAdminLayout}
          component={settingsApprovalsForm}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/approvals/:id/edit"
          layout={EmployeeAdminLayout}
          component={settingsApprovalsForm}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/login-security"
          layout={EmployeeAdminLayout}
          component={settingsLoginAndSecurity}
        />
        {/* <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/notifications"
          layout={EmployeeAdminLayout}
          component={settingsNotifications}
        /> */}
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/ondemand-pay"
          layout={EmployeeAdminLayout}
          component={settingsOnDemandPay}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/transaction-limits"
          layout={EmployeeAdminLayout}
          component={settingsTransactionLimits}
        />
        {/* <RouteLayout exact authUser="loggedIn" authRole={["employer"]} path="/settings/integrations" layout={EmployeeAdminLayout} component={settingsIntegrations} /> */}
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer']}
          path="/settings/integrations"
          layout={EmployeeAdminLayout}
          component={integrationsFinch}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer']}
          path="/settings/integrations/bento"
          layout={EmployeeAdminLayout}
          component={integrationsBento}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer']}
          path="/settings/integrations/bento/manage"
          layout={EmployeeAdminLayout}
          component={integrationsBentoManage}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer']}
          path="/settings/integrations/finch"
          layout={EmployeeAdminLayout}
          component={integrationsFinch}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          authRole={['employer', 'employer-admin']}
          path="/settings/settlements"
          layout={EmployeeAdminLayout}
          component={settlements}
        />

        <RouteLayout
          exact
          path="/admin/verify/:id/:adminToken"
          layout={AuthLayout}
          component={settingsAdministratorsOnboarding}
        />
        <RouteLayout
          exact
          path="/admin/verify/success"
          layout={AuthLayout}
          component={settingsAdministratorsOnboardingSuccess}
        />
        <RouteLayout
          exact
          path="/admin/verify/:adminToken"
          layout={AuthLayout}
          component={subAccountAdminOnboarding}
        />

        <RouteLayout
          exact
          path="/temp/iframe-check"
          layout={NullLayout}
          component={XFrameOptions}
        />
        <RouteLayout
          exact
          path="/401"
          layout={NullLayout}
          component={Error401}
        />
        <RouteLayout
          exact
          path="/404"
          layout={NullLayout}
          component={Error404}
        />
        <RouteLayout
          exact
          authUser="loggedIn"
          path="/"
          layout={EmployeeAdminLayout}
          component={Dashboard}
        />

        <Redirect from="/compliance" to="/compliance/onboarding" />
        {/* <Redirect from="/compliance" to="/compliance/business-details" /> */}
        <Redirect
          from="/payments/transfers"
          to="/payments/transfers/instant-transfers"
        />
        <Redirect from="/payments/bills" to="/payments/bills/airtime" />
        {/* <Redirect from="/payments" to="/payments/transfer" /> */}
        <Redirect
          from="/payments/beneficiaries"
          to="/payments/beneficiaries/transfers"
        />
        <Redirect from="/wallet" to="/account/all-accounts" />
        {/* <Redirect from="/credit" to="/credit/credit-info" /> */}
        <Redirect from="/savings" to="/" />
        <Redirect
          from="/payroll-configuration"
          to="/payroll-configuration/pay-schedule"
        />
        <Redirect from="/account/profile" to="/settings/profile" />
        <Redirect from="/settings" to="/settings/profile" />
        <Redirect from="/approvals" to="/approvals/dashboard" />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
