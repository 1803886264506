import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customInfoToast } from "utils/customToast";

const initialState = {
    paymentNotesLoading: false,
    paymentNotes: {},
    
    paymentNotesDownloadLoading: false,
    paymentNotesDownload: {},

    paymentNoteLoading: false,
    paymentNote: {},

    invoicePDFLoading: false,
    invoicePDF: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_INVOICE:
            state = {
                ...state,
                paymentNotesLoading: false,
                paymentNotes: action.payload,
            }
            return state;


        case actions.GET_ALL_INVOICE_START:
            state = {
                ...state,
                paymentNotesLoading: true,
            }
            return state;


        case actions.GET_ALL_INVOICE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                paymentNotesLoading: false,
                paymentNotes: {},
            }
            return state;

        
        case actions.DOWNLOAD_PAYMENT_NOTES:
            state = {
                ...state,
                paymentNotesDownloadLoading: false,
                paymentNotesDownload: action.payload
            }
            return state;


        case actions.DOWNLOAD_PAYMENT_NOTES_START:
            customInfoToast({ message: "Downloading Data..." });
            state = {
                ...state,
                paymentNotesDownloadLoading: true,
            }
            return state;


        case actions.DOWNLOAD_PAYMENT_NOTES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                paymentNotesDownloadLoading: false,
                paymentNotesDownload: {},
            }
            return state;
        

        case actions.GET_INVOICE:
            state = {
                ...state,
                paymentNoteLoading: false,
                paymentNote: action.payload,
            }
            return state;


        case actions.GET_INVOICE_START:
            state = {
                ...state,
                paymentNoteLoading: true,
                paymentNote: {},
            }
            return state;


        case actions.GET_INVOICE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                paymentNoteLoading: false,
                paymentNote: {},
            }
            return state;


        case actions.DOWNLOAD_INVOICE_PDF:
            // console.log(action.payload.pdf);
            state = {
                ...state,
                invoicePDFLoading: false,
                invoicePDF: action.payload,
            }
            return state;


        case actions.DOWNLOAD_INVOICE_PDF_START:
            state = {
                ...state,
                invoicePDFLoading: true,
            }
            return state;


        case actions.DOWNLOAD_INVOICE_PDF_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                invoicePDFLoading: false,
                invoicePDF: {},
            }
            return state;


        default:
            return state;
    }
}