
export const GET_INFO = "GET_INFO";
export const GET_INFO_START = "GET_INFO_START";
export const GET_INFO_FAILED = "GET_INFO_FAILED";

export const UPDATE_LEGAL_INFO = "UPDATE_LEGAL_INFO";
export const UPDATE_LEGAL_INFO_SILENT = "UPDATE_LEGAL_INFO_SILENT";
export const UPDATE_LEGAL_INFO_START = "UPDATE_LEGAL_INFO_START";
export const UPDATE_LEGAL_INFO_FAILED = "UPDATE_LEGAL_INFO_FAILED";
export const UPDATE_LEGAL_INFO_RESET = "UPDATE_LEGAL_INFO_RESET";

export const UPDATE_POLICY_INFO = "UPDATE_POLICY_INFO";
export const UPDATE_POLICY_INFO_SILENT = "UPDATE_POLICY_INFO_SILENT";
export const UPDATE_POLICY_INFO_START = "UPDATE_POLICY_INFO_START";
export const UPDATE_POLICY_INFO_FAILED = "UPDATE_POLICY_INFO_FAILED";
export const UPDATE_POLICY_INFO_RESET = "UPDATE_POLICY_INFO_RESET";

export const UPDATE_COMPANY_SETTINGS = "UPDATE_COMPANY_SETTINGS";
export const UPDATE_COMPANY_SETTINGS_START = "UPDATE_COMPANY_SETTINGS_START";
export const UPDATE_COMPANY_SETTINGS_FAILED = "UPDATE_COMPANY_SETTINGS_FAILED";

export const CREATE_BULK_EMPLOYEE_EVA = "CREATE_BULK_EMPLOYEE_EVA";
export const CREATE_BULK_EMPLOYEE_EVA_START = "CREATE_BULK_EMPLOYEE_EVA_START";
export const CREATE_BULK_EMPLOYEE_EVA_FAILED = "CREATE_BULK_EMPLOYEE_EVA_FAILED";

export const TOGGLE_PAYROLL = "TOGGLE_PAYROLL";
export const TOGGLE_PAYROLL_START = "TOGGLE_PAYROLL_START";
export const TOGGLE_PAYROLL_FAILED = "TOGGLE_PAYROLL_FAILED";

export const TOGGLE_EMPLOYEE_EVA_OFF = "TOGGLE_EMPLOYEE_EVA_OFF";
export const TOGGLE_EMPLOYEE_EVA_OFF_START = "TOGGLE_EMPLOYEE_EVA_OFF_START";
export const TOGGLE_EMPLOYEE_EVA_OFF_FAILED = "TOGGLE_EMPLOYEE_EVA_OFF_FAILED";

export const UPDATE_BANK_STATEMENT = "UPDATE_BANK_STATEMENT";
export const UPDATE_BANK_STATEMENT_START = "UPDATE_BANK_STATEMENT_START";
export const UPDATE_BANK_STATEMENT_FAILED = "UPDATE_BANK_STATEMENT_FAILED";

export const GET_COMPANY_FEES = "GET_COMPANY_FEES";
export const GET_COMPANY_FEES_START = "GET_COMPANY_FEES_START";
export const GET_COMPANY_FEES_FAILED = "GET_COMPANY_FEES_FAILED";

export const GET_ODP_CONFIGURATION = "GET_ODP_CONFIGURATION";
export const GET_ODP_CONFIGURATION_START = "GET_ODP_CONFIGURATION_START";
export const GET_ODP_CONFIGURATION_FAILED = "GET_ODP_CONFIGURATION_FAILED";

export const UPDATE_ODP_CONFIGURATION = "UPDATE_ODP_CONFIGURATION";
export const UPDATE_ODP_CONFIGURATION_START = "UPDATE_ODP_CONFIGURATION_START";
export const UPDATE_ODP_CONFIGURATION_FAILED = "UPDATE_ODP_CONFIGURATION_FAILED";
export const UPDATE_ODP_CONFIGURATION_RESET = "UPDATE_ODP_CONFIGURATION_RESET";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";

export const UPLOAD_PROFILE_IMAGE = "UPLOAD_PROFILE_IMAGE";
export const UPLOAD_PROFILE_IMAGE_START = "UPLOAD_PROFILE_IMAGE_START";
export const UPLOAD_PROFILE_IMAGE_FAILED = "UPLOAD_PROFILE_IMAGE_FAILED";

export const REMOVE_PROFILE_IMAGE = "REMOVE_PROFILE_IMAGE";
export const REMOVE_PROFILE_IMAGE_START = "REMOVE_PROFILE_IMAGE_START";
export const REMOVE_PROFILE_IMAGE_FAILED = "REMOVE_PROFILE_IMAGE_FAILED";

export const UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO";
export const UPDATE_PERSONAL_INFO_START = "UPDATE_PERSONAL_INFO_START";
export const UPDATE_PERSONAL_INFO_FAILED = "UPDATE_PERSONAL_INFO_FAILED";


export const UPLOAD_COMPANY_LOGO = "UPLOAD_COMPANY_LOGO";
export const UPLOAD_COMPANY_LOGO_START = "UPLOAD_COMPANY_LOGO_START";
export const UPLOAD_COMPANY_LOGO_FAILED = "UPLOAD_COMPANY_LOGO_FAILED";
export const UPLOAD_COMPANY_LOGO_RESET = "UPLOAD_COMPANY_LOGO_RESET";

export const UPDATE_COMPANY_INFO = "UPDATE_COMPANY_INFO";
export const UPDATE_COMPANY_INFO_START = "UPDATE_COMPANY_INFO_START";
export const UPDATE_COMPANY_INFO_FAILED = "UPDATE_COMPANY_INFO_FAILED";
export const UPDATE_COMPANY_INFO_RESET = "UPDATE_COMPANY_INFO_RESET";

export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_MEMBERS_START = 'GET_MEMBERS_START';
export const GET_MEMBERS_FAILED = 'GET_MEMBERS_FAILED';

export const INVITE_MEMBER = 'INVITE_MEMBER';
export const INVITE_MEMBER_START = 'INVITE_MEMBER_START';
export const INVITE_MEMBER_FAILED = 'INVITE_MEMBER_FAILED';

export const ACCEPT_INVITE = 'ACCEPT_INVITE';
export const ACCEPT_INVITE_START = 'ACCEPT_INVITE_START';
export const ACCEPT_INVITE_FAILED = 'ACCEPT_INVITE_FAILED';

export const RESEND_MEMBER_INVITE = 'RESEND_MEMBER_INVITE';
export const RESEND_MEMBER_INVITE_START = 'RESEND_MEMBER_INVITE_START';
export const RESEND_MEMBER_INVITE_FAILED = 'RESEND_MEMBER_INVITE_FAILED';

export const TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP';
export const TRANSFER_OWNERSHIP_START = 'TRANSFER_OWNERSHIP_START';
export const TRANSFER_OWNERSHIP_FAILED = 'TRANSFER_OWNERSHIP_FAILED';

export const EDIT_TEAM_MEMBER = 'EDIT_TEAM_MEMBER';
export const EDIT_TEAM_MEMBER_START = 'EDIT_TEAM_MEMBER_START';
export const EDIT_TEAM_MEMBER_FAILED = 'EDIT_TEAM_MEMBER_FAILED';

export const DEACTIVATE_MEMBER = 'DEACTIVATE_MEMBER';
export const DEACTIVATE_MEMBER_START = 'DEACTIVATE_MEMBER_START';
export const DEACTIVATE_MEMBER_FAILED = 'DEACTIVATE_MEMBER_FAILED';

export const FORGOT_PASSWORD_MEMBER = 'FORGOT_PASSWORD_MEMBER';
export const FORGOT_PASSWORD_MEMBER_START = 'FORGOT_PASSWORD_MEMBER_START';
export const FORGOT_PASSWORD_MEMBER_FAILED = 'FORGOT_PASSWORD_MEMBER_FAILED';
export const FORGOT_PASSWORD_MEMBER_STATE_RESET = 'FORGOT_PASSWORD_MEMBER_STATE_RESET';

export const RESET_PASSWORD_MEMBER = 'RESET_PASSWORD_MEMBER';
export const RESET_PASSWORD_MEMBER_START = 'RESET_PASSWORD_MEMBER_START';
export const RESET_PASSWORD_MEMBER_FAILED = 'RESET_PASSWORD_MEMBER_FAILED';

export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_START = "GET_ROLES_START";
export const GET_ROLES_FAILED = "GET_ROLES_FAILED";

export const CREATE_CUSTOM_ROLE = "CREATE_CUSTOM_ROLE";
export const CREATE_CUSTOM_ROLE_START = "CREATE_CUSTOM_ROLE_START";
export const CREATE_CUSTOM_ROLE_FAILED = "CREATE_CUSTOM_ROLE_FAILED";

export const EDIT_ROLE = "EDIT_ROLE";
export const EDIT_ROLE_START = "EDIT_ROLE_START";
export const EDIT_ROLE_FAILED = "EDIT_ROLE_FAILED";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_START = "DELETE_ROLE_START";
export const DELETE_ROLE_FAILED = "DELETE_ROLE_FAILED";

export const GET_PERMISSION = "GET_PERMISSION";
export const GET_PERMISSION_START = "GET_PERMISSION_START";
export const GET_PERMISSION_FAILED = "GET_PERMISSION_FAILED";

export const GET_ROLE_PERMISSION = "GET_ROLE_PERMISSION";
export const GET_ROLE_PERMISSION_START = "GET_ROLE_PERMISSION_START";
export const GET_ROLE_PERMISSION_FAILED = "GET_ROLE_PERMISSION_FAILED";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const CHANGE_TRANSACTION_PIN = "CHANGE_TRANSACTION_PIN";
export const CHANGE_TRANSACTION_PIN_START = "CHANGE_TRANSACTION_PIN_START";
export const CHANGE_TRANSACTION_PIN_FAILED = "CHANGE_TRANSACTION_PIN_FAILED";

export const CHANGE_TWO_FACTOR_AUTH_STATUS = "CHANGE_TWO_FACTOR_AUTH_STATUS";
export const CHANGE_TWO_FACTOR_AUTH_STATUS_START = "CHANGE_TWO_FACTOR_AUTH_STATUS_START";
export const CHANGE_TWO_FACTOR_AUTH_STATUS_FAILED = "CHANGE_TWO_FACTOR_AUTH_STATUS_FAILED";
export const CHANGE_TWO_FACTOR_AUTH_STATUS_RESET = "CHANGE_TWO_FACTOR_AUTH_STATUS_RESET";

export const SEND_TRANSACTION_PIN_OTP = "SEND_TRANSACTION_PIN_OTP";
export const SEND_TRANSACTION_PIN_OTP_START = "SEND_TRANSACTION_PIN_OTP_START";
export const SEND_TRANSACTION_PIN_OTP_FAILED = "SEND_TRANSACTION_PIN_OTP_FAILED";

export const UPDATE_DAILY_TRANSACTION_LIMIT_START = "UPDATE_DAILY_TRANSACTION_LIMIT_START"
export const UPDATE_DAILY_TRANSACTION_LIMIT = "UPDATE_DAILY_TRANSACTION_LIMIT"
export const UPDATE_DAILY_TRANSACTION_LIMIT_FAILED = "UPDATE_DAILY_TRANSACTION_LIMIT_FAILED"
export const UPDATE_DAILY_TRANSACTION_LIMIT_RESET = "UPDATE_DAILY_TRANSACTION_LIMIT_FAILED"

export const UPDATE_SINGLE_TRANSACTION_LIMIT_START = "UPDATE_SINGLE_TRANSACTION_LIMIT_START"
export const UPDATE_SINGLE_TRANSACTION_LIMIT = "UPDATE_SINGLE_TRANSACTION_LIMIT"
export const UPDATE_SINGLE_TRANSACTION_LIMIT_FAILED = "UPDATE_SINGLE_TRANSACTION_LIMIT_FAILED"
export const UPDATE_SINGLE_TRANSACTION_LIMIT_RESET = "UPDATE_SINGLE_TRANSACTION_LIMIT_RESET"

export const GET_TRANSACTION_LIMIT_START = "GET_TRANSACTION_LIMIT_START"
export const GET_TRANSACTION_LIMIT = "GET_TRANSACTION_LIMIT"
export const GET_TRANSACTION_LIMIT_FAILED = "GET_TRANSACTION_LIMIT_FAILED"

export const GET_DEFAULT_TRANSACTION_LIMIT_START = "GET_DEFAULT_TRANSACTION_LIMIT_START"
export const GET_DEFAULT_TRANSACTION_LIMIT = "GET_DEFAULT_TRANSACTION_LIMIT"
export const GET_DEFAULT_TRANSACTION_LIMIT_FAILED = "GET_DEFAULT_TRANSACTION_LIMIT_FAILED"
export const GET_DEFAULT_TRANSACTION_LIMIT_RESET = "GET_DEFAULT_TRANSACTION_LIMIT_RESET"