import { CustomToast } from 'components/__new/customToast/CustomToast';
import { toast } from 'react-toastify';

const defaultToastOption = {
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: false,
};

export const customErrorToast = (
    { message, title, isLoading },
    customToastOption
) => {
    const toastOption = customToastOption ? customToastOption : {};
    toast(
        <CustomToast
            type="error"
            title={title}
            message={message}
            isLoading={isLoading}
        />,
        { ...defaultToastOption, ...toastOption }
    );
};

export const customSuccessToast = (
    { message, title, isLoading },
    customToastOption
) => {
    const toastOption = customToastOption ? customToastOption : {};
    toast(
        <CustomToast
            type="success"
            title={title}
            message={message}
            isLoading={isLoading}
        />,
        { ...defaultToastOption, ...toastOption }
    );
};

export const customWarningToast = (
    { message, title, isLoading },
    customToastOption
) => {
    const toastOption = customToastOption ? customToastOption : {};
    toast(
        <CustomToast
            type="warning"
            title={title}
            message={message}
            isLoading={isLoading}
        />,
        { ...defaultToastOption, ...toastOption }
    );
};

export const customInfoToast = (
    { message, title, isLoading },
    customToastOption
) => {
    const toastOption = customToastOption ? customToastOption : {};
    toast(
        <CustomToast
            type="info"
            title={title}
            message={message}
            isLoading={isLoading}
        />,
        { ...defaultToastOption, ...toastOption }
    );
};
