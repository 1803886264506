import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customInfoToast, customSuccessToast } from "utils/customToast";
const initialState = {
    loading: false,
    allPayroll: {},
    payrollMetricsLoading: false,
    payrollMetrics: {},

    downloadLoading: false,
    downloadAllPayroll: {},

    currentPayrollLoading: false,
    currentPayroll: {},
    currentPayrollBulkLoading: false,
    currentPayrollBulk: [],

    payrollDashboardInfoLoading: false,
    payrollDashboardInfo: {},
    payrollStatusLoading: false,
    payrollStatus: {},
    payrollTransactionsLoading: false,
    payrollTransactions: {},
    downloadPayrollTransactionsLoading: false,
    downloadPayrollTransactions: {},
    payrollCompletedTransactionsLoading: false,
    payrollCompletedTransactions: {},
    payrollByIdLoading: false,
    payrollById: {},

    payrollsLoading: false,
    payrolls: {},
    downloadPayrollsLoading: false,
    downloadPayrolls: {},

    payrollScheduleLoading: false,
    payrollSchedule: {},
    payrollRunLoading: false,
    payrollRun: {},
    payrollRetryLoading: false,
    payrollRetrySuccess: {},

    updatePayrollTransactionLoading: false,
    updatePayrollTransactionSuccess: [],
    updatePayrollTransactionFailed: [],
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_PAYROLL_METRICS:
            state = {
                ...state,
                payrollMetricsLoading: false,
                payrollMetrics: action.payload,
            }
            return state;


        case actions.GET_PAYROLL_METRICS_START:
            state = {
                ...state,
                payrollMetricsLoading: true,
            }
            return state;


        case actions.GET_PAYROLL_METRICS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollMetricsLoading: false,
                payrollMetrics: {},
            }
            return state;


        case actions.GET_ALL_PAYROLL:
            state = {
                ...state,
                loading: false,
                allPayroll: action.payload,
            }
            return state;


        case actions.GET_ALL_PAYROLL_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_ALL_PAYROLL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                loading: false,
                allPayroll: {},
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_PAYROLL:
            state = {
                ...state,
                downloadLoading: false,
                downloadAllPayroll: { ...action.payload.payrolls }
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_PAYROLL_START:
            customInfoToast({ message: "Downloading Data..." })
            state = {
                ...state,
                downloadLoading: true,
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_PAYROLL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                downloadLoading: false,
                downloadAllPayroll: {},
            }
            return state;


        case actions.GET_FETCH_PAYROLLS:
            state = {
                ...state,
                payrollsLoading: false,
                payrolls: { ...action.payload }
            }
            return state;


        case actions.GET_FETCH_PAYROLLS_START:
            state = {
                ...state,
                payrollsLoading: true,
            }
            return state;


        case actions.GET_FETCH_PAYROLLS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollsLoading: false,
                payrolls: {},
            }
            return state;


        case actions.DOWNLOAD_FETCH_PAYROLLS:
            state = {
                ...state,
                downloadPayrollsLoading: false,
                downloadPayrolls: { ...action.payload.payrolls }
            }
            return state;


        case actions.DOWNLOAD_FETCH_PAYROLLS_START:
            customInfoToast({ message: "Downloading Data..." })
            state = {
                ...state,
                downloadPayrollsLoading: true,
            }
            return state;


        case actions.DOWNLOAD_FETCH_PAYROLLS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                downloadPayrollsLoading: false,
                downloadPayrolls: {},
            }
            return state;


        case actions.GET_CURRENT_PAYROLL:
            state = {
                ...state,
                currentPayrollLoading: false,
                currentPayroll: { ...action.payload }
            }
            return state;


        case actions.GET_CURRENT_PAYROLL_START:
            state = {
                ...state,
                currentPayrollLoading: true,
            }
            return state;


        case actions.GET_CURRENT_PAYROLL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                currentPayrollLoading: false,
                currentPayroll: {},
            }
            return state;


        case actions.GET_CURRENT_PAYROLL_BULK:
            state = {
                ...state,
                currentPayrollBulkLoading: false,
                currentPayrollBulk: [
                    ...state.currentPayrollBulk,
                    { ...action.payload.data },
                ],
            }
            return state;


        case actions.GET_CURRENT_PAYROLL_BULK_START:
            state = {
                ...state,
                currentPayrollBulkLoading: true,
                currentPayrollBulk: [],
            }
            return state;


        case actions.GET_CURRENT_PAYROLL_BULK_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                currentPayrollBulkLoading: false,
                /*
                currentPayrollBulk: [
                    ...state.currentPayrollBulk,
                    Date.now(),
                ],
                */
            }
            return state;


        case actions.GET_PAYROLL_DASHBOARD_INFO:
            state = {
                ...state,
                payrollDashboardInfoLoading: false,
                payrollDashboardInfo: { ...action.payload.data }
            }
            return state;


        case actions.GET_PAYROLL_DASHBOARD_INFO_START:
            state = {
                ...state,
                payrollDashboardInfoLoading: true,
            }
            return state;


        case actions.GET_PAYROLL_DASHBOARD_INFO_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollDashboardInfoLoading: false,
                payrollDashboardInfo: {},
            }
            return state;


        case actions.GET_PAYROLL_STATUS:
            state = {
                ...state,
                payrollStatusLoading: false,
                payrollStatus: { ...action.payload.data }
            }
            return state;


        case actions.GET_PAYROLL_STATUS_START:
            state = {
                ...state,
                payrollStatusLoading: true,
            }
            return state;


        case actions.GET_PAYROLL_STATUS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollStatusLoading: false,
                payrollStatus: {},
            }
            return state;


        case actions.GET_PAYROLL_BY_ID:
            state = {
                ...state,
                payrollByIdLoading: false,
                payrollById: { ...action.payload.data }
            }
            return state;


        case actions.GET_PAYROLL_BY_ID_START:
            state = {
                ...state,
                payrollByIdLoading: true,
            }
            return state;


        case actions.GET_PAYROLL_BY_ID_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollByIdLoading: false,
                payrollById: {},
            }
            return state;


        case actions.GET_PAYROLL_TRANSACTIONS:
            state = {
                ...state,
                payrollTransactionsLoading: false,
                payrollTransactions: { ...action.payload.data }
            }
            return state;


        case actions.GET_PAYROLL_TRANSACTIONS_START:
            state = {
                ...state,
                payrollTransactionsLoading: true,
            }
            return state;


        case actions.GET_PAYROLL_TRANSACTIONS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollTransactionsLoading: false,
                payrollTransactions: {},
            }
            return state;



        case actions.DOWNLOAD_PAYROLL_TRANSACTIONS:
            state = {
                ...state,
                downloadPayrollTransactionsLoading: false,
                downloadPayrollTransactions: { ...action.payload.data }
            }
            return state;


        case actions.DOWNLOAD_PAYROLL_TRANSACTIONS_START:
            state = {
                ...state,
                downloadPayrollTransactionsLoading: true,
            }
            return state;


        case actions.DOWNLOAD_PAYROLL_TRANSACTIONS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                downloadPayrollTransactionsLoading: false,
                downloadPayrollTransactions: {},
            }
            return state;



        case actions.GET_COMPLETED_PAYROLL_TRANSACTIONS:
            state = {
                ...state,
                payrollCompletedTransactionsLoading: false,
                payrollCompletedTransactions: { ...action.payload.data }
            }
            return state;


        case actions.GET_COMPLETED_PAYROLL_TRANSACTIONS_START:
            state = {
                ...state,
                payrollCompletedTransactionsLoading: true,
            }
            return state;


        case actions.GET_COMPLETED_PAYROLL_TRANSACTIONS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollCompletedTransactionsLoading: false,
                payrollCompletedTransactions: {},
            }
            return state;



        case actions.SCHEDULE_PAYROLL:
            state = {
                ...state,
                payrollScheduleLoading: false,
                payrollSchedule: { ...action.payload.data },
            }
            return state;


        case actions.SCHEDULE_PAYROLL_START:
            state = {
                ...state,
                payrollScheduleLoading: true,
            }
            return state;


        case actions.SCHEDULE_PAYROLL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollScheduleLoading: false,
                payrollSchedule: {},
            }
            return state;


        case actions.RUN_PAYROLL:
            state = {
                ...state,
                payrollRunLoading: false,
                payrollRun: { ...action.payload.data }
            }
            return state;


        case actions.RUN_PAYROLL_START:
            state = {
                ...state,
                payrollRunLoading: true,
            }
            return state;


        case actions.RUN_PAYROLL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollRunLoading: false,
                payrollRun: {},
            }
            return state;


        case actions.UPDATE_PAYROLL_TRANSACTION_START:
            // alert("start here");
            state = {
                ...state,
                updatePayrollTransactionLoading: true,
                updatePayrollTransactionSuccess: [],
                updatePayrollTransactionFailed: [],
            }
            return state;


        case actions.UPDATE_PAYROLL_TRANSACTION_SUCCESS:
            // alert("success");
            state = {
                ...state,
                updatePayrollTransactionSuccess: [
                    ...state.updatePayrollTransactionSuccess,
                    { ...action.payload.data },
                ],
            }
            return state;


        case actions.UPDATE_PAYROLL_TRANSACTION_FAILED:
            // alert("fail");
            state = {
                ...state,
                updatePayrollTransactionFailed: [
                    ...state.updatePayrollTransactionFailed,
                    { ...action.payload.data },
                ],
            }
            return state;


        case actions.UPDATE_PAYROLL_TRANSACTION_RESET:
            // alert("reset here");
            state = {
                ...state,
                updatePayrollTransactionLoading: false,
            }
            return state;

    
        case actions.RETRY_PAYROLL:
            customSuccessToast({ message: action.payload.message })
            state = {
                ...state,
                payrollRetryLoading: false,
                payrollRetrySuccess: { ...action.payload }
            }
            return state;


        case actions.RETRY_PAYROLL_START:
            state = {
                ...state,
                payrollRetryLoading: true,
            }
            return state;


        case actions.RETRY_PAYROLL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollRetryLoading: false,
                payrollRetrySuccess: {},
            }
            return state;
    

        default:
            return state;
    
    }
}