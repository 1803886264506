import * as actions from "./actionTypes";
import * as config from "config";


export function updateComplianceProgress(params) {
    return {
        type: actions.UPDATE_COMPLIANCE_PROGRESS,
        payload: params,
    }
}

export function saveComplianceInfo(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiDummyBaseUrl,
            url: "",
            method: "post",
            data: params,
            onStart: actions.SAVE_COMPLIANCE_INFO_START,
            onSuccess: actions.SAVE_COMPLIANCE_INFO,
            onError: actions.SAVE_COMPLIANCE_INFO_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function resetSaveComplianceInfo() {
    return {
        type: actions.SAVE_COMPLIANCE_INFO_RESET,
        payload: {},
    }
}

export function saveBvnInfo(params) {
    return {
        type: actions.UPDATE_BVN,
        payload: params,
    }
}

export function saveComplianceBusinessType(params) {
    return {
        type: actions.UPDATE_COMPLIANCE_BUSINESS_TYPE,
        payload: params,
    }
}

export function addSocials(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiAddSocials,
            method: "post",
            data: params,
            onStart: actions.ADD_SOCIALS_START,
            onSuccess: actions.ADD_SOCIALS,
            onError: actions.ADD_SOCIALS_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function resetAddSocials() {
    return {
        type: actions.ADD_SOCIALS_RESET,
        payload: {},
    }
}

export function addDirectors(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiAddDirector,
            method: "post",
            data: params,
            onStart: actions.ADD_DIRECTORS_START,
            onSuccess: actions.ADD_DIRECTORS,
            onError: actions.ADD_DIRECTORS_FAILED,
        },
    }
}

export function resetAddDirectors() {
    return {
        type: actions.ADD_DIRECTORS_RESET,
        payload: {},
    }
}

export function startUpdateDirectors() {
    return {
        type: actions.UPDATE_DIRECTORS_START,
        payload: {},
    }
}

export function updateDirectors(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateDirector + id,
            method: "patch",
            data: params,
            // onStart: actions.UPDATE_DIRECTORS_START,
            onSuccess: actions.UPDATE_DIRECTORS,
            onError: actions.UPDATE_DIRECTORS_FAILED,
        },
    }
}

export function resetUpdateDirectors() {
    return {
        type: actions.UPDATE_DIRECTORS_RESET,
        payload: {},
    }
}

export function deleteDirectors(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiDeleteDirector + id,
            method: "delete",
            data: {},
            onStart: actions.DELETE_DIRECTORS_START,
            onSuccess: actions.DELETE_DIRECTORS,
            onError: actions.DELETE_DIRECTORS_FAILED,
        },
    }
}

export function resetDeleteDirectors() {
    return {
        type: actions.DELETE_DIRECTORS_RESET,
        payload: {},
    }
}

export function validateBvnAndNin(payload) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiValidateBvnAndNin,
            method: "post",
            data: payload,
            onStart: actions.VALIDATE_BVN_AND_NIN_START,
            onSuccess: actions.VALIDATE_BVN_AND_NIN,
            onError: actions.VALIDATE_BVN_AND_NIN_FAILED,
        },
    }
}

export function resetBvnAndNinResult() {
    return {
        type: actions.VALIDATE_BVN_AND_NIN_RESET,
        payload: {},
    }
}
