import { Dialog } from '@headlessui/react';
import Maybe from "./Maybe";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";


export function FormDrawer(props) {
    const { isOpen, setIsOpen, children, size, display, isBackdropBlur, closeDialog, backdropClickable, dialogPanelClassName } = props;
    let sizeRender = size || "lg";
    let backdropBlur = isBackdropBlur === true ? "backdrop-blur-[3px]" : "";
    let isBackdropClickable = backdropClickable ?? true;
    
    if (display && display === "center"){
        return (
            <>        
                {(isOpen === true) &&
                    <Dialog open={isOpen} onClose={() => isBackdropClickable && setIsOpen(false)} className="relative z-50">

                        {/* backdrop */}
                        <div className={`fixed inset-0 bg-black/40 ${backdropBlur}`} aria-hidden="true" />

                        <div className="fixed inset-0 w-screen overflow-y-auto"> {/* Full-screen scrollable container */}
                            <div className="flex min-h-full items-center justify-center py-8"> {/* Container to center the panel */}
                                <Dialog.Panel className={`w-full mx-auto max-w-${sizeRender} form-drawer-panel bg-white rounded-xl shadow-xl ${dialogPanelClassName}`}>
                                    {children}
                                </Dialog.Panel>
                            </div>
                        </div>

                    </Dialog>
                }
            </>
        )
    }
    
    if (display === undefined || display === "right"){
        const displayClassBackdrop = "fixed overflow-auto z-50 bg-gray-900 bg-opacity-25 inset-0 transform " + (isOpen ? "transition-opacity opacity-100 translate-x-0": "opacity-0 translate-x-full");
        const displayClassModal = "w-screen max-w-"+(sizeRender)+" right-0 absolute bg-white h-full shadow-xl transform  " + (isOpen ? "translate-x-0 " : "translate-x-full");
        const displayClassModalContent = "relative w-screen max-w-"+(sizeRender)+" flex flex-col space-y-6 overflow-y-scroll h-full";
    
        return (
            <div className={displayClassBackdrop}>
              <div className={displayClassModal}>
                <div className={displayClassModalContent}>
                  {children}
                </div>
              </div>
        
              <Maybe condition={isBackdropClickable === true}>
                <Maybe condition={closeDialog !== undefined}>
                  <div
                    className=" w-screen h-full "
                    onClick={() => {
                      closeDialog();
                    }}
                  ></div>
                </Maybe>
          
                <Maybe condition={closeDialog === undefined}>
                  <div
                    className=" w-screen h-full "
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  ></div>
                </Maybe>
              </Maybe>
        
            </div>
          );
    }
}


const FormDrawerTitle = (props) => {
    const {title, description, customDescriptionStyles, showCloseButton = true, close, headerBorderBottomStyles, titleClassName} = props;
    
    return (
        <div className={`flex justify-between ${titleClassName}`}>
            <div style={{ ...headerBorderBottomStyles }}>
                <div className="page-title">
                    {title}
                </div>
                <div className="mt-2 text-ep-gray-600 page-description" style={{ ...customDescriptionStyles }}>
                    {description}
                </div>
            </div>
            <div>
                <Maybe condition={showCloseButton}>
                    <div onClick={() => close()} className="w-8 h-8 p-1 flex rounded-sm bg-[#F4F4F4] hover:bg-gray-300 cursor-pointer overflow-hidden">
                        <Xclose className="m-auto" />
                    </div>
                </Maybe>
            </div>
        </div>
    )
}


FormDrawer.Title = FormDrawerTitle;
export default FormDrawer;