import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import { Link, NavLink, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import moment from 'moment';
import "./header.css"

import * as dashboardActions from "store/entities/dashboard/action";
import * as walletAction from "store/entities/wallet/action";
import * as notificationAction from "store/entities/notification/action";
import * as accountActions from "store/auth/account/action";

import { nameInitials, getBusinessInitials } from 'utils/nameInitials.jsx';
import useLoginInfo from "hooks/useLoginInfo";
import Maybe from "components/__new/common/Maybe";
import CurrencyFormat from "utils/currencyFormat";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubAccountSwitch from "components/__new/subAccounts/SubAccountSwitch";
import NavigationLinks from "components/__new/general/NavigationLinks";
import iconFinance from "assets/images/icons/icon-finance.svg";
import IconNotification from "assets/images/icons/project-icons/icon-notification.svg";
import iconWallet from "assets/images/icons/project-icons/wallet-3.svg";
import logo from "assets/images/icons/logo-color2@svg.svg";
import { ReactComponent as IconEye } from "assets/images/icons/project-icons/eye.svg";
import { ReactComponent as IconEyeSlash } from "assets/images/icons/project-icons/eye-slash.svg";
import { ReactComponent as IconUserStroke } from "assets/images/icons/project-icons/user-2-stroke.svg";
import { ReactComponent as IconUserPlusStroke } from "assets/images/icons/project-icons/user-plus-2-stroke.svg";
import { ReactComponent as IconSearch } from "assets/images/icons/project-icons/search-4.svg";
import { ReactComponent as IconLogout } from "assets/images/icons/project-icons/icon-logout.svg";
import { ReactComponent as IconTopRight } from "assets/images/icons/project-icons/arrow-up-right-dark.svg";
import { ReactComponent as IconGlobe } from "assets/images/icons/project-icons/globe.svg";
import { ReactComponent as IconHelp } from "assets/images/icons/project-icons/help.svg";
import { ReactComponent as IconChevronUp } from "assets/images/icons/project-icons/chevron-up.svg";
import { ReactComponent as IconCreateBusiness } from "assets/images/icons/icon-create-business.svg";
import { ReactComponent as IconSwitchBusiness } from "assets/images/icons/icon-switch-business.svg";
import { getRandomColor } from 'utils/randomColor';
import { useOutsideClick } from 'hooks/useOutsideClick';
import LogoutConfirmModal from './LogoutConfirmModal';
import Spinner from './Spinner';
import {capitalizeEachWord} from 'utils/stringFormatter'
import { queryOptions } from 'data/queryOptions'

const pathsThatAlreadyFetchDashboardData = ['/dashboard', '/account/all-accounts'];
const Header = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    
    const { dashboard } = useSelector(state => state.entities.dashboard);
    const {employerInfo} = useSelector(state => state.auth.account);
    
    const { userInfo, logoutUser } = useLoginInfo();
    const companyInfo = employerInfo?.data?.employer?.company
    const subAccountRef = useRef();
    const userProfileMenuDropdownRef = useRef(null);
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const [isUserProfileDropdown, setIsUserProfileDropdown] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    // const profile = dashboard?.employer
    
    // const profileImage = dashboard?.member?.userType === "employer" ? (dashboard?.employer?.profileImage) : null;
    const firstName = dashboard?.member?.firstName || "";
    const lastName = dashboard?.member?.lastName || "";
    // const email = dashboard?.member?.email || "";
    
    const profileImage = companyInfo?.businessLogo
    
    
    const toggleMobileNavOpen = () => {
        setIsMobileNavOpen(data => !data);
    }

    const toggleUserProfileDropdown = () => {
        setIsUserProfileDropdown(data => !data);
    }
    
    useOutsideClick(userProfileMenuDropdownRef, () => {
        if (isUserProfileDropdown) {
            toggleUserProfileDropdown();
        }
    });

    useEffect(() => {
        if (isMobileNavOpen === true){
            setIsMobileNavOpen(false)
        }
        // eslint-disable-next-line
    }, [location])
    

    
    
    useEffect(() => {
        if (!pathsThatAlreadyFetchDashboardData.includes(location?.pathname) && employerInfo?.data?.employer?.status === "approved") {
            dispatch(dashboardActions.getDashboard());
        }
    }, [location, employerInfo]);
    
   useEffect(() => {
    dispatch(accountActions.getEmployerInfo())
   }, []);


    return (
        <div className="border-b-[1px] border-ep-gray-200">

            <SubAccountSwitch
                ref={subAccountRef}
                isSelectorVisible={false}
            />

            <LogoutConfirmModal
                isOpen={showLogoutModal}
                setIsOpen={setShowLogoutModal}
            />

            <header className="h-16 bg-white px-6 hidden md:flex">
                <div className="w-full px-0 sm:px-4 flex space-x-6 items-center justify-between">

                    <div>
                        <Breadcrumbs location={location} />
                    </div>

                    <div className="flex-shrink-0 flex space-x-4 items-center justify-end">
                        {/* <FinancingOptions /> */}

                        <Wallet />

                        <div className="w-px h-6 my-auto bg-[#ddd]"></div>

                        <Notification />

                        <div className="w-px h-6 my-auto bg-[#ddd]"></div>

                        <Menu as="div" className="relative" ref={userProfileMenuDropdownRef}>
                            <Menu.Button as="div" onClick={toggleUserProfileDropdown} className="h-10 relative flex cursor-pointer rounded hover:bg-gray-100">
                                {profileImage
                                    ? 
                                    (!profileImage ? 
                                    <div className="w-10 h-10 my-auto bg-ep-primary flex text-lg font-medium rounded-full overflow-hidden">
                                        <span className="m-auto text-sm text-white">
                                            {/* {nameInitials(firstName, lastName)} */}
                                            {getBusinessInitials(companyInfo?.companyName)}
                                        </span>
                                    </div>
                                    : 
                                    <img 
                                        className='h-10 w-10' 
                                        src={profileImage} 
                                        alt="business logo" 
                                        style={{
                                            borderRadius: '50%',
                                            width: '40px',
                                            height: '40px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                )
                                :
                                <div className="w-10 h-10 my-auto bg-ep-primary flex text-lg font-medium rounded-full overflow-hidden">
                                    <span className="m-auto text-sm text-white">
                                        {/* {nameInitials(firstName, lastName)} */}
                                        {getBusinessInitials(companyInfo?.companyName)}
                                    </span>
                                </div>
                                }
                                <div className="text-left my-auto mx-3">
                                    <div className="text-sm text-ep-gray-600 truncate font-medium capitalize ellipsis" style={{maxWidth: "200px"}}>
                                        {/* {`${firstName} ${lastName}`} */}
                                        {capitalizeEachWord(companyInfo?.companyName)}
                                    </div>
                                    <div className="text-xs text-[#9CA3AF] ellipsis" style={{maxWidth: "200px"}}>
                                        {/* {email} */}
                                        {`${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`}
                                    </div>
                                </div>
                                <div className="h-full flex">
                                    <IconChevronUp className="m-auto svg-fill-black rotate-180" />
                                </div>
                            </Menu.Button>
                            {isUserProfileDropdown && (
                                <Menu.Items as="div" static className="dropdown dropdown-menu-items dropdown-right z-50 min-w-[200px] max-w-none top-12">
                                    <Menu.Item as="div">
                                        <div>
                                            {/* <div className="max-w-[300px] px-5 pt-5 pb-3 font-medium text-sm">
                                                {userInfo?.company?.companyName}
                                            </div> */}
                                            <div onClick={toggleUserProfileDropdown} className="border-t border-gray-100">
                                                {/* <Maybe condition={userInfo.employer.userType === 'employer'}> */}
                                                    {/* <NavLink to="/settings/profile" className="dropdown-item-user-header">
                                                        <IconUserStroke className="w-5 h-5 text-left mr-3" />
                                                        My Profile
                                                    </NavLink>
                                                    <NavLink to="/settings" className="dropdown-item-user-header">
                                                        <IconSettingsStroke className="w-5 h-5 text-left mr-3" />
                                                        Settings
                                                    </NavLink>
                                                    <Maybe condition={userInfo?.employer?.accountType === "main"}>
                                                        <NavLink to="/settings/administrators" className="dropdown-item-user-header">
                                                            <IconUserPlusStroke className="w-5 h-5 text-left mr-3" />
                                                            Administrators
                                                        </NavLink>
                                                    </Maybe> */}
                                                {/* </Maybe> */}
                                                <div className='px-3 py-5'>
                                                    <NavLink to="/settings/profile" className='p-3 flex justify-center rounded-lg bg-ep-primary-600 bg-opacity-10 font-medium text-sm text-ep-primary-600'>
                                                        View Profile
                                                    </NavLink>
                                                </div>

                                                <Maybe condition={subAccountRef.current?.hasSubAccountAccess === true}>
                                                    <div onClick={() => subAccountRef.current?.setIsOpenModalSwitch(true)} className="flex text-sm items-center rounded-lg cursor-pointer p-3 text-[#505666] hover:bg-[#F4F4F4]">
                                                        <IconSwitchBusiness className="w-5 h-5 text-left mr-2" />
                                                        Switch business
                                                    </div>
                                                    {/* <div onClick={() => subAccountRef.current?.addNewBusiness()} className="flex text-sm items-center rounded-lg cursor-pointer p-3 text-[#505666] hover:bg-[#F4F4F4]">
                                                        <IconCreateBusiness className="w-5 h-5 text-left mr-2" />
                                                        Create business 
                                                    </div> */}
                                                </Maybe>

                                                <div onClick={() => {
                                                    window.location.href = 'mailto:business@earnipay.com?subject=Earnipay%Business%Support&body=Good%day%team';
                                                }} className='flex text-sm items-center justify-between rounded-lg cursor-pointer p-3 text-[#505666] hover:bg-[#F4F4F4]'>
                                                    <div className='flex items-center'>
                                                        <IconHelp className='w-5 h-5 mr-2'/>
                                                        Support
                                                    </div>
                                                    <div><IconTopRight /></div>
                                                </div>

                                                <div onClick={() => {
                                                    window.open('https://blog.earnipay.com/', '_blank');
                                                }} className='flex text-sm items-center justify-between rounded-lg cursor-pointer p-3 text-[#505666] hover:bg-[#F4F4F4]'>
                                                    <div className='flex items-center'>
                                                        <IconGlobe className='w-5 h-5 mr-2'/>
                                                        Blog
                                                    </div>
                                                    <div><IconTopRight /></div>
                                                </div>

                                                <div onClick={() => setShowLogoutModal(true)} className='flex text-sm items-center rounded-lg cursor-pointer p-3 text-[#505666] hover:bg-[#F4F4F4]'>
                                                    <IconLogout className='w-5 h-5 mr-2'/>
                                                    Logout
                                                </div>

                                                <div className="border-t border-gray-100"></div>
                                                <div className='flex items-center text-[#718096] text-sm space-x-2 p-3'>
                                                    <div>
                                                        <Link to="https://www.earnipay.com/privacy-policy">Privacy</Link>
                                                    </div>
                                                    <div>
                                                        <Link to="https://www.earnipay.com/terms">Terms</Link>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </Menu.Item>
                                </Menu.Items>
                            )}
                        </Menu>
                    </div>

                </div>
            </header>


            <header className="w-full header-navigation-links bg-white md:hidden">
                <div className="h-14 px-6 flex items-center justify-between header-responsive">
                    <div>
                        {/* <SubAccountSwitch /> */}
                        <Link to="/" className="h-7 inline-block">
                            <img src={logo} alt="EarniPay logo" className="h-full object-contain" />
                        </Link>
                    </div>

                    <div className="flex-grow flex space-x-4 justify-end">

                        {/* 
                        <Link to="/financing-options" className="w-10 h-10 flex rounded hover:bg-white">
                            <IconFinance className="m-auto svg-fill-white" />
                        </Link>
                        */}

                        <Menu as="div">
                            <Menu.Button as="button" onClick={toggleMobileNavOpen} className="w-10 h-10 text-ep-gray-600 flex rounded cursor-pointer">
                                <FontAwesomeIcon icon="bars" className="text-xl m-auto" />
                            </Menu.Button>
                            {isMobileNavOpen && (
                                <Menu.Items as="div" static className="dropdown dropdown-right z-50 min-w-full max-w-none bg-white">
                                    <div className="flex flex-col pt-4 pb-4">

                                        <NavigationLinks view="mobile" />

                                        <div className="w-full h-px my-4 bg-black opacity-10"></div>

                                        <NavLink exact to="/settings/profile" className="sidebar-links">
                                            <IconUserStroke className="sidebar-links-icon" />
                                            <div>
                                                My Profile
                                            </div>
                                        </NavLink>
                                        <Maybe condition={subAccountRef.current?.hasSubAccountAccess === true}>
                                            <div onClick={() => {toggleMobileNavOpen(); subAccountRef.current?.setIsOpenModalSwitch(true)}} className="sidebar-links">
                                                <IconSwitchBusiness className="sidebar-links-icon" />
                                                Switch business
                                            </div>
                                            {/* <div onClick={() => {toggleMobileNavOpen(); subAccountRef.current?.addNewBusiness()}} className="sidebar-links">
                                                <IconCreateBusiness className="sidebar-links-icon" />
                                                Create business 
                                            </div> */}
                                        </Maybe>
                                        <Maybe condition={userInfo?.employer?.accountType === "main"}>
                                            <NavLink exact to="/settings/administrators" className="sidebar-links">
                                                <IconUserPlusStroke className="sidebar-links-icon" />
                                                <div>
                                                    Administrators
                                                </div>
                                            </NavLink>
                                        </Maybe>
                                        <div onClick={logoutUser} className="sidebar-links">
                                            <IconLogout className="sidebar-links-icon" />
                                            <div>
                                                Logout
                                            </div>
                                        </div>

                                    </div>
                                </Menu.Items>
                            )}
                        </Menu>
                        
                    </div>
                </div>
            </header>

            
        </div>
    )
}

function capitalizeFirstLetter(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

/* const Breadcrumbs = ({ location }) => {
    const routes = location.pathname.split('/');
    
    const currentOption = queryOptions.find(option => option.path === location.pathname);
    const subRoute = currentOption ? currentOption.subRoute : capitalizeFirstLetter(routes[1]);

    return (
        <div className="flex-shrink-0 hidden lg:flex space-x-3">
            <Link to={`${location.pathname}`} className="text-[#9CA3AF] hover:underline">{capitalizeFirstLetter(routes[1])}</Link>
            <Maybe condition={routes.length > 2}>
                <span className="text-[#374151]">/</span>
            </Maybe>
            <span className="text-[#374151]">{capitalizeFirstLetter(routes[2])}</span>
        </div>
    )
}
 */

const Breadcrumbs = ({ location }) => {
    const routes = location?.pathname?.split('/')?.filter(Boolean);
    let [routeOne, routeTwo] = routes;
    routeTwo = routeTwo?.split("-")?.join(" ");

    const currentOption = queryOptions?.find(option => option?.path === location?.pathname);
    const subRoute = currentOption ? currentOption?.subRoute : capitalizeFirstLetter(routeTwo);

    return (
        <div className="flex-shrink-0 hidden lg:flex space-x-3">
            <Link to={`/${routeOne}`} className="text-[#9CA3AF] hover:underline">
                {capitalizeFirstLetter(routeOne)}
            </Link>
            {routes.length > 1 && (
                <>
                    <span className="text-[#374151]">/</span>
                    <span className="text-[#374151]">{subRoute}</span>
                </>
            )}
        </div>
    );
}


// eslint-disable-next-line no-unused-vars
const FinancingOptions = () => {
    return (
        <Link to="/financing-options">
            <div style={{cursor: "pointer"}} className="px-3 py-2 flex items-center rounded-md border bg-ep-primary-100 border-ep-primary-200">
                <img src={iconFinance} alt="Financing Options" />
                <p className="mb-0 ml-2 text-ep-primary-600 font-medium text-sm">Financing Options</p>
            </div>
        </Link>
    )
}

const Wallet = () => {
    const dispatch = useDispatch();
    const {userInfo} = useLoginInfo();
    const { walletBalanceLoading, walletBalance } = useSelector(s => s.entities.wallet);
    const [isVisible, setIsVisible] = useState(true);
    
    const getDashboard = () => {
        dispatch(walletAction.getDashboardSilent(userInfo.token));
    }

    useEffect(() => {
        getDashboard();
        dispatch(walletAction.getWalletBalance());
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {/*
            <div className="h-10 px-3 my-auto cursor-pointer flex space-x-3 items-center bg-ep-primary-light-100 rounded hover:bg-gray-100">
                <Link to="/wallet" className="w-6 h-12 flex">
                    <img src={iconWallet} alt="Wallet" className="m-auto object-contain" />
                </Link>
                <Link to="/wallet">
                    <Maybe condition={walletBalanceLoading === true}>
                        <FontAwesomeIcon icon="circle-notch" spin />
                    </Maybe>
                    <Maybe condition={walletBalanceLoading === false}>
                        <Maybe condition={isVisible === true}>
                            {CurrencyFormat(dashboard?.data?.walletDetails?.balance / 100) ?? "-"}
                        </Maybe>
                        <Maybe condition={isVisible === false}>
                            <div className="text-xl font-bold relative top-1">*******</div>
                        </Maybe>
                    </Maybe>
                </Link>
                <div onClick={() => setIsVisible(!isVisible)} className="w-7 h-7 flex">
                    <Maybe condition={isVisible === false}>
                        <IconEye className="h-5 m-auto object-contain" />
                    </Maybe>
                    <Maybe condition={isVisible === true}>
                        <IconEyeSlash className="m-auto object-contain" />
                    </Maybe>
                </div>
            </div>
            */}
            
            <div className="h-10 my-auto flex space-x-0 bg-ep-primary-light-100 rounded overflow-hidden">
                <Link to="/wallet" className="h-10 px-3 flex space-x-3 items-center hover:bg-gray-100 rounded cursor-pointer">
                    <div className="w-6 h-12 flex">
                        <img src={iconWallet} alt="Wallet" className="m-auto object-contain" />
                    </div>
                    <div>
                        <Maybe condition={walletBalanceLoading === true}>
                            <FontAwesomeIcon icon="circle-notch" spin />
                        </Maybe>
                        <Maybe condition={walletBalanceLoading === false}>
                            <Maybe condition={isVisible === true}>
                                {CurrencyFormat(walletBalance / 100 || 0, true)}
                            </Maybe>
                            <Maybe condition={isVisible === false}>
                                ••••
                            </Maybe>
                        </Maybe>
                    </div>
                </Link>
                <div onClick={() => setIsVisible(!isVisible)} className="w-7 h-10 flex hover:bg-gray-100 cursor-pointer">
                    <Maybe condition={isVisible === false}>
                        <IconEye className="h-5 m-auto object-contain" />
                    </Maybe>
                    <Maybe condition={isVisible === true}>
                        <IconEyeSlash className="m-auto object-contain" />
                    </Maybe>
                </div>
            </div>
        </>
    )
}

const Notification = () => {
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const dispatch = useDispatch();
    const notificationDropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const { allNotifications, allNotificationsAsReadLoading, singleNotificationAsReadLoading, singleNotificationMarkedAsRead } = useSelector(s => s.entities.notifications);
    const [idsOfToBeMarkedNotification, setIdsOfToBeMarkedNotification] = useState({});
    const toggleNotificationWindow = () => {
        setIsNotificationDropdown(data => !data);
    }
    const [activeTab, setActiveTab] = useState('all');

    const routerOptions = [
        {
            title: "All",
            value: "all",
        },
        {
            title: "Unread",
            value: "unread",
        },
        {
            title: "Read",
            value: "read",
        },
    ]
    
    const {
        ALL_NOTIFICATIONS,
        UNREAD_NOTIFICATIONS,
        READ_NOTIFICATIONS,
    } = useMemo(() => {
        const notifications = allNotifications?.notifications?.docs;
        if (notifications?.length === 0) {
            return {
                ALL_NOTIFICATIONS: 0,
                UNREAD_NOTIFICATIONS: 0,
                READ_NOTIFICATIONS: 0,
            }
        }
        
        return {
            ALL_NOTIFICATIONS: notifications,
            UNREAD_NOTIFICATIONS: notifications?.filter((n) => !n.read),
            READ_NOTIFICATIONS: notifications?.filter((n) => n.read),
        }
    }, [allNotifications])
    
    const handleMarkSingleNotificationAsRead = (id) => {
        setIdsOfToBeMarkedNotification({
            ...idsOfToBeMarkedNotification,
            [id]: true,
        })
        dispatch(notificationAction.markSingleNotificationsAsRead(id)).then(() => {
            setIdsOfToBeMarkedNotification({
                ...idsOfToBeMarkedNotification,
                [id]: false,
            })
            dispatch(notificationAction.getAllNotifications());
        });
    }

    const handleMarkAllNotificationAsRead = () => {
        dispatch(notificationAction.markAllNotificationsAsRead()).then(() => {
            dispatch(notificationAction.getAllNotifications());
        });
    }
    
    useOutsideClick(notificationDropdownRef, () => {
        if (isNotificationDropdown) {
            toggleNotificationWindow();
        }
    }, buttonRef);

    return (
        <>
            <Menu as="div" className="fixed z-10" ref={notificationDropdownRef}>
                {isNotificationDropdown && (
                    <Menu.Items as="div" static className="z-10 bg-white absolute shadow-box-shadow rounded-xl outline-none overflow-hidden dropdown-notification dropdown-right max-w-none top-12">
                        <Menu.Item as="div">
                            <div className='p-5 scrollable-notification-panel'>
                                <div className='my-2'>
                                    <div className='flex justify-between pb-3'>
                                        <div className='font-semibold text-lg'>Notifications</div>
                                        <div onClick={handleMarkAllNotificationAsRead} className='text-ep-primary-600 cursor-pointer'>Mark all as read</div>
                                    </div>
                                    {/* Create a component for this */}
                                    <div className='flex items-center space-x-2 w-max rounded-xl py-1 px-1 bg-ep-gray-200'>
                                        {routerOptions && routerOptions.map((router, index) => (
                                            <div className={`py-2 px-4 cursor-pointer flex items-center justify-center w-full ${activeTab === router.value ? 'bg-white font-medium text-ep-gray-700 shadow-md rounded-lg' : 'text-ep-gray-500'}`}
                                                onClick={() => setActiveTab(router.value)}
                                                key={index}
                                            >
                                                {router.title}
                                               <Maybe condition={activeTab === router.value}>
                                                    <div className='ml-2 flex items-center justify-center rounded-full w-6 h-6 bg-ep-primary-600 bg-opacity-5'>
                                                        {activeTab === 'all' && (<div>{ALL_NOTIFICATIONS?.length}</div>)}
                                                        {activeTab === 'unread' && (<div>{UNREAD_NOTIFICATIONS?.length}</div>)}
                                                        {activeTab === 'read' && (<div>{READ_NOTIFICATIONS?.length}</div>)}
                                                    </div>
                                               </Maybe>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="border-t border-gray-100"></div>
                                <Maybe condition={allNotificationsAsReadLoading}>
                                    <Spinner />
                                </Maybe>
                                <Maybe condition={!allNotificationsAsReadLoading}>
                                    <div className="py-3 h-full overflow-y-auto" style={{ height: "340px" }}>
                                        {/* All notifications */}
                                        <Maybe condition={activeTab === 'all'}>
                                            <div className='text-[#667085] uppercase mt-5'>Today</div>
                                            <div>
                                                <div className='mt-3'>
                                                        {ALL_NOTIFICATIONS && ALL_NOTIFICATIONS.map((notification) => (
                                                            <div 
                                                                onClick={() => handleMarkSingleNotificationAsRead(notification?._id)} className="flex items-center space-x-3 my-7 cursor-pointer py-2" 
                                                                key={notification?._id}
                                                            >
                                                                <div className="rounded-full w-10 h-10 flex justify-center items-center flex-shrink-0 relative" style={{backgroundColor: getRandomColor()}}>
                                                                    <div className="text-lg font-normal text-white">
                                                                        {notification?.firstName?.charAt().toUpperCase()}{notification?.lastName?.charAt().toUpperCase()}
                                                                    </div>
                                                                    {notification?.read === false && (
                                                                        <div className="w-3 h-3 bg-[#F49D1B] rounded-full absolute top-1 left-0"></div>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <div className="text-[#101828]">
                                                                        <span className='capitalize font-medium'>{notification?.firstName} {notification?.lastName}</span>{" "}
                                                                        {notification?.message} • <span className='text-[#667085]'>{moment(notification?.createdAt).fromNow()}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </Maybe>

                                        {/* Unread notifications */}
                                        <Maybe condition={activeTab === 'unread'}>
                                            <div className='text-[#667085] uppercase mt-5'>Today</div>
                                            <div>
                                                <div className='my-3 cursor-pointer'>
                                                        {UNREAD_NOTIFICATIONS && UNREAD_NOTIFICATIONS.filter((n) => !n.read).map((notification) => (
                                                            <div 
                                                                onClick={() => handleMarkSingleNotificationAsRead(notification?._id)} 
                                                                className="flex items-center space-x-3 my-7 cursor-pointer py-2" 
                                                                key={notification?._id}
                                                            >
                                                                <div className="rounded-full w-10 h-10 flex justify-center items-center flex-shrink-0 relative" style={{backgroundColor: getRandomColor()}}>
                                                                    <div className="text-lg font-normal text-white">
                                                                        {notification?.firstName?.charAt().toUpperCase()}{notification?.lastName?.charAt().toUpperCase()}
                                                                    </div>
                                                                    {notification?.read === false && (
                                                                        <div className="w-3 h-3 bg-[#F49D1B] rounded-full absolute top-1 left-0"></div>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <div className="text-[#101828]">
                                                                        <span className='capitalize font-medium'>{notification?.firstName} {notification?.lastName}</span>{" "}
                                                                        {notification?.message} • <span className='text-[#667085]'>{moment(notification?.createdAt).fromNow()}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </Maybe>

                                        {/* Read notifications */}
                                        <Maybe condition={activeTab === 'read'}>
                                            <div className='text-[#667085] uppercase mt-5'>Today</div>
                                            <div>
                                                <div className='my-3 cursor-pointer'>
                                                        {READ_NOTIFICATIONS && READ_NOTIFICATIONS.filter((n) => n.read).map((notification) => (
                                                            <div
                                                                className="flex items-center space-x-3 my-7 cursor-pointer py-2" 
                                                                key={notification?._id}
                                                            >
                                                                <div className="rounded-full w-10 h-10 flex justify-center items-center flex-shrink-0 relative" style={{backgroundColor: getRandomColor()}}>
                                                                    <div className="text-lg font-normal text-white">
                                                                        {notification?.firstName?.charAt().toUpperCase()}{notification?.lastName?.charAt().toUpperCase()}
                                                                    </div>
                                                                    {notification?.read === false && (
                                                                        <div className="w-3 h-3 bg-[#F49D1B] rounded-full absolute top-1 left-0"></div>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <div className="text-[#101828]">
                                                                        <span className='capitalize font-medium'>{notification?.firstName} {notification?.lastName}</span>{" "}
                                                                        {notification?.message} • <span className='text-[#667085]'>{moment(notification?.createdAt).fromNow()}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </Maybe>
                                    </div>
                                </Maybe>
                            </div>
                        </Menu.Item>
                    </Menu.Items>
                )}
            </Menu>

            <div className="flex px-3 h-10 my-auto relative rounded hover:bg-gray-100 cursor-pointer" ref={buttonRef} onClick={toggleNotificationWindow}>
                <div className="w-5 h-5 text-xs text-white bg-ep-secondary flex items-center justify-center absolute top-0 right-1.5 rounded-full overflow-hidden">
                    {UNREAD_NOTIFICATIONS?.length || 0}
                </div>
                <img src={IconNotification} alt="Notifications" className="m-auto object-contain" />
            </div>
        </>
    )
}

export default Header;
