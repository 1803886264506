import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customInfoToast, customSuccessToast } from "utils/customToast";

const initialState = {
    loading: false,
    allPaymentMethod: {},
    reloadPage: false,
    
    paymentMethodCreateCardLoading: false,
    paymentMethodCreateCardSuccess: false,
    
    paymentMethodUpdateCardLoading: false,
    paymentMethodUpdateCardSuccess: false,

    paymentMethodCreateBankLoading: false,
    paymentMethodCreateBankSuccess: false,

    paymentMethodUpdateBankLoading: false,
    paymentMethodUpdateBankSuccess: false,

    paymentMethodDeleteBankLoading: false,
    paymentMethodDeleteBankSuccess: false,
};


export default function reducer(state = initialState, action){
    switch (action.type){

        case actions.GET_ALL_PAYMENT_METHOD:
            state = {
                ...state,
                loading: false,
                allPaymentMethod: {...action.payload.paymentMethods}
            }
            return state;

           
        case actions.GET_ALL_PAYMENT_METHOD_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_ALL_PAYMENT_METHOD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                loading: false,
                allPaymentMethod: {}
            }
            return state;


        case actions.CREATE_PAYMENT_METHOD_CARD:
            customSuccessToast({ message: action.payload.message });
            state = {
                ...state,
                paymentMethodCreateCardLoading: false,
                paymentMethodCreateCardSuccess: true
            }
            return state;
    

        case actions.CREATE_PAYMENT_METHOD_CARD_RESET:
            state = {
                ...state,
                paymentMethodCreateCardSuccess: false
            }
            return state;


        case actions.CREATE_PAYMENT_METHOD_CARD_START:
            state = {
                ...state,
                paymentMethodCreateCardLoading: true,
            }
            return state;


        case actions.CREATE_PAYMENT_METHOD_CARD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                paymentMethodCreateCardLoading: false,
            }
            return state;


        case actions.CREATE_PAYMENT_METHOD_BANK:
            customSuccessToast({ message: action.payload.message });
            state = {
                ...state,
                paymentMethodCreateBankLoading: false,
                paymentMethodCreateBankSuccess: true
            }
            return state;
    

        case actions.CREATE_PAYMENT_METHOD_BANK_RESET:
            state = {
                ...state,
                paymentMethodCreateBankSuccess: false
            }
            return state;


        case actions.CREATE_PAYMENT_METHOD_BANK_START:
            state = {
                ...state,
                paymentMethodCreateBankLoading: true,
            }
            return state;


        case actions.CREATE_PAYMENT_METHOD_BANK_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                paymentMethodCreateBankLoading: false,
            }
            return state;

            
        case actions.UPDATE_PAYMENT_METHOD:
            customSuccessToast({ message: action.payload.message });
            state = {
                ...state,
                paymentMethodUpdateLoading: false,
                paymentMethodUpdateSuccess: true
            }
            return state;
    

        case actions.UPDATE_PAYMENT_METHOD_RESET:
            state = {
                ...state,
                paymentMethodUpdateSuccess: false
            }
            return state;


        case actions.UPDATE_PAYMENT_METHOD_START:
            state = {
                ...state,
                paymentMethodUpdateLoading: true,
            }
            return state;


        case actions.UPDATE_PAYMENT_METHOD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                paymentMethodUpdateLoading: false,
            }
            return state;

            
        case actions.DELETE_PAYMENT_METHOD:
            customSuccessToast({ message: action.payload.message });
            state = {
                ...state,
                paymentMethodDeleteLoading: false,
                paymentMethodDeleteSuccess: true,
                reloadPage: true
            }
            return state;
    

        case actions.DELETE_PAYMENT_METHOD_RESET:
            state = {
                ...state,
                paymentMethodDeleteSuccess: false
            }
            return state;


        case actions.DELETE_PAYMENT_METHOD_START:
            customInfoToast({ message: "Deleting Payment Method" });
            state = {
                ...state,
                paymentMethodDeleteLoading: true,
            }
            return state;


        case actions.DELETE_PAYMENT_METHOD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                paymentMethodDeleteLoading: false,
            }
            return state;


        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false
            }
            return state;

            
        default:
            return state;
    }
}