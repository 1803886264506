import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customInfoToast } from "utils/customToast";

const initialState = {
    historyLoading: false,
    history: {},

    historyDownloadLoading: false,
    historyDownload: {},
};


export default function reducer(state = initialState, action){
    switch (action.type){

        case actions.GET_ALL_PAYMENT_HISTORY:
            state = {
                ...state,
                historyLoading: false,
                history: action.payload,
            }
            return state;

           
        case actions.GET_ALL_PAYMENT_HISTORY_START:
            state = {
                ...state,
                historyLoading: true,
            }
            return state;


        case actions.GET_ALL_PAYMENT_HISTORY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                historyLoading: false,
                history: {},
            }
            return state;


        case actions.DOWNLOAD_HISTORY:
            state = {
                ...state,
                historyDownloadLoading: false,
                historyDownload: action.payload
            }
            return state;


        case actions.DOWNLOAD_HISTORY_START:
            customInfoToast({ message: "Downloading Data..." });
            state = {
                ...state,
                historyDownloadLoading: true,
            }
            return state;


        case actions.DOWNLOAD_HISTORY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                historyDownloadLoading: false,
                historyDownload: {},
            }
            return state;
    

        default:
            return state;
    }
}