import { useEffect, lazy } from "react";
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as CreditBanner01 } from "assets/images/icons/creditbanner-01.svg";
import { ReactComponent as BankIcon } from "assets/images/icons/bank.svg";
import useLoginInfo from "hooks/useLoginInfo";
import * as walletActions from 'store/entities/wallet/action';
import Maybe from "components/__new/common/Maybe";
import Spinner from "components/__new/common/Spinner";
import useRouteAccess from 'hooks/useRouteAccess';
import OnboardingCredit from 'components/__new/onboardingFeatures/credit';

export const CreditStatus = {
  PENDING: "pending",
  DONE: "done",
  FAILED: "failed",
};

const CreditEligibilityStatus = {
  ELIGIBLE: 'eligible',
  NOT_ELIGIBLE: 'noteligible',
  PENDING: 'pending',
};

const CreditInfo = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userInfo } = useLoginInfo();
    const { employerInfo } = useSelector((s) => s.auth.account);
    const user = useSelector((s) => s.auth.userPersist);
    const {isOnboardingRequired} = useRouteAccess();
    const { businessCredit, businessCreditLoading } = useSelector(s => s.entities.wallet);
    const employerId = userInfo?.employer?._id
    
    const handleResetBusinessCredit = () => {
      dispatch(walletActions.resetBusinessCredit());
    }

    useEffect(() => {
      dispatch(walletActions.getBusinessCredit(employerId));
    }, [dispatch]);

    /**
     * credit status is pending => processing screen
     * credit status is failed => failed screen to re-try
     * credit status is done && credit eligible status is NOT eligible => not eligible screen
     * credit status is done && credit eligible status is eligible => eligible screen
    */
    useEffect(() => {
      // credit status is pending => processing screen
      if (businessCredit?.status === CreditStatus.PENDING) {
        // history.push('/credit/application-processing');
        history.push('/credit/application-submitted');
      } else if (businessCredit?.status === CreditStatus.FAILED) {
        // redirect business to retry credit screen
      } else if(businessCredit?.status === CreditStatus.DONE && businessCredit.eligibilityStatus === CreditEligibilityStatus.NOT_ELIGIBLE) {
        // redirect business to not eligible screen
        history.push('/credit/application-not-eligible');
      } else if(businessCredit?.status === CreditStatus.DONE && businessCredit.eligibilityStatus === CreditEligibilityStatus.ELIGIBLE) {
        // redirect business to eligible screen
        history.push('/credit/application-eligible');
      } else {
        // redirect business to start screen
        history.push('/credit')
      }
  }, [businessCredit]);
  
  const isAdminVerified = employerInfo?.data?.employer?.adminVerify || user?.data?.employer?.adminVerify;
  
  if (!isAdminVerified || isOnboardingRequired) {
      return (
          <OnboardingCredit />
      )
  }

  return (
    <>
      <Maybe condition={businessCreditLoading}>
        <Spinner />
      </Maybe>
      <Maybe condition={!businessCreditLoading && Object.entries(businessCredit).length > 0}>
        <div className="flex justify-center items-center">
          <div className="relative bg-[#FFFFFF] h-full p-[22px] rounded-lg">
            <CreditBanner01 />
            <div className="text-2xl font-bold leading-7 text-left mt-4 mb-2">
              <div>Credit to Keep Your Business Moving Forward</div>
            </div>
            <div className="font-normal text-[#4A5564]">
              <div className="leading-5">
                Access the funding you need to fuel growth, seize opportunities, and <br />
                drive your business forward without constraints
              </div>
            </div>
            
            <div className="text-[#0D0C2B] font-medium text-xl leading-7 text-left mt-[48px]">
              Requirements
            </div>
            
            <div className="mt-4 flex border gap-2 p-3 items-center rounded-lg">
              <div>
                <BankIcon />
              </div>
              <div>
                <div className="font-medium text-lg leading-5 mb-3">Active Bank Account</div>
                <div className="leading-5 font-normal text-[#4A5564]">Provide your most active business bank account statement. The bank<br/> statement must cover at least 3 months of transactions.</div>
              </div>
            </div>
          
            <div className="mt-[56px] flex w-full">
              <Link to="/credit/application" onClick={handleResetBusinessCredit} className="btn h-[56px] w-full btn-ep-primary font-medium text-lg leading-7">
                  Apply for Credit
              </Link>
            </div>
          </div>
        </div>
      </Maybe>
    </>
  )
}

export default CreditInfo;
