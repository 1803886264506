import { customSuccessToast } from "utils/customToast";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

const initialState = {
  getLoading: false,

  companyInfoLoading: false,
  companyInfo: {},
  reloadPage: false,

  updateLegalInfoLoading: false,
  updateLegalInfoSuccess: false,
  updatePolicyInfoLoading: false,
  updatePolicyInfoSuccess: false,
  updateCompanySettingsLoading: false,
  updateCompanySettingsSuccess: false,

  createBulkEmployeeEVALoading: false,
  createBulkEmployeeEVAContent: {},

  eraPayrollLoading: false,
  eraPayroll: {},

  employeeEraOffLoading: false,
  employeeEraOff: {},

  bankStatementLoading: false,
  bankStatement: {},

  companyFeesLoading: false,
  companyFees: {},

  odpConfigurationLoading: false,
  odpConfiguration: {},
  updateODPConfigurationLoading: false,
  updateODPConfiguration: {},

  profileImageLoading: false,
  profileImage: null,

  profileImageRemovedLoading: false,
  profileImageRemoved: null,

  personalInfoLoading: false,
  personalInfo: {},

  uploadCompanyLogoLoading: false,
  uploadCompanyLogo: null,

  companyInfoSettingsLoading: false,
  companyInfoSettings: {},

  getMembersLoading: false,
  getMembers: {},

  inviteMemberLoading: false,
  inviteMember: {},

  acceptInviteLoading: false,
  acceptInvite: {},

  resendMemberInviteLoading: false,
  resendMemberInvite: {},

  transferOwnershipLoading: false,
  transferOwnership: {},

  editTeamMemberLoading: false,
  editTeamMember: {},

  deactivateMemberLoading: false,
  deactivateMember: {},

  forgotPasswordMemberLoading: false,
  forgotPasswordMember: {},

  resetPasswordMemberLoading: false,
  resetPasswordMember: {},

  getRolesLoading: false,
  getRoles: {},

  createCustomRoleLoading: false,
  createCustomRole: {},

  editRoleLoading: false,
  editRole: {},

  deleteRoleLoading: false,
  deleteRole: {},

  permissionLoading: false,
  permission: {},

  rolePermissionLoading: false,
  rolePermission: {},

  changePasswordLoading: false,
  changePassword: {},

  changeTransactionPinLoading: false,
  changeTransactionPin: null,

  changeTwoFactorAuthStatusLoading: false,
  changeTwoFactorAuthStatus: null,

  sendTransactionPinOtpLoading: false,
  sendTransactionPinOtp: {},

  dailyTransactionLimitLoading: false,
  dailyTransactionLimit: {},

  singleTransactionLimitLoading: false,
  singleTransactionLimit: {},

  transactionLimitLoading: false,
  transactionLimit: {},

  defaultTransactionLimitLoading: false,
  defaultTransactionLimit: {}
};


export default function reducer(state = initialState, action) {
    switch (action.type) {
		case actions.GET_INFO:
			state = {
				...state,
				getLoading: false,
				companyInfoLoading: false,
				companyInfo: action.payload,
			};
			return state;

		case actions.GET_INFO_START:
			state = {
				...state,
				getLoading: true,
				companyInfoLoading: true,
			};
			return state;

		case actions.GET_INFO_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				getLoading: false,
				companyInfoLoading: false,
				companyInfo: {},
			};
			return state;

		case actions.CREATE_BULK_EMPLOYEE_EVA:
			state = {
				...state,
				createBulkEmployeeEVALoading: false,
				createBulkEmployeeEVAContent: { ...action.payload },
			};
			return state;

		case actions.CREATE_BULK_EMPLOYEE_EVA_START:
			state = {
				...state,
				createBulkEmployeeEVALoading: true,
			};
			return state;

		case actions.CREATE_BULK_EMPLOYEE_EVA_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				createBulkEmployeeEVALoading: false,
				createBulkEmployeeEVAContent: {},
			};
			return state;

		case actions.UPDATE_LEGAL_INFO:
			customSuccessToast({ message: action.payload.message });
			state = {
				...state,
				updateLegalInfoLoading: false,
				reloadPage: true,
				updateLegalInfoSuccess: true,
			};
			return state;

		case actions.UPDATE_LEGAL_INFO_SILENT:
			state = {
				...state,
				updateLegalInfoLoading: false,
				reloadPage: true,
				updateLegalInfoSuccess: true,
			};
			return state;

		case actions.UPDATE_LEGAL_INFO_START:
			state = {
				...state,
				updateLegalInfoLoading: true,
				updateLegalInfoSuccess: false,
			};
			return state;

		case actions.UPDATE_LEGAL_INFO_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				updateLegalInfoLoading: false,
			};
			return state;

		case actions.UPDATE_LEGAL_INFO_RESET:
			state = {
				...state,
				updateLegalInfoLoading: false,
				updateLegalInfoSuccess: false,
			};
			return state;

		case actions.UPDATE_POLICY_INFO:
			customSuccessToast({ message: action.payload.message });
			state = {
				...state,
				updatePolicyInfoLoading: false,
				reloadPage: true,
				updatePolicyInfoSuccess: true,
			};
			return state;

		case actions.UPDATE_POLICY_INFO_SILENT:
			state = {
				...state,
				updatePolicyInfoLoading: false,
				reloadPage: true,
				updatePolicyInfoSuccess: true,
			};
			return state;

		case actions.UPDATE_POLICY_INFO_START:
			state = {
				...state,
				updatePolicyInfoLoading: true,
				updatePolicyInfoSuccess: false,
			};
			return state;

		case actions.UPDATE_POLICY_INFO_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				updatePolicyInfoLoading: false,
			};
			return state;

		case actions.UPDATE_POLICY_INFO_RESET:
			state = {
				...state,
				updatePolicyInfoLoading: false,
				updatePolicyInfoSuccess: false,
			};
			return state;

		case actions.TOGGLE_PAYROLL:
			state = {
				...state,
				eraPayrollLoading: false,
				eraPayroll: { ...action.payload },
			};
			return state;

		case actions.TOGGLE_PAYROLL_START:
			state = {
				...state,
				eraPayrollLoading: true,
			};
			return state;

		case actions.TOGGLE_PAYROLL_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				eraPayrollLoading: false,
				eraPayroll: {},
			};
			return state;

		case actions.TOGGLE_EMPLOYEE_EVA_OFF:
			state = {
				...state,
				employeeEraOffLoading: false,
				employeeEraOff: { ...action.payload },
			};
			return state;

		case actions.TOGGLE_EMPLOYEE_EVA_OFF_START:
			state = {
				...state,
				employeeEraOffLoading: true,
			};
			return state;

		case actions.TOGGLE_EMPLOYEE_EVA_OFF_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				employeeEraOffLoading: false,
				employeeEraOff: {},
			};
			return state;

		case actions.UPDATE_BANK_STATEMENT:
			state = {
				...state,
				bankStatementLoading: false,
				bankStatement: { ...action.payload },
				reloadPage: true,
			};
			return state;

		case actions.UPDATE_BANK_STATEMENT_START:
			state = {
				...state,
				bankStatementLoading: true,
			};
			return state;

		case actions.UPDATE_BANK_STATEMENT_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				bankStatementLoading: false,
				bankStatement: {},
			};
			return state;

		case actions.GET_COMPANY_FEES:
			state = {
				...state,
				companyFeesLoading: false,
				companyFees: action.payload,
			};
			return state;

		case actions.GET_COMPANY_FEES_START:
			state = {
				...state,
				companyFeesLoading: true,
			};
			return state;

		case actions.GET_COMPANY_FEES_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				companyFeesLoading: false,
				companyFees: {},
			};
			return state;

		case actions.UPDATE_COMPANY_SETTINGS:
			state = {
				...state,
				updateCompanySettingsLoading: false,
				updateCompanySettingsSuccess: true,
			};
			return state;

		case actions.UPDATE_COMPANY_SETTINGS_START:
			state = {
				...state,
				updateCompanySettingsLoading: true,
				updateCompanySettingsSuccess: false,
			};
			return state;

		case actions.UPDATE_COMPANY_SETTINGS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				updateCompanySettingsLoading: false,
				updateCompanySettingsSuccess: false,
			};
			return state;
			
			case actions.GET_MEMBERS:
				state = {
					...state,
					getMembersLoading: false,
					getMembers: action.payload,
				};
				return state;
	
			case actions.GET_MEMBERS_START:
				state = {
					...state,
					getMembersLoading: true,
				};
				return state;
	
			case actions.GET_MEMBERS_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					getMembersLoading: false,
					getMembers: {},
				};
				return state;
			
			
			case actions.INVITE_MEMBER:
				state = {
					...state,
					inviteMemberLoading: false,
					inviteMember: action.payload,
				};
				return state;
	
			case actions.INVITE_MEMBER_START:
				state = {
					...state,
					inviteMemberLoading: true,
				};
				return state;
	
			case actions.INVITE_MEMBER_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					inviteMemberLoading: false,
					inviteMember: {},
				};
				return state;
			
			case actions.ACCEPT_INVITE:
				state = {
				...state,
				acceptInviteLoading: false,
				acceptInvite: action.payload,
				};
				return state;
	
			case actions.ACCEPT_INVITE_START:
				state = {
					...state,
					acceptInviteLoading: true,
				};
				return state;
	
			case actions.ACCEPT_INVITE_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					acceptInviteLoading: false,
					acceptInvite: {},
				};
				return state;
			
			case actions.RESEND_MEMBER_INVITE:
				state = {
					...state,
					resendMemberInviteLoading: false,
					resendMemberInvite: action.payload,
				};
				return state;
	
			case actions.RESEND_MEMBER_INVITE_START:
				state = {
					...state,
					resendMemberInviteLoading: true,
				};
				return state;
	
			case actions.RESEND_MEMBER_INVITE_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					resendMemberInviteLoading: false,
					resendMemberInvite: {},
				};
				return state;
			
			case actions.TRANSFER_OWNERSHIP:
				state = {
					...state,
					transferOwnershipLoading: false,
					transferOwnership: action.payload,
				};
				return state;
	
			case actions.TRANSFER_OWNERSHIP_START:
				state = {
					...state,
					transferOwnershipLoading: true,
				};
				return state;
	
			case actions.TRANSFER_OWNERSHIP_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					transferOwnershipLoading: false,
					transferOwnership: {},
				};
				return state;
			
			case actions.EDIT_TEAM_MEMBER:
				state = {
					...state,
					editTeamMemberLoading: false,
					editTeamMember: action.payload,
				};
				return state;
	
			case actions.EDIT_TEAM_MEMBER_START:
				state = {
					...state,
					editTeamMemberLoading: true,
				};
				return state;
	
			case actions.EDIT_TEAM_MEMBER_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					editTeamMemberLoading: false,
					editTeamMember: {},
				};
				return state;
			
			case actions.DEACTIVATE_MEMBER:
				state = {
					...state,
					deactivateMemberLoading: false,
					deactivateMember: action.payload,
				};
				return state;
	
			case actions.DEACTIVATE_MEMBER_START:
				state = {
					...state,
					deactivateMemberLoading: true,
				};
				return state;
	
			case actions.DEACTIVATE_MEMBER_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					deactivateMemberLoading: false,
					deactivateMember: {},
				};
				return state;
			
			case actions.FORGOT_PASSWORD_MEMBER:
				state = {
          ...state,
          forgotPasswordMemberLoading: false,
          forgotPasswordMember: action.payload,
        };
				return state;
	
			case actions.FORGOT_PASSWORD_MEMBER_START:
				state = {
					...state,
					forgotPasswordMemberLoading: true,
				};
				return state;
	
			case actions.FORGOT_PASSWORD_MEMBER_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					forgotPasswordMemberLoading: false,
					forgotPasswordMember: {},
				};
				return state;
				
			case actions.FORGOT_PASSWORD_MEMBER_STATE_RESET:
				state = {
					...state,
					forgotPasswordMemberLoading: false,
					forgotPasswordMember: {},
				};
				return state;
			
			case actions.RESET_PASSWORD_MEMBER:
				state = {
					...state,
					resetPasswordMemberLoading: false,
					resetPasswordMember: action.payload,
				};
				return state;
	
			case actions.RESET_PASSWORD_MEMBER_START:
				state = {
					...state,
					resetPasswordMemberLoading: true,
				};
				return state;
	
			case actions.RESET_PASSWORD_MEMBER_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					resetPasswordMemberLoading: false,
					resetPasswordMember: {},
				};
				return state;
			
			case actions.GET_ROLES:
				state = {
					...state,
					getRolesLoading: false,
					getRoles: action.payload,
				};
				return state;
	
			case actions.GET_ROLES_START:
				state = {
					...state,
					getRolesLoading: true,
				};
				return state;
	
			case actions.GET_ROLES_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					getRolesLoading: false,
					getRoles: {},
				};
				return state;
			
			case actions.CREATE_CUSTOM_ROLE:
				state = {
					...state,
					createCustomRoleLoading: false,
					createCustomRole: action.payload,
				};
				return state;
	
			case actions.CREATE_CUSTOM_ROLE_START:
				state = {
					...state,
					createCustomRoleLoading: true,
				};
				return state;
	
			case actions.CREATE_CUSTOM_ROLE_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					createCustomRoleLoading: false,
					createCustomRole: {},
				};
				return state;
			
			case actions.EDIT_ROLE:
				state = {
					...state,
					editRoleLoading: false,
					editRole: action.payload,
				};
				return state;
	
			case actions.EDIT_ROLE_START:
				state = {
					...state,
					editRoleLoading: true,
				};
				return state;
	
			case actions.EDIT_ROLE_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					editRoleLoading: false,
					editRole: {},
				};
				return state;
			
			case actions.DELETE_ROLE:
				state = {
					...state,
					deleteRoleLoading: false,
					deleteRole: action.payload,
				};
				return state;
	
			case actions.DELETE_ROLE_START:
				state = {
					...state,
					deleteRoleLoading: true,
				};
				return state;
	
			case actions.DELETE_ROLE_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					deleteRoleLoading: false,
					deleteRole: {},
				};
				return state;
			
			case actions.GET_PERMISSION:
				state = {
					...state,
					permissionLoading: false,
					permission: action.payload,
				};
				return state;
	
			case actions.GET_PERMISSION_START:
				state = {
					...state,
					permissionLoading: true,
				};
				return state;
	
			case actions.GET_PERMISSION_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					permissionLoading: false,
					permission: {},
				};
				return state;
			
			case actions.GET_ROLE_PERMISSION:
				state = {
					...state,
					rolePermissionLoading: false,
					rolePermission: action.payload,
				};
				return state;
	
			case actions.GET_ROLE_PERMISSION_START:
				state = {
					...state,
					rolePermissionLoading: true,
				};
				return state;
	
			case actions.GET_ROLE_PERMISSION_FAILED:
				errorReducer(action.payload);
				state = {
					...state,
					rolePermissionLoading: false,
					rolePermission: {},
				};
				return state;

		case actions.GET_ODP_CONFIGURATION:
			state = {
				...state,
				odpConfigurationLoading: false,
				odpConfiguration: action.payload,
			};
			return state;

		case actions.GET_ODP_CONFIGURATION_START:
			state = {
				...state,
				odpConfigurationLoading: true,
			};
			return state;

		case actions.GET_ODP_CONFIGURATION_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				odpConfigurationLoading: false,
				odpConfiguration: {},
			};
			return state;

		case actions.UPDATE_ODP_CONFIGURATION:
			customSuccessToast({ message: action.payload.message });
			state = {
				...state,
				updateODPConfigurationLoading: false,
				updateODPConfiguration: action.payload,
			};
			return state;

		case actions.UPDATE_ODP_CONFIGURATION_START:
			state = {
				...state,
				updateODPConfigurationLoading: true,
			};
			return state;

		case actions.UPDATE_ODP_CONFIGURATION_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				updateODPConfigurationLoading: false,
				updateODPConfiguration: {},
			};
			return state;

		case actions.UPDATE_ODP_CONFIGURATION_RESET:
			state = {
				...state,
				updateODPConfigurationLoading: false,
				updateODPConfiguration: {},
			};
			return state;

		case actions.RELOAD_PAGE_RESET:
			state = {
				...state,
				reloadPage: false,
			};
			return state;

		case actions.UPLOAD_PROFILE_IMAGE:
			state = {
				...state,
				profileImageLoading: false,
				profileImage: action.payload,
			};
			return state;

		case actions.UPLOAD_PROFILE_IMAGE_START:
			state = {
				...state,
				profileImageLoading: true,
			};
			return state;

		case actions.UPLOAD_PROFILE_IMAGE_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				profileImageLoading: false,
				profileImage: null,
			};
			return state;

		case actions.REMOVE_PROFILE_IMAGE:
			state = {
				...state,
				profileImageRemovedLoading: false,
				profileImageRemoved: action.payload,
			};
			return state;

		case actions.REMOVE_PROFILE_IMAGE_START:
			state = {
				...state,
				profileImageRemovedLoading: true,
			};
			return state;

		case actions.REMOVE_PROFILE_IMAGE_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				profileImageRemovedLoading: false,
				profileImageRemoved: null,
			};
			return state;

		case actions.UPDATE_PERSONAL_INFO:
			state = {
				...state,
				personalInfoLoading: false,
				personalInfo: action.payload,
			};
			return state;

		case actions.UPDATE_PERSONAL_INFO_START:
			state = {
				...state,
				personalInfoLoading: true,
			};
			return state;

		case actions.UPDATE_PERSONAL_INFO_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				personalInfoLoading: false,
				personalInfo: {},
			};
			return state;

		case actions.UPLOAD_COMPANY_LOGO:
			state = {
				...state,
				uploadCompanyLogoLoading: false,
				uploadCompanyLogo: action.payload,
			};
			return state;

		case actions.UPLOAD_COMPANY_LOGO_START:
			state = {
				...state,
				uploadCompanyLogoLoading: true,
			};
			return state;

		case actions.UPLOAD_COMPANY_LOGO_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				uploadCompanyLogoLoading: false,
				uploadCompanyLogo: null,
			};
			return state;
			
		
			case actions.UPLOAD_COMPANY_LOGO_RESET:
				state = {
					...state,
					uploadCompanyLogoLoading: false,
					uploadCompanyLogo: action.payload,
				};
				return state;

		case actions.UPDATE_COMPANY_INFO:
			state = {
				...state,
				companyInfoSettingsLoading: false,
				companyInfoSettings: action.payload,
			};
			return state;

		case actions.UPDATE_COMPANY_INFO_START:
			state = {
				...state,
				companyInfoSettingsLoading: true,
			};
			return state;

		case actions.UPDATE_COMPANY_INFO_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				companyInfoSettingsLoading: false,
				companyInfoSettings: {},
			};
			return state;
			
			case actions.UPDATE_COMPANY_INFO_RESET:
			state = {
				...state,
				companyInfoSettingsLoading: false,
				companyInfoSettings: {},
			};
			return state;


		case actions.CHANGE_PASSWORD_START:
			state = {
				...state,
				changePasswordLoading: true,
			};
			return state;
            
            
		case actions.CHANGE_PASSWORD:
			state = {
				...state,
				changePasswordLoading: false,
				changePassword: action.payload,
			};
			return state;

		case actions.CHANGE_PASSWORD_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				changeTransactionPinLoading: false,
				changeTransactionPin: {},
			};
			return state;

		case actions.CHANGE_TRANSACTION_PIN_START:
			state = {
				...state,
				changeTransactionPinLoading: true,
			};
			return state;
            
            
		case actions.CHANGE_TRANSACTION_PIN:
			state = {
				...state,
				changeTransactionPinLoading: false,
				changeTransactionPin: JSON.parse(action.payload),
			};
			return state;

		case actions.CHANGE_TRANSACTION_PIN_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				changeTransactionPinLoading: false,
				changeTransactionPin: null,
			};
			return state;

		case actions.CHANGE_TWO_FACTOR_AUTH_STATUS_START:
			state = {
				...state,
				changeTwoFactorAuthStatusLoading: true,
			};
			return state;
            
            
		case actions.CHANGE_TWO_FACTOR_AUTH_STATUS:
			state = {
				...state,
				changeTwoFactorAuthStatusLoading: false,
				changeTwoFactorAuthStatus: action.payload,
			};
			return state;

		case actions.CHANGE_TWO_FACTOR_AUTH_STATUS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				changeTwoFactorAuthStatusLoading: false,
				changeTwoFactorAuthStatus: null,
			};
			return state;

		case actions.CHANGE_TWO_FACTOR_AUTH_STATUS_RESET:
			state = {
				...state,
				changeTwoFactorAuthStatusLoading: false,
				changeTwoFactorAuthStatus: null,
			};
			return state;

		case actions.SEND_TRANSACTION_PIN_OTP_START:
			state = {
				...state,
				sendTransactionPinOtpLoading: true,
			};
			return state;
            
            
		case actions.SEND_TRANSACTION_PIN_OTP:
			state = {
				...state,
				sendTransactionPinOtpLoading: false,
				sendTransactionPinOtp: action.payload,
			};
			return state;

		case actions.SEND_TRANSACTION_PIN_OTP_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				sendTransactionPinOtpLoading: false,
				sendTransactionPinOtp: {},
			};
			return state;

		case actions.UPDATE_DAILY_TRANSACTION_LIMIT_START:
			state = {
				...state,
				dailyTransactionLimitLoading: true,
				dailyTransactionLimit: {}
			};
			return state;

		case actions.UPDATE_DAILY_TRANSACTION_LIMIT:
			state = {
				...state,
				dailyTransactionLimitLoading: false,
				dailyTransactionLimit: action.payload,
			};
			return state;

		case actions.UPDATE_DAILY_TRANSACTION_LIMIT_FAILED:
			// errorReducer(action.payload);
			state = {
				...state,
				dailyTransactionLimitLoading: false,
				dailyTransactionLimit: {},
			};
			return state;

		case actions.UPDATE_DAILY_TRANSACTION_LIMIT_RESET:
			state = {
				...state,
				dailyTransactionLimitLoading: false,
				dailyTransactionLimit: {},
			};
			return state;

		case actions.UPDATE_SINGLE_TRANSACTION_LIMIT_START:
			state = {
				...state,
				singleTransactionLimitLoading: true,
				singleTransactionLimit: {}
			};
			return state;

		case actions.UPDATE_SINGLE_TRANSACTION_LIMIT:
			state = {
				...state,
				singleTransactionLimitLoading: false,
				singleTransactionLimit: action.payload,
			};
			return state;

		case actions.UPDATE_SINGLE_TRANSACTION_LIMIT_FAILED:
			// errorReducer(action.payload);
			state = {
				...state,
				singleTransactionLimitLoading: false,
				singleTransactionLimit: {},
			};
			return state;

		case actions.UPDATE_SINGLE_TRANSACTION_LIMIT_RESET:
			state = {
				...state,
				singleTransactionLimitLoading: false,
				singleTransactionLimit: {},
			};
			return state;

		case actions.GET_TRANSACTION_LIMIT_START:
			state = {
				...state,
				transactionLimitLoading: true,
				transactionLimit: {}
			};
			return state;

		case actions.GET_TRANSACTION_LIMIT:
			state = {
				...state,
				transactionLimitLoading: false,
				transactionLimit: action.payload,
			};
			return state;

		case actions.GET_TRANSACTION_LIMIT_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				transactionLimitLoading: false,
				transactionLimit: {},
			};
			return state;

		case actions.GET_DEFAULT_TRANSACTION_LIMIT_START:
			state = {
				...state,
				defaultTransactionLimitLoading: true,
				defaultTransactionLimit: {}
			};
			return state;

		case actions.GET_DEFAULT_TRANSACTION_LIMIT:
			state = {
				...state,
				defaultTransactionLimitLoading: false,
				defaultTransactionLimit: action.payload,
			};
			return state;

		case actions.GET_DEFAULT_TRANSACTION_LIMIT_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				defaultTransactionLimitLoading: false,
				defaultTransactionLimit: {},
			};
			return state;

		case actions.GET_DEFAULT_TRANSACTION_LIMIT_RESET:
			state = {
				...state,
				defaultTransactionLimitLoading: false,
				defaultTransactionLimit: {},
			};
			return state;

		default:
			return state;
	}
}
