import * as actions from "./actionTypes";
import * as config from "config";
// import store from "store/store";
// import ObjectToQueryString from 'utils/ObjectToQueryString';

export function getAllNotifications(){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiBaseUrl,
            url: config.apiGetAllNotifications,
            method: "get",
            data: {},
            onStart: actions.GET_ALL_NOTIFICATIONS_START,
            onSuccess: actions.GET_ALL_NOTIFICATIONS,
            onError: actions.GET_ALL_NOTIFICATIONS_FAILED,
        },
    }
}

export function markAllNotificationsAsRead(){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiBaseUrl,
            url: config.apiMarkAllNotificationAsRead,
            method: "patch",
            data: {},
            onStart: actions.MARK_ALL_NOTIFICATION_AS_READ_START,
            onSuccess: actions.MARK_ALL_NOTIFICATION_AS_READ,
            onError: actions.MARK_ALL_NOTIFICATION_AS_READ_FAILED,
        },
    }
}

export function markSingleNotificationsAsRead(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiBaseUrl,
            url: config.apiMarkSingleNotificationAsRead + "/" + id,
            method: "patch",
            data: {},
            onStart: actions.MARK_SINGLE_NOTIFICATION_AS_READ_START,
            onSuccess: actions.MARK_SINGLE_NOTIFICATION_AS_READ,
            onError: actions.MARK_SINGLE_NOTIFICATION_AS_READ_FAILED,
        },
    }
}

export function resetMarkSingleNotificationAsRead() {
    return {
        type: actions.RESET_MARK_SINGLE_NOTIFICATION_AS_READ,
        payload: {}
    }
}