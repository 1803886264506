import * as actions from "./actionTypes";
import * as config from "config";
import ObjectToQueryString from 'utils/ObjectToQueryString';


export function getInfo(companyId) {
    return {
        type: config.apiRequestStart,
        payload: {
            // url: config.apiGetCompanyInfo,
            url: config.apiGetCompanyInfo + "/" + companyId,
            method: "get",
            data: {},
            onStart: actions.GET_INFO_START,
            onSuccess: actions.GET_INFO,
            onError: actions.GET_INFO_FAILED,
        },
    }
}

export function editLegalInfo(params, silent = false) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateLegalInfo,
            method: "patch",
            data: params,
            onStart: actions.UPDATE_LEGAL_INFO_START,
            onSuccess: (silent === 'silent' ? actions.UPDATE_LEGAL_INFO_SILENT : actions.UPDATE_LEGAL_INFO),
            onError: actions.UPDATE_LEGAL_INFO_FAILED,
        },
    }
}

export function resetEditLegalInfo() {
    return {
        type: actions.UPDATE_LEGAL_INFO_RESET,
        payload: {},
    }
}

export function editPolicyInfo(params, silent = false) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdatePolicyInfo,
            method: "post",
            data: params,
            onStart: actions.UPDATE_POLICY_INFO_START,
            onSuccess: (silent === 'silent' ? actions.UPDATE_POLICY_INFO_SILENT : actions.UPDATE_POLICY_INFO),
            onError: actions.UPDATE_POLICY_INFO_FAILED,
        },
    }
}

export function resetEditPolicyInfo() {
    return {
        type: actions.UPDATE_POLICY_INFO_RESET,
        payload: {},
    }
}

export function editCompanySettings(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateCompanySettings,
            method: "post",
            data: params,
            onStart: actions.UPDATE_COMPANY_SETTINGS_START,
            onSuccess: actions.UPDATE_COMPANY_SETTINGS,
            onError: actions.UPDATE_COMPANY_SETTINGS_FAILED,
        },
    }
}

export function createBulkEmployeeEVA() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCreateBulkEmployeeEVA,
            method: "post",
            data: {},
            onStart: actions.CREATE_BULK_EMPLOYEE_EVA_START,
            onSuccess: actions.CREATE_BULK_EMPLOYEE_EVA,
            onError: actions.CREATE_BULK_EMPLOYEE_EVA_FAILED,
        },
    }
}

export function togglePayroll(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiPayrollBaseUrl,
            url: config.apiTogglePayroll,
            method: "post",
            data: params,
            onStart: actions.TOGGLE_PAYROLL_START,
            onSuccess: actions.TOGGLE_PAYROLL,
            onError: actions.TOGGLE_PAYROLL_FAILED,
        },
    }
}

export function toggleOffEmployeeEva() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiToggleOffEmployeeEva,
            method: "post",
            data: {},
            onStart: actions.TOGGLE_EMPLOYEE_EVA_OFF_START,
            onSuccess: actions.TOGGLE_EMPLOYEE_EVA_OFF,
            onError: actions.TOGGLE_EMPLOYEE_EVA_OFF_FAILED,
        },
    }
}

export function updateBankStatement(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUploadBankStatement,
            method: "post",
            data: params,
            onStart: actions.UPDATE_BANK_STATEMENT_START,
            onSuccess: actions.UPDATE_BANK_STATEMENT,
            onError: actions.UPDATE_BANK_STATEMENT_FAILED,
        },
    }
}

export function resetReloadPage() {
    return {
        type: actions.RELOAD_PAGE_RESET,
        payload: {},
    }
}

export function getCompanyFees() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetCompanyFees,
            method: "get",
            data: {},
            onStart: actions.GET_COMPANY_FEES_START,
            onSuccess: actions.GET_COMPANY_FEES,
            onError: actions.GET_COMPANY_FEES_FAILED,
        },
    }
}

export function getODPConfiguration() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetODPConfiguration,
            method: "get",
            data: {},
            onStart: actions.GET_ODP_CONFIGURATION_START,
            onSuccess: actions.GET_ODP_CONFIGURATION,
            onError: actions.GET_ODP_CONFIGURATION_FAILED,
        },
    }
}

export function updateODPConfiguration(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateODPConfiguration,
            method: "patch",
            data: params,
            onStart: actions.UPDATE_ODP_CONFIGURATION_START,
            onSuccess: actions.UPDATE_ODP_CONFIGURATION,
            onError: actions.UPDATE_ODP_CONFIGURATION_FAILED,
        },
    }
}

export function resetUpdateODPConfiguration() {
    return {
        type: actions.UPDATE_ODP_CONFIGURATION_RESET,
        payload: {},
    }
}

// Updated UI - Personal Info, Company Details, Login & Security etc

export function uploadProfileImage(file) {

        
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiUploadProfileImage,
            method: "post",
            data: file,
            onStart: actions.UPLOAD_PROFILE_IMAGE_START,
            onSuccess: actions.UPLOAD_PROFILE_IMAGE,
            onError: actions.UPLOAD_PROFILE_IMAGE_FAILED,
        },
    }
}

export function removeProfileImage() {

        
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiRemoveProfileImage,
            method: "post",
            data: {},
            onStart: actions.REMOVE_PROFILE_IMAGE_START,
            onSuccess: actions.REMOVE_PROFILE_IMAGE,
            onError: actions.REMOVE_PROFILE_IMAGE_FAILED,
        },
    }
}

export function updatePersonalInfo(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiUpdatePersonalInfo,
            method: "post",
            data: params,
            onStart: actions.UPDATE_PERSONAL_INFO_START,
            onSuccess: actions.UPDATE_PERSONAL_INFO,
            onError: actions.UPDATE_PERSONAL_INFO_FAILED,
        },
    }
}



export function uploadCompanyLogo(file) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiUploadCompanyLogo,
            method: "post",
            data: file,
            onStart: actions.UPLOAD_COMPANY_LOGO_START,
            onSuccess: actions.UPLOAD_COMPANY_LOGO,
            onError: actions.UPLOAD_COMPANY_LOGO_FAILED,
        },
    }
}


export function resetUploadCompanyLogo() {
	return {
		type: actions.UPLOAD_COMPANY_LOGO_RESET,
		payload: {},
	};
}

export function updateCompanyInfoSettings(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiGrossPayrollBaseUrl,
			url: config.apiUpdateCompanyInfoSettings,
			method: "post",
			data: params,
			onStart: actions.UPDATE_COMPANY_INFO_START,
			onSuccess: actions.UPDATE_COMPANY_INFO,
			onError: actions.UPDATE_COMPANY_INFO_FAILED,
		},
	};
}

export function resetUpdateCompanyInfo() {
	return {
		type: actions.UPDATE_COMPANY_INFO_RESET,
		payload: {},
	};
}


export function getMembers() {
    return {
      type: config.apiRequestStart,
      payload: {
        baseUrl: config.apiGrossPayrollBaseUrl,
        url: config.apiGetMembers,
        method: 'get',
        data: {},
        onStart: actions.GET_MEMBERS_START,
        onSuccess: actions.GET_MEMBERS,
        onError: actions.GET_MEMBERS_FAILED,
      },
    };
}

export function getAllMembers(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiGetAllMembers + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_MEMBERS_START,
            onSuccess: actions.GET_MEMBERS,
            onError: actions.GET_MEMBERS_FAILED,
        },
    }
}

export function inviteMember(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiInviteMember,
            method: "post",
            data: params,
            onStart: actions.INVITE_MEMBER_START,
            onSuccess: actions.INVITE_MEMBER,
            onError: actions.INVITE_MEMBER_FAILED,
        },
    }
}

export function acceptInvite(params, token) {
    return {
      type: config.apiRequestStart,
      payload: {
        baseUrl: config.apiGrossPayrollBaseUrl,
        url: config.apiAcceptInvite + token + '/activate',
        method: 'post',
        data: params,
        onStart: actions.ACCEPT_INVITE_START,
        onSuccess: actions.ACCEPT_INVITE,
        onError: actions.ACCEPT_INVITE_FAILED,
        useEncryption: true,
      },
    };
}

export function resendMemberInvite(email) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiResendMemberInvite + email,
            method: "get",
            data: {},
            onStart: actions.RESEND_MEMBER_INVITE_START,
            onSuccess: actions.RESEND_MEMBER_INVITE,
            onError: actions.RESEND_MEMBER_INVITE_FAILED,
        },
    }
}


export function transferOwnership(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiTransferOwnership + id + '/transfer',
            method: "get",
            data: {},
            onStart: actions.TRANSFER_OWNERSHIP_START,
            onSuccess: actions.TRANSFER_OWNERSHIP,
            onError: actions.TRANSFER_OWNERSHIP_FAILED,
        },
    }
}

export function editTeamMember(params, id) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiEditTeamMember + id,
            method: "patch",
            data: params,
            onStart: actions.EDIT_TEAM_MEMBER_START,
            onSuccess: actions.EDIT_TEAM_MEMBER,
            onError: actions.EDIT_TEAM_MEMBER_FAILED,
        },
    }
}

export function deactivateMember(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiDeactivateMember + id + '/deactivate',
            method: "get",
            data: {},
            onStart: actions.DEACTIVATE_MEMBER_START,
            onSuccess: actions.DEACTIVATE_MEMBER,
            onError: actions.DEACTIVATE_MEMBER_FAILED,
        },
    }
}


export function forgotPasswordMember(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiForgotPasswordMember,
            method: "post",
            data: params,
            onStart: actions.FORGOT_PASSWORD_MEMBER_START,
            onSuccess: actions.FORGOT_PASSWORD_MEMBER,
            onError: actions.FORGOT_PASSWORD_MEMBER_FAILED,
            useEncryption: true,
        },
    }
}

export function forgotPasswordMemberStateReset () {
    return {
        type: actions.FORGOT_PASSWORD_MEMBER_STATE_RESET,
        payload: {},
    }
}


export function resetPasswordMember(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiResetPasswordMember,
            method: "post",
            data: params,
            onStart: actions.RESET_PASSWORD_MEMBER_START,
            onSuccess: actions.RESET_PASSWORD_MEMBER,
            onError: actions.RESET_PASSWORD_MEMBER_FAILED,
            useEncryption: true,
        },
    }
}


export function getRoles() {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiGetRoles,
            method: "get",
            data: {},
            onStart: actions.GET_ROLES_START,
            onSuccess: actions.GET_ROLES,
            onError: actions.GET_ROLES_FAILED,
        },
    }
}

export function getAllRoles(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiGetRoles + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_ROLES_START,
            onSuccess: actions.GET_ROLES,
            onError: actions.GET_ROLES_FAILED,
        },
    }
}

export function createCustomRole(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiCreateCustomRole,
            method: "post",
            data: params,
            onStart: actions.CREATE_CUSTOM_ROLE_START,
            onSuccess: actions.CREATE_CUSTOM_ROLE,
            onError: actions.CREATE_CUSTOM_ROLE_FAILED,
        },
    }
}

export function editRole(params, id) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiEditRole + id,
            method: "patch",
            data: params,
            onStart: actions.EDIT_ROLE_START,
            onSuccess: actions.EDIT_ROLE,
            onError: actions.EDIT_ROLE_FAILED,
        },
    }
}

export function deleteRole(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiDeleteRole + id,
            method: "delete",
            data: {},
            onStart: actions.DELETE_ROLE_START,
            onSuccess: actions.DELETE_ROLE,
            onError: actions.DELETE_ROLE_FAILED,
        },
    }
}

export function getPermission() {
  return {
    type: config.apiRequestStart,
    payload: {
      baseUrl: config.apiGrossPayrollBaseUrl,
      url: config.apiGetPermission,
      method: 'get',
      data: {},
      onStart: actions.GET_PERMISSION_START,
      onSuccess: actions.GET_PERMISSION,
      onError: actions.GET_PERMISSION_FAILED,
    },
  };
}

export function getRolePermission(roleId) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiGetRolePermission + roleId,
            method: "get",
            data: {},
            onStart: actions.GET_ROLE_PERMISSION_START,
            onSuccess: actions.GET_ROLE_PERMISSION,
            onError: actions.GET_ROLE_PERMISSION_FAILED,
        },
    }
}


export function changePassword(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiGrossPayrollBaseUrl,
			url: config.apiChangePassword,
			method: "post",
			data: params,
			onStart: actions.CHANGE_PASSWORD_START,
			onSuccess: actions.CHANGE_PASSWORD,
			onError: actions.CHANGE_PASSWORD_FAILED,
		},
	};
}


export function changeTransactionPin(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiGrossPayrollBaseUrl,
			url: config.apiChangeTransactionPin,
			method: "post",
			data: params,
			onStart: actions.CHANGE_TRANSACTION_PIN_START,
			onSuccess: actions.CHANGE_TRANSACTION_PIN,
			onError: actions.CHANGE_TRANSACTION_PIN_FAILED,
            useEncryption: true,
		},
	};
}

export function changeTwoFactorAuthStatus(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiGrossPayrollBaseUrl,
			url: config.apiChangeTwoFactorAuthStatus,
			method: "post",
			data: params,
			onStart: actions.CHANGE_TWO_FACTOR_AUTH_STATUS_START,
			onSuccess: actions.CHANGE_TWO_FACTOR_AUTH_STATUS,
			onError: actions.CHANGE_TWO_FACTOR_AUTH_STATUS_FAILED,
		},
	};
}

export function resetTwoFactorAuthStatus() {
	return {
		type: actions.CHANGE_TWO_FACTOR_AUTH_STATUS_RESET,
		payload: {},
	};
}

export function sendTransactionPinOtp(token, otpType) {
	return {
		type: config.apiRequestStart,
		payload: {
			// baseUrl: config.apiWalletBaseUrl,
			baseUrl: config.apiGrossPayrollBaseUrl,
			url: config.apiWalletGenerateOtp,
            additionalHeaders: {token},
            
			method: "post",
			data: {
                otpType,
            },
			onStart: actions.SEND_TRANSACTION_PIN_OTP_START,
			onSuccess: actions.SEND_TRANSACTION_PIN_OTP,
			onError: actions.SEND_TRANSACTION_PIN_OTP_FAILED,
		},
	};
}

export function updateDailyTransactionLimit(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url: config.apiUpdateDailyTransactionLimit,
			method: "patch",
			data: params,
			onStart: actions.UPDATE_DAILY_TRANSACTION_LIMIT_START,
			onSuccess: actions.UPDATE_DAILY_TRANSACTION_LIMIT,
			onError: actions.UPDATE_DAILY_TRANSACTION_LIMIT_FAILED,
		},
	};
}

export function updateSingleTransactionLimit(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url: config.apiUpdateSingleTransactionLimit,
			method: "patch",
			data: params,
			onStart: actions.UPDATE_SINGLE_TRANSACTION_LIMIT_START,
			onSuccess: actions.UPDATE_SINGLE_TRANSACTION_LIMIT,
			onError: actions.UPDATE_SINGLE_TRANSACTION_LIMIT_FAILED,
		},
	};
}

export function getTransactionLimit() {
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url: config.apiGetTransactionLimit,
			method: "get",
			data: {},
			onStart: actions.GET_TRANSACTION_LIMIT_START,
			onSuccess: actions.GET_TRANSACTION_LIMIT,
			onError: actions.GET_TRANSACTION_LIMIT_FAILED,
		},
	};
}

export function resetUpdateDailyTransactionLimit(payload) {
	return {
		type: actions.UPDATE_DAILY_TRANSACTION_LIMIT_RESET,
		payload
	};
}

export function resetUpdateSingleTransactionLimit(payload) {
	return {
		type: actions.UPDATE_SINGLE_TRANSACTION_LIMIT_RESET,
		payload
	};
}

export function getDefaultTransactionLimit(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url: config.apiGetDefaultTransactionLimit +  ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.GET_DEFAULT_TRANSACTION_LIMIT_START,
			onSuccess: actions.GET_DEFAULT_TRANSACTION_LIMIT,
			onError: actions.GET_DEFAULT_TRANSACTION_LIMIT_FAILED,
		},
	};
}

export function getDefaultTransactionLimitReset() {
	return {
		type: actions.GET_DEFAULT_TRANSACTION_LIMIT_RESET,
		payload: {},
	};
}