import useLoginInfo from './useLoginInfo';

const useEmployerProducts = () => {
    const { userInfo } = useLoginInfo();
    const products = userInfo?.employer?.products ?? [];
    
    const isOnDemandUser = products?.includes("ON_DEMAND_PAY");
    const isBulkPayUser = products?.includes("BULK_PAY");
    const isPayrollUser = products?.includes("PAYROLL");

    return { isOnDemandUser, isBulkPayUser, isPayrollUser }
}

export default useEmployerProducts