import { useState } from "react";
import { Tooltip } from "../Tooltip";
import Maybe from "components/__new/common/Maybe";
import { ReactComponent as IconInformationOutline } from "assets/images/icons/project-icons/information-outline.svg";
import validate from "utils/validate";

export function SelectInput(props) {
    const { value, label, required, tooltip, tooltipBg, tooltipTextColor, tooltipClassName, tooltipPlace, defaultValue, readonly, disabled, defaultProps, onChange, containerClass, selectClass, validationName, checkFormValidation, ...rest } = props;
    const [errors, setErrors] = useState({});
    
    const doValidation = (e) => {
        if (validationName !== undefined){
            const data = {[validationName]: e.target.value};
            const vErrors = validate(data);
            if (vErrors){
                setErrors(vErrors);
            }
            else{
                setErrors({});
            }
            checkFormValidation(undefined, validationName, e.target.value);
        }
    }
    
    return (
        <div className={`form-group ${containerClass || ""}`}>
            <Maybe condition={label !== "" && label !== " "}>
                <label>
                    {label || ""}
                    <Maybe condition={required === true}>
                        <span className="form-input-required">*</span>
                    </Maybe>
                    <Maybe condition={tooltip !== undefined}>
                        <Tooltip 
                            id={label || ""} 
                            className="tooptip-size max-w-[250px]"
                            backgroundColor={tooltipBg}
                            textColor={tooltipTextColor}
                            tooltipClassName={tooltipClassName}
                            place={tooltipPlace}
                        >
                            {tooltip}
                        </Tooltip>
                    </Maybe>
                </label>
            </Maybe>

            <select
                className={"select-input " + (selectClass || "") + " " + ((readonly || disabled) === true && "disabled")}
                
                value={value}
                defaultValue={defaultValue}
                onChange={(e) => {onChange(e); doValidation(e)}}
                disabled={disabled}

                readOnly={readonly || disabled}
                {...defaultProps }
                {...rest}
            >
                {props.children}
            </select>
            
            <Maybe condition={errors[validationName] !== undefined}>
                <div className="form-input-message">
                    {errors[validationName]}
                </div>
            </Maybe>
        </div>
    );
}

export default SelectInput;
