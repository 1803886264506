import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Spinner = ({message, spinnerTableInfoClassNameReplace }) => {
    return (
    <div className="mx-auto">
        <div 
            className={`${spinnerTableInfoClassNameReplace} table-info`}
        >
            <FontAwesomeIcon icon="circle-notch" spin />
            <div className="font-bold uppercase">
                {message ||"Loading"}
            </div>
        </div>
    </div>
    )
}

export default Spinner