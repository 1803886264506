import { Link } from "react-router-dom";
import errorIcon from "assets/images/icons/error-icon-only.svg";
import useBackButton from "hooks/useBackButton";
import useLoginInfo from "hooks/useLoginInfo";
import Maybe from "components/__new/common/Maybe";
import DynamicHeadTag from "components/__new/common/DynamicHeadTag";

const Error401 = () => {
    const {goBack} = useBackButton();
    const {isLogin, logoutUser} = useLoginInfo();
    
    return (
        <>
            <DynamicHeadTag
                headerText="401 - Unauthorized"
            />


            <div className="w-screen h-screen flex overflow-hidden">
                <div className="m-auto px-8 text-center max-w-md">

                    <div className="h-24 sm:h-32 md:h-56">
                        <img src={errorIcon} alt="EarniPay" className="h-full object-contain mx-auto" />
                    </div>
                    <div className="mt-8 font-semibold text-2xl sm:text-3xl">
                        401 - Unauthorized
                    </div>
                    <div className="mt-4 text-gray-500">
                        Sorry, you are not permitted to access this page.
                        Please contact us if you feel this is an error from us.
                    </div>

                    <div className="mt-8 space-y-2">
                        <button type="button" onClick={goBack} className="btn btn-block btn-lg btn-ep-primary mx-auto">
                            Back to previous page
                        </button>
                        
                        <Maybe condition={isLogin === true}>
                            <button type="button" onClick={logoutUser} className="btn btn-lg btn-block text-ep-primary font-semibold mt-3 hover:bg-gray-100">
                                Login as another user
                            </button>
                        </Maybe>

                        <Maybe condition={isLogin === false}>
                            <Link to="/login" className="btn btn-lg btn-block text-ep-primary font-semibold mt-3 hover:bg-gray-100">
                                Login to your account
                            </Link>
                        </Maybe>
                    </div>

                </div>
            </div>
        </>
    )
}


export default Error401;