import jwt_decode from "jwt-decode";
import * as actions from "./actionTypes";

const initialState = {
    loggedIn: false,
    data: {},
    signedUp: false,
    signupUser: {},
    mainBusinessState: null,
};


// - Employer logs in
// - On switching, keep token of old one somewhere
// - put the new token as the mainLoggedinUser & filter by employerList (of old user if exist or of new user if it doesn’t exist)
// - display the name & job title & all of the old guy when logged in as the new guy
// - when loggedin as subEmployer, attach header: x_default_request: token_of_main_userId


export default function reducer(state = initialState, action) {
    let parsedData;
    
    if (action?.payload && typeof action?.payload === "string") {
        parsedData = JSON.parse(action?.payload);
    } else {
        parsedData = action?.payload;
    }

    switch (action.type) {
      case actions.LOGIN_USER:
        if (parsedData?.data?.stage === 2) {
          state = {
            ...state,
            loggedIn: false,
            data: parsedData,
            signedUp: false,
          };
          return state;
        } else {
          /**
           * To enable the employer-admin have the ability
           * to perform the same compliance actions as the main employer
           */
          let mainEmployerData = {};
          const mainEmployer = parsedData?.data?.mainEmployerId;
          if (mainEmployer && Object.keys(mainEmployer).length > 0) {
            mainEmployerData = {
              adminVerify: mainEmployer.adminVerify,
              isActive: mainEmployer.isActive,
              isCompliant: mainEmployer.isCompliant,
              products: [...mainEmployer.products],
              rejectComment: [...mainEmployer.rejectComment],
              status: mainEmployer.status,
            };
          }

          /**
           * To exclude sub-accounts from
           * 1. having to fill compliance
           * 2. all compliance restrictions
           */
          let complianceData = {};
          if (parsedData.data?.employer?.accountType === 'sub') {
            complianceData = {
              adminVerify: true,
              isActive: true,
              isCompliant: true,
              status: 'approved',
            };
          }
          state = {
            ...state,
            loggedIn: true,
            data: {
              token: parsedData?.data?.token,
              tokenExpiry: jwt_decode(parsedData?.data?.token)?.exp,
              name:
                parsedData?.data?.employer?.firstName +
                ' ' +
                parsedData?.data?.employer?.lastName,
              company: {
                ...parsedData?.data?.company,
                selectedCompanyId:
                  parsedData?.data?.company?._id ||
                  parsedData?.data?.company?.id,
              },
              employer: {
                ...parsedData?.data?.employer,
                ...mainEmployerData,
                ...complianceData,
                selectedCompanyId:
                  parsedData?.data?.company?._id ||
                  parsedData?.data?.company?.id,
                companyName: parsedData?.data?.company?.companyName,
                mainCompanyId: parsedData?.data?.employer?.company,
                _id: parsedData?.data?.employer?.id,
              },
              mainAccount: {},
              member: parsedData?.data?.member,
            },
            signedUp: false,
          };
          return state;
        }

      case actions.LOGIN_USER_START:
        state = {
          ...state,
          loggedIn: false,
          data: {},
        };
        return state;

      case actions.LOGIN_USER_FAILED:
        state = {
          ...state,
          loggedIn: false,
          data: {},
        };
        return state;

      case actions.UPDATE_LOGIN_USER:
        state = {
          ...state,
          data: {
            ...state.data,
            name: parsedData?.data.firstName + ' ' + parsedData?.data.lastName,
            employer: {
              ...state.data.employer,
              ...parsedData?.data,
              firstName: parsedData?.data.firstName,
              lastName: parsedData?.data.lastName,
              jobTitle: parsedData?.data.jobTitle,
              phoneNumber: parsedData?.data.phoneNumber,
            },
            member: parsedData?.data?.member,
          },
        };
        return state;

      case actions.UPDATE_LOGIN_PROFILE:
        state = {
          ...state,
          data: {
            ...state.data,
            ...parsedData,
          },
        };
        return state;

      case actions.ADMIN_LOGIN_USER:
        state = {
          ...state,
          loggedIn: true,
          data: {
            token: parsedData?.token,
            tokenExpiry: jwt_decode(parsedData?.token)?.exp,
            _id: parsedData?._id,
            firstName: parsedData?.firstName,
            lastName: parsedData?.lastName,
            name: parsedData?.firstName + ' ' + parsedData?.lastName,
            email: parsedData?.email,
            phoneNumber: parsedData?.phoneNumber,
            userType: parsedData?.userType,
            jobTitle: parsedData?.jobTitle,
            isActive: parsedData?.isActive,
            disabled: parsedData?.disabled,
            accountType: parsedData?.accountType,
            alias: parsedData?.alias,
            employerList: parsedData?.employerList,
          },
          signedUp: false,
          member: parsedData?.member,
        };
        return state;

      case actions.LOGOUT_USER:
        state = {
          ...state,
          loggedIn: false,
          data: {},
        };
        return state;

      case actions.SIGNUP_1_USER:
        state = {
          ...state,
          loggedIn: false,
          data: {},
          signedUp: false,
        };
        return state;

      case actions.SIGNUP_2_USER:
        state = {
          ...state,
          signedUp: true,
          signupUser: {
            name: parsedData?.user.name,
            userId: parsedData?.user.userId,
            email: parsedData?.user.email,
          },
        };
        return state;

      case actions.SIGNUP_USER_START:
        state = {
          ...state,
          loggedIn: false,
          data: {},
          signupUser: {},
        };
        return state;

      case actions.SIGNUP_USER_FAILED:
        state = {
          ...state,
          signupUser: {},
        };
        return state;

      case actions.LOGIN_SUB_ACCOUNTS:
        /**
         * To retain main-account (main-employer)'s data
         * so that it can be possible to switch back to the main employer account
         */
        let mainAccountData = {};
        if (Object.keys(state.data?.mainAccount).length === 0) {
          mainAccountData = {
            token: state.data.token,
            tokenExpiry: jwt_decode(state.data.token)?.exp,
            name: state.data.name,
            company: { ...state.data.company },
            employer: { ...state.data.employer },
            member: { ...state.data.member },
          };
        } else {
          mainAccountData = { ...parsedData?.mainAccountData };
        }

        /**
         * To exclude sub-accounts from
         * 1. having to fill compliance
         * 2. all compliance restrictions
         */
        let complianceData = {
          adminVerify: true,
          isActive: true,
          isCompliant: true,
          status: 'approved',
        };

        state = {
          ...state,
          data: {
            token: parsedData?.subUserToken,
            tokenExpiry: jwt_decode(mainAccountData?.token)?.exp,
            name:
              mainAccountData?.employer?.firstName +
              ' ' +
              mainAccountData?.employer?.lastName,
            company: {
              ...parsedData?.subAccountData.company,
              selectedCompanyId:
                parsedData?.subAccountData?.company?._id ||
                parsedData?.subAccountData?.company?.id,
            },
            employer: {
              ...parsedData?.subAccountData,
              ...complianceData,
              selectedCompanyId:
                parsedData?.subAccountData?.company?._id ||
                parsedData?.subAccountData?.company?.id,
              companyName: mainAccountData?.employer?.companyName,
              mainCompanyId: mainAccountData?.employer?.company,
              accountType: 'sub',
            },
            member: mainAccountData.member,
            mainAccount: {
              ...mainAccountData,
            },
          },
          signedUp: false,
        };

        if (state?.mainBusinessState === null) {
          state = {
            ...state,
            mainBusinessState: state,
          };
        }
        return state;

      case actions.LOGIN_MAIN_ACCOUNTS:
        state = {
          ...state,
          data: {
            ...state.data.mainAccount,
            mainAccount: {},
          },
        };
        return state;

      case actions.RESET_USER_MAIN_ACCOUNT:
        state = {
          ...state,
          data: {
            ...state.data,
            mainAccount: {},
          },
        };
        return state;

      case actions.RESET_USER_INITIAL_STATE:
        state = {
          ...state.mainBusinessState,
          mainBusinessState: null,
        };
        return state;

      default:
        return state;
    }
}
