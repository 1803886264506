import { useState } from "react";
import ReactToolTip from "react-tooltip";
import Maybe from "components/__new/common/Maybe";
import { ReactComponent as IconInformationOutline } from "assets/images/icons/project-icons/information-outline.svg";
import validate from "utils/validate";
import { validationGenerics } from "data/validationGenerics";
import CurrencyFormat from "utils/currencyFormat";

export const Input = (props) => {
    const {
        type,
        showInsufficientBalanceValidation,
        showBalance, balanceFigure,
        label,
        placeholder,
        min,
        max,
        step,
        value,
        onChange,
        onKeyUp,
        onBlur,
        required,
        multiple,
        accept,
        tooltip,
        readonly,
        disabled,
        leftIcon,
        leftIconClass,
        leftIconInputClass,
        leftIconClicked,
        rightIcon,
        rightIconClass,
        rightIconClicked,
        defaultProps,
        containerClass,
        inputClass,
        autoComplete,
        validationName,
        checkFormValidation,
        tagText,
        tagTextClass,
        tagTextIsRed,
        showCharacterLength,
        characterCount,
        maxLength,
        displayCharacterCountInside,
        displayBesideLabel,
        className,
        shouldNotValidate,
        name,
        ...rest
    } = props;
    const [errors, setErrors] = useState({});
    
    const doValidation = (e) => {
        if (validationName !== undefined){
            const data = {[validationName]: e.target.value};
            const vErrors = validate(data);
            if (vErrors){
                setErrors(vErrors);
            }
            else{
                setErrors({});
            }
            checkFormValidation && checkFormValidation(undefined, validationName, e.target.value);
        }
    }
    
    return (
        <>
            <Maybe condition={tooltip !== undefined}>
                <ReactToolTip id="title-info" className="tooptip-size max-w-[250px]">
                    {tooltip}
                </ReactToolTip>
            </Maybe>

            <div className={`form-group ${containerClass || ""}`}>
                <div className="flex justify-between items-center">
                    <Maybe condition={label !== "" && label !== " "}>
                        <label>
                            {label || ""}
                            <Maybe condition={required === true}>
                                <span className="form-input-required">*</span>
                            </Maybe>
                            <Maybe condition={tooltip !== undefined}>
                                <IconInformationOutline className="svg-stroke-black w-4 ml-2" data-tip data-for="title-info" />
                            </Maybe>
                        </label>
                    </Maybe>

                    <Maybe condition={showCharacterLength && displayBesideLabel}>
                        <span className="text-[#121619] text-sm">{characterCount || 0}/{maxLength}</span>
                    </Maybe>
                </div>

                <div className="relative">
                    <Maybe condition={leftIcon}>
                        <div className={`${leftIconClass} w-max h-full flex justify-center items-center absolute text-gray-500 ${(leftIconClicked && "cursor-pointer")}`} onClick={leftIconClicked ?? null}>
                            {leftIcon}
                        </div>
                    </Maybe>

                    <input
                        type={type || "text"}
                        placeholder={placeholder || ""}
                        value={value}
                        // onChange={onChange}
                        // onKeyUp={(e) => doValidation(e)}
                        name={name}
                        onChange={(e) => {
                            !shouldNotValidate && doValidation(e); 
                            onChange(e);
                        }}
                        onKeyUp={onKeyUp}
                        onBlur={onBlur}
                        min={min}
                        max={max}
                        maxLength={maxLength}
                        step={step}
                        multiple={multiple || false}
                        accept={accept || "*"}
                        readOnly={readonly || disabled}
                        autoComplete={autoComplete}
                        displaybesidelabel={displayBesideLabel?.toString()}
                        showcharacterlength={showCharacterLength?.toString()}
                        charactercount={characterCount}
                        displaycharactercountinside={displayCharacterCountInside?.toString()}
                        {...defaultProps}
                        {...rest}
                        className={`
                            ${((readonly || disabled) === true && "disabled")}
                            ${leftIcon ? leftIconInputClass : ""}
                            ${rightIcon ? "pr-10" : ""}
                            ${type === "search" ? "pl-10" : ""}
                            
                            form-input ${inputClass || ""}
                            ${className}
                        `}
                    />
                     <Maybe condition={showCharacterLength && displayCharacterCountInside}>
                        <div className='font-normal space-x-2 absolute top-2 right-3 text-[#9CA3AF] text-[14px]'>
                            {characterCount} / {maxLength}
                        </div>
                    </Maybe>
                    <Maybe condition={showBalance}>
                        <span className="text-[#718096] text-sm">Balance: {CurrencyFormat(balanceFigure / 100 || 0, true)}</span>
                    </Maybe>

                    <Maybe condition={showInsufficientBalanceValidation}>
                        <span className="text-ep-danger-200 text-sm">Insufficient balance</span>
                    </Maybe>

                    <span className={`text-xs transition-opacity duration-500 ${tagText?.length ? "opacity-100" : "opacity-0"} ${tagTextIsRed && "text-ep-danger-200"} ${tagTextClass}`}>{tagText}</span>

                    <Maybe condition={rightIcon}>
                        <div className={`${rightIconClass} w-max h-full flex justify-center items-center absolute top-0 right-0 text-gray-500 ${(rightIconClicked && "cursor-pointer")}`} onClick={rightIconClicked ?? null}>
                            {rightIcon}
                        </div>
                    </Maybe>
                </div>
                
                <Maybe condition={errors[validationName] !== undefined}>
                    <div className="form-input-message">
                        <Maybe condition={validationGenerics?.includes(validationName) === true}>
                            {label?.toLowerCase()}&nbsp;
                        </Maybe>
                        {errors[validationName]}
                    </div>
                </Maybe>
            </div>
        </>
    )
}


export default Input
