import { useEffect, useState } from "react";
import Checkbox from "./Checkbox";

export function CheckboxGroupSelector(props) {
  const { selectOptions, onValueChange, defaultProps, allowValueChange } = props;
  const [items, setItems] = useState(selectOptions);

  const handleClick = (data, index) => {
    if (allowValueChange === undefined || allowValueChange === true){
      setItems(
        items.map((obj, i) => {
          if (index === i) {
            return { ...obj, selected: true };
          }
          return { ...obj, selected: false };
        })
      );
      onValueChange(data)
    }
  }

  useEffect(() => {
    setItems(selectOptions);
  }, [selectOptions])
  

  return (
    <div className="flex flex-wrap">
      {items.map((item, i) => (
        <div key={i} className="mr-8 mb-2">
          <input {...defaultProps} type="hidden" />
          
          <div onClick={() => handleClick(item?.value, i)}>
            <Checkbox
              type="radio"
              label={item?.label}
              checked={item?.selected ? true : false}
              readonly={true}
            />
          </div>

        </div>
      ))}
    </div>
  );
}

export default CheckboxGroupSelector;
