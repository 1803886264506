/*
export const CurrencyFormat = (amount) => {

  if (!isNaN(amount)){

    const formatter = new Intl.NumberFormat('en-US', {
      // style: 'currency',
      // currency: 'USD',
      // currency: '₦',
      // currencyDisplay: 'narrowSymbol',
      // minimumFractionDigits: 2,
    });
  
    return ("₦"+formatter.format(amount));
    
  }

};
*/

export const CurrencyFormat = (amount, alwaysShowKobo = false) => {
  if (!isNaN(amount) && amount !== null && amount !== undefined){
    let data = parseFloat(amount).toFixed(2);
    if (alwaysShowKobo === false){
      data = parseFloat(data);                                    //removes trailing .00
    }
    data = data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")  // formats into separate-3-numbers-with-comma
    return ("₦" + data);
  }
  else{
    return amount
  }
};

export const CurrencyFormatWithoutSymbol = (amount) => {
  const numberString = amount?.toString().replace(/[^\d]/g, '');
  return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const CurrencyFormatKoboOnly = (amount) => {
  if (!isNaN(amount) && amount !== null && amount !== undefined){
    let data = amount.toString().split(".");
    return (data[1] ?? null);
  }
  else{
    return amount
  }
};


export default CurrencyFormat;