export const isValidJSON = (data) => {
  try {
    JSON.parse(data);
    return true;
  } catch (err) {
    return false;
  }
};

export const getUniqueFileName = (label) => {
  return `${label}-${Date.now().toString(36)}`;
};

export const removeEmptyFields = (obj) => {
  Object.keys(obj)?.forEach((key) => obj[key] === '' && delete obj[key]);
  return obj;
};

export const splitStringAtPosition = (inputString, position) => {
  const part1 = inputString.slice(0, position); // From the start to the position (exclusive)
  const part2 = inputString.slice(position); // From the position to the end
  return [part1, part2];
};
