import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import NavigationLinks from 'components/__new/general/NavigationLinks.jsx';
import { Link } from "react-router-dom";
import logo from "assets/images/icons/logo-color2@svg.svg";
import logoIcon from "assets/images/icons/logo-icon@svg.svg";
import sidebarToggle from "assets/images/icons/project-icons/sidebar-toggle.svg";
import Maybe from "./Maybe";
import ReactTooltip from "react-tooltip";
// import SubAccountSwitch from "components/__new/subAccounts/SubAccountSwitch";
import * as preferencesAction from "store/ui/preferences/action";


const Sidebar = () => {
    const dispatch = useDispatch();
    const { isSidebarCollapsed: savedSidebarPreference } = useSelector((s) => s.ui.preferences);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(savedSidebarPreference);
    
    
    const toggleSidebarSize = (e) => {
        const obj = {
            "expand": false,
            undefined: !isSidebarCollapsed,
        }
        setIsSidebarCollapsed(obj[e]);
        dispatch(preferencesAction.updateSidebarPreference(obj[e]));
    }
    
    
    return ( 
        <div className="h-screen flex-none fixed z-50 bg-white hidden md:block border-r-[1px] border-ep-gray-200" style={{width: (isSidebarCollapsed ? "60px":"250px")}}>
                
            <div className={`h-20 ${isSidebarCollapsed ? "px-4" : "px-4" } border-b border-white border-opacity-10`}>
                <Maybe condition={isSidebarCollapsed === false}>
                    <div className="w-full h-full flex justify-between items-center">
                        <Link to="/" className="w-full h-14 p-3 mt-1 block rounded-lg" style={{boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.15)"}}>
                            <img src={logo} alt="Earnipay logo" className="h-full object-contain" />
                        </Link>
                        {/* 
                        <div onClick={() => toggleSidebarSize()} data-tip data-for="info-sidebar-toggle-collapse" className="w-6 h-6 rounded hover:bg-[#002B30] cursor-pointer">
                            <img src={sidebarToggle} alt="collapse" className="h-full object-contain" />
                        </div>
                        */}
                        {/* <SubAccountSwitch /> */}
                    </div>
                    
                    {/* 
                    <ReactTooltip id="info-sidebar-toggle-collapse" place="right" className="tooptip-size">
                        Collapse
                    </ReactTooltip>
                    */}
                </Maybe>

                <Maybe condition={isSidebarCollapsed === true}>
                    <div className="w-full h-full flex justify-center items-center">
                        <div onClick={() => toggleSidebarSize()} data-tip data-for="info-sidebar-toggle-expand" className="h-6 inline-block group cursor-pointer">
                            <img src={logoIcon} alt="EarniPay logo icon" className="h-full object-contain block group-hover:hidden" />
                            <img src={sidebarToggle} alt="collapse" className="h-full object-contain hidden group-hover:block" />
                        </div>
                    </div>
                    
                    <ReactTooltip id="info-sidebar-toggle-expand" place="right" className="tooptip-size">
                        Expand
                    </ReactTooltip>
                </Maybe>
            </div>
            

            <div className="sidebar-body h-screen pb-32 overflow-auto">
                <NavigationLinks
                    view="desktop"
                    isSidebarCollapsed={isSidebarCollapsed}
                    toggleSidebarSize={(e) => toggleSidebarSize(e)}
                />
            </div>

        </div>
    )
}


export default Sidebar;