import React from 'react';
import OnboardingFeatureWrapper from '../common/OnboardingFeatureWrapper';
import { ReactComponent as CreditBg } from 'assets/images/icons/onboarding-feature/credit-bg.svg';
import { ReactComponent as Cash } from 'assets/images/icons/onboarding-feature/cash.svg';
const Credit = () => {
  return (
    <div>
      <OnboardingFeatureWrapper
        headerText="Business Loans"
        headerSubText="Access fast and convenient loans tailored to your business needs"
        headerColor="text-[#6B4C31]"
        bodyText="We offer tailored financing solutions to ambitious businesses. Complete the compliance process to gain access to the credit menu and submit an application today."
        bgColor="bg-[#FFF7EE]"
        bgImage={<CreditBgWrapper />}
      />
    </div>
  );
};

const CreditBgWrapper = () => {
  return (
    <div className="hidden sm:block relative w-full h-full overflow-hidden">
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2">
        <CreditBg />
      </div>
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2">
        <Cash />
      </div>
    </div>
  );
};

export default Credit;
