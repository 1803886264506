import store from "store/store";
import * as actions from "./actionTypes";
import * as config from "config";
import { customInfoToast } from "utils/customToast";


function getMainAccountToken(){
    const user = store.getState().auth.userPersist.data;
    const mainAccountToken = Object.keys(user.mainAccount).length > 0 ? user.mainAccount.token : "";
    return mainAccountToken;
}


export function loginUser(data) {
    const params = data?.stage === 1 ? ({ email: data?.email, password: data?.password, stage: data?.stage, ipAddress: data?.ipAddress  }) : { email: data?.email, password: data?.password, stage: data?.stage, otp: data?.otp, ipAddress: data?.ipAddress };
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiLoginUser,
            method: "post",
            data: params,
            onStart: actions.LOGIN_USER_START,
            onSuccess: actions.LOGIN_USER,
            onError: actions.LOGIN_USER_FAILED,
            useEncryption: true,
        },
    }
}

export function earnipayAdminLogin(payload) {
    return {
        type: actions.ADMIN_LOGIN_USER,
        payload,
    }
}

export function signUp1(form) {
    return {
        type: actions.SIGNUP_1_USER,
        payload: { form }
    }
}

export function signUp2(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRegisterUser,
            method: "post",
            data: params,
            onStart: actions.SIGNUP_USER_START,
            onSuccess: actions.SIGNUP_2_USER,
            onError: actions.SIGNUP_USER_FAILED
        }
    }
}

export function verifyAccount(id, activationToken) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiVerifyEmail,
            method: "post",
            data: {
                id: id,
                activationToken: activationToken,
            },
            onStart: actions.VERIFY_ACCOUNT_START,
            onSuccess: actions.VERIFY_ACCOUNT,
            onError: actions.VERIFY_ACCOUNT_FAILED
        }
    }
}

export function resendVerifyEmail(email) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiResendVerifyEmail,
            method: "post",
            data: {
                email: email,
            },
            onStart: actions.RESEND_VERIFY_EMAIL_START,
            onSuccess: actions.RESEND_VERIFY_EMAIL,
            onError: actions.RESEND_VERIFY_EMAIL_FAILED
        }
    }
}

export function resetResendVerifyEmail() {
    return {
        type: actions.RESEND_VERIFY_EMAIL_RESET,
        payload: {},
    }
}

export function forgotPassword(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiForgotPassword,
            method: "post",
            data: params,
            onStart: actions.FORGOT_PASSWORD_START,
            onSuccess: actions.FORGOT_PASSWORD,
            onError: actions.FORGOT_PASSWORD_FAILED
        }
    }
}

export function resetForgotPassword() {
    return {
        type: actions.FORGOT_PASSWORD_RESET,
        payload: {},
    }
}

export function resetPassword(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiResetPassword,
            method: "post",
            data: {
                email: params.email,
                resetPin: +params.pin,
                password: params.password,
                password_confirmation: params.confirmPassword,
            },
            onStart: actions.RESET_PASSWORD_START,
            onSuccess: actions.RESET_PASSWORD,
            onError: actions.RESET_PASSWORD_FAILED,
            useEncryption: true,
        }
    }
}

export function resetResetPassword() {
    return {
        type: actions.RESET_PASSWORD_RESET,
        payload: {},
    }
}

export function logoutUser() {
    customInfoToast({
        message: 'Logging out, please wait',
    }, {
        autoClose: 2000,
    });
    
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiLogoutUser,
            method: "post",
            data: {},
            onStart: actions.LOGOUT_USER_START,
            onSuccess: actions.LOGOUT_USER,
            onError: actions.LOGOUT_USER
        }
    }

    // return {
    //     type: actions.LOGOUT_USER,
    //     payload: {}
    // }
}

export function getReferralPoints() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReferralPoints,
            method: "get",
            data: {},
            onStart: actions.GET_REFERRAL_POINTS_START,
            onSuccess: actions.GET_REFERRAL_POINTS,
            onError: actions.GET_REFERRAL_POINTS_FAILED
        }
    }
}

export function getSubAccounts(params) {
    const mainAccountToken = getMainAccountToken();
    
    let payload = {
        url: config.apiGetSubAccounts,
        method: "post",
        data: params,
        onStart: actions.GET_SUB_ACCOUNTS_START,
        onSuccess: actions.GET_SUB_ACCOUNTS,
        onError: actions.GET_SUB_ACCOUNTS_FAILED,
    }

    if (mainAccountToken !== ""){
        payload.customToken = mainAccountToken
    }

    return {
        type: config.apiRequestStart,
        payload,
    }
}

export function addSubAccount(params) {
    const mainAccountToken = getMainAccountToken();
    
    let payload = {
        url: config.apiAddSubAccount,
            method: "post",
            data: params,
            onStart: actions.ADD_SUB_ACCOUNTS_START,
            onSuccess: actions.ADD_SUB_ACCOUNTS,
            onError: actions.ADD_SUB_ACCOUNTS_FAILED,
    }

    if (mainAccountToken !== ""){
        payload.customToken = mainAccountToken
    }

    return {
        type: config.apiRequestStart,
        payload,
    }
}

export function saveSubAccountForm(params) {
    return {
        type: actions.SAVE_SUB_ACCOUNT_FORM,
        payload: params,
    }
}

export function loginSubAccount(params) {
    const mainAccountToken = getMainAccountToken();

    let payload = {
        url: config.apiLoginSubAccount,
        method: "post",
        data: params,
        onStart: actions.LOGIN_SUB_ACCOUNTS_START,
        onSuccess: actions.LOGIN_SUB_ACCOUNTS,
        onError: actions.LOGIN_SUB_ACCOUNTS_FAILED,
    }

    if (mainAccountToken !== ""){
        payload.customToken = mainAccountToken
    }

    return {
        type: config.apiRequestStart,
        payload,
    }
}

export function loginMainAccount() {
    return {
        type: actions.LOGIN_MAIN_ACCOUNTS,
        payload: {},
    }
}

export function resetUserMainAccount() {
    return {
        type: actions.RESET_USER_MAIN_ACCOUNT,
        payload: {},
    }
}

export function resetUserInitialState() {
    return {
        type: actions.RESET_USER_INITIAL_STATE,
        payload: {},
    }
}

export function saveUserModules(params) {
    return {
        type: actions.SAVE_USER_MODULES,
        payload: params,
    }
}

export function saveNewUserdetails(params) {
    return {
        type: actions.SAVE_NEW_USER_DETAILS,
        payload: params,
    }
}

export function clearSignupInfo() {
    return {
        type: actions.CLEAR_SIGNUP_INFO,
        payload: {},
    }
}

export function resendTwoFaOtp(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiResendTwoFaOtp,
            method: "post",
            data: params,
            onStart: actions.RESEND_TWO_FA_OTP_START,
            onSuccess: actions.RESEND_TWO_FA_OTP,
            onError: actions.RESEND_TWO_FA_OTP_FAILED,
            useEncryption: true,
        }
    }
}

export function requestNewUserOtp(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiRequestNewUserOtp,
            method: "post",
            data: params,
            onStart: actions.REQUEST_NEW_USER_OTP_START,
            onSuccess: actions.REQUEST_NEW_USER_OTP_DONE,
            onError: actions.REQUEST_NEW_USER_OTP_FAILED,
            useEncryption: true,
        }
    }
}

export function clearNewUserOtpData() {
    return {
        type: actions.CLEAR_NEW_USER_OTP_DATA,
        payload: {},
    }
}

export function verifyNewUserOtp(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiVerifyNewUserOtp,
            method: "post",
            data: params,
            onStart: actions.VERIFY_NEW_USER_OTP_START,
            onSuccess: actions.VERIFY_NEW_USER_OTP_DONE,
            onError: actions.VERIFY_NEW_USER_OTP_FAILED
        }
    }
}

export function verifyOtpAndCreateEmployer(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiVerifyOtpAndCreateEmployer,
            method: "post",
            data: params,
            onStart: actions.VERIFY_OTP_AND_CREATE_EMPLOYER_START,
            onSuccess: actions.VERIFY_OTP_AND_CREATE_EMPLOYER,
            onError: actions.VERIFY_OTP_AND_CREATE_EMPLOYER_FAILED,
            useEncryption: true,
        }
    }
}

export function createNewUser(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiCreateNewUser,
            method: "post",
            data: params,
            onStart: actions.CREATE_NEW_USER_START,
            onSuccess: actions.CREATE_NEW_USER_DONE,
            onError: actions.CREATE_NEW_USER_FAILED,
            useEncryption: true,
        }
    }
}