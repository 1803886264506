import { combineReducers } from "redux";
import banksReducer from "./banks/reducer";
import bvnReducer from "./bvn/reducer";
import companiesReducer from "./companies/reducer";
import selectionsReducer from "./selections/reducer";
import mediaServiceReducer from "./mediaService/reducer";
import preferencesReducer from "./preferences/reducer";

export default combineReducers({
    banks: banksReducer,
    bvn: bvnReducer,
    companies: companiesReducer,
    selections: selectionsReducer,
    mediaService: mediaServiceReducer,
    preferences: preferencesReducer,
});