export const GET_PAYROLLS = "GET_PAYROLLS";
export const GET_PAYROLLS_START = "GET_PAYROLLS_START";
export const GET_PAYROLLS_FAILED = "GET_PAYROLLS_FAILED";

export const DOWNLOAD_GET_PAYROLLS = "DOWNLOAD_GET_PAYROLLS";
export const DOWNLOAD_GET_PAYROLLS_START = "DOWNLOAD_GET_PAYROLLS_START";
export const DOWNLOAD_GET_PAYROLLS_FAILED = "DOWNLOAD_GET_PAYROLLS_FAILED";

export const CREATE_PAYROLL = "CREATE_PAYROLL";
export const CREATE_PAYROLL_START = "CREATE_PAYROLL_START";
export const CREATE_PAYROLL_FAILED = "CREATE_PAYROLL_FAILED";
export const CREATE_PAYROLL_RESET = "CREATE_PAYROLL_RESET";

export const GET_PAYROLL_DETAILS = "GET_PAYROLL_DETAILS";
export const GET_PAYROLL_DETAILS_START = "GET_PAYROLL_DETAILS_START";
export const GET_PAYROLL_DETAILS_FAILED = "GET_PAYROLL_DETAILS_FAILED";

export const GET_PAYROLL_TAX_AND_DEDUCTION_SUMMARY = "GET_PAYROLL_TAX_AND_DEDUCTION_SUMMARY";
export const GET_PAYROLL_TAX_AND_DEDUCTION_SUMMARY_START = "GET_PAYROLL_TAX_AND_DEDUCTION_SUMMARY_START";
export const GET_PAYROLL_TAX_AND_DEDUCTION_SUMMARY_FAILED = "GET_PAYROLL_TAX_AND_DEDUCTION_SUMMARY_FAILED";

export const GET_PAYROLL_TRANSACTIONS = "GET_PAYROLL_TRANSACTIONS";
export const GET_PAYROLL_TRANSACTIONS_START = "GET_PAYROLL_TRANSACTIONS_START";
export const GET_PAYROLL_TRANSACTIONS_FAILED = "GET_PAYROLL_TRANSACTIONS_FAILED";

export const GET_PAYROLL_ANALYTICS = "GET_PAYROLL_ANALYTICS";
export const GET_PAYROLL_ANALYTICS_START = "GET_PAYROLL_ANALYTICS_START";
export const GET_PAYROLL_ANALYTICS_FAILED = "GET_PAYROLL_ANALYTICS_FAILED";

export const DOWNLOAD_PAYROLL_TRANSACTIONS = "DOWNLOAD_PAYROLL_TRANSACTIONS";
export const DOWNLOAD_PAYROLL_TRANSACTIONS_START = "DOWNLOAD_PAYROLL_TRANSACTIONS_START";
export const DOWNLOAD_PAYROLL_TRANSACTIONS_FAILED = "DOWNLOAD_PAYROLL_TRANSACTIONS_FAILED";

export const GENERATE_PAYROLL_TRANSACTIONS = "GENERATE_PAYROLL_TRANSACTIONS";
export const GENERATE_PAYROLL_TRANSACTIONS_START = "GENERATE_PAYROLL_TRANSACTIONS_START";
export const GENERATE_PAYROLL_TRANSACTIONS_FAILED = "GENERATE_PAYROLL_TRANSACTIONS_FAILED";
export const GENERATE_PAYROLL_TRANSACTIONS_RESET = "GENERATE_PAYROLL_TRANSACTIONS_RESET";

export const UPDATE_PAYROLL_TRANSACTION = "UPDATE_PAYROLL_TRANSACTION";
export const UPDATE_PAYROLL_TRANSACTION_START = "UPDATE_PAYROLL_TRANSACTION_START";
export const UPDATE_PAYROLL_TRANSACTION_FAILED = "UPDATE_PAYROLL_TRANSACTION_FAILED";
export const UPDATE_PAYROLL_TRANSACTION_RESET = "UPDATE_PAYROLL_TRANSACTION_RESET";

export const UPDATE_PAYROLL_STATUS = "UPDATE_PAYROLL_STATUS";
export const UPDATE_PAYROLL_STATUS_START = "UPDATE_PAYROLL_STATUS_START";
export const UPDATE_PAYROLL_STATUS_FAILED = "UPDATE_PAYROLL_STATUS_FAILED";
export const UPDATE_PAYROLL_STATUS_RESET = "UPDATE_PAYROLL_STATUS_RESET";

export const PAYROLL_PAY_SALARY = "PAYROLL_PAY_SALARY";
export const PAYROLL_PAY_SALARY_START = "PAYROLL_PAY_SALARY_START";
export const PAYROLL_PAY_SALARY_FAILED = "PAYROLL_PAY_SALARY_FAILED";
export const PAYROLL_PAY_SALARY_RESET = "PAYROLL_PAY_SALARY_RESET";

export const PAYROLL_GET_REMITTANCES = "PAYROLL_GET_REMITTANCES";
export const PAYROLL_GET_REMITTANCES_START = "PAYROLL_GET_REMITTANCES_START";
export const PAYROLL_GET_REMITTANCES_FAILED = "PAYROLL_GET_REMITTANCES_FAILED";

export const PAYROLL_PAY_REMITTANCE = "PAYROLL_PAY_REMITTANCE";
export const PAYROLL_PAY_REMITTANCE_START = "PAYROLL_PAY_REMITTANCE_START";
export const PAYROLL_PAY_REMITTANCE_FAILED = "PAYROLL_PAY_REMITTANCE_FAILED";
export const PAYROLL_PAY_REMITTANCE_RESET = "PAYROLL_PAY_REMITTANCE_RESET";

export const DELETE_PAYROLL_STATUS = "DELETE_PAYROLL_STATUS";
export const DELETE_PAYROLL_STATUS_START = "DELETE_PAYROLL_STATUS_START";
export const DELETE_PAYROLL_STATUS_FAILED = "DELETE_PAYROLL_STATUS_FAILED";
export const DELETE_PAYROLL_STATUS_RESET = "DELETE_PAYROLL_STATUS_RESET";
