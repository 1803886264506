export const UPDATE_COMPLIANCE_PROGRESS = "UPDATE_COMPLIANCE_PROGRESS";
export const UPDATE_COMPLIANCE_BUSINESS_TYPE = "UPDATE_COMPLIANCE_BUSINESS_TYPE";

export const SAVE_COMPLIANCE_INFO = "SAVE_COMPLIANCE_INFO";
export const SAVE_COMPLIANCE_INFO_START = "SAVE_COMPLIANCE_INFO_START";
export const SAVE_COMPLIANCE_INFO_FAILED = "SAVE_COMPLIANCE_INFO_FAILED";
export const SAVE_COMPLIANCE_INFO_RESET = "SAVE_COMPLIANCE_INFO_RESET";

export const UPDATE_BVN = "UPDATE_BVN";

export const ADD_SOCIALS = "ADD_SOCIALS";
export const ADD_SOCIALS_START = "ADD_SOCIALS_START";
export const ADD_SOCIALS_FAILED = "ADD_SOCIALS_FAILED";
export const ADD_SOCIALS_RESET = "ADD_SOCIALS_RESET";

export const ADD_DIRECTORS = "ADD_DIRECTORS";
export const ADD_DIRECTORS_START = "ADD_DIRECTORS_START";
export const ADD_DIRECTORS_FAILED = "ADD_DIRECTORS_FAILED";
export const ADD_DIRECTORS_RESET = "ADD_DIRECTORS_RESET";

export const UPDATE_DIRECTORS = "UPDATE_DIRECTORS";
export const UPDATE_DIRECTORS_START = "UPDATE_DIRECTORS_START";
export const UPDATE_DIRECTORS_FAILED = "UPDATE_DIRECTORS_FAILED";
export const UPDATE_DIRECTORS_RESET = "UPDATE_DIRECTORS_RESET";

export const DELETE_DIRECTORS = "DELETE_DIRECTORS";
export const DELETE_DIRECTORS_START = "DELETE_DIRECTORS_START";
export const DELETE_DIRECTORS_FAILED = "DELETE_DIRECTORS_FAILED";
export const DELETE_DIRECTORS_RESET = "DELETE_DIRECTORS_RESET";

export const VALIDATE_BVN_AND_NIN = "VALIDATE_BVN_AND_NIN";
export const VALIDATE_BVN_AND_NIN_START = "VALIDATE_BVN_AND_NIN_START";
export const VALIDATE_BVN_AND_NIN_FAILED = "VALIDATE_BVN_AND_NIN_FAILED";
export const VALIDATE_BVN_AND_NIN_RESET = "VALIDATE_BVN_AND_NIN_RESET";
