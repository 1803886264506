import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customInfoToast, customSuccessToast } from "utils/customToast";

const initialState = {
    loading: false,
    allAdmin: {},
    reloadPage: false,

    getAdmin: {},
    getAdminLoading: false,

    adminCreateLoading: false,
    adminCreateSuccess: false,

    adminUpdateLoading: false,
    adminUpdateSuccess: false,
    adminUpdateReset: false,

    adminUpdateStatusLoading: false,
    adminUpdateStatusSuccess: false,

    adminOnboardLoading: false,
    adminOnboard: false,

    adminAssignBusinessLoading: false,
    adminAssignBusinessStatus: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_ADMIN:
            state = {
                ...state,
                loading: false,
                allAdmin: { ...action.payload.admins }
            }
            return state;


        case actions.GET_ALL_ADMIN_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_ALL_ADMIN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                loading: false,
                allAdmin: {}
            }
            return state;


        case actions.GET_ADMIN:
            state = {
                ...state,
                getAdminLoading: false,
                getAdmin: { ...action.payload.admin }
            }
            return state;


        case actions.GET_ADMIN_START:
            state = {
                ...state,
                getAdminLoading: true,
            }
            return state;


        case actions.GET_ADMIN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                getAdminLoading: false,
                getAdmin: {}
            }
            return state;


        case actions.CREATE_ADMIN:
            customSuccessToast({ message: action.payload.message });
            state = {
                ...state,
                adminCreateLoading: false,
                adminCreateSuccess: true
            }
            return state;


        case actions.CREATE_ADMIN_START:
            state = {
                ...state,
                adminCreateLoading: true,
            }
            return state;


        case actions.CREATE_ADMIN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                adminCreateLoading: false,
            }
            return state;


        case actions.CREATE_ADMIN_RESET:
            state = {
                ...state,
                adminCreateSuccess: false
            }
            return state;


        case actions.UPDATE_ADMIN:
            customSuccessToast({ message: action.payload.message })
            state = {
                ...state,
                adminUpdateLoading: false,
                adminUpdateSuccess: true,
            }
            return state;


        case actions.UPDATE_ADMIN_START:
            state = {
                ...state,
                adminUpdateLoading: true,
                adminUpdateSuccess: false,
            }
            return state;


        case actions.UPDATE_ADMIN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                adminUpdateLoading: false,
                adminUpdateSuccess: false,
            }
            return state;


        case actions.UPDATE_ADMIN_RESET:
            state = {
                ...state,
                adminUpdateSuccess: false,
            }
            return state;


        case actions.UPDATE_ADMIN_STATUS:
            
            state = {
                ...state,
                adminUpdateStatusLoading: false,
                reloadPage: true,
            }
            return state;


        case actions.UPDATE_ADMIN_STATUS_START:
            customInfoToast({ message: "Updating Admin Status" });
            state = {
                ...state,
                adminUpdateStatusLoading: true,
            }
            return state;


        case actions.UPDATE_ADMIN_STATUS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                adminUpdateStatusLoading: false,
            }
            return state;


        case actions.DELETE_ADMIN:
            customSuccessToast({ message: action.payload.message })
            state = {
                ...state,
                adminDeleteLoading: false,
                reloadPage: true
            }
            return state;


        case actions.DELETE_ADMIN_START:
            customInfoToast({ message: "Deleting Admin" });
            state = {
                ...state,
                adminDeleteLoading: true,
            }
            return state;


        case actions.DELETE_ADMIN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                adminDeleteLoading: false,
            }
            return state;


        case actions.RESEND_INVITE:
            customSuccessToast({ message: "Invite Sent" });
            state = {
                ...state,
            }
            return state;


        /*
        case actions.RESEND_INVITE_START:
            state = {
                ...state,
            }
            return state;
        */

        case actions.RESEND_INVITE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
            }
            return state;


        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false
            }
            return state;


        case actions.ADMIN_ONBOARD:
            state = {
                ...state,
                adminOnboardLoading: false,
                adminOnboard: true,
            }
            return state;


        case actions.ADMIN_ONBOARD_START:
            state = {
                ...state,
                adminOnboardLoading: true,
                adminOnboard: false,
            }
            return state;


        case actions.ADMIN_ONBOARD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                adminOnboardLoading: false,
                adminOnboard: false,
            }
            return state;


        case actions.ADMIN_ONBOARD_RESET:
            state = {
                ...state,
                adminOnboard: false,
            }
            return state;


        case actions.ADMIN_ASSIGN_BUSINESS:
            customSuccessToast({ message: "Admin assigned to business(es)" });
            state = {
                ...state,
                adminAssignBusinessLoading: false,
                adminAssignBusinessStatus: true,
            }
            return state;


        case actions.ADMIN_ASSIGN_BUSINESS_START:
            state = {
                ...state,
                adminAssignBusinessLoading: true,
                adminAssignBusinessStatus: false,
            }
            return state;


        case actions.ADMIN_ASSIGN_BUSINESS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                adminAssignBusinessLoading: false,
                adminAssignBusinessStatus: false,
            }
            return state;


        default:
            return state;
    }
}