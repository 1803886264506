import { customErrorToast } from "./customToast";

export const errorReducer = (actionPayload) => {
    if (actionPayload?.message === "Validation Errors") {

        let payloadErrors = {};
        if (actionPayload?.errors){
            payloadErrors = {...actionPayload?.errors};
        }
        else if (actionPayload?.data?.errors){
            payloadErrors = {...actionPayload?.data?.errors};
        }
        else if (actionPayload?.data?.responses){
            payloadErrors = {...actionPayload?.data?.responses};
        }

        Object.keys(payloadErrors).forEach(function (key) {
            for (let i = 0; i < payloadErrors[key].length; i++) {
                customErrorToast({ message: payloadErrors[key][i] });
            }
        });
        
    }
    else if (actionPayload?.validationErrors !== undefined) {

        const errors = actionPayload?.validationErrors;

        if (typeof errors === "string"){
            customErrorToast({ message: errors });
        }
        else if (Array.isArray(errors)){
            for (let i = 0; i < errors.length; i++) {
                customErrorToast({ message: errors[i] });
            }
        }
        else{
            customErrorToast({ message: "An error occurred" }, {
                toastId: "unknown error",
            })
        }
        
    }
    else {
        if (actionPayload?.message){
            customErrorToast({ message: actionPayload?.message }, {
                toastId: "expired",
            })
        }
        else{
            customErrorToast({ message: "An error occurred" }, {
                toastId: "unknown error",
            })
        }
    }

};


export default errorReducer;