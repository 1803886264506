import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customErrorToast, customSuccessToast } from "utils/customToast";

const initialState = {
    profileUpdateLoading: false,
    profileUpdated: {},
    passwordUpdateLoading: false,
    passwordUpdated: false,
    
    employerInfoLoading: false,
    employerInfo: {},
    
    companyInfoUpdateLoading: false,
    companyInfoUpdate: {},
    
    employerProductsLoading: false,
    employerProducts: {}
};


export default function reducer(state = initialState, action){
    switch (action.type){

        case actions.UPDATE_PROFILE:
            state = {
                ...state,
                profileUpdateLoading: false,
                profileUpdated: action.payload,
            }
            return state;

           
        case actions.UPDATE_PROFILE_START:
            state = {
                ...state,
                profileUpdateLoading: true,
                profileUpdated: {},
            }
            return state;


        case actions.UPDATE_PROFILE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                profileUpdateLoading: false,
                profileUpdated: action.payload,
            }
            return state;


        case actions.UPDATE_PROFILE_RESET:
            state = {
                ...state,
                profileUpdateLoading: false,
                profileUpdated: {},
            }
            return state;


        case actions.UPDATE_PASSWORD:
            customSuccessToast({ title: "Password Update", message: action.payload.message });
            state = {
                ...state,
                passwordUpdateLoading: false,
                passwordUpdated: true,
            }
            return state;

           
        case actions.UPDATE_PASSWORD_START:
            state = {
                ...state,
                passwordUpdateLoading: true,
            }
            return state;


        case actions.UPDATE_PASSWORD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                passwordUpdateLoading: false,
            }
            return state;


        case actions.UPDATE_PASSWORD_RESET:
            state = {
                ...state,
                passwordUpdated: false,
            }
            return state;


        case actions.GET_EMPLOPYER_INFO:
            state = {
                ...state,
                employerInfoLoading: false,
                employerInfo: JSON.parse(action.payload),
            }
            return state;


        case actions.GET_EMPLOPYER_INFO_START:
            state = {
                ...state,
                employerInfoLoading: true,
                employerInfo: {},
            }
            return state;


        case actions.GET_EMPLOPYER_INFO_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employerInfoLoading: false,
                employerInfo: action.payload,
            }
            return state;

        case actions.UPDATE_EMPLOYER_PRODUCTS_START:
            state = {
                ...state,
                employerProductsLoading: true,
                employerProducts: {},
            }
            return state;
            
        case actions.UPDATE_EMPLOYER_PRODUCTS:
            state = {
                ...state,
                employerProductsLoading: false,
                employerProducts: action.payload,
            }
            return state;

        case actions.UPDATE_EMPLOYER_PRODUCTS_FAILED:
            customErrorToast({ message: action.payload.message });
            state = {
                ...state,
                employerProductsLoading: false,
                employerProducts: action.payload,
            }
            return state;
            
        case actions.RESET_EMPLOYER_PRODUCTS_ACTION:
            state = {
                ...state,
                employerProductsLoading: false,
                employerProducts: {},
            }
            return state;
        

        case actions.UPDATE_EMPLOPYER_INFO:
            state = {
                ...state,
                // employerInfo: action.payload,
                employerInfo: {
                    ...state.employerInfo,
                    data: {
                        ...state.employerInfo.data,
                        employer: {
                            ...state.employerInfo.data.employer,
                            company: {
                                // ...state.employerInfo.data.employer.company,
                                ...action.payload,
                            }
                        }
                    }
                }
            }
            return state;

            
        case actions.UPDATE_COMPANY_INFO:
            state = {
                ...state,
                companyInfoUpdateLoading: false,
                companyInfoUpdate: action.payload,
            }
            return state;

            
        case actions.UPDATE_COMPANY_INFO_START:
            state = {
                ...state,
                companyInfoUpdateLoading: true,
            }
            return state;


        case actions.UPDATE_COMPANY_INFO_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companyInfoUpdateLoading: false,
                companyInfoUpdate: action.payload,
            }
            return state;


        case actions.UPDATE_COMPANY_INFO_RESET:
            state = {
                ...state,
                companyInfoUpdateLoading: false,
                companyInfoUpdate: {},
            }
            return state;
    

        default:
            return state;
    }
}