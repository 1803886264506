import { useEffect } from 'react';

export const useOutsideClick = (ref, callback, buttonRef) => {
    useEffect(() => {
        const handleClickOutside = (evt) => {
            if (ref?.current && !ref?.current?.contains(evt?.target)) {
                if (buttonRef?.current && buttonRef?.current?.contains(evt?.target)) {
                    return;
                }
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback, buttonRef]);
};
