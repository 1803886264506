
export const CREATE_APPROVAL_WORKFLOW_START = "CREATE_APPROVAL_WORKFLOW_START";
export const CREATE_APPROVAL_WORKFLOW = "CREATE_APPROVAL_WORKFLOW";
export const CREATE_APPROVAL_WORKFLOW_FAILED = "CREATE_APPROVAL_WORKFLOW_FAILED";
export const CREATE_APPROVAL_WORKFLOW_RESET = "CREATE_APPROVAL_WORKFLOW_RESET";

export const GET_APPROVAL_WORKFLOWS_START = "GET_APPROVAL_WORKFLOWS_START";
export const GET_APPROVAL_WORKFLOWS = "GET_APPROVAL_WORKFLOWS";
export const GET_APPROVAL_WORKFLOWS_FAILED = "GET_APPROVAL_WORKFLOWS_FAILED";

export const UPDATE_APPROVAL_WORKFLOW_START = "UPDATE_APPROVAL_WORKFLOW_START";
export const UPDATE_APPROVAL_WORKFLOW = "UPDATE_APPROVAL_WORKFLOW";
export const UPDATE_APPROVAL_WORKFLOW_FAILED = "UPDATE_APPROVAL_WORKFLOW_FAILED";
export const UPDATE_APPROVAL_WORKFLOW_RESET = "UPDATE_APPROVAL_WORKFLOW_RESET";

export const UPDATE_APPROVAL_WORKFLOW_STAGE_START = "UPDATE_APPROVAL_WORKFLOW_STAGE_START";
export const UPDATE_APPROVAL_WORKFLOW_STAGE = "UPDATE_APPROVAL_WORKFLOW_STAGE";
export const UPDATE_APPROVAL_WORKFLOW_STAGE_FAILED = "UPDATE_APPROVAL_WORKFLOW_STAGE_FAILED";
export const UPDATE_APPROVAL_WORKFLOW_STAGE_RESET = "UPDATE_APPROVAL_WORKFLOW_STAGE_RESET";

export const DELETE_APPROVAL_WORKFLOW_START = "DELETE_APPROVAL_WORKFLOW_START";
export const DELETE_APPROVAL_WORKFLOW = "DELETE_APPROVAL_WORKFLOW";
export const DELETE_APPROVAL_WORKFLOW_FAILED = "DELETE_APPROVAL_WORKFLOW_FAILED";
export const DELETE_APPROVAL_WORKFLOW_RESET = "DELETE_APPROVAL_WORKFLOW_RESET";

export const DISABLE_APPROVAL_WORKFLOW_START = "DISABLE_APPROVAL_WORKFLOW_START";
export const DISABLE_APPROVAL_WORKFLOW = "DISABLE_APPROVAL_WORKFLOW";
export const DISABLE_APPROVAL_WORKFLOW_FAILED = "DISABLE_APPROVAL_WORKFLOW_FAILED";
export const DISABLE_APPROVAL_WORKFLOW_RESET = "DISABLE_APPROVAL_WORKFLOW_RESET";

export const ENABLE_APPROVAL_WORKFLOW_START = "ENABLE_APPROVAL_WORKFLOW_START";
export const ENABLE_APPROVAL_WORKFLOW = "ENABLE_APPROVAL_WORKFLOW";
export const ENABLE_APPROVAL_WORKFLOW_FAILED = "ENABLE_APPROVAL_WORKFLOW_FAILED";
export const ENABLE_APPROVAL_WORKFLOW_RESET = "ENABLE_APPROVAL_WORKFLOW_RESET";

export const CREATE_REQUEST_START = "CREATE_REQUEST_START";
export const CREATE_REQUEST = "CREATE_REQUEST";
export const CREATE_REQUEST_FAILED = "CREATE_REQUEST_FAILED";
export const CREATE_REQUEST_RESET = "CREATE_REQUEST_RESET";

export const GET_REQUESTS_START = "GET_REQUESTS_START";
export const GET_REQUESTS = "GET_REQUESTS";
export const GET_REQUESTS_FAILED = "GET_REQUESTS_FAILED";

export const GET_REQUEST_ANALYTICS_START = "GET_REQUEST_ANALYTICS_START";
export const GET_REQUEST_ANALYTICS = "GET_REQUEST_ANALYTICS";
export const GET_REQUEST_ANALYTICS_FAILED = "GET_REQUEST_ANALYTICS_FAILED";
export const GET_REQUEST_ANALYTICS_RESET = "GET_REQUEST_ANALYTICS_RESET";

export const APPROVE_REQUEST_START = "APPROVE_REQUEST_START";
export const APPROVE_REQUEST = "APPROVE_REQUEST";
export const APPROVE_REQUEST_FAILED = "APPROVE_REQUEST_FAILED";
export const APPROVE_REQUEST_RESET = "APPROVE_REQUEST_RESET";

export const REJECT_REQUEST_START = "REJECT_REQUEST_START";
export const REJECT_REQUEST = "REJECT_REQUEST";
export const REJECT_REQUEST_FAILED = "REJECT_REQUEST_FAILED";
export const REJECT_REQUEST_RESET = "REJECT_REQUEST_RESET";

export const GET_REQUEST_APPROVALS_START = "GET_REQUEST_APPROVALS_START";
export const GET_REQUEST_APPROVALS = "GET_REQUEST_APPROVALS";
export const GET_REQUEST_APPROVALS_FAILED = "GET_REQUEST_APPROVALS_FAILED";

export const POST_REQUEST_COMMENT_START = "POST_REQUEST_COMMENT_START";
export const POST_REQUEST_COMMENT = "POST_REQUEST_COMMENT";
export const POST_REQUEST_COMMENT_FAILED = "POST_REQUEST_COMMENT_FAILED";
export const POST_REQUEST_COMMENT_RESET = "POST_REQUEST_COMMENT_RESET";

export const GET_REQUEST_COMMENTS_START = "GET_REQUEST_COMMENTS_START";
export const GET_REQUEST_COMMENTS = "GET_REQUEST_COMMENT";
export const GET_REQUEST_COMMENTS_FAILED = "GET_REQUEST_COMMENTS_FAILED";

export const UPDATE_REQUEST_DISBURSEMENT_START = "UPDATE_REQUEST_DISBURSEMENT_START";
export const UPDATE_REQUEST_DISBURSEMENT = "UPDATE_REQUEST_DISBURSEMENT";
export const UPDATE_REQUEST_DISBURSEMENT_FAILED = "UPDATE_REQUEST_DISBURSEMENT_FAILED";
export const UPDATE_REQUEST_DISBURSEMENT_RESET = "UPDATE_REQUEST_DISBURSEMENT_RESET";

export const CANCEL_REQUEST_START = "CANCEL_REQUEST_START";
export const CANCEL_REQUEST = "CANCEL_REQUEST";
export const CANCEL_REQUEST_FAILED = "CANCEL_REQUEST_FAILED";
export const CANCEL_REQUEST_RESET = "CANCEL_REQUEST_RESET";

export const GET_ROLES_START = "GET_ROLES_START"
export const GET_ROLES = "GET_ROLES"
export const GET_ROLES_FAILED = "GET_ROLES_FAILED"