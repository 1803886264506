// import { decryptPayload } from "utils/encrypt_decrypt";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

const initialState = {
    allNotificationsLoading: false,
    allNotifications: {},

    allNotificationsAsReadLoading: false,
    allNotificationsAsRead: {},
    
    singleNotificationAsReadLoading: false,
    singleNotificationMarkedAsRead: {},
};

export default function reducer(state = initialState, action){
    switch (action.type){

        case actions.GET_ALL_NOTIFICATIONS_START:
            state = {
                ...state,
                allNotificationsLoading: true,
                allNotifications: {},
            }
            return state;

        case actions.GET_ALL_NOTIFICATIONS:
            state = {
                ...state,
                allNotificationsLoading: false,
                allNotifications: action.payload
            }
            return state;

        case actions.GET_ALL_NOTIFICATIONS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                allNotificationsLoading: false,
                allNotifications: {},
            }
            return state;

        case actions.MARK_ALL_NOTIFICATION_AS_READ_START:
            state = {
                ...state,
                allNotificationsAsReadLoading: true,
                allNotificationsAsRead: {},
            }
            return state;

        case actions.MARK_ALL_NOTIFICATION_AS_READ:
            state = {
                ...state,
                allNotificationsAsReadLoading: false,
                allNotificationsAsRead: action.payload
            }
            return state;

        case actions.MARK_ALL_NOTIFICATION_AS_READ_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                allNotificationsAsReadLoading: false,
                allNotificationsAsRead: {},
            }
            return state;

        case actions.MARK_SINGLE_NOTIFICATION_AS_READ_START:
            state = {
                ...state,
                singleNotificationAsReadLoading: true,
                singleNotificationMarkedAsRead: {}
            }
            return state;

        case actions.MARK_SINGLE_NOTIFICATION_AS_READ:
            state = {
                ...state,
                singleNotificationAsReadLoading: false,
                singleNotificationMarkedAsRead: action.payload,
            }
            return state;

        case actions.MARK_SINGLE_NOTIFICATION_AS_READ_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                singleNotificationAsReadLoading: false,
                singleNotificationMarkedAsRead: {},
            }
            return state;
            
        case actions.RESET_MARK_SINGLE_NOTIFICATION_AS_READ:
            state = {
                ...state,
                singleNotificationAsReadLoading: false,
                singleNotificationMarkedAsRead: {},
            }
            return state;

        default:
            return state;
    }
}