import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "config";

// pay schedules

export function getPaySchedule(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiPaySchedule + ObjectToQueryString(params),
            method: "get",
            data: params,
            onStart: actions.GET_PAY_SCHEDULE_START,
            onSuccess: actions.GET_PAY_SCHEDULE_DONE,
            onError: actions.GET_PAY_SCHEDULE_FAILED,
        },
    }
}


export function createPaySchedule(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiPaySchedule,
            method: "post",
            data: params,
            onStart: actions.CREATE_PAY_SCHEDULE_START,
            onSuccess: actions.CREATE_PAY_SCHEDULE_DONE,
            onError: actions.CREATE_PAY_SCHEDULE_FAILED,
        },
    }
}

export function selectPaySchedule(payload) {
    return {
        type: actions.SELECT_PAY_SCHEDULE,
        payload
    }
}

export function editPaySchedule(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiPaySchedule + "/" + id,
            method: "put",
            data: params,
            onStart: actions.EDIT_PAY_SCHEDULE_START,
            onSuccess: actions.EDIT_PAY_SCHEDULE_DONE,
            onError: actions.EDIT_PAY_SCHEDULE_FAILED,
        },
    }
}

export function deletePaySchedule(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPaySchedule}/${params}`,
            method: "delete",
            data: params,
            onStart: actions.DELETE_PAY_SCHEDULE_START,
            onSuccess: actions.DELETE_PAY_SCHEDULE_DONE,
            onError: actions.DELETE_PAY_SCHEDULE_FAILED,
        },
    }
}

export function deleteMultiplePaySchedules(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPaySchedule}/delete-many`,
            method: "post",
            data: params,
            onStart: actions.DELETE_MULTIPLE_SCHEDULES_START,
            onSuccess: actions.DELETE_MULTIPLE_SCHEDULES_DONE,
            onError: actions.DELETE_MULTIPLE_SCHEDULES_FAILED,
        },
    }
}

// pay groups


export function togglePayGroupEmployeeAssignment() {
    return {
        type: actions.TOGGLE_PAY_GROUP_EMPLOYEES_ASSIGNMENT,
    }
}

export function getPayGroups(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiPayGroup + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_PAY_GROUP_START,
            onSuccess: actions.GET_PAY_GROUP_DONE,
            onError: actions.GET_PAY_GROUP_FAILED,
        },
    }
}

export function getSinglePayGroup(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/${params.id}`,
            method: "get",
            data: {},
            onStart: actions.GET_A_PAY_GROUP_START,
            onSuccess: actions.GET_A_PAY_GROUP_DONE,
            onError: actions.GET_A_PAY_GROUP_FAILED,
        },
    }
}

export function getPayGroupEmployees(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/${params.id}/assigned-employees`,
            method: "get",
            data: {},
            onStart: actions.GET_PAY_GROUP_EMPLOYEES_START,
            onSuccess: actions.GET_PAY_GROUP_EMPLOYEES_DONE,
            onError: actions.GET_PAY_GROUP_EMPLOYEES_FAILED,
        },
    }
}

export function createPayGroup(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiPayGroup,
            method: "post",
            data: params,
            onStart: actions.CREATE_PAY_GROUP_START,
            onSuccess: actions.CREATE_PAY_GROUP_DONE,
            onError: actions.CREATE_PAY_GROUP_FAILED,
        },
    }
}

export function selectPayGroup(payload) {
    return {
        type: actions.SELECT_PAY_GROUP,
        payload
    }
}

export function savePayGroupEdits(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/${params.id}`,
            method: "put",
            data: params,
            onStart: actions.EDIT_PAY_GROUP_START,
            onSuccess: actions.EDIT_PAY_GROUP_DONE,
            onError: actions.EDIT_PAY_GROUP_FAILED,
        },
    }
}

export function assignPayGroupEmployees(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/${params.id}/assign-employees`,
            method: "patch",
            data: {employee_ids: params.employee_ids},
            onStart: actions.UPDATE_PAY_GROUP_EMPLOYEES_START,
            onSuccess: actions.UPDATE_PAY_GROUP_EMPLOYEES_DONE,
            onError: actions.UPDATE_PAY_GROUP_EMPLOYEES_FAILED,
        },
    }
}

export function resetPayGroupEmployeesAssignment() {
    return {
        type: actions.RESET_PAY_GROUP_EMPLOYEES_ASSIGNMENT,
        payload: {},
    }
}

export function removeEmployeeFromGroup(groupId, employeeId) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiRemoveEmployeeFromGroup}/${groupId}/${employeeId}`,
            method: "patch",
            data: {},
            onStart: actions.REMOVE_EMPLOYEE_FROM_GROUP_START,
            onSuccess: actions.REMOVE_EMPLOYEE_FROM_GROUP_DONE,
            onError: actions.REMOVE_EMPLOYEE_FROM_GROUP_FAILED,
        },
    }
}

export function deletePayGroupEmployee(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/${params.id}/unassign-employees`,
            method: "patch",
            data: params,
            onStart: actions.DELETE_PAY_GROUP_EMPLOYEE_START,
            onSuccess: actions.DELETE_PAY_GROUP_EMPLOYEE_DONE,
            onError: actions.DELETE_PAY_GROUP_EMPLOYEE_FAILED,
        },
    }
}

export function deletePayGroup(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/${params}`,
            method: "delete",
            data: params,
            onStart: actions.DELETE_PAY_GROUP_START,
            onSuccess: actions.DELETE_PAY_GROUP_DONE,
            onError: actions.DELETE_PAY_GROUP_FAILED,
        },
    }
}

export function deleteMultiplePayGroups(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/delete-many`,
            method: "post",
            data: params,
            onStart: actions.DELETE_MULTIPLE_PAY_GROUPS_START,
            onSuccess: actions.DELETE_MULTIPLE_PAY_GROUPS_DONE,
            onError: actions.DELETE_MULTIPLE_PAY_GROUPS_FAILED,
        },
    }
}

export function savePayGroupDetails(payload) {
    return {
        type: actions.SAVE_PAY_GROUP_DETAILS,
        payload: payload
    }
}

export function clearPayGroupDetails() {
    return {
        type: actions.CLEAR_PAY_GROUP_INFO,
    }
}

// pay structure

export function getPayStructureGroups(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}?pay_structure_id=${params.id}`,
            method: "get",
            data: params,
            onStart: actions.GET_STRUCTURE_PAY_GROUPS_START,
            onSuccess: actions.GET_STRUCTURE_PAY_GROUPS_DONE,
            onError: actions.GET_STRUCTURE_PAY_GROUPS_FAILED,
        },
    }
}

export function assignPayStructureGroups(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/assign-pay-structure`,
            method: "post",
            data: params,
            onStart: actions.ASSIGN_STRUCTURE_PAY_GROUPS_START,
            onSuccess: actions.ASSIGN_STRUCTURE_PAY_GROUPS_DONE,
            onError: actions.ASSIGN_STRUCTURE_PAY_GROUPS_FAILED,
        },
    }
}

export function unassignPayStructureGroups(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/unassign-pay-structure`,
            method: "post",
            data: params,
            onStart: actions.UNASSIGN_STRUCTURE_PAY_GROUPS_START,
            onSuccess: actions.UNASSIGN_STRUCTURE_PAY_GROUPS_DONE,
            onError: actions.UNASSIGN_STRUCTURE_PAY_GROUPS_FAILED,
        },
    }
}

export function getPayStructure(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiPayStructure + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_PAY_STRUCTURE_START,
            onSuccess: actions.GET_PAY_STRUCTURE_DONE,
            onError: actions.GET_PAY_STRUCTURE_FAILED,
        },
    }
}

export function createPayStructure(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiPayStructure,
            method: "post",
            data: params,
            onStart: actions.CREATE_PAY_STRUCTURE_START,
            onSuccess: actions.CREATE_PAY_STRUCTURE_DONE,
            onError: actions.CREATE_PAY_STRUCTURE_FAILED,
        },
    }
}

export function updatePayStructure(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayStructure}/${params.id}`,
            method: "put",
            data: params,
            onStart: actions.UPDATE_PAY_STRUCTURE_START,
            onSuccess: actions.UPDATE_PAY_STRUCTURE_DONE,
            onError: actions.UPDATE_PAY_STRUCTURE_FAILED,
        },
    }
}

export function togglePayStructureGroupsModal() {
    return {
        type: actions.TOGGLE_PAY_STRUCTURE_GROUPS_MODAL
    }
}

export function setPayStructureResponse(payload) {
    return {
        type: actions.SELECT_PAY_STRUCTURE_RESPONSE,
        payload: payload
    }
}

export function togglePfaModal() {
    return {
        type: actions.TOGGLE_PFA_SETUP_MODAL
    }
}

export function clearPayStructureITems() {
    return {
        type: actions.CLEAR_PAY_STRUCTURE_ITEMS
    }
}

export function deletePayStructure(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayStructure}/${params}`,
            method: "delete",
            data: params,
            onStart: actions.DELETE_PAY_STRUCTURE_START,
            onSuccess: actions.DELETE_PAY_STRUCTURE_DONE,
            onError: actions.DELETE_PAY_STRUCTURE_FAILED,
        },
    }
}

export function selectPayStructure(payload) {
    return {
        type: actions.SELECT_PAY_STRUCTURE,
        payload: payload
    }
}

export function savePayStructurePayload(payload) {
    return {
        type: actions.SAVE_PAY_STRUCTURE_PAYLOAD,
        payload: payload
    }
}

export function deleteMultiplePayStructures(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayStructure}/delete-many`,
            method: "post",
            data: params,
            onStart: actions.DELETE_MULTIPLE_STRUCTURES_START,
            onSuccess: actions.DELETE_MULTIPLE_STRUCTURES_DONE,
            onError: actions.DELETE_MULTIPLE_STRUCTURES_FAILED,
        },
    }
}

export function saveBreakdownPayload(payload) {
    return {
        type: actions.SAVE_BREAKDOWN_PAYLOAD,
        payload: payload
    }
}

export function toggleEditDeductionModal(payload) {
    return {
        type: actions.TOGGLE_EDIT_DEDUCTION_MODAL,
        payload: payload
    }
}

export function saveEditedDeduction(payload) {
    return {
        type: actions.SAVE_EDITED_CUSTOM_DEDUCTION,
        payload: payload
    }
}

export function selectCustomDeduction(payload) {
    return {
        type: actions.SELECT_CUSTOM_DEDUCTION,
        payload: payload
    }
}

export function addCustomDeduction(payload) {
    return {
        type: actions.ADD_CUSTOM_DEDUCTION,
        payload: payload
    }
}

export function deleteCustomDeduction(payload) {
    return {
        type: actions.DELETE_CUSTOM_DEDUCTION,
        payload: payload
    }
}

// benefits

export function toggleBenefitGroupAssignment() {
    return {
        type: actions.TOGGLE_BENEFIT_GROUP_ASSIGNMENT,
    }
}

export function selectBenefit(payload) {
    return {
        type: actions.SELECT_BENEFIT,
        payload
    }
}

export function toggleCreateBenefit() {
    return {
        type: actions.TOGGLE_BENEFITS_MODAL,
    }
}

export function saveBenefitDetails(payload) {
    return {
        type: actions.SAVE_BENEFIT_DETAILS,
        payload: payload
    }
}

export function getBenefits(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiBenefitsInKind + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_BENEFITS_START,
            onSuccess: actions.GET_BENEFITS_DONE,
            onError: actions.GET_BENEFITS_FAILED,
        },
    }
}

export function getBenefitPayGroups(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}?benefit_in_kind_id=${params.id}`,
            method: "get",
            data: params,
            onStart: actions.GET_BENEFIT_PAY_GROUPS_START,
            onSuccess: actions.GET_BENEFIT_PAY_GROUPS_DONE,
            onError: actions.GET_BENEFIT_PAY_GROUPS_FAILED,
        },
    }
}

export function assignBenefitPayGroups(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/assign-benefits-in-kind`,
            method: "post",
            data: params,
            onStart: actions.ASSIGN_BENEFIT_PAY_GROUPS_START,
            onSuccess: actions.ASSIGN_BENEFIT_PAY_GROUPS_DONE,
            onError: actions.ASSIGN_BENEFIT_PAY_GROUPS_FAILED,
        },
    }
}

export function unassignBenefitPayGroups(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/unassign-benefits-in-kind`,
            method: "post",
            data: params,
            onStart: actions.UNASSIGN_BENEFIT_PAY_GROUPS_START,
            onSuccess: actions.UNASSIGN_BENEFIT_PAY_GROUPS_DONE,
            onError: actions.UNASSIGN_BENEFIT_PAY_GROUPS_FAILED,
        },
    }
}

export function createBenefits(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiBenefitsInKind,
            method: "post",
            data: params,
            onStart: actions.CREATE_BENEFITS_START,
            onSuccess: actions.CREATE_BENEFITS_DONE,
            onError: actions.CREATE_BENEFITS_FAILED,
        },
    }
}

export function deleteBenefit(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiBenefitsInKind}/${params}`,
            method: "delete",
            data: params,
            onStart: actions.DELETE_BENEFITS_START,
            onSuccess: actions.DELETE_BENEFITS_DONE,
            onError: actions.DELETE_BENEFITS_FAILED,
        },
    }
}

export function deleteMultipleBenefits(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiBenefitsInKind}/delete-many`,
            method: "post",
            data: params,
            onStart: actions.DELETE_MULTIPLE_BENEFITS_START,
            onSuccess: actions.DELETE_MULTIPLE_BENEFITS_DONE,
            onError: actions.DELETE_MULTIPLE_BENEFITS_FAILED,
        },
    }
}

export function saveBenefitEdits(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiBenefitsInKind}/${params.id}`,
            method: "put",
            data: params,
            onStart: actions.EDIT_BENEFIT_START,
            onSuccess: actions.EDIT_BENEFIT_DONE,
            onError: actions.EDIT_BENEFIT_FAILED,
        },
    }
}

// allowances

export function toggleAllowanceGroupAssignment() {
    return {
        type: actions.TOGGLE_ALLOWANCE_GROUP_ASSIGNMENT,
    }
}

export function toggleCreateAllowance() {
    return {
        type: actions.TOGGLE_ALLOWANCES_MODAL,
    }
}

export function saveAllowanceDetails(payload) {
    return {
        type: actions.SAVE_ALLOWANCE_DETAILS,
        payload: payload
    }
}

export function selectAllowance(payload) {
    return {
        type: actions.SELECT_ALLOWANCE,
        payload
    }
}

export function toggleEditBenefitModal() {
    return {
        type: actions.TOGGLE_EDIT_ALLOWANCE_MODAL,
    }
}

export function toggleEditAllowanceModal() {
    return {
        type: actions.TOGGLE_EDIT_ALLOWANCE_MODAL,
    }
}

export function saveAllowanceEdits(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiAllowances}/${params.id}`,
            method: "put",
            data: params,
            onStart: actions.EDIT_ALLOWANCE_START,
            onSuccess: actions.EDIT_ALLOWANCE_DONE,
            onError: actions.EDIT_ALLOWANCE_FAILED,
        },
    }
}

export function getAllowances(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiAllowances + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_ALLOWANCE_START,
            onSuccess: actions.GET_ALLOWANCE_DONE,
            onError: actions.GET_ALLOWANCE_FAILED,
        },
    }
}


export function getAllowancePayGroups(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}?allowance_and_bonus_id=${params.id}`,
            method: "get",
            data: params,
            onStart: actions.GET_ALLOWANCE_PAY_GROUPS_START,
            onSuccess: actions.GET_ALLOWANCE_PAY_GROUPS_DONE,
            onError: actions.GET_ALLOWANCE_PAY_GROUPS_FAILED,
        },
    }
}

export function assignAllowancePayGroups(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/assign-allowance-and-bonus`,
            method: "post",
            data: params,
            onStart: actions.ASSIGN_ALLOWANCE_PAY_GROUPS_START,
            onSuccess: actions.ASSIGN_ALLOWANCE_PAY_GROUPS_DONE,
            onError: actions.ASSIGN_ALLOWANCE_PAY_GROUPS_FAILED,
        },
    }
}

export function unassignAllowancePayGroups(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiPayGroup}/unassign-allowance-and-bonus`,
            method: "post",
            data: params,
            onStart: actions.UNASSIGN_ALLOWANCE_PAY_GROUPS_START,
            onSuccess: actions.UNASSIGN_ALLOWANCE_PAY_GROUPS_DONE,
            onError: actions.UNASSIGN_ALLOWANCE_PAY_GROUPS_FAILED,
        },
    }
}

export function createAllowance(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiAllowances,
            method: "post",
            data: params,
            onStart: actions.CREATE_ALLOWANCE_START,
            onSuccess: actions.CREATE_ALLOWANCE_DONE,
            onError: actions.CREATE_ALLOWANCE_FAILED,
        },
    }
}

export function deleteAllowance(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiAllowances}/${params}`,
            method: "delete",
            data: params,
            onStart: actions.DELETE_ALLOWANCE_START,
            onSuccess: actions.DELETE_ALLOWANCE_DONE,
            onError: actions.DELETE_ALLOWANCE_FAILED,
        },
    }
}

export function deleteMultipleAllowances(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiAllowances}/delete-many`,
            method: "post",
            data: params,
            onStart: actions.DELETE_MULTIPLE_ALLOWANCE_START,
            onSuccess: actions.DELETE_MULTIPLE_ALLOWANCE_DONE,
            onError: actions.DELETE_MULTIPLE_ALLOWANCE_FAILED,
        },
    }
}

// remittances

export function verifyRemittanceBankDetails(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiVerifyBankAccount,
            method: "post",
            data: params,
            onStart: actions.VERIFY_REMITTANCE_BANK_DETAILS_START,
            onSuccess: actions.VERIFY_REMITTANCE_BANK_DETAILS_DONE,
            onError: actions.VERIFY_REMITTANCE_BANK_DETAILS_FAILED,
        },
    }
}

export function getRemittance(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiRemittance + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_REMITTANCE_START,
            onSuccess: actions.GET_REMITTANCE_DONE,
            onError: actions.GET_REMITTANCE_FAILED,
        },
    }
}

export function createRemittance(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiRemittance,
            method: "post",
            data: params,
            onStart: actions.CREATE_REMITTANCE_START,
            onSuccess: actions.CREATE_REMITTANCE_DONE,
            onError: actions.CREATE_REMITTANCE_FAILED,
        },
    }
}

export function deleteRemittance(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiRemittance}/${params}`,
            method: "delete",
            data: params,
            onStart: actions.DELETE_REMITTANCE_START,
            onSuccess: actions.DELETE_REMITTANCE_DONE,
            onError: actions.DELETE_REMITTANCE_FAILED,
        },
    }
}

export function updateRemittance(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiRemittance}/${params.id}`,
            method: "put",
            data: params,
            onStart: actions.EDIT_REMITTANCE_START,
            onSuccess: actions.EDIT_REMITTANCE_DONE,
            onError: actions.EDIT_REMITTANCE_FAILED,
        },
    }
}

export function deleteMultipleRemittances(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: `${config.apiRemittance}/delete-many`,
            method: "post",
            data: params,
            onStart: actions.DELETE_MULTIPLE_REMITTANCE_START,
            onSuccess: actions.DELETE_MULTIPLE_REMITTANCE_DONE,
            onError: actions.DELETE_MULTIPLE_REMITTANCE_FAILED,
        },
    }
}

export function selectRemittance(payload) {
    return {
        type: actions.SELECT_REMITTANCE,
        payload: payload
    }
}

export function toggleRemittanceModal() {
    return {
        type: actions.TOGGLE_REMITTANCE_MODAL,
    }
}

export function saveRemittanceDetails(payload) {
    return {
        type: actions.SAVE_REMITTANCE_DETAILS,
        payload: payload
    }
}

export function toggleFreeTrialOfferModal() {
    return {
        type: actions.TOGGLE_FREE_TRIAL_OFFER_MODAL,
    }
}

export function togglePayrollFeaturesModal() {
    return {
        type: actions.TOGGLE_PAYROLL_FEATURES_MODAL,
    }
}

export function getPensionProviders() {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiBaseUrl,
            url: config.apiGetPensionProviders,
            method: "get",
            data: {},
            onStart: actions.GET_PENSION_PROVIDERS_START,
            onSuccess: actions.GET_PENSION_PROVIDERS_DONE,
            onError: actions.GET_PENSION_PROVIDERS_FAILED,
        },
    }
}
