
export const VALIDATE_EARNIPAY_ACCOUNT_START = "VALIDATE_EARNIPAY_ACCOUNT_START";
export const VALIDATE_EARNIPAY_ACCOUNT_DONE = "VALIDATE_EARNIPAY_ACCOUNT_DONE";
export const VALIDATE_EARNIPAY_ACCOUNT_FAILED = "VALIDATE_EARNIPAY_ACCOUNT_FAILED";

export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_START = "GET_DASHBOARD_START";
export const GET_DASHBOARD_FAILED = "GET_DASHBOARD_FAILED";
export const GET_DASHBOARD_FAILED_SILENT = "GET_DASHBOARD_FAILED_SILENT";

export const GET_TRANSACTIONS_COUNT = "GET_TRANSACTIONS_COUNT";
export const GET_TRANSACTIONS_COUNT_START = "GET_TRANSACTIONS_COUNT_START";
export const GET_TRANSACTIONS_COUNT_FAILED = "GET_TRANSACTIONS_COUNT_FAILED";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_START = "GET_TRANSACTIONS_START";
export const GET_TRANSACTIONS_FAILED = "GET_TRANSACTIONS_FAILED";
export const GET_TRANSACTIONS_FAILED_SILENT = "GET_TRANSACTIONS_FAILED_SILENT";

export const GET_TRANSACTIONS_DOWNLOAD = "GET_TRANSACTIONS_DOWNLOAD";
export const GET_TRANSACTIONS_DOWNLOAD_START = "GET_TRANSACTIONS_DOWNLOAD_START";
export const GET_TRANSACTIONS_DOWNLOAD_FAILED = "GET_TRANSACTIONS_DOWNLOAD_FAILED";

export const GET_YIELD_SETTINGS = "GET_YIELD_SETTINGS";
export const GET_YIELD_SETTINGS_START = "GET_YIELD_SETTINGS_START";
export const GET_YIELD_SETTINGS_FAILED = "GET_YIELD_SETTINGS_FAILED";

export const GET_YIELD_REPORT = "GET_YIELD_REPORT";
export const GET_YIELD_REPORT_START = "GET_YIELD_REPORT_START";
export const GET_YIELD_REPORT_FAILED = "GET_YIELD_REPORT_FAILED";

export const DOWNLOAD_YIELD_REPORT = "DOWNLOAD_YIELD_REPORT";
export const DOWNLOAD_YIELD_REPORT_START = "DOWNLOAD_YIELD_REPORT_START";
export const DOWNLOAD_YIELD_REPORT_FAILED = "DOWNLOAD_YIELD_REPORT_FAILED";

export const OPT_IN_OUT_YIELD = "OPT_IN_OUT_YIELD";
export const OPT_IN_OUT_YIELD_START = "OPT_IN_OUT_YIELD_START";
export const OPT_IN_OUT_YIELD_FAILED = "OPT_IN_OUT_YIELD_FAILED";
export const OPT_IN_OUT_YIELD_RESET = "OPT_IN_OUT_YIELD_RESET";

export const CREATE_WALLET = "CREATE_WALLET";
export const CREATE_WALLET_START = "CREATE_WALLET_START";
export const CREATE_WALLET_FAILED = "CREATE_WALLET_FAILED";
export const CREATE_WALLET_RESET = "CREATE_WALLET_RESET";

export const CREATE_ADDITIONAL_WALLET = "CREATE_ADDITIONAL_WALLET";
export const CREATE_ADDITIONAL_WALLET_START = "CREATE_ADDITIONAL_WALLET_START";
export const CREATE_ADDITIONAL_WALLET_FAILED = "CREATE_ADDITIONAL_WALLET_FAILED";
export const CREATE_ADDITIONAL_WALLET_RESET = "CREATE_ADDITIONAL_WALLET_RESET";

export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";
export const GET_WALLET_BALANCE_START = "GET_WALLET_BALANCE_START";
export const GET_WALLET_BALANCE_FAILED = "GET_WALLET_BALANCE_FAILED";

export const GET_BENEFICIARIES = "GET_BENEFICIARIES";
export const GET_BENEFICIARIES_START = "GET_BENEFICIARIES_START";
export const GET_BENEFICIARIES_FAILED = "GET_BENEFICIARIES_FAILED";

export const GET_BENEFICIARIES_DOWNLOAD = "GET_BENEFICIARIES_DOWNLOAD";
export const GET_BENEFICIARIES_DOWNLOAD_START = "GET_BENEFICIARIES_DOWNLOAD_START";
export const GET_BENEFICIARIES_DOWNLOAD_FAILED = "GET_BENEFICIARIES_DOWNLOAD_FAILED";

export const DELETE_BENEFICIARY = "DELETE_BENEFICIARY";
export const DELETE_BENEFICIARY_START = "DELETE_BENEFICIARY_START";
export const DELETE_BENEFICIARY_FAILED = "DELETE_BENEFICIARY_FAILED";
export const DELETE_BENEFICIARY_RESET = "DELETE_BENEFICIARY_RESET";

export const GET_BANKS = "GET_BANKS";
export const GET_BANKS_START = "GET_BANKS_START";
export const GET_BANKS_FAILED = "GET_BANKS_FAILED";

export const GENERATE_OTP = "GENERATE_OTP";
export const GENERATE_OTP_START = "GENERATE_OTP_START";
export const GENERATE_OTP_FAILED = "GENERATE_OTP_FAILED";
export const GENERATE_OTP_RESET = "GENERATE_OTP_RESET";

export const GENERATE_OTP_RESET_PIN = "GENERATE_OTP_RESET_PIN";
export const GENERATE_OTP_RESET_PIN_START = "GENERATE_OTP_RESET_PIN_START";
export const GENERATE_OTP_RESET_PIN_FAILED = "GENERATE_OTP_RESET_PIN_FAILED";
export const GENERATE_OTP_RESET_PIN_RESET = "GENERATE_OTP_RESET_PIN_RESET";

export const GENERATE_BULK_BENEFICIARY_OTP_START = "GENERATE_BULK_BENEFICIARY_OTP_START";
export const GENERATE_BULK_BENEFICIARY_OTP_DONE = "GENERATE_BULK_BENEFICIARY_OTP_DONE";
export const GENERATE_BULK_BENEFICIARY_OTP_FAILED = "GENERATE_BULK_BENEFICIARY_OTP_FAILED";

export const CREATE_ACCESS_PIN = "CREATE_ACCESS_PIN";
export const CREATE_ACCESS_PIN_START = "CREATE_ACCESS_PIN_START";
export const CREATE_ACCESS_PIN_FAILED = "CREATE_ACCESS_PIN_FAILED";
export const CREATE_ACCESS_PIN_RESET = "CREATE_ACCESS_PIN_RESET";

export const RESET_ACCESS_PIN = "RESET_ACCESS_PIN";
export const RESET_ACCESS_PIN_START = "RESET_ACCESS_PIN_START";
export const RESET_ACCESS_PIN_FAILED = "RESET_ACCESS_PIN_FAILED";
export const RESET_ACCESS_PIN_RESET = "RESET_ACCESS_PIN_RESET";

export const VALIDATE_ACCOUNT = "VALIDATE_ACCOUNT";
export const VALIDATE_ACCOUNT_START = "VALIDATE_ACCOUNT_START";
export const VALIDATE_ACCOUNT_FAILED = "VALIDATE_ACCOUNT_FAILED";
export const VALIDATE_ACCOUNT_RESET = "VALIDATE_ACCOUNT_RESET";

export const CREATE_BENEFICIARY = "CREATE_BENEFICIARY";
export const CREATE_BENEFICIARY_START = "CREATE_BENEFICIARY_START";
export const CREATE_BENEFICIARY_FAILED = "CREATE_BENEFICIARY_FAILED";
export const CREATE_BENEFICIARY_RESET = "CREATE_BENEFICIARY_RESET";

export const CREATE_BULK_BENEFICIARIES_START = "CREATE_BULK_BENEFICIARIES_START";
export const CREATE_BULK_BENEFICIARIES_DONE = "CREATE_BULK_BENEFICIARIES_DONE";
export const CREATE_BULK_BENEFICIARIES_FAILED = "CREATE_BULK_BENEFICIARIES_FAILED";
export const RESET_BULK_BENEFICIARY_OTP_COUNT = "RESET_BULK_BENEFICIARY_OTP_COUNT";

export const WALLET_TRANSFER = "WALLET_TRANSFER";
export const WALLET_TRANSFER_START = "WALLET_TRANSFER_START";
export const WALLET_TRANSFER_FAILED = "WALLET_TRANSFER_FAILED";
export const WALLET_TRANSFER_RESET = "WALLET_TRANSFER_RESET";

export const WALLET_TRANSFER_UPLOAD = "WALLET_TRANSFER_UPLOAD";
export const WALLET_TRANSFER_UPLOAD_FAILED = "WALLET_TRANSFER_UPLOAD_FAILED";

export const GENERATE_ACCOUNT_STATEMENT = "GENERATE_ACCOUNT_STATEMENT";
export const GENERATE_ACCOUNT_STATEMENT_START = "GENERATE_ACCOUNT_STATEMENT_START";
export const GENERATE_ACCOUNT_STATEMENT_FAILED = "GENERATE_ACCOUNT_STATEMENT_FAILED";
export const GENERATE_ACCOUNT_STATEMENT_RESET = "GENERATE_ACCOUNT_STATEMENT_RESET";

export const FUND_BUSINESS_ACCOUNT = 'FUND_BUSINESS_ACCOUNT';
export const FUND_BUSINESS_ACCOUNT_START = 'FUND_BUSINESS_ACCOUNT_START';
export const FUND_BUSINESS_ACCOUNT_FAILED = 'FUND_BUSINESS_ACCOUNT_FAILED';
export const VERIFY_BUSINESS_ACCOUNT_FUNDING = 'VERIFY_BUSINESS_ACCOUNT_FUNDING';
export const VERIFY_BUSINESS_ACCOUNT_FUNDING_START = 'VERIFY_BUSINESS_ACCOUNT_FUNDING_START';
export const VERIFY_BUSINESS_ACCOUNT_FUNDING_FAILED = 'VERIFY_BUSINESS_ACCOUNT_FUNDING_FAILED';

export const GET_BUSINESS_ACCOUNTS = 'GET_BUSINESS_ACCOUNTS';
export const GET_BUSINESS_ACCOUNTS_START = 'GET_BUSINESS_ACCOUNTS_START';
export const GET_BUSINESS_ACCOUNTS_FAILED = 'GET_BUSINESS_ACCOUNTS_FAILED';

export const FREEZE_ACCOUNT = 'FREEZE_ACCOUNT';
export const FREEZE_ACCOUNT_START = 'FREEZE_ACCOUNT_START';
export const FREEZE_ACCOUNT_FAILED = 'FREEZE_ACCOUNT_FAILED';
export const FREEZE_ACCOUNT_RESET = "FREEZE_ACCOUNT_RESET";

export const UNFREEZE_ACCOUNT = 'UNFREEZE_ACCOUNT';
export const UNFREEZE_ACCOUNT_START = 'UNFREEZE_ACCOUNT_START';
export const UNFREEZE_ACCOUNT_FAILED = 'UNFREEZE_ACCOUNT_FAILED';
export const UNFREEZE_ACCOUNT_RESET = 'UNFREEZE_ACCOUNT_RESET';

export const GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS';
export const GET_ACCOUNT_DETAILS_START = 'GET_ACCOUNT_DETAILS_START';
export const GET_ACCOUNT_DETAILS_FAILED = 'GET_ACCOUNT_DETAILS_FAILED';

export const GET_ACCOUNT_TRANSACTIONS = 'GET_ACCOUNT_TRANSACTIONS';
export const GET_ACCOUNT_TRANSACTIONS_START = 'GET_ACCOUNT_TRANSACTIONS_START';
export const GET_ACCOUNT_TRANSACTIONS_FAILED = 'GET_ACCOUNT_TRANSACTIONS_FAILED';

export const CLOSE_ACCOUNT = 'CLOSE_ACCOUNT';
export const CLOSE_ACCOUNT_START = 'CLOSE_ACCOUNT_START';
export const CLOSE_ACCOUNT_FAILED = 'CLOSE_ACCOUNT_FAILED';
export const CLOSE_ACCOUNT_RESET = 'CLOSE_ACCOUNT_RESET';

export const UPDATE_WALLET_ACCOUNT = 'UPDATE_WALLET_ACCOUNT';
export const UPDATE_WALLET_ACCOUNT_START = 'UPDATE_WALLET_ACCOUNT_START';
export const UPDATE_WALLET_ACCOUNT_FAILED = 'UPDATE_WALLET_ACCOUNT_FAILED';
export const UPDATE_WALLET_ACCOUNT_RESET = 'UPDATE_WALLET_ACCOUNT_RESET';

export const CREATE_BUSINESS_CREDIT = 'CREATE_BUSINESS_CREDIT';
export const CREATE_BUSINESS_CREDIT_START = 'CREATE_BUSINESS_CREDIT_START';
export const CREATE_BUSINESS_CREDIT_FAILED = 'CREATE_BUSINESS_CREDIT_FAILED';
export const CREATE_BUSINESS_CREDIT_RESET = 'CREATE_BUSINESS_CREDIT_RESET'

export const GET_BUSINESS_CREDIT = 'GET_BUSINESS_CREDIT';
export const GET_BUSINESS_CREDIT_START = 'GET_BUSINESS_CREDIT_START';
export const GET_BUSINESS_CREDIT_FAILED = 'GET_BUSINESS_CREDIT_FAILED';

export const MOVE_MONEY = 'MOVE_MONEY';
export const MOVE_MONEY_START = 'MOVE_MONEY_START';
export const MOVE_MONEY_FAILED = 'MOVE_MONEY_FAILED';
export const MOVE_MONEY_RESET = 'MOVE_MONEY_RESET';

export const GET_YIELD_BALANCE = "GET_YIELD_BALANCE"
export const GET_YIELD_BALANCE_START = "GET_YIELD_BALANCE_START"
export const GET_YIELD_BALANCE_FAILED = "GET_YIELD_BALANCE_FAILED"

export const SET_SOURCE_WALLET_ID = "SET_SOURCE_WALLET_ID"
export const SET_MULTIPLE_SOURCE_WALLET_ID = "SET_MULTIPLE_SOURCE_WALLET_ID"