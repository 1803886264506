import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customSuccessToast } from "utils/customToast";

const initialState = {
    dashboardLoading: false,
    dashboard: {},
    isFiltering: false,
    
    bulkPayMetricsLoading: false,
    bulkPayMetricsData: {},
    bulkPayAggregatesLoading: false,
    bulkPayAggregatesData: {},
    
    onDemandOnboardingForm: null,
    submitOnboardingLoading: false,
    submitOnboardingData: null,
};


export default function reducer(state = initialState, action){
    switch (action.type){

        case actions.GET_BUSINESS_DASHBOARD:
            state = {
                ...state,
                dashboardLoading: false,
                dashboard: action.payload,
            }
            return state;

           
        case actions.GET_BUSINESS_DASHBOARD_START:
            state = {
                ...state,
                dashboardLoading: true,
            }
            return state;


        case actions.GET_BUSINESS_DASHBOARD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                dashboardLoading: false,
                dashboard: {},
            }
            return state;
            
        case actions.FILTER_BUSINESS_DASHBOARD:
            state = {
                ...state,
                isFiltering: false,
                dashboard: action.payload,
            }
            return state;

           
        case actions.FILTER_BUSINESS_DASHBOARD_START:
            state = {
                ...state,
                isFiltering: true,
            }
            return state;


        case actions.FILTER_BUSINESS_DASHBOARD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                isFiltering: false,
                dashboard: {},
            }
            return state;


        case actions.GET_BULKPAY_METRICS_START:
            state = {
                ...state,
                bulkPayMetricsLoading: true,
            }
            return state;


        case actions.GET_BULKPAY_METRICS_DONE:
            state = {
                ...state,
                bulkPayMetricsLoading: false,
                bulkPayMetricsData: action.payload,
            }
            return state;


        case actions.GET_BULKPAY_METRICS_FAILED:
            if (action.payload?.message !== "Wallet Not Found"){
                errorReducer(action.payload);
            }
            state = {
                ...state,
                bulkPayMetricsLoading: false,
                bulkPayMetricsData: {},
            }
            return state;


        case actions.GET_BULKPAY_AGGREGATES_START:
            state = {
                ...state,
                bulkPayAggregatesLoading: true,
            }
            return state;


        case actions.GET_BULKPAY_AGGREGATES_DONE:
            state = {
                ...state,
                bulkPayAggregatesLoading: false,
                bulkPayAggregatesData: action.payload,
            }
            return state;


        case actions.GET_BULKPAY_AGGREGATES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                bulkPayAggregatesLoading: false,
                bulkPayAggregatesData: {},
            }
            return state;


        case actions.SAVE_ON_DEMAND_ONBOARDING:
            state = {
                ...state,
                onDemandOnboardingForm: action.payload,
            }
            return state;


            case actions.SUBMIT_ONBOARDING_FORM_START:
                state = {
                    ...state,
                    submitOnboardingLoading: true,
                    submitOnboardingData: null,
                }
                return state;
    
    
            case actions.SUBMIT_ONBOARDING_FORM_DONE:
                customSuccessToast({ message: action.payload.message })
                state = {
                    ...state,
                    submitOnboardingLoading: false,
                    submitOnboardingData: action.payload,
                }
                return state;
    
    
            case actions.SUBMIT_ONBOARDING_FORM_FAILED:
                errorReducer(action.payload);
                state = {
                    ...state,
                    submitOnboardingLoading: false,
                    submitOnboardingData: null,
                }
                return state;

        default:
            return state;
    }
}