import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";


const initialState = {
    complianceProgress: 0,
    businessType: "",
    
    complianceInfoSavedLoading: false,
    complianceInfoSaved: {},
    savedBvnInfo: {},
    
    socialsLoading: false,
    socials: {},
    
    directorsAddedLoading: false,
    directorsAdded: {},
    directorsUpdatedLoading: false,
    directorsUpdated: [],
    directorsDeletedLoading: false,
    directorsDeleted: {},
    
    validatingBvnAndNin: false,
    bvnAndNinValidationResult: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {


        case actions.UPDATE_COMPLIANCE_PROGRESS:
            state = {
                ...state,
                complianceProgress: action.payload,
            }
            return state;
            
            
        case actions.UPDATE_COMPLIANCE_BUSINESS_TYPE:
            state = {
                ...state,
                businessType: action.payload,
            }
            return state;
            
            
        case actions.SAVE_COMPLIANCE_INFO:
            state = {
                ...state,
                complianceInfoSavedLoading: false,
                complianceInfoSaved: action.payload
            }
            return state;


        case actions.SAVE_COMPLIANCE_INFO_START:
            state = {
                ...state,
                complianceInfoSavedLoading: true,
            }
            return state;


        case actions.SAVE_COMPLIANCE_INFO_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                complianceInfoSavedLoading: false,
                complianceInfoSaved: action.payload
            }
            return state;

            
        case actions.SAVE_COMPLIANCE_INFO_RESET:
            state = {
                ...state,
                complianceInfoSavedLoading: false,
                complianceInfoSaved: {},
            }
            return state;
                        
            
        case actions.UPDATE_BVN:
            state = {
                ...state,
                savedBvnInfo: action.payload,
            }
            return state;
            
            
        case actions.ADD_SOCIALS:
            state = {
                ...state,
                socialsLoading: false,
                socials: action.payload
            }
            return state;


        case actions.ADD_SOCIALS_START:
            state = {
                ...state,
                socialsLoading: true,
            }
            return state;


        case actions.ADD_SOCIALS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                socialsLoading: false,
                socials: action.payload
            }
            return state;

            
        case actions.ADD_SOCIALS_RESET:
            state = {
                ...state,
                socialsLoading: false,
                socials: {},
            }
            return state;
            
            
        case actions.ADD_DIRECTORS:
            state = {
                ...state,
                directorsAddedLoading: false,
                directorsAdded: action.payload
            }
            return state;


        case actions.ADD_DIRECTORS_START:
            state = {
                ...state,
                directorsAddedLoading: true,
            }
            return state;


        case actions.ADD_DIRECTORS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                directorsAddedLoading: false,
                directorsAdded: action.payload
            }
            return state;

            
        case actions.ADD_DIRECTORS_RESET:
            state = {
                ...state,
                directorsAddedLoading: false,
                directorsAdded: {},
            }
            return state;
            
    
        case actions.UPDATE_DIRECTORS_START:
            state = {
                ...state,
                directorsUpdatedLoading: true,
                directorsUpdated: [],
            }
            return state;

    
        case actions.UPDATE_DIRECTORS:
            state = {
                ...state,
                directorsUpdated: [
                    ...state.directorsUpdated,
                    action.payload,
                ]
            }
            return state;


        case actions.UPDATE_DIRECTORS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                directorsUpdated: [
                    ...state.directorsUpdated,
                    action.payload,
                ]
            }
            return state;

            
        case actions.UPDATE_DIRECTORS_RESET:
            state = {
                ...state,
                directorsUpdatedLoading: false,
            }
            return state;
            
        
        case actions.DELETE_DIRECTORS:
            state = {
                ...state,
                directorsDeletedLoading: false,
                directorsDeleted: action.payload
            }
            return state;


        case actions.DELETE_DIRECTORS_START:
            state = {
                ...state,
                directorsDeletedLoading: true,
            }
            return state;


        case actions.DELETE_DIRECTORS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                directorsDeletedLoading: false,
                directorsDeleted: action.payload
            }
            return state;

            
        case actions.DELETE_DIRECTORS_RESET:
            state = {
                ...state,
                directorsDeletedLoading: false,
                directorsDeleted: {},
            }
            return state;
            
        case actions.VALIDATE_BVN_AND_NIN:
            state = {
                ...state,
                validatingBvnAndNin: false,
                bvnAndNinValidationResult: action.payload
            }
            return state;


        case actions.VALIDATE_BVN_AND_NIN_START:
            state = {
                ...state,
                validatingBvnAndNin: true,
            }
            return state;


        case actions.VALIDATE_BVN_AND_NIN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                validatingBvnAndNin: false,
                bvnAndNinValidationResult: action.payload
            }
            return state;

            
        case actions.VALIDATE_BVN_AND_NIN_RESET:
            state = {
                ...state,
                validatingBvnAndNin: false,
                bvnAndNinValidationResult: {},
            }
            return state;
        
        default:
            return state;
    }
}