import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ButtonLoader = (props) => {
    const {type, loading, disabled, className, onClick, children, ...rest} = props;

    return (
        <>
            
            <button type={type} disabled={loading || disabled} className={className} onClick={onClick} {...rest}>
                <FontAwesomeIcon icon="circle-notch" spin className={"text-xl mr-2 " + (loading?"inline":"hidden")} />
                {children}
            </button>

        </>
    )
}

export default ButtonLoader
