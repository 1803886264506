import { ButtonLoader } from 'components/__new/common/form';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

const OnboardingFeatureWrapper = (props) => {
  const {
    headerText,
    headerSubText,
    headerColor,
    bodyText,
    bgColor,
    bgImage,
    isPeople
  } = props;
  
  const history = useHistory()
  
  const handleGetStarted = () => {
    if(isPeople){
        history.push('/compliance/onboarding');
    } else {
        history.push('/compliance/onboarding');
    }
  };

  return (
    <div className="bg-white max-w-[642px] min-h-full flex flex-col justify-start items-start mt-[28px] mx-auto p-[16px] border-[1px] border-gray-200 rounded-[29px]">
      <main
        className={clsx(
          'relative flex flex-col justify-start items-start w-full h-[404px]  rounded-[24px] mb-[24px] overflow-hidden',
          bgColor
        )}
      >
        <div className="pl-[35px] pt-[45px] w-[90%]">
          <h2
            className={clsx(
              'font-medium text-[24px] leading-[28.8px] mb-[8px]',
              headerColor
            )}
          >
            {headerText}
          </h2>
          <p className='text-[20px] leading-[24px] text-[#1A202C]'>{headerSubText}</p>
          <div
          className='absolute bottom-0  left-0 w-full h-full'>{bgImage}</div>
        </div>
      </main>
      <p className="font-normal text-[16px] leading-[24px] text-ep-gray-600 mb-[16px]">
        {bodyText}
      </p>
      <footer className="flex justify-end space-x-4">
        <ButtonLoader
          type="button"
          className="btn btn-ep-primary min-w-[132px] h-[48px] rounded-[10px] font-medium text-[16px] leading-[20px] whitespace-nowrap"
          onClick={handleGetStarted}
        >
          Get Started
        </ButtonLoader>
      </footer>
    </div>
  );
};

export default OnboardingFeatureWrapper;
