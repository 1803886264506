import React from 'react'
import payrollProcessing from "assets/images/icons/project-icons/payroll-feature-processing.svg"
import taxManagement from "assets/images/icons/project-icons/payroll-feature-tax-mgt.svg"
import pensionRemittance from "assets/images/icons/project-icons/payroll-feature-pension-remittance.svg"
import multiplePayStructures from "assets/images/icons/project-icons/payroll-feature-multiple-pay-structure.svg"
import timeSavings from "assets/images/icons/project-icons/payroll-feature-time.svg"
import accuracy from "assets/images/icons/project-icons/payroll-feature-accuracy.svg"
import compliance from "assets/images/icons/project-icons/payroll-feature-compliance.svg"
import convenience from "assets/images/icons/project-icons/payroll-feature-convenience.svg"
import { ReactComponent as IconsTimes } from "assets/images/icons/project-icons/times.svg";
import usePayrollEssentials from 'hooks/usePayrollEssentials'
import FormDrawer from '../common/form-drawer'
import { togglePayrollFeaturesModal } from 'store/entities/payroll/payrollSetup/action'
import logger from 'utils/logger'
import useLoginInfo from 'hooks/useLoginInfo'


const PayrollFeaturesModal = () => {
    const {dispatch, useSelector, history} = usePayrollEssentials();
    const {isPayrollFeaturesModalOpen} = useSelector((state) => state.entities.payrollSetup);
    const {userInfo} = useLoginInfo();
    
    const toggleModal = () => dispatch(togglePayrollFeaturesModal())
    
    const startFreeTrial = () => {
        // make an api call to register the user's interest in the payroll free trial
        // also with this request, the backend would need to add "PAYROLL" to the user's array of products if not already there
        logger("Employer-Payroll-visit", userInfo);
        dispatch(togglePayrollFeaturesModal());
        history.push("/payroll-configuration");
    }
    
    const payrollFeatures = [
        {
            title: "Features Overview",
            subFeatures: [
                {
                    title: "Payroll Processing",
                    subtitle: "Streamline your payments with efficient processing",
                    icon: payrollProcessing
                },
                {
                    title: "Tax Management",
                    subtitle: "Ensure timely and accurate tax submissions without the hassle",
                    icon: taxManagement
                },
                {
                    title: "Pension Remittance",
                    subtitle: "Reliable and timely pension contributions for your team",
                    icon: pensionRemittance
                },
                {
                    title: "Multiple Pay Structure Creation",
                    subtitle: "Customize and manage diverse pay structures effortlessly",
                    icon: multiplePayStructures
                },
            ]
        },
        {
            title: "Why Choose Our Payroll Service?",
            subFeatures: [
                {
                    title: "Time Savings",
                    subtitle: "Spend less time on payroll and more on growing your business",
                    icon: timeSavings
                },
                {
                    title: "Accuracy",
                    subtitle: "Say goodbye to manual errors with our automated processes",
                    icon: accuracy
                },
                {
                    title: "Compliance",
                    subtitle: "Stay up-to-date with regulations and requirements",
                    icon: compliance
                },
                {
                    title: "Convenience",
                    subtitle: "Access and manage payroll anytime, anywhere",
                    icon: convenience
                },
            ]
        }
    ]
    
    
    return (
        <FormDrawer display="center" size="4xl" isOpen={isPayrollFeaturesModalOpen} setIsOpen={() => {}}>
            <div className="p-8">
                <div className="w-full flex items-center justify-end mb-3">
                    <IconsTimes onClick={toggleModal} className="w-3 h-3 cursor-pointer hover:scale-110 transition-transform" />
                </div>
                
                <div className="flex flex-col items-center justify-center w-full">
                    <div className="lg:text-lg font-medium mb-2">Earnipay Payroll</div>
                    <div className="text-[#848F9F] text-sm">Look what our Payroll service offers you!</div>
                </div>
                
                <div className="flex items-start justify-center lg:justify-between flex-wrap lg:flex-nowrap mt-8">
                    {payrollFeatures.map(({title, subFeatures }) => (
                        <div key={title} className="w-full lg:w-[47%] mb-4 lg:mb-0 lg:pr-7">
                            <div className="font-medium mb-4 lg:mb-7">
                                {title}
                            </div>
                            
                            <div>
                                {subFeatures.map(({title, subtitle, icon}) => {
                                    return (
                                        <div key={title} className="flex items-start mb-4 lg:mb-6">
                                            <div className="bg-[#EAFBF6] p-2 rounded-lg mr-5 flex items-center justify-center h-12 w-12">
                                                <img src={icon} alt={title}  />
                                            </div>
                                            
                                            <div>
                                                <div className="text-[#353F50] mb-1 text-sm lg:text-[14px]">
                                                    {title}
                                                </div>
                                                <div className="text-[#5F738C] text-xs lg:text-[12px]">
                                                    {subtitle}.
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    ))}
                </div>
                
                <div className="mb-8 lg:mt-4">
                    <div className="text-[#1F2937] lg:text-lg mb-0">
                        Try It Out - On the House!
                    </div>
                    
                    {/* <div className="text-[#848F9F]">
                        Enjoy a <span className="font-medium">45-Day Free</span> Trial. Experience our full suite of payroll services without spending a dime during this period.
                    </div> */}
                </div>
                
                <div className="w-full flex items-center justify-center pb-12 lg:pb-0">
                    {/* <button type="button" onClick={toggleModal} className="btn btn-md btn-transparent-primary mr-5">
                        I'll do this later
                    </button> */}
                    
                    <button type="button" onClick={startFreeTrial} className="btn btn-md btn-ep-primary">
                        Start Now
                    </button>
                </div>
                
            </div>
        </FormDrawer>
    )
}

export default PayrollFeaturesModal