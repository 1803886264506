export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_START = "UPDATE_PASSWORD_START";
export const UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED";
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET";

export const GET_EMPLOPYER_INFO = "GET_EMPLOPYER_INFO";
export const GET_EMPLOPYER_INFO_START = "GET_EMPLOPYER_INFO_START";
export const GET_EMPLOPYER_INFO_FAILED = "GET_EMPLOPYER_INFO_FAILED";
export const UPDATE_EMPLOPYER_INFO = "UPDATE_EMPLOPYER_INFO";

export const UPDATE_EMPLOYER_PRODUCTS = "UPDATE_EMPLOYER_PRODUCTS";
export const UPDATE_EMPLOYER_PRODUCTS_START = "UPDATE_EMPLOYER_PRODUCTS_START";
export const UPDATE_EMPLOYER_PRODUCTS_FAILED = "UPDATE_EMPLOYER_PRODUCTS_FAILED";
export const RESET_EMPLOYER_PRODUCTS_ACTION = "RESET_EMPLOYER_PRODUCTS_ACTION";

export const UPDATE_COMPANY_INFO = "UPDATE_COMPANY_INFO";
export const UPDATE_COMPANY_INFO_START = "UPDATE_COMPANY_INFO_START";
export const UPDATE_COMPANY_INFO_FAILED = "UPDATE_COMPANY_INFO_FAILED";
export const UPDATE_COMPANY_INFO_RESET = "UPDATE_COMPANY_INFO_RESET";
