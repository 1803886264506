import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

const initialState = {
    approvalWorkflowLoading: false,
    approvalWorkflow: {},

    approvalWorkflowsLoading: false,
    approvalWorkflows: {},

    approvalWorkflowStageLoading: false,
    approvalWorkflowStage: {},

    deletedApprovalWorkflowLoading: false,
    deletedApprovalWorkflow: {},

    requestLoading: false,
    request: {},

    requestsLoading: false,
    requests: {},

    requestAnalyticsLoading: false,
    requestAnalytics: {},

    requestApprovalsLoading: false,
    requestApprovals: {},

    requestCommentLoading: false,
    requestComment: {},

    requestCommentsLoading: false,
    requestComments: {},

    rolesLoading: false,
    roles: {},

    approvedRequestLoading: false,
    approvedRequest: {},

    rejectedRequestLoading: false,
    rejectedRequest: {},

    disabledWorkflowLoading: false,
    disabledWorkflow: {},

    enabledWorkflowLoading: false,
    enabledWorkflow: {},

    updatedWorkflowLoading: false,
    updatedWorkflow: {},

    requestDisbursementLoading: false,
    requestDisbursement: {},

    cancelledRequestLoading: false,
    cancelledRequest: {}
};


export default function reducer(state = initialState, action){
    switch (action.type){

        case actions.CREATE_APPROVAL_WORKFLOW_START:
            state = {
                ...state,
                approvalWorkflowLoading: true,
                approvalWorkflow: {},
            }
            return state;

        case actions.CREATE_APPROVAL_WORKFLOW:
            state = {
                ...state,
                approvalWorkflowLoading: false,
                approvalWorkflow: action.payload
            }
            return state;

        case actions.CREATE_APPROVAL_WORKFLOW_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                approvalWorkflowLoading: false,
                approvalWorkflow: {},
            }
            return state;

        case actions.CREATE_APPROVAL_WORKFLOW_RESET:
                state = {
                    ...state,
                    approvalWorkflow: {},
                }
                return state;

        case actions.GET_APPROVAL_WORKFLOWS_START:
            state = {
                ...state,
                approvalWorkflowsLoading: true,
                approvalWorkflows: {},
            }
            return state;

        case actions.GET_APPROVAL_WORKFLOWS:
            state = {
                ...state,
                approvalWorkflowsLoading: false,
                approvalWorkflows: action.payload
            }
            return state;

        case actions.GET_APPROVAL_WORKFLOWS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                approvalWorkflowsLoading: false,
                approvalWorkflows: {},
            }
            return state;

        case actions.UPDATE_APPROVAL_WORKFLOW_START:
            state = {
                ...state,
                updatedWorkflowLoading: true,
                updatedWorkflow: {},
            }
            return state;

        case actions.UPDATE_APPROVAL_WORKFLOW:
            state = {
                ...state,
                updatedWorkflowLoading: false,
                updatedWorkflow: action.payload
            }
            return state;

        case actions.UPDATE_APPROVAL_WORKFLOW_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                updatedWorkflowLoading: false,
                updatedWorkflow: {},
            }
            return state;

        case actions.UPDATE_APPROVAL_WORKFLOW_RESET:
            state = {
                ...state,
                updatedWorkflow: {},
            }
            return state;

        case actions.DELETE_APPROVAL_WORKFLOW_START:
            state = {
                ...state,
                deletedApprovalWorkflowLoading: true,
                deletedApprovalWorkflow: {},
            }
            return state;

        case actions.DELETE_APPROVAL_WORKFLOW:
            state = {
                ...state,
                deletedApprovalWorkflowLoading: false,
                deletedApprovalWorkflow: action.payload
            }
            return state;

        case actions.DELETE_APPROVAL_WORKFLOW_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                deletedApprovalWorkflowLoading: false,
                deletedApprovalWorkflow: {},
            }
            return state;

        case actions.DELETE_APPROVAL_WORKFLOW_RESET:
            state = {
                ...state,
                deletedApprovalWorkflow: {},
            }
            return state;

        case actions.ENABLE_APPROVAL_WORKFLOW_START:
            state = {
                ...state,
                enabledWorkflowLoading: true,
                enabledWorkflow: {},
            }
            return state;

        case actions.ENABLE_APPROVAL_WORKFLOW:
            state = {
                ...state,
                enabledWorkflowLoading: false,
                enabledWorkflow: action.payload
            }
            return state;

        case actions.ENABLE_APPROVAL_WORKFLOW_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                enabledWorkflowLoading: false,
                enabledWorkflow: {},
            }
            return state;

        case actions.ENABLE_APPROVAL_WORKFLOW_RESET:
            state = {
                ...state,
                enabledWorkflow: {},
            }
            return state;

        case actions.DISABLE_APPROVAL_WORKFLOW_START:
            state = {
                ...state,
                disabledWorkflowLoading: true,
                disabledWorkflow: {},
            }
            return state;

        case actions.DISABLE_APPROVAL_WORKFLOW:
            state = {
                ...state,
                disabledWorkflowLoading: false,
                disabledWorkflow: action.payload
            }
            return state;

        case actions.DISABLE_APPROVAL_WORKFLOW_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                disabledWorkflowLoading: false,
                disabledWorkflow: {},
            }
            return state;

        case actions.DISABLE_APPROVAL_WORKFLOW_RESET:
            state = {
                ...state,
                disabledWorkflow: {},
            }
            return state;

        case actions.UPDATE_APPROVAL_WORKFLOW_STAGE_START:
            state = {
                ...state,
                approvalWorkflowStageLoading: true,
                approvalWorkflowStage: {},
            }
            return state;

        case actions.UPDATE_APPROVAL_WORKFLOW_STAGE:
            state = {
                ...state,
                approvalWorkflowStageLoading: false,
                approvalWorkflowStage: action.payload
            }
            return state;

        case actions.UPDATE_APPROVAL_WORKFLOW_STAGE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                approvalWorkflowStageLoading: false,
                approvalWorkflowStage: {},
            }
            return state;

        case actions.UPDATE_APPROVAL_WORKFLOW_STAGE_RESET:
            state = {
                ...state,
                approvalWorkflowStage: {},
            }
            return state;

        case actions.CREATE_REQUEST_START:
            state = {
                ...state,
                requestLoading: true,
                request: {},
            }
            return state;

        case actions.CREATE_REQUEST:
            state = {
                ...state,
                requestLoading: false,
                request: action.payload
            }
            return state;

        case actions.CREATE_REQUEST_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                requestLoading: false,
                request: {},
            }
            return state;

        case actions.CREATE_REQUEST_RESET:
            state = {
                ...state,
                request: {},
            }
            return state;

        case actions.GET_REQUEST_ANALYTICS_START:
            state = {
                ...state,
                requestAnalyticsLoading: true,
                requestAnalytics: {},
            }
            return state;

        case actions.GET_REQUEST_ANALYTICS:
            state = {
                ...state,
                requestAnalyticsLoading: false,
                requestAnalytics: action.payload
            }
            return state;

        case actions.GET_REQUEST_ANALYTICS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                requestAnalyticsLoading: false,
                requestAnalytics: {},
            }
            return state;

        case actions.GET_REQUESTS_START:
            state = {
                ...state,
                requestsLoading: true,
                requests: {},
            }
            return state;

        case actions.GET_REQUESTS:
            state = {
                ...state,
                requestsLoading: false,
                requests: action.payload
            }
            return state;

        case actions.GET_REQUESTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                requestsLoading: false,
                requests: {},
            }
            return state;

        case actions.GET_REQUEST_APPROVALS_START:
            state = {
                ...state,
                requestApprovalsLoading: true,
                requestApprovals: {},
            }
            return state;

        case actions.GET_REQUEST_APPROVALS:
            state = {
                ...state,
                requestApprovalsLoading: false,
                requestApprovals: action.payload
            }
            return state;

        case actions.GET_REQUEST_APPROVALS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                requestApprovalsLoading: false,
                requestApprovals: {},
            }
            return state;

        case actions.POST_REQUEST_COMMENT_START:
            state = {
                ...state,
                requestCommentLoading: true,
                requestComment: {},
            }
            return state;

        case actions.POST_REQUEST_COMMENT:
            state = {
                ...state,
                requestCommentLoading: false,
                requestComment: action.payload
            }
            return state;

        case actions.POST_REQUEST_COMMENT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                requestCommentLoading: false,
                requestComment: {},
            }
            return state;

        case actions.POST_REQUEST_COMMENT_RESET:
            state = {
                ...state,
                requestComment: {},
            }
            return state;

        case actions.GET_REQUEST_COMMENTS_START:
            state = {
                ...state,
                requestCommentsLoading: true,
                requestComments: {},
            }
            return state;

        case actions.GET_REQUEST_COMMENTS:
            state = {
                ...state,
                requestCommentsLoading: false,
                requestComments: action.payload
            }
            return state;

        case actions.GET_REQUEST_COMMENTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                requestCommentsLoading: false,
                requestComments: {},
            }
            return state;

        case actions.GET_ROLES_START:
            state = {
                ...state,
                rolesLoading: true,
                roles: {},
            }
            return state;

        case actions.GET_ROLES:
            state = {
                ...state,
                rolesLoading: false,
                roles: action.payload
            }
            return state;

        case actions.GET_ROLES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rolesLoading: false,
                roles: {},
            }
            return state;

        case actions.APPROVE_REQUEST_START:
            state = {
                ...state,
                approvedRequestLoading: true,
                approvedRequest: {},
            }
            return state;

        case actions.APPROVE_REQUEST:
            state = {
                ...state,
                approvedRequestLoading: false,
                approvedRequest: action.payload
            }
            return state;

        case actions.APPROVE_REQUEST_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                approvedRequestLoading: false,
                approvedRequest: {},
            }
            return state;

        case actions.APPROVE_REQUEST_RESET:
            state = {
                ...state,
                approvedRequest: {},
            }
            return state;

        case actions.REJECT_REQUEST_START:
            state = {
                ...state,
                rejectedRequestLoading: true,
                rejectedRequest: {},
            }
            return state;

        case actions.REJECT_REQUEST:
            state = {
                ...state,
                rejectedRequestLoading: false,
                rejectedRequest: action.payload
            }
            return state;

        case actions.REJECT_REQUEST_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rejectedRequestLoading: false,
                rejectedRequest: {},
            }
            return state;

        case actions.REJECT_REQUEST_RESET:
            state = {
                ...state,
                rejectedRequest: {},
            }
            return state;

        case actions.UPDATE_REQUEST_DISBURSEMENT_START:
            state = {
                ...state,
                requestDisbursementLoading: true,
                requestDisbursement: {},
            }
            return state;

        case actions.UPDATE_REQUEST_DISBURSEMENT:
            state = {
                ...state,
                requestDisbursementLoading: false,
                requestDisbursement: action.payload
            }
            return state;

        case actions.UPDATE_REQUEST_DISBURSEMENT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                requestDisbursementLoading: false,
                requestDisbursement: {},
            }
            return state;

        case actions.UPDATE_REQUEST_DISBURSEMENT_RESET:
            state = {
                ...state,
                requestDisbursement: {},
            }
            return state;

        case actions.CANCEL_REQUEST_START:
            state = {
                ...state,
                cancelledRequestLoading: true,
                cancelledRequest: {},
            }
            return state;

        case actions.CANCEL_REQUEST:
            state = {
                ...state,
                cancelledRequestLoading: false,
                cancelledRequest: action.payload
            }
            return state;

        case actions.CANCEL_REQUEST_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                cancelledRequestLoading: false,
                cancelledRequest: {},
            }
            return state;

        case actions.CANCEL_REQUEST_RESET:
            state = {
                ...state,
                cancelledRequest: {},
            }
            return state;

        default:
            return state;
    }
}