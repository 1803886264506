import FormDrawer from "components/__new/common/form-drawer";
import useLoginInfo from "hooks/useLoginInfo";

const LogoutConfirmModal = (props) => {
    const {isOpen, setIsOpen} = props;
    const { logoutUser } = useLoginInfo();
    
    const handleLogout = () => {
        logoutUser()
        setIsOpen(false);
    }

    return (
        <FormDrawer display="center" size="md" isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="p-6">
                <FormDrawer.Title
                    title="Logout"
                    description=""
                    showCloseButton={true}
                    close={setIsOpen}
                />

                <div className="mt-4">
                    <div className="flex items-center text-[#4A5564] font-normal">
                    Are you sure you want to logout of your account?
                    </div>
                </div>

                <div className="p-1 mt-4 flex items-center space-x-3">
                    <button type="button" className="btn btn-lg w-full rounded-lg whitespace-nowrap border border-[#DC2626] text-white bg-[#DC2626]"
                    onClick={handleLogout}
                    >
                        Yes
                    </button>
                    <button type="button" className="btn btn-lg w-full rounded-lg whitespace-nowrap border border-ep-primary-600 text-ep-primary-600 bg-white"
                        onClick={() => setIsOpen(false)}
                    >
                        No
                    </button>
                </div>
            </div>
        </FormDrawer>
    )
}

export default LogoutConfirmModal;