import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customErrorToast, customInfoToast, customSuccessToast } from "utils/customToast";

const initialState = {
    loading: false,
    allEmployees: {},
    employeesLoading: false,// y
    employees: {},// y
    
    employeeRoleLoading: false,
    employeeRoles: {},// y
    
    employeesDownloadLoading: false,// y
    employeesDownload: {},// y
    downloadLoading: false,
    downloadAllEmployees: {},
    downloadAllAcceptedEmployees: {},
    acceptedEmployeeLoading: false,
    acceptedEmployees: 0,

    employee: {}, // y
    employeeLoading: false, // y

    employeeCreateLoading: false, // y
    employeeCreateSuccess: false, // y
    employeeCreated: {},
    employeeCreateStatus: {}, // y
    employeeUploadLoading: false, // y
    employeeUploadStatusLoading: false,
    employeeUploadStatus: {},
    
    employeeBulkPreviewLoading: false, // y
    employeeBulkPreviewSuccess: false, // y
    employeeBulkPreview: null, // y
    
    employeeUpdateLoading: false,
    employeeUpdateSuccess: false,

    employeeStopEarningsLoading: false,
    employeeResumeEarningsLoading: false,
    employeeActivateLoading: false,
    employeeBulkActivateLoading: false,
    employeeBulkActivateSuccess: false,
    employeeDeactivateLoading: false,
    employeeBulkDeactivateLoading: false,
    employeeBulkDeactivateSuccess: false,
    employeeArchiveLoading: false,
    employeeBulkArchiveLoading: false,
    employeeBulkArchiveSuccess: false,
    eraEmployeeLoading: false, // y

    transactionsLoading: false,
    transactions: {},

    reloadPage: false,

    employeeMetricsLoading: false, // y
    employeeMetrics: {}, // y

    prevMonthEmployeeMetricsLoading: false,
    prevMonthEmployeeMetrics: {},
    
    inviteToOdpLoading: false,
    inviteToOdp: {},
    
    pauseEmployeeWithdrawalLoading: false,
    pauseEmployeeWithdrawal: {},
    
    resumeEmployeeWithdrawalLoading: false,
    resumeEmployeeWithdrawal: {},
    
    employeesInGroup: [],
    employeesInGroupLoading: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_EMPLOYEES:
            state = {
                ...state,
                loading: false,
                allEmployees: { ...action.payload.employees },
                employeesLoading: false,
                employees: action.payload,
            }
            return state;


        case actions.GET_ALL_EMPLOYEES_START:
            state = {
                ...state,
                loading: true,
                employeesLoading: true,
            }
            return state;


        case actions.GET_ALL_EMPLOYEES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                loading: false,
                allEmployees: {},
                employeesLoading: false,
                employees: action.payload,
            }
            return state;


        case actions.GET_ALL_ACCEPTED_EMPLOYEES:
            state = {
                ...state,
                acceptedEmployeeLoading: false,
                acceptedEmployees: { ...action.payload.employees }
            }
            return state;


        case actions.GET_ALL_ACCEPTED_EMPLOYEES_START:
            state = {
                ...state,
                acceptedEmployeeLoading: true,
            }
            return state;


        case actions.GET_ALL_ACCEPTED_EMPLOYEES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                acceptedEmployeeLoading: false,
                acceptedEmployees: {},
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_EMPLOYEES:
            state = {
                ...state,
                downloadLoading: false,
                downloadAllEmployees: { ...action.payload.employees },
                employeesDownloadLoading: false,
                employeesDownload: action.payload,
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_EMPLOYEES_START:
            customInfoToast({ message: "Downloading Data..." });
            state = {
                ...state,
                downloadLoading: true,
                employeesDownloadLoading: true,
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_EMPLOYEES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                downloadLoading: false,
                downloadAllEmployees: {},
                employeesDownloadLoading: false,
                employeesDownload: action.payload,
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_ACCEPTED_EMPLOYEES:
            state = {
                ...state,
                downloadLoading: false,
                downloadAllAcceptedEmployees: { ...action.payload.employees }
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_ACCEPTED_EMPLOYEES_START:
            customInfoToast({ message: "Downloading Data..." });
            state = {
                ...state,
                downloadLoading: true,
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_ACCEPTED_EMPLOYEES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                downloadLoading: false,
                downloadAllAcceptedEmployees: {},
            }
            return state;


        case actions.RESEND_INVITE:
            customSuccessToast({ message: "Invite Sent" });
            state = {
                ...state,
            }
            return state;

        case actions.RESEND_INVITE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
            }
            return state;
            
        case actions.CREATE_EMPLOYEE:
            state = {
                ...state,
                employeeCreateLoading: false,
                employeeCreateSuccess: true,
                employeeCreated: action.payload,
            }
            return state;


        case actions.CREATE_EMPLOYEE_RESET:
            state = {
                ...state,
                employeeCreateSuccess: false,
                employeeCreateStatus: {},
                employeeCreated: {},
            }
            return state;


        case actions.CREATE_EMPLOYEE_START:
            state = {
                ...state,
                employeeCreateLoading: true,
                employeeCreateStatus: {},
            }
            return state;


        case actions.CREATE_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeCreateLoading: false,
            }
            return state;


        case actions.UPLOAD_EMPLOYEE:
            if (action.payload.errorList.length === 0) {
                customSuccessToast({ message: "Employees Uploaded" });
            }
            else {
                customErrorToast({ message: "Unable to upload some employees. Please review and try again" });
            }

            state = {
                ...state,
                employeeUploadLoading: false,
                employeeCreateSuccess: true,
                employeeCreateStatus: action.payload,
            }
            return state;


        case actions.UPLOAD_EMPLOYEE_START:
            state = {
                ...state,
                employeeUploadLoading: true,
                employeeCreateStatus: {},
            }
            return state;


        case actions.UPLOAD_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeUploadLoading: false,
            }
            return state;


        case actions.UPLOAD_EMPLOYEE_QUEUE:
            state = {
                ...state,
                employeeUploadLoading: false,
                employeeCreateSuccess: true,
                employeeCreateStatus: {...action.payload.data},
            }
            return state;


        case actions.UPLOAD_EMPLOYEE_QUEUE_START:
            state = {
                ...state,
                employeeUploadLoading: true,
                employeeCreateStatus: {},
            }
            return state;


        case actions.UPLOAD_EMPLOYEE_QUEUE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeUploadLoading: false,
            }
            return state;
            
        case actions.UPLOAD_EMPLOYEE_PREVIEW:
            state = {
                ...state,
                employeeBulkPreviewLoading: false,
                employeeBulkPreviewSuccess: true,
                // employeeBulkPreview: {...action.payload.data},
                employeeBulkPreview: {...action.payload},
            }
            return state;
            
        case actions.UPLOAD_EMPLOYEE_PREVIEW_SUCCESS_RESET:
            state = {
                ...state,
                employeeBulkPreviewSuccess: false,
            }
            return state;

        case actions.UPLOAD_EMPLOYEE_PREVIEW_START:
            state = {
                ...state,
                employeeBulkPreviewLoading: true,
                employeeBulkPreview: null,
            }
            return state;


        case actions.UPLOAD_EMPLOYEE_PREVIEW_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeBulkPreviewLoading: false,
            }
            return state;


        case actions.UPLOAD_EMPLOYEE_STATUS:
            state = {
                ...state,
                employeeUploadStatusLoading: false,
                employeeUploadStatus: action.payload,
            }
            return state;


        case actions.UPLOAD_EMPLOYEE_STATUS_START:
            state = {
                ...state,
                employeeUploadStatusLoading: true,
                employeeUploadStatus: {},
            }
            return state;


        case actions.UPLOAD_EMPLOYEE_STATUS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeUploadStatusLoading: false,
                employeeUploadStatus: {},
            }
            return state;

        case actions.UPLOAD_EMPLOYEE_STATUS_RESET:
            state = {
                ...state,
                employeeUploadStatusLoading: false,
                employeeUploadStatus: {},
            }
            return state;


        case actions.GET_EMPLOYEE:
            state = {
                ...state,
                employeeLoading: false,
                employee: action.payload,
            }
            return state;


        case actions.GET_EMPLOYEE_START:
            state = {
                ...state,
                employeeLoading: true,
            }
            return state;


        case actions.GET_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeLoading: false,
                employee: {},
            }
            return state;


        case actions.UPDATE_EMPLOYEE:
            state = {
                ...state,
                employeeUpdateLoading: false,
                employeeUpdateSuccess: true,
                employeeEdited: action.payload,
            }
            return state;


        case actions.UPDATE_EMPLOYEE_RESET:
            state = {
                ...state,
                employeeUpdateSuccess: false,
                employeeEdited: {},
            }
            return state;


        case actions.UPDATE_EMPLOYEE_START:
            state = {
                ...state,
                employeeUpdateLoading: true,
            }
            return state;


        case actions.UPDATE_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeUpdateLoading: false,
            }
            return state;


        case actions.STOP_EMPLOYEE_EARNING:
            customSuccessToast({ message: action.payload.message })
            state = {
                ...state,
                employeeStopEarningsLoading: false,
                reloadPage: true,
            }
            return state;


        case actions.STOP_EMPLOYEE_EARNING_START:
            customInfoToast({ message: "Stopping employee earnings" })
            state = {
                ...state,
                employeeStopEarningsLoading: true,
            }
            return state;


        case actions.STOP_EMPLOYEE_EARNING_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeStopEarningsLoading: false,
            }
            return state;


        case actions.RESUME_EMPLOYEE_EARNING:
            customSuccessToast({ message: "Employee earnings resumed" })
            state = {
                ...state,
                employeeResumeEarningsLoading: false,
                reloadPage: true,
            }
            return state;


        case actions.RESUME_EMPLOYEE_EARNING_START:
            customInfoToast({ message: "Resuming employee earnings" })
            state = {
                ...state,
                employeeResumeEarningsLoading: true,
            }
            return state;


        case actions.RESUME_EMPLOYEE_EARNING_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeResumeEarningsLoading: false,
            }
            return state;


        case actions.PAUSE_EMPLOYEE_WITHDRAWAL:
            state = {
                ...state,
                pauseEmployeeWithdrawalLoading: false,
                pauseEmployeeWithdrawal: action.payload,
            }
            return state;


        case actions.PAUSE_EMPLOYEE_WITHDRAWAL_START:
            state = {
                ...state,
                pauseEmployeeWithdrawalLoading: true,
            }
            return state;


        case actions.PAUSE_EMPLOYEE_WITHDRAWAL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                pauseEmployeeWithdrawalLoading: false,
            }
            return state;


        case actions.RESUME_EMPLOYEE_WITHDRAWAL:
            state = {
                ...state,
                resumeEmployeeWithdrawalLoading: false,
                resumeEmployeeWithdrawal: action.payload,
            }
            return state;


        case actions.RESUME_EMPLOYEE_WITHDRAWAL_START:
            state = {
                ...state,
                resumeEmployeeWithdrawalLoading: true,
            }
            return state;


        case actions.RESUME_EMPLOYEE_WITHDRAWAL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                resumeEmployeeWithdrawalLoading: false,
            }
            return state;



        case actions.ENABLE_EMPLOYEE:
            customSuccessToast({ message: "Employee Activated" });
            state = {
                ...state,
                employeeActivateLoading: false,
                reloadPage: true,
            }
            return state;


        case actions.ENABLE_EMPLOYEE_START:
            customInfoToast({ message: "Activating Employee" })
            state = {
                ...state,
                employeeActivateLoading: true,
            }
            return state;


        case actions.ENABLE_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeActivateLoading: false,
            }
            return state;


        case actions.BULK_ENABLE_EMPLOYEE:
            customSuccessToast({ message: action.payload.message })
            state = {
                ...state,
                employeeBulkActivateLoading: false,
                employeeBulkActivateSuccess: true,
            }
            return state;


        case actions.BULK_ENABLE_EMPLOYEE_START:
            customInfoToast({ message: "Activating Employees" });
            state = {
                ...state,
                employeeBulkActivateLoading: true,
                employeeBulkActivateSuccess: false,
            }
            return state;


        case actions.BULK_ENABLE_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeBulkActivateLoading: false,
                employeeBulkActivateSuccess: false,
            }
            return state;


        case actions.DISABLE_EMPLOYEE:
            customSuccessToast({ message: "Employee Deactivated" });
            state = {
                ...state,
                employeeDeactivateLoading: false,
                reloadPage: true,
            }
            return state;


        case actions.DISABLE_EMPLOYEE_START:
            customInfoToast({ message: "Deactivating Employees" })
            state = {
                ...state,
                employeeDeactivateLoading: true,
            }
            return state;


        case actions.DISABLE_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeDeactivateLoading: false,
            }
            return state;


        case actions.BULK_DISABLE_EMPLOYEE:
            customSuccessToast({ message: action.payload.message });
            state = {
                ...state,
                employeeBulkDeactivateLoading: false,
                employeeBulkDeactivateSuccess: true,
            }
            return state;


        case actions.BULK_DISABLE_EMPLOYEE_START:
            customInfoToast({ message: "Deactivating Employee" });
            state = {
                ...state,
                employeeBulkDeactivateLoading: true,
                employeeBulkDeactivateSuccess: false,
            }
            return state;


        case actions.BULK_DISABLE_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeBulkDeactivateLoading: false,
                employeeBulkDeactivateSuccess: false,
            }
            return state;


        case actions.ARCHIVE_EMPLOYEE:
            customSuccessToast({ message: "Employee Deleted" });
            state = {
                ...state,
                employeeArchiveLoading: false,
                reloadPage: true,
            }
            return state;


        case actions.ARCHIVE_EMPLOYEE_START:
            customInfoToast({ message: "Deleting Employees" })
            state = {
                ...state,
                employeeArchiveLoading: true,
            }
            return state;


        case actions.ARCHIVE_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeArchiveLoading: false,
            }
            return state;


        case actions.BULK_ARCHIVE_EMPLOYEE:
            customSuccessToast({ message: action.payload.message });
            state = {
                ...state,
                employeeBulkArchiveLoading: false,
                employeeBulkArchiveSuccess: true,
            }
            return state;


        case actions.BULK_ARCHIVE_EMPLOYEE_START:
            customInfoToast({ message: "Deleting Employee" })
            state = {
                ...state,
                employeeBulkArchiveLoading: true,
                employeeBulkArchiveSuccess: false,
            }
            return state;


        case actions.BULK_ARCHIVE_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeBulkArchiveLoading: false,
                employeeBulkArchiveSuccess: false,
            }
            return state;


        case actions.GENERATE_ERA_EMPLOYEE:
            customSuccessToast({ message: action.payload.message });
            state = {
                ...state,
                eraEmployeeLoading: false,
            }
            return state;


        case actions.GENERATE_ERA_EMPLOYEE_START:
            state = {
                ...state,
                eraEmployeeLoading: true,
            }
            return state;


        case actions.GENERATE_ERA_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                eraEmployeeLoading: false,
            }
            return state;


        case actions.GET_TRANSACTIONS:
            state = {
                ...state,
                transactionsLoading: false,
                transactions: { ...action.payload.data }
            }
            return state;


        case actions.GET_TRANSACTIONS_START:
            state = {
                ...state,
                transactionsLoading: true,
            }
            return state;


        case actions.GET_TRANSACTIONS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                transactionsLoading: false,
                transactions: {}
            }
            return state;


        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false
            }
            return state;


        case actions.GET_EMPLOYEES:
            state = {
                ...state,
                loading: false,
                allEmployees: { ...action.payload },
                employeesLoading: false,
                employees: action.payload,
            }
            return state;


        case actions.GET_EMPLOYEES_START:
            state = {
                ...state,
                loading: true,
                employeesLoading: true,
            }
            return state;


        case actions.GET_EMPLOYEES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                loading: false,
                allEmployees: {},
                employeesLoading: false,
                employees: action.payload,
            }
            return state;


        case actions.GET_EMPLOYEES_METRICS:
            state = {
                ...state,
                employeeMetricsLoading: false,
                employeeMetrics: action.payload,
            }
            return state;


        case actions.GET_EMPLOYEES_METRICS_START:
            state = {
                ...state,
                employeeMetricsLoading: true,
            }
            return state;


        case actions.GET_EMPLOYEES_METRICS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeMetricsLoading: false,
                employeeMetrics: action.payload,
            }
            return state;


        case actions.GET_PREV_MONTH_EMPLOYEES_METRICS_START:
            state = {
                ...state,
                prevMonthEmployeeMetricsLoading: true,
                prevMonthEmployeeMetrics: {},
            }
            return state;

        
        case actions.GET_PREV_MONTH_EMPLOYEES_METRICS_DONE:
            state = {
                ...state,
                prevMonthEmployeeMetricsLoading: false,
                prevMonthEmployeeMetrics: action.payload,
            }
            return state;


        case actions.GET_PREV_MONTH_EMPLOYEES_METRICS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                prevMonthEmployeeMetricsLoading: false,
                prevMonthEmployeeMetrics: {},
            }
            return state;

            
        case actions.GET_ALL_EMPLOYEES_IN_GROUP:
            state = {
                ...state,
                employeesInGroupLoading: false,
                employeesInGroup: action.payload,
            }
            return state;


        case actions.GET_ALL_EMPLOYEES_IN_GROUP_START:
            state = {
                ...state,
                employeesInGroupLoading: true,
            }
            return state;


        case actions.GET_ALL_EMPLOYEES_IN_GROUP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeesInGroupLoading: false,
                employeesInGroup: [],
            }
            return state;
            
        case actions.INVITE_TO_ODP:
            customSuccessToast({ message: action.payload.message });
            state = {
                ...state,
                inviteToOdpLoading: false,
                inviteToOdp: action.payload,
            }
            return state;


        case actions.INVITE_TO_ODP_START:
            state = {
                ...state,
                inviteToOdpLoading: true,
            }
            return state;


        case actions.INVITE_TO_ODP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                inviteToOdpLoading: false,
                inviteToOdp: {},
            }
            return state;
    

        case actions.INVITE_TO_ODP_RESET:
            state = {
                ...state,
                inviteToOdpLoading: false,
                inviteToOdp: {},
            }
            return state;
            
        case actions.GET_EMPLOYEE_ROLES:
            state = {
                ...state,
                employeeRoleLoading: false,
                employeeRoles: action.payload.data,
            }
            return state;
            
        case actions.GET_EMPLOYEE_START_ROLES:
            state = {
                ...state,
                employeeRoleLoading: true,
            }
            return state;
    
    
        case actions.GET_EMPLOYEE_FAILED_ROLES:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeRoleLoading: false,
                employeeRoles: action.payload,
            }
            return state;
            

        default:
            return state;
    }
}