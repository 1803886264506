export const GET_SAVINGS_REQUEST_METRICS = "GET_SAVINGS_REQUEST_METRICS";
export const GET_SAVINGS_REQUEST_METRICS_START = "GET_SAVINGS_REQUEST_METRICS_START";
export const GET_SAVINGS_REQUEST_METRICS_FAILED = "GET_SAVINGS_REQUEST_METRICS_FAILED";

export const GET_SAVINGS_REQUESTS = "GET_SAVINGS_REQUESTS";
export const GET_SAVINGS_REQUESTS_START = "GET_SAVINGS_REQUESTS_START";
export const GET_SAVINGS_REQUESTS_FAILED = "GET_SAVINGS_REQUESTS_FAILED";

export const DOWNLOAD_GET_SAVINGS_REQUESTS = "DOWNLOAD_GET_SAVINGS_REQUESTS";
export const DOWNLOAD_GET_SAVINGS_REQUESTS_START = "DOWNLOAD_GET_SAVINGS_REQUESTS_START";
export const DOWNLOAD_GET_SAVINGS_REQUESTS_FAILED = "DOWNLOAD_GET_SAVINGS_REQUESTS_FAILED";

export const APPROVE_SAVINGS_REQUEST = "APPROVE_SAVINGS_REQUEST";
export const APPROVE_SAVINGS_REQUEST_START = "APPROVE_SAVINGS_REQUEST_START";
export const APPROVE_SAVINGS_REQUEST_FAILED = "APPROVE_SAVINGS_REQUEST_FAILED";

export const REJECT_SAVINGS_REQUEST = "REJECT_SAVINGS_REQUEST";
export const REJECT_SAVINGS_REQUEST_START = "REJECT_SAVINGS_REQUEST_START";
export const REJECT_SAVINGS_REQUEST_FAILED = "REJECT_SAVINGS_REQUEST_FAILED";
export const REJECT_SAVINGS_REQUEST_RESET = "REJECT_SAVINGS_REQUEST_RESET";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";