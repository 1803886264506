import { useDispatch, useSelector } from "react-redux";
import * as auth from "store/auth/user/action";
import * as accountActions from "store/auth/account/action";
import { persistor } from "store/store";


const useLoginInfo = () => {
    const dispatch = useDispatch();
    

    let userInfo = {}
    const { loggedIn: isLogin, data } = useSelector((s) => s.auth.userPersist);
    
    if (data?.stage === 2){
        userInfo = {
            ...data,
        }
        return { isLogin, userInfo };
    } else{
        if (data?.token){
            userInfo = {
                ...data,
                parentToken: data?.mainAccount ? data?.mainAccount?.token : data?.token,
            }
        }
    
    
        const updateIsAccessPinSet = (value) => {
            const params = {
                ...userInfo,
                employer: {
                    ...userInfo?.employer,
                    isAccessPinSet: value,
                }
            }
            dispatch(accountActions.updateLoginProfile(params));
        }
        
    
        const logoutUser = (params = {}) => {
            localStorage.removeItem("user");
            dispatch(auth.logoutUser(params));
        }
        
        const updateUserEarnYieldStatus = (shouldEarnYield) => {
            const userData = {
                ...userInfo,
                employer: {
                    ...userInfo?.employer,
                    shouldEarnYield,
                }
            }
            dispatch(accountActions.updateLoginProfile(JSON.stringify(userData)));
        }
        
        const updateEmployerProductsState = (products) => {
            const userData = {
                ...userInfo,
                employer: {
                    ...userInfo?.employer,
                    products,
                }
            }
            dispatch(accountActions.updateLoginProfile(JSON.stringify(userData)));
        }
        
        const updateAccessPinStatus = () => {
            const userData = {
                ...userInfo,
                member: {
                    ...userInfo?.member,
                    isAccessPinSet: true,
                }
            }
            dispatch(accountActions.updateLoginProfile(JSON.stringify(userData)));
        }
        
    return { isLogin, userInfo, updateIsAccessPinSet, updateUserEarnYieldStatus, updateEmployerProductsState, updateAccessPinStatus, logoutUser };
        
    }
    
    
    
    
}


export default useLoginInfo;
