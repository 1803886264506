import * as actions from "./actionTypes";

const initialState = {
    isSidebarCollapsed: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.UPDATE_SIDEBAR:
            state = {
                ...state,
                isSidebarCollapsed: action.payload,
            }
            return state;


        default:
            return state;
    }
}