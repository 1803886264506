import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";

const initialState = {
    setWithdrawalLimitLoading: false,
    setWithdrawalLimitSuccess: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.SET_WITHDRAWAL_LIMIT:
            state = {
                ...state,
                setWithdrawalLimitLoading: false,
                setWithdrawalLimitSuccess: true,
            }
            return state;


        case actions.SET_WITHDRAWAL_LIMIT_START:
            state = {
                ...state,
                setWithdrawalLimitLoading: true,
                setWithdrawalLimitSuccess: false,
            }
            return state;


        case actions.SET_WITHDRAWAL_LIMIT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                setWithdrawalLimitLoading: false,
                setWithdrawalLimitSuccess: false,
            }
            return state;


        default:
            return state;
    }
}