export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_EMPLOYEE_START = "GET_EMPLOYEE_START";
export const GET_EMPLOYEE_FAILED = "GET_EMPLOYEE_FAILED";

export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const GET_ALL_EMPLOYEES_START = "GET_ALL_EMPLOYEES_START";
export const GET_ALL_EMPLOYEES_FAILED = "GET_ALL_EMPLOYEES_FAILED";

export const GET_ALL_EMPLOYEES_IN_GROUP = "GET_ALL_EMPLOYEES_IN_GROUP";
export const GET_ALL_EMPLOYEES_IN_GROUP_START = "GET_ALL_EMPLOYEES_IN_GROUP_START";
export const GET_ALL_EMPLOYEES_IN_GROUP_FAILED = "GET_ALL_EMPLOYEES_IN_GROUP_FAILED";

export const GET_ALL_ACCEPTED_EMPLOYEES = "GET_ALL_ACCEPTED_EMPLOYEES";
export const GET_ALL_ACCEPTED_EMPLOYEES_START = "GET_ALL_ACCEPTED_EMPLOYEES_START";
export const GET_ALL_ACCEPTED_EMPLOYEES_FAILED = "GET_ALL_ACCEPTED_EMPLOYEES_FAILED";

export const DOWNLOAD_GET_ALL_EMPLOYEES = "DOWNLOAD_GET_ALL_EMPLOYEES";
export const DOWNLOAD_GET_ALL_EMPLOYEES_START = "DOWNLOAD_GET_ALL_EMPLOYEES_START";
export const DOWNLOAD_GET_ALL_EMPLOYEES_FAILED = "DOWNLOAD_GET_ALL_EMPLOYEES_FAILED";

export const DOWNLOAD_GET_ALL_ACCEPTED_EMPLOYEES = "DOWNLOAD_GET_ALL_ACCEPTED_EMPLOYEES";
export const DOWNLOAD_GET_ALL_ACCEPTED_EMPLOYEES_START = "DOWNLOAD_GET_ALL_ACCEPTED_EMPLOYEES_START";
export const DOWNLOAD_GET_ALL_ACCEPTED_EMPLOYEES_FAILED = "DOWNLOAD_GET_ALL_ACCEPTED_EMPLOYEES_FAILED";

export const RESEND_INVITE = "RESEND_INVITE";
export const RESEND_INVITE_START = "RESEND_INVITE_START";
export const RESEND_INVITE_FAILED = "RESEND_INVITE_FAILED";

export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const CREATE_EMPLOYEE_START = "CREATE_EMPLOYEE_START";
export const CREATE_EMPLOYEE_FAILED = "CREATE_EMPLOYEE_FAILED";
export const CREATE_EMPLOYEE_RESET = "CREATE_EMPLOYEE_RESET";

export const UPLOAD_EMPLOYEE = "UPLOAD_EMPLOYEE";
export const UPLOAD_EMPLOYEE_START = "UPLOAD_EMPLOYEE_START";
export const UPLOAD_EMPLOYEE_FAILED = "UPLOAD_EMPLOYEE_FAILED";

export const UPLOAD_EMPLOYEE_PREVIEW = "UPLOAD_EMPLOYEE_PREVIEW";
export const UPLOAD_EMPLOYEE_PREVIEW_START = "UPLOAD_EMPLOYEE_PREVIEW_START";
export const UPLOAD_EMPLOYEE_PREVIEW_FAILED = "UPLOAD_EMPLOYEE_PREVIEW_FAILED";
export const UPLOAD_EMPLOYEE_PREVIEW_SUCCESS_RESET = "UPLOAD_EMPLOYEE_PREVIEW_SUCCESS_RESET";

export const UPLOAD_EMPLOYEE_QUEUE = "UPLOAD_EMPLOYEE_QUEUE";
export const UPLOAD_EMPLOYEE_QUEUE_START = "UPLOAD_EMPLOYEE_QUEUE_START";
export const UPLOAD_EMPLOYEE_QUEUE_FAILED = "UPLOAD_EMPLOYEE_QUEUE_FAILED";

export const UPLOAD_EMPLOYEE_STATUS = "UPLOAD_EMPLOYEE_STATUS";
export const UPLOAD_EMPLOYEE_STATUS_START = "UPLOAD_EMPLOYEE_STATUS_START";
export const UPLOAD_EMPLOYEE_STATUS_FAILED = "UPLOAD_EMPLOYEE_STATUS_FAILED";
export const UPLOAD_EMPLOYEE_STATUS_RESET = "UPLOAD_EMPLOYEE_STATUS_RESET";

export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_START = "UPDATE_EMPLOYEE_START";
export const UPDATE_EMPLOYEE_FAILED = "UPDATE_EMPLOYEE_FAILED";
export const UPDATE_EMPLOYEE_RESET = "UPDATE_EMPLOYEE_RESET";

export const STOP_EMPLOYEE_EARNING = "STOP_EMPLOYEE_EARNING";
export const STOP_EMPLOYEE_EARNING_START = "STOP_EMPLOYEE_EARNING_START";
export const STOP_EMPLOYEE_EARNING_FAILED = "STOP_EMPLOYEE_EARNING_FAILED";

export const RESUME_EMPLOYEE_EARNING = "RESUME_EMPLOYEE_EARNING";
export const RESUME_EMPLOYEE_EARNING_START = "RESUME_EMPLOYEE_EARNING_START";
export const RESUME_EMPLOYEE_EARNING_FAILED = "RESUME_EMPLOYEE_EARNING_FAILED";

export const PAUSE_EMPLOYEE_WITHDRAWAL = "PAUSE_EMPLOYEE_WITHDRAWAL";
export const PAUSE_EMPLOYEE_WITHDRAWAL_START = "PAUSE_EMPLOYEE_WITHDRAWAL_START";
export const PAUSE_EMPLOYEE_WITHDRAWAL_FAILED = "RESUME_EMPLOYEE_EARNING_FAILED";

export const RESUME_EMPLOYEE_WITHDRAWAL = "RESUME_EMPLOYEE_WITHDRAWAL";
export const RESUME_EMPLOYEE_WITHDRAWAL_START = "RESUME_EMPLOYEE_WITHDRAWAL_START";
export const RESUME_EMPLOYEE_WITHDRAWAL_FAILED = "RESUME_EMPLOYEE_WITHDRAWAL_FAILED";

export const ENABLE_EMPLOYEE = "ENABLE_EMPLOYEE";
export const ENABLE_EMPLOYEE_START = "ENABLE_EMPLOYEE_START";
export const ENABLE_EMPLOYEE_FAILED = "ENABLE_EMPLOYEE_FAILED";

export const BULK_ENABLE_EMPLOYEE = "BULK_ENABLE_EMPLOYEE";
export const BULK_ENABLE_EMPLOYEE_START = "BULK_ENABLE_EMPLOYEE_START";
export const BULK_ENABLE_EMPLOYEE_FAILED = "BULK_ENABLE_EMPLOYEE_FAILED";

export const DISABLE_EMPLOYEE = "DISABLE_EMPLOYEE";
export const DISABLE_EMPLOYEE_START = "DISABLE_EMPLOYEE_START";
export const DISABLE_EMPLOYEE_FAILED = "DISABLE_EMPLOYEE_FAILED";

export const BULK_DISABLE_EMPLOYEE = "BULK_DISABLE_EMPLOYEE";
export const BULK_DISABLE_EMPLOYEE_START = "BULK_DISABLE_EMPLOYEE_START";
export const BULK_DISABLE_EMPLOYEE_FAILED = "BULK_DISABLE_EMPLOYEE_FAILED";

export const ARCHIVE_EMPLOYEE = "ARCHIVE_EMPLOYEE";
export const ARCHIVE_EMPLOYEE_START = "ARCHIVE_EMPLOYEE_START";
export const ARCHIVE_EMPLOYEE_FAILED = "ARCHIVE_EMPLOYEE_FAILED";

export const BULK_ARCHIVE_EMPLOYEE = "BULK_ARCHIVE_EMPLOYEE";
export const BULK_ARCHIVE_EMPLOYEE_START = "BULK_ARCHIVE_EMPLOYEE_START";
export const BULK_ARCHIVE_EMPLOYEE_FAILED = "BULK_ARCHIVE_EMPLOYEE_FAILED";

export const GENERATE_ERA_EMPLOYEE = "GENERATE_ERA_EMPLOYEE";
export const GENERATE_ERA_EMPLOYEE_START = "GENERATE_ERA_EMPLOYEE_START";
export const GENERATE_ERA_EMPLOYEE_FAILED = "GENERATE_ERA_EMPLOYEE_FAILED";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_START = "GET_TRANSACTIONS_START";
export const GET_TRANSACTIONS_FAILED = "GET_TRANSACTIONS_FAILED";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";

export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_EMPLOYEES_START = "GET_EMPLOYEES_START";
export const GET_EMPLOYEES_FAILED = "GET_EMPLOYEES_FAILED";

export const GET_EMPLOYEES_METRICS = "GET_EMPLOYEES_METRICS";
export const GET_EMPLOYEES_METRICS_START = "GET_EMPLOYEES_METRICS_START";
export const GET_EMPLOYEES_METRICS_FAILED = "GET_EMPLOYEES_METRICS_FAILED";

export const GET_PREV_MONTH_EMPLOYEES_METRICS_START = "GET_PREV_MONTH_EMPLOYEES_METRICS_START";
export const GET_PREV_MONTH_EMPLOYEES_METRICS_DONE = "GET_PREV_MONTH_EMPLOYEES_METRICS_DONE";
export const GET_PREV_MONTH_EMPLOYEES_METRICS_FAILED = "GET_PREV_MONTH_EMPLOYEES_METRICS_FAILED";

export const INVITE_TO_ODP = "INVITE_TO_ODP";
export const INVITE_TO_ODP_START = "INVITE_TO_ODP_START";
export const INVITE_TO_ODP_FAILED = "INVITE_TO_ODP_FAILED";
export const INVITE_TO_ODP_RESET = "INVITE_TO_ODP_RESET";

export const GET_EMPLOYEE_ROLES = "GET_EMPLOYEE_ROLES";
export const GET_EMPLOYEE_START_ROLES = "GET_EMPLOYEE_START_ROLES";
export const GET_EMPLOYEE_FAILED_ROLES = "GET_EMPLOYEE_FAILED_ROLES";

export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE_START = "DELETE_EMPLOYEE_START";
export const DELETE_EMPLOYEE_FAILED = "DELETE_EMPLOYEE_FAILED";