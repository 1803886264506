export const GET_BUSINESS_DASHBOARD = "GET_BUSINESS_DASHBOARD";
export const GET_BUSINESS_DASHBOARD_START = "GET_BUSINESS_DASHBOARD_START";
export const GET_BUSINESS_DASHBOARD_FAILED = "GET_BUSINESS_DASHBOARD_FAILED";

export const FILTER_BUSINESS_DASHBOARD = "FILTER_BUSINESS_DASHBOARD";
export const FILTER_BUSINESS_DASHBOARD_START = "FILTER_BUSINESS_DASHBOARD_START";
export const FILTER_BUSINESS_DASHBOARD_FAILED = "FILTER_BUSINESS_DASHBOARD_FAILED";

// export const GET_ALL_DASHBOARD = "GET_ALL_DASHBOARD";
// export const GET_ALL_DASHBOARD_START = "GET_ALL_DASHBOARD_START";
// export const GET_ALL_DASHBOARD_FAILED = "GET_ALL_DASHBOARD_FAILED";

export const GET_BULKPAY_METRICS_START = "GET_BULKPAY_METRICS_START";
export const GET_BULKPAY_METRICS_DONE = "GET_BULKPAY_METRICS_DONE";
export const GET_BULKPAY_METRICS_FAILED = "GET_BULKPAY_METRICS_FAILED";

export const GET_BULKPAY_AGGREGATES_START = "GET_BULKPAY_AGGREGATES_START";
export const GET_BULKPAY_AGGREGATES_DONE = "GET_BULKPAY_AGGREGATES_DONE";
export const GET_BULKPAY_AGGREGATES_FAILED = "GET_BULKPAY_AGGREGATES_FAILED";

export const SAVE_ON_DEMAND_ONBOARDING = "SAVE_ON_DEMAND_ONBOARDING";
export const SAVE_ONBOARDING_STATEMENT = "SAVE_ONBOARDING_STATEMENT";

export const SUBMIT_ONBOARDING_FORM_START = "SUBMIT_ONBOARDING_FORM_START";
export const SUBMIT_ONBOARDING_FORM_DONE = "SUBMIT_ONBOARDING_FORM_DONE";
export const SUBMIT_ONBOARDING_FORM_FAILED = "SUBMIT_ONBOARDING_FORM_FAILED";
