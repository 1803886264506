export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";

export const RESET_USER_MAIN_ACCOUNT = "RESET_USER_MAIN_ACCOUNT";

export const RESET_USER_INITIAL_STATE = "RESET_USER_INITIAL_STATE";

export const SAVE_USER_MODULES = "SAVE_USER_MODULES";

export const ADMIN_LOGIN_USER = "ADMIN_LOGIN_USER";

export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const VERIFY_ACCOUNT_START = "VERIFY_ACCOUNT_START";
export const VERIFY_ACCOUNT_FAILED = "VERIFY_ACCOUNT_FAILED";

export const RESEND_VERIFY_EMAIL = "RESEND_VERIFY_EMAIL";
export const RESEND_VERIFY_EMAIL_START = "RESEND_VERIFY_EMAIL_START";
export const RESEND_VERIFY_EMAIL_FAILED = "RESEND_VERIFY_EMAIL_FAILED";
export const RESEND_VERIFY_EMAIL_RESET = "RESEND_VERIFY_EMAIL_RESET";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";

export const SIGNUP_1_USER = "SIGNUP_1_USER";   // first form
export const SIGNUP_2_USER = "SIGNUP_2_USER";   // second form
export const SIGNUP_USER_START = "SIGNUP_USER_START";
export const SIGNUP_USER_FAILED = "SIGNUP_USER_FAILED";

export const GET_REFERRAL_POINTS = "GET_REFERRAL_POINTS";
export const GET_REFERRAL_POINTS_START = "GET_REFERRAL_POINTS_START";
export const GET_REFERRAL_POINTS_FAILED = "GET_REFERRAL_POINTS_FAILED";

export const GET_SUB_ACCOUNTS = "GET_SUB_ACCOUNTS";
export const GET_SUB_ACCOUNTS_START = "GET_SUB_ACCOUNTS_START";
export const GET_SUB_ACCOUNTS_FAILED = "GET_SUB_ACCOUNTS_FAILED";

export const ADD_SUB_ACCOUNTS = "ADD_SUB_ACCOUNTS";
export const ADD_SUB_ACCOUNTS_START = "ADD_SUB_ACCOUNTS_START";
export const ADD_SUB_ACCOUNTS_FAILED = "ADD_SUB_ACCOUNTS_FAILED";

export const SAVE_SUB_ACCOUNT_FORM = "SAVE_SUB_ACCOUNT_FORM";

export const LOGIN_SUB_ACCOUNTS = "LOGIN_SUB_ACCOUNTS";
export const LOGIN_SUB_ACCOUNTS_START = "LOGIN_SUB_ACCOUNTS_START";
export const LOGIN_SUB_ACCOUNTS_FAILED = "LOGIN_SUB_ACCOUNTS_FAILED";

export const LOGIN_MAIN_ACCOUNTS = "LOGIN_MAIN_ACCOUNTS";

export const UPDATE_LOGIN_USER = "UPDATE_LOGIN_USER";
export const UPDATE_LOGIN_PROFILE = "UPDATE_LOGIN_PROFILE";
export const LOGOUT_USER_START = "LOGOUT_USER_START";
export const LOGOUT_USER = "LOGOUT_USER";

export const SAVE_NEW_USER_DETAILS = "SAVE_NEW_USER_DETAILS";
export const CLEAR_SIGNUP_INFO = "CLEAR_SIGNUP_INFO";

export const RESEND_TWO_FA_OTP = "RESEND_TWO_FA_OTP";
export const RESEND_TWO_FA_OTP_START = "RESEND_TWO_FA_OTP_START";
export const RESEND_TWO_FA_OTP_FAILED = "RESEND_TWO_FA_OTP_FAILED";

export const REQUEST_NEW_USER_OTP_START = "REQUEST_NEW_USER_OTP_START";
export const REQUEST_NEW_USER_OTP_DONE = "REQUEST_NEW_USER_OTP_DONE";
export const REQUEST_NEW_USER_OTP_FAILED = "REQUEST_NEW_USER_OTP_FAILED";
export const CLEAR_NEW_USER_OTP_DATA = "CLEAR_NEW_USER_OTP_DATA";

export const VERIFY_OTP_AND_CREATE_EMPLOYER_START = "VERIFY_OTP_AND_CREATE_EMPLOYER_START";
export const VERIFY_OTP_AND_CREATE_EMPLOYER = "VERIFY_OTP_AND_CREATE_EMPLOYER";
export const VERIFY_OTP_AND_CREATE_EMPLOYER_FAILED = "VERIFY_OTP_AND_CREATE_EMPLOYER_FAILED";

export const VERIFY_NEW_USER_OTP_START = "VERIFY_NEW_USER_OTP_START";
export const VERIFY_NEW_USER_OTP_DONE = "VERIFY_NEW_USER_OTP_DONE";
export const VERIFY_NEW_USER_OTP_FAILED = "VERIFY_NEW_USER_OTP_FAILED";

export const CREATE_NEW_USER_START = "CREATE_NEW_USER_START";
export const CREATE_NEW_USER_DONE = "CREATE_NEW_USER_DONE";
export const CREATE_NEW_USER_FAILED = "CREATE_NEW_USER_FAILED";
