export const GET_ALL_INVOICE = "GET_ALL_INVOICE";
export const GET_ALL_INVOICE_START = "GET_ALL_INVOICE_START";
export const GET_ALL_INVOICE_FAILED = "GET_ALL_INVOICE_FAILED";

export const DOWNLOAD_PAYMENT_NOTES = "DOWNLOAD_PAYMENT_NOTES";
export const DOWNLOAD_PAYMENT_NOTES_START = "DOWNLOAD_PAYMENT_NOTES_START";
export const DOWNLOAD_PAYMENT_NOTES_FAILED = "DOWNLOAD_PAYMENT_NOTES_FAILED";

export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_START = "GET_INVOICE_START";
export const GET_INVOICE_FAILED = "GET_INVOICE_FAILED";

export const DOWNLOAD_INVOICE_PDF = "DOWNLOAD_INVOICE_PDF";
export const DOWNLOAD_INVOICE_PDF_START = "DOWNLOAD_INVOICE_PDF_START";
export const DOWNLOAD_INVOICE_PDF_FAILED = "DOWNLOAD_INVOICE_PDF_FAILED";
