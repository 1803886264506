import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customInfoToast } from "utils/customToast";

const initialState = {
    payrollsLoading: false,
    payrolls: {},
    
    payrollAnalyticsLoading: false,
    payrollAnalytics: {},

    payrollsDownloadLoading: false,
    payrollsDownload: {},

    payrollLoading: false,
    payroll: {},

    payrollTaxAndDeductionSummaryLoading: false,
    payrollTaxAndDeductionSummary: {},

    createPayrollLoading: false,
    createPayroll: [],

    payrollTransactionsLoading: false,
    payrollTransactions: [],

    payrollTransactionsDownloadLoading: false,
    payrollTransactionsDownload: [],

    payrollTransactionsGenerateLoading: false,
    payrollTransactionsGenerate: null,

    updatePayrollTransactionLoading: false,
    updatePayrollTransaction: {},

    payrollStatusUpdateLoading: false,
    payrollStatusUpdate: {},

    payrollSalaryPaymentLoading: false,
    payrollSalaryPayment: {},

    payrollRemittancesLoading: false,
    payrollRemittances: [],

    payrollRemittancePaymentLoading: false,
    payrollRemittancePayment: {},
    
    payrollDeleteLoading: false,
    payrollDelete: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_PAYROLLS:
            state = {
                ...state,
                payrollsLoading: false,
                payrolls: action.payload,
            }
            return state;


        case actions.GET_PAYROLLS_START:
            state = {
                ...state,
                payrollsLoading: true,
            }
            return state;


        case actions.GET_PAYROLLS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollsLoading: false,
                payrolls: {},
            }
            return state;

        case actions.GET_PAYROLL_ANALYTICS_START:
            state = {
                ...state,
                payrollAnalyticsLoading: true,
            }
            return state;
            
        case actions.GET_PAYROLL_ANALYTICS:
            const currentYear = new Date().getFullYear();

            const filteredData = action?.payload?.data?.filter(item => {
                const updatedAtYear = new Date(item?.updatedAt).getFullYear();
                return updatedAtYear === currentYear;
            });

            state = {
                ...state,
                payrollAnalyticsLoading: false,
                payrollAnalytics: {
                    data: filteredData,
                },
            }
            return state;


        case actions.GET_PAYROLL_ANALYTICS_FAILED:
            state = {
                ...state,
                payrollAnalyticsLoading: false,
                payrollAnalytics: {},
            }
            return state;


        case actions.DOWNLOAD_GET_PAYROLLS:
            state = {
                ...state,
                payrollsDownloadLoading: false,
                payrollsDownload: action.payload,
            }
            return state;


        case actions.DOWNLOAD_GET_PAYROLLS_START:
            customInfoToast({ message: "Downloading Data..." });
            state = {
                ...state,
                payrollsDownloadLoading: true,
            }
            return state;


        case actions.DOWNLOAD_GET_PAYROLLS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollsDownloadLoading: false,
                payrollsDownload: {},
            }
            return state;


        case actions.CREATE_PAYROLL:
            state = {
                ...state,
                createPayrollLoading: false,
                createPayroll: action.payload,
            }
            return state;


        case actions.CREATE_PAYROLL_START:
            state = {
                ...state,
                createPayrollLoading: true,
                createPayroll: [],
            }
            return state;


        case actions.CREATE_PAYROLL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createPayrollLoading: false,
                createPayroll: [],
            }
            return state;


        case actions.CREATE_PAYROLL_RESET:
            state = {
                ...state,
                createPayrollLoading: false,
                createPayroll: [],
            }
            return state;


        case actions.GET_PAYROLL_DETAILS:
            state = {
                ...state,
                payrollLoading: false,
                payroll: action.payload,
            }
            return state;


        case actions.GET_PAYROLL_DETAILS_START:
            state = {
                ...state,
                payrollLoading: true,
                payroll: {},
            }
            return state;


        case actions.GET_PAYROLL_DETAILS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollLoading: false,
                payroll: {},
            }
            return state;


        case actions.GET_PAYROLL_TAX_AND_DEDUCTION_SUMMARY:
            state = {
                ...state,
                payrollTaxAndDeductionSummaryLoading: false,
                payrollTaxAndDeductionSummary: action.payload,
            }
            return state;


        case actions.GET_PAYROLL_TAX_AND_DEDUCTION_SUMMARY_START:
            state = {
                ...state,
                payrollTaxAndDeductionSummaryLoading: true,
                payrollTaxAndDeductionSummary: {},
            }
            return state;


        case actions.GET_PAYROLL_TAX_AND_DEDUCTION_SUMMARY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollTaxAndDeductionSummaryLoading: false,
                payrollTaxAndDeductionSummary: {},
            }
            return state;

            
        case actions.GET_PAYROLL_TRANSACTIONS:
            state = {
                ...state,
                payrollTransactionsLoading: false,
                payrollTransactions: action.payload,
            }
            return state;


        case actions.GET_PAYROLL_TRANSACTIONS_START:
            state = {
                ...state,
                payrollTransactionsLoading: true,
                payrollTransactions: [],
            }
            return state;


        case actions.GET_PAYROLL_TRANSACTIONS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollTransactionsLoading: false,
                payrollTransactions: [],
            }
            return state;
    

        case actions.DOWNLOAD_PAYROLL_TRANSACTIONS:
            state = {
                ...state,
                payrollTransactionsDownloadLoading: false,
                payrollTransactionsDownload: action.payload,
            }
            return state;


        case actions.DOWNLOAD_PAYROLL_TRANSACTIONS_START:
            state = {
                ...state,
                payrollTransactionsDownloadLoading: true,
                payrollTransactionsDownload: [],
            }
            return state;


        case actions.DOWNLOAD_PAYROLL_TRANSACTIONS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollTransactionsDownloadLoading: false,
                payrollTransactionsDownload: [],
            }
            return state;
    

        case actions.GENERATE_PAYROLL_TRANSACTIONS:
            state = {
                ...state,
                payrollTransactionsGenerateLoading: false,
                payrollTransactionsGenerate: action.payload,
            }
            return state;


        case actions.GENERATE_PAYROLL_TRANSACTIONS_START:
            state = {
                ...state,
                payrollTransactionsGenerateLoading: true,
                payrollTransactionsGenerate: null,
            }
            return state;


        case actions.GENERATE_PAYROLL_TRANSACTIONS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollTransactionsGenerateLoading: false,
                payrollTransactionsGenerate: null,
            }
            return state;
    

        case actions.GENERATE_PAYROLL_TRANSACTIONS_RESET:
            state = {
                ...state,
                payrollTransactionsGenerateLoading: false,
                payrollTransactionsGenerate: null,
            }
            return state;
    

        case actions.UPDATE_PAYROLL_TRANSACTION:
            state = {
                ...state,
                updatePayrollTransactionLoading: false,
                updatePayrollTransaction: action.payload,
            }
            return state;


        case actions.UPDATE_PAYROLL_TRANSACTION_START:
            state = {
                ...state,
                updatePayrollTransactionLoading: true,
                updatePayrollTransaction: {},
            }
            return state;


        case actions.UPDATE_PAYROLL_TRANSACTION_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                updatePayrollTransactionLoading: false,
                updatePayrollTransaction: {},
            }
            return state;
    

        case actions.UPDATE_PAYROLL_TRANSACTION_RESET:
            state = {
                ...state,
                updatePayrollTransactionLoading: false,
                updatePayrollTransaction: {},
            }
            return state;
    

        case actions.UPDATE_PAYROLL_STATUS:
            state = {
                ...state,
                payrollStatusUpdateLoading: false,
                payrollStatusUpdate: action.payload,
            }
            return state;


        case actions.UPDATE_PAYROLL_STATUS_START:
            state = {
                ...state,
                payrollStatusUpdateLoading: true,
                payrollStatusUpdate: {},
            }
            return state;


        case actions.UPDATE_PAYROLL_STATUS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollStatusUpdateLoading: false,
                payrollStatusUpdate: {},
            }
            return state;
    

        case actions.UPDATE_PAYROLL_STATUS_RESET:
            state = {
                ...state,
                payrollStatusUpdateLoading: false,
                payrollStatusUpdate: {},
            }
            return state;
    

        case actions.PAYROLL_PAY_SALARY:
            state = {
                ...state,
                payrollSalaryPaymentLoading: false,
                payrollSalaryPayment: action.payload,
            }
            return state;


        case actions.PAYROLL_PAY_SALARY_START:
            state = {
                ...state,
                payrollSalaryPaymentLoading: true,
                payrollSalaryPayment: {},
            }
            return state;


        case actions.PAYROLL_PAY_SALARY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollSalaryPaymentLoading: false,
                payrollSalaryPayment: {},
            }
            return state;
    

        case actions.PAYROLL_PAY_SALARY_RESET:
            state = {
                ...state,
                payrollSalaryPaymentLoading: false,
                payrollSalaryPayment: {},
            }
            return state;
    

        case actions.PAYROLL_GET_REMITTANCES:
            state = {
                ...state,
                payrollRemittancesLoading: false,
                payrollRemittances: action.payload,
            }
            return state;


        case actions.PAYROLL_GET_REMITTANCES_START:
            state = {
                ...state,
                payrollRemittancesLoading: true,
            }
            return state;


        case actions.PAYROLL_GET_REMITTANCES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollRemittancesLoading: false,
                payrollRemittances: [],
            }
            return state;


        case actions.PAYROLL_PAY_REMITTANCE:
            state = {
                ...state,
                payrollRemittancePaymentLoading: false,
                payrollRemittancePayment: action.payload,
            }
            return state;


        case actions.PAYROLL_PAY_REMITTANCE_START:
            state = {
                ...state,
                payrollRemittancePaymentLoading: true,
                payrollRemittancePayment: {},
            }
            return state;


        case actions.PAYROLL_PAY_REMITTANCE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollRemittancePaymentLoading: false,
                payrollRemittancePayment: {},
            }
            return state;
    

        case actions.PAYROLL_PAY_REMITTANCE_RESET:
            state = {
                ...state,
                payrollRemittancePaymentLoading: false,
                payrollRemittancePayment: {},
            }
            return state;


        case actions.DELETE_PAYROLL_STATUS:
            state = {
                ...state,
                payrollDeleteLoading: false,
                payrollDelete: action.payload,
            }
            return state;
    

        case actions.DELETE_PAYROLL_STATUS_START:
            state = {
                ...state,
                payrollDeleteLoading: true,
                payrollDelete: {},
            }
            return state;


        case actions.DELETE_PAYROLL_STATUS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                payrollDeleteLoading: false,
                payrollDelete: action.payload,
            }
            return state;
    

        case actions.DELETE_PAYROLL_STATUS_RESET:
            state = {
                ...state,
                payrollDeleteLoading: false,
                payrollDelete: {},
            }
            return state;
    

        default:
            return state;
    
    }
}