import useEmployerProducts from "./useEmployerProducts";
import useLoginInfo from "hooks/useLoginInfo";
import store from 'store/store';

const useRouteAccess = () => {
    const {userInfo} = useLoginInfo();
    const {isOnDemandUser, isPayrollUser} = useEmployerProducts();
    const employer = store.getState().auth.account.employerInfo?.data?.employer;
    const employerId = employer?._id || userInfo?.employer?.id;
    const completedPayrollOnboarding = localStorage.getItem(`completedPayrollOnboarding-${employerId}`);
    
    let isOnboardingRequired = !(employer?.adminVerify || userInfo?.employer?.adminVerify);
    let isOnDemandOnboardingRequired = !isOnDemandUser;
    const isPayrollOnboardingRequired = !isPayrollUser && !completedPayrollOnboarding;
    const isPayrollOdpCreditRestricted = userInfo?.company?.companyType !== "registered business";
    
    return {isOnboardingRequired, isOnDemandOnboardingRequired, isPayrollOnboardingRequired, isPayrollOdpCreditRestricted};
}

export default useRouteAccess
