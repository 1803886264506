// pay groups
export const GET_PAY_GROUP_START = "GET_PAY_GROUP_START"
export const GET_PAY_GROUP_DONE = "GET_PAY_GROUP_DONE"
export const GET_PAY_GROUP_FAILED = "GET_PAY_GROUP_FAILED"

export const GET_A_PAY_GROUP_START = "GET_A_PAY_GROUP_START"
export const GET_A_PAY_GROUP_DONE = "GET_A_PAY_GROUP_DONE"
export const GET_A_PAY_GROUP_FAILED = "GET_A_PAY_GROUP_FAILED"

export const REMOVE_EMPLOYEE_FROM_GROUP_START = "REMOVE_EMPLOYEE_FROM_GROUP_START"
export const REMOVE_EMPLOYEE_FROM_GROUP_DONE = "REMOVE_EMPLOYEE_FROM_GROUP_DONE"
export const REMOVE_EMPLOYEE_FROM_GROUP_FAILED = "REMOVE_EMPLOYEE_FROM_GROUP_FAILED"

export const GET_PAY_GROUP_EMPLOYEES_START = "GET_PAY_GROUP_EMPLOYEES_START"
export const GET_PAY_GROUP_EMPLOYEES_DONE = "GET_PAY_GROUP_EMPLOYEES_DONE"
export const GET_PAY_GROUP_EMPLOYEES_FAILED = "GET_PAY_GROUP_EMPLOYEES_FAILED"

export const CREATE_PAY_GROUP_START = "CREATE_PAY_GROUP_START"
export const CREATE_PAY_GROUP_DONE = "CREATE_PAY_GROUP_DONE"
export const CREATE_PAY_GROUP_FAILED = "CREATE_PAY_GROUP_FAILED"

export const SELECT_PAY_GROUP = "SELECT_PAY_GROUP"
export const SAVE_PAY_GROUP_DETAILS = "SAVE_PAY_GROUP_DETAILS"
export const CLEAR_PAY_GROUP_INFO = "CLEAR_PAY_GROUP_INFO"

export const EDIT_PAY_GROUP_START = "EDIT_PAY_GROUP_START"
export const EDIT_PAY_GROUP_DONE = "EDIT_PAY_GROUP_DONE"
export const EDIT_PAY_GROUP_FAILED = "EDIT_PAY_GROUP_FAILED"

export const UPDATE_PAY_GROUP_EMPLOYEES_START = "UPDATE_PAY_GROUP_EMPLOYEES_START"
export const UPDATE_PAY_GROUP_EMPLOYEES_DONE = "UPDATE_PAY_GROUP_EMPLOYEES_DONE"
export const UPDATE_PAY_GROUP_EMPLOYEES_FAILED = "UPDATE_PAY_GROUP_EMPLOYEES_FAILED"

export const DELETE_PAY_GROUP_START = "DELETE_PAY_GROUP_START"
export const DELETE_PAY_GROUP_DONE = "DELETE_PAY_GROUP_DONE"
export const DELETE_PAY_GROUP_FAILED = "DELETE_PAY_GROUP_FAILED"

export const DELETE_MULTIPLE_PAY_GROUPS_START = "DELETE_MULTIPLE_PAY_GROUPS_START"
export const DELETE_MULTIPLE_PAY_GROUPS_DONE = "DELETE_MULTIPLE_PAY_GROUPS_DONE"
export const DELETE_MULTIPLE_PAY_GROUPS_FAILED = "DELETE_MULTIPLE_PAY_GROUPS_FAILED"

export const DELETE_PAY_GROUP_EMPLOYEE_START = "DELETE_PAY_GROUP_EMPLOYEE_START"
export const DELETE_PAY_GROUP_EMPLOYEE_DONE = "DELETE_PAY_GROUP_EMPLOYEE_DONE"
export const DELETE_PAY_GROUP_EMPLOYEE_FAILED = "DELETE_PAY_GROUP_EMPLOYEE_FAILED"

export const TOGGLE_PAY_GROUP_EMPLOYEES_ASSIGNMENT = "TOGGLE_PAY_GROUP_EMPLOYEES_ASSIGNMENT"
export const RESET_PAY_GROUP_EMPLOYEES_ASSIGNMENT = "RESET_PAY_GROUP_EMPLOYEES_ASSIGNMENT"

// pay schedule
export const GET_PAY_SCHEDULE_START = "GET_PAY_SCHEDULE_START"
export const GET_PAY_SCHEDULE_DONE = "GET_PAY_SCHEDULE_DONE"
export const GET_PAY_SCHEDULE_FAILED = "GET_PAY_SCHEDULE_FAILED"

export const SELECT_PAY_SCHEDULE = "SELECT_PAY_SCHEDULE"
export const CREATE_PAY_SCHEDULE_START = "CREATE_PAY_SCHEDULE_START"
export const CREATE_PAY_SCHEDULE_DONE = "CREATE_PAY_SCHEDULE_DONE"
export const CREATE_PAY_SCHEDULE_FAILED = "CREATE_PAY_SCHEDULE_FAILED"

export const EDIT_PAY_SCHEDULE_START = "EDIT_PAY_SCHEDULE_START"
export const EDIT_PAY_SCHEDULE_DONE = "EDIT_PAY_SCHEDULE_DONE"
export const EDIT_PAY_SCHEDULE_FAILED = "EDIT_PAY_SCHEDULE_FAILED"

export const DELETE_PAY_SCHEDULE_START = "DELETE_PAY_SCHEDULE_START"
export const DELETE_PAY_SCHEDULE_DONE = "DELETE_PAY_SCHEDULE_DONE"
export const DELETE_PAY_SCHEDULE_FAILED = "DELETE_PAY_SCHEDULE_FAILED"

export const DELETE_MULTIPLE_SCHEDULES_START = "DELETE_MULTIPLE_SCHEDULES_START"
export const DELETE_MULTIPLE_SCHEDULES_DONE = "DELETE_MULTIPLE_SCHEDULES_DONE"
export const DELETE_MULTIPLE_SCHEDULES_FAILED = "DELETE_MULTIPLE_SCHEDULES_FAILED"

// pay structure
export const GET_PAY_STRUCTURE_START = "GET_PAY_STRUCTURE_START"
export const GET_PAY_STRUCTURE_DONE = "GET_PAY_STRUCTURE_DONE"
export const GET_PAY_STRUCTURE_FAILED = "GET_PAY_STRUCTURE_FAILED"

export const TOGGLE_PAY_STRUCTURE_GROUPS_MODAL = "TOGGLE_PAY_STRUCTURE_GROUPS_MODAL"
export const SELECT_PAY_STRUCTURE_RESPONSE = "SELECT_PAY_STRUCTURE_RESPONSE"

export const GET_STRUCTURE_PAY_GROUPS_START = "GET_STRUCTURE_PAY_GROUPS_START"
export const GET_STRUCTURE_PAY_GROUPS_DONE = "GET_STRUCTURE_PAY_GROUPS_DONE"
export const GET_STRUCTURE_PAY_GROUPS_FAILED = "GET_STRUCTURE_PAY_GROUPS_FAILED"

export const ASSIGN_STRUCTURE_PAY_GROUPS_START = "ASSIGN_STRUCTURE_PAY_GROUPS_START"
export const ASSIGN_STRUCTURE_PAY_GROUPS_DONE = "ASSIGN_STRUCTURE_PAY_GROUPS_DONE"
export const ASSIGN_STRUCTURE_PAY_GROUPS_FAILED = "ASSIGN_STRUCTURE_PAY_GROUPS_FAILED"

export const UNASSIGN_STRUCTURE_PAY_GROUPS_START = "UNASSIGN_STRUCTURE_PAY_GROUPS_START"
export const UNASSIGN_STRUCTURE_PAY_GROUPS_DONE = "UNASSIGN_STRUCTURE_PAY_GROUPS_DONE"
export const UNASSIGN_STRUCTURE_PAY_GROUPS_FAILED = "UNASSIGN_STRUCTURE_PAY_GROUPS_FAILED"

export const UPDATE_PAY_STRUCTURE_START = "UPDATE_PAY_STRUCTURE_START"
export const UPDATE_PAY_STRUCTURE_DONE = "UPDATE_PAY_STRUCTURE_DONE"
export const UPDATE_PAY_STRUCTURE_FAILED = "UPDATE_PAY_STRUCTURE_FAILED"

export const DELETE_PAY_STRUCTURE_START = "DELETE_PAY_STRUCTURE_START"
export const DELETE_PAY_STRUCTURE_DONE = "DELETE_PAY_STRUCTURE_DONE"
export const DELETE_PAY_STRUCTURE_FAILED = "DELETE_PAY_STRUCTURE_FAILED"

export const DELETE_MULTIPLE_STRUCTURES_START = "DELETE_MULTIPLE_STRUCTURES_START"
export const DELETE_MULTIPLE_STRUCTURES_DONE = "DELETE_MULTIPLE_STRUCTURES_DONE"
export const DELETE_MULTIPLE_STRUCTURES_FAILED = "DELETE_MULTIPLE_STRUCTURES_FAILED"

export const CREATE_PAY_STRUCTURE_START = "CREATE_PAY_STRUCTURE_START"
export const CREATE_PAY_STRUCTURE_DONE = "CREATE_PAY_STRUCTURE_DONE"
export const CREATE_PAY_STRUCTURE_FAILED = "CREATE_PAY_STRUCTURE_FAILED"

export const CLEAR_PAY_STRUCTURE_ITEMS = "CLEAR_PAY_STRUCTURE_ITEMS"
export const SAVE_PAY_STRUCTURE_PAYLOAD = "SAVE_PAY_STRUCTURE_PAYLOAD"
export const SELECT_PAY_STRUCTURE = "SELECT_PAY_STRUCTURE"

export const TOGGLE_PFA_SETUP_MODAL = "TOGGLE_PFA_SETUP_MODAL"
export const SAVE_BREAKDOWN_PAYLOAD = "SAVE_BREAKDOWN_PAYLOAD"
export const ADD_CUSTOM_DEDUCTION = "ADD_CUSTOM_DEDUCTION"

export const DELETE_CUSTOM_DEDUCTION = "DELETE_CUSTOM_DEDUCTION"
export const SELECT_CUSTOM_DEDUCTION = "SELECT_CUSTOM_DEDUCTION"
export const SAVE_EDITED_CUSTOM_DEDUCTION = "SAVE_EDITED_CUSTOM_DEDUCTION"
export const TOGGLE_EDIT_DEDUCTION_MODAL = "TOGGLE_EDIT_DEDUCTION_MODAL"

// benefits
export const TOGGLE_BENEFITS_MODAL = "TOGGLE_BENEFITS_MODAL"
export const TOGGLE_BENEFIT_GROUP_ASSIGNMENT = "TOGGLE_BENEFIT_GROUP_ASSIGNMENT"

export const SAVE_BENEFIT_DETAILS = "SAVE_BENEFIT_DETAILS"
export const SELECT_BENEFIT = "SELECT_BENEFIT"
export const TOGGLE_EDIT_BENEFIT_MODAL = "TOGGLE_EDIT_BENEFIT_MODAL"

export const EDIT_BENEFIT_START = "EDIT_BENEFIT_START"
export const EDIT_BENEFIT_DONE = "EDIT_BENEFIT_DONE"
export const EDIT_BENEFIT_FAILED = "EDIT_BENEFIT_FAILED"

export const GET_BENEFITS_START = "GET_BENEFITS_START"
export const GET_BENEFITS_DONE = "GET_BENEFITS_DONE"
export const GET_BENEFITS_FAILED = "GET_BENEFITS_FAILED"

export const GET_BENEFIT_PAY_GROUPS_START = "GET_BENEFIT_PAY_GROUPS_START"
export const GET_BENEFIT_PAY_GROUPS_DONE = "GET_BENEFIT_PAY_GROUPS_DONE"
export const GET_BENEFIT_PAY_GROUPS_FAILED = "GET_BENEFIT_PAY_GROUPS_FAILED"

export const ASSIGN_BENEFIT_PAY_GROUPS_START = "ASSIGN_BENEFIT_PAY_GROUPS_START"
export const ASSIGN_BENEFIT_PAY_GROUPS_DONE = "ASSIGN_BENEFIT_PAY_GROUPS_DONE"
export const ASSIGN_BENEFIT_PAY_GROUPS_FAILED = "ASSIGN_BENEFIT_PAY_GROUPS_FAILED"

export const UNASSIGN_BENEFIT_PAY_GROUPS_START = "UNASSIGN_BENEFIT_PAY_GROUPS_START"
export const UNASSIGN_BENEFIT_PAY_GROUPS_DONE = "UNASSIGN_BENEFIT_PAY_GROUPS_DONE"
export const UNASSIGN_BENEFIT_PAY_GROUPS_FAILED = "UNASSIGN_BENEFIT_PAY_GROUPS_FAILED"

export const CREATE_BENEFITS_START = "CREATE_BENEFITS_START"
export const CREATE_BENEFITS_DONE = "CREATE_BENEFITS_DONE"
export const CREATE_BENEFITS_FAILED = "CREATE_BENEFITS_FAILED"

export const DELETE_BENEFITS_START = "DELETE_BENEFITS_START"
export const DELETE_BENEFITS_DONE = "DELETE_BENEFITS_DONE"
export const DELETE_BENEFITS_FAILED = "DELETE_BENEFITS_FAILED"

export const DELETE_MULTIPLE_BENEFITS_START = "DELETE_MULTIPLE_BENEFITS_START"
export const DELETE_MULTIPLE_BENEFITS_DONE = "DELETE_MULTIPLE_BENEFITS_DONE"
export const DELETE_MULTIPLE_BENEFITS_FAILED = "DELETE_MULTIPLE_BENEFITS_FAILED"

// allowances
export const TOGGLE_ALLOWANCES_MODAL = "TOGGLE_ALLOWANCES_MODAL"
export const SAVE_ALLOWANCE_DETAILS = "SAVE_ALLOWANCE_DETAILS"
export const TOGGLE_ALLOWANCE_GROUP_ASSIGNMENT = "TOGGLE_ALLOWANCE_GROUP_ASSIGNMENT"

export const GET_ALLOWANCE_START = "GET_ALLOWANCE_START"
export const GET_ALLOWANCE_DONE = "GET_ALLOWANCE_DONE"
export const GET_ALLOWANCE_FAILED = "GET_ALLOWANCE_FAILED"

export const GET_ALLOWANCE_PAY_GROUPS_START = "GET_ALLOWANCE_PAY_GROUPS_START"
export const GET_ALLOWANCE_PAY_GROUPS_DONE = "GET_ALLOWANCE_PAY_GROUPS_DONE"
export const GET_ALLOWANCE_PAY_GROUPS_FAILED = "GET_ALLOWANCE_PAY_GROUPS_FAILED"

export const ASSIGN_ALLOWANCE_PAY_GROUPS_START = "ASSIGN_ALLOWANCE_PAY_GROUPS_START"
export const ASSIGN_ALLOWANCE_PAY_GROUPS_DONE = "ASSIGN_ALLOWANCE_PAY_GROUPS_DONE"
export const ASSIGN_ALLOWANCE_PAY_GROUPS_FAILED = "ASSIGN_ALLOWANCE_PAY_GROUPS_FAILED"

export const UNASSIGN_ALLOWANCE_PAY_GROUPS_START = "UNASSIGN_ALLOWANCE_PAY_GROUPS_START"
export const UNASSIGN_ALLOWANCE_PAY_GROUPS_DONE = "UNASSIGN_ALLOWANCE_PAY_GROUPS_DONE"
export const UNASSIGN_ALLOWANCE_PAY_GROUPS_FAILED = "UNASSIGN_ALLOWANCE_PAY_GROUPS_FAILED"

export const CREATE_ALLOWANCE_START = "CREATE_ALLOWANCE_START"
export const CREATE_ALLOWANCE_DONE = "CREATE_ALLOWANCE_DONE"
export const CREATE_ALLOWANCE_FAILED = "CREATE_ALLOWANCE_FAILED"

export const DELETE_ALLOWANCE_START = "DELETE_ALLOWANCE_START"
export const DELETE_ALLOWANCE_DONE = "DELETE_ALLOWANCE_DONE"
export const DELETE_ALLOWANCE_FAILED = "DELETE_ALLOWANCE_FAILED"

export const DELETE_MULTIPLE_ALLOWANCE_START = "DELETE_MULTIPLE_ALLOWANCE_START"
export const DELETE_MULTIPLE_ALLOWANCE_DONE = "DELETE_MULTIPLE_ALLOWANCE_DONE"
export const DELETE_MULTIPLE_ALLOWANCE_FAILED = "DELETE_MULTIPLE_ALLOWANCE_FAILED"

export const SELECT_ALLOWANCE = "SELECT_ALLOWANCE"
export const TOGGLE_EDIT_ALLOWANCE_MODAL = "TOGGLE_EDIT_ALLOWANCE_MODAL"

export const EDIT_ALLOWANCE_START = "EDIT_ALLOWANCE_START"
export const EDIT_ALLOWANCE_DONE = "EDIT_ALLOWANCE_DONE"
export const EDIT_ALLOWANCE_FAILED = "EDIT_ALLOWANCE_FAILED"

// remtitance parties
export const TOGGLE_REMITTANCE_MODAL = "TOGGLE_REMITTANCE_MODAL"
export const SAVE_REMITTANCE_DETAILS = "SAVE_REMITTANCE_DETAILS"
export const SELECT_REMITTANCE = "SELECT_REMITTANCE"

export const EDIT_REMITTANCE_START = "EDIT_REMITTANCE_START"
export const EDIT_REMITTANCE_DONE = "EDIT_REMITTANCE_DONE"
export const EDIT_REMITTANCE_FAILED = "EDIT_REMITTANCE_FAILED"

export const DELETE_REMITTANCE_START = "DELETE_REMITTANCE_START"
export const DELETE_REMITTANCE_DONE = "DELETE_REMITTANCE_DONE"
export const DELETE_REMITTANCE_FAILED = "DELETE_REMITTANCE_FAILED"

export const DELETE_MULTIPLE_REMITTANCE_START = "DELETE_MULTIPLE_REMITTANCE_START"
export const DELETE_MULTIPLE_REMITTANCE_DONE = "DELETE_MULTIPLE_REMITTANCE_DONE"
export const DELETE_MULTIPLE_REMITTANCE_FAILED = "DELETE_MULTIPLE_REMITTANCE_FAILED"

export const CREATE_REMITTANCE_START = "CREATE_REMITTANCE_START"
export const CREATE_REMITTANCE_DONE = "CREATE_REMITTANCE_DONE"
export const CREATE_REMITTANCE_FAILED = "CREATE_REMITTANCE_FAILED"

export const GET_REMITTANCE_START = "GET_REMITTANCE_START"
export const GET_REMITTANCE_DONE = "GET_REMITTANCE_DONE"
export const GET_REMITTANCE_FAILED = "GET_REMITTANCE_FAILED"

export const VERIFY_REMITTANCE_BANK_DETAILS_START = "VERIFY_REMITTANCE_BANK_DETAILS_START"
export const VERIFY_REMITTANCE_BANK_DETAILS_DONE = "VERIFY_REMITTANCE_BANK_DETAILS_DONE"
export const VERIFY_REMITTANCE_BANK_DETAILS_FAILED = "VERIFY_REMITTANCE_BANK_DETAILS_FAILED"

export const TOGGLE_FREE_TRIAL_OFFER_MODAL = "TOGGLE_FREE_TRIAL_OFFER_MODAL"
export const TOGGLE_PAYROLL_FEATURES_MODAL = "TOGGLE_PAYROLL_FEATURES_MODAL"

export const GET_PENSION_PROVIDERS_START = "GET_PENSION_PROVIDERS_START"
export const GET_PENSION_PROVIDERS_DONE = "GET_PENSION_PROVIDERS_DONE"
export const GET_PENSION_PROVIDERS_FAILED = "GET_PENSION_PROVIDERS_FAILED"
