import { decryptPayload } from "utils/encrypt_decrypt";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

const initialState = {
    dashboardLoading: false,
    dashboard: {},
    transactionsCountLoading: false,
    transactionsCount: {},
    transactionsLoading: false,
    transactions: {},
    transactionsDownloadLoading: false,
    transactionsDownload: {},
    walletLoading: false,
    wallet: {},
    beneficiariesLoading: false,
    beneficiaries: {},
    beneficiariesDownloadLoading: false,
    beneficiariesDownload: {},
    deleteBeneficiaryLoading: false,
    deleteBeneficiary: {},
    banksLoading: false,
    banks: {},
    otpGenerationLoading: false,
    otpGeneration: {},
    otpPinResetGenerationLoading: false,
    otpPinResetGeneration: {},
    accessPinLoading: false,
    accessPin: {},
    resetPinLoading: false,
    resetPin: {},
    accountValidationLoading: false,
    accountValidation: {},
    accountValidationError: null,
    beneficiaryCreateLoading: false,
    bulkBeneficiaryOtpLoading: false,
    bulkBeneficiaryOtpCount: 0,
    bulkBeneficiaryCreateLoading: false,
    bulkBeneficiaryCreateData: {},
    beneficiaryCreate: {},
    walletTransferLoading: false,
    walletTransfer: {},

    validateEarnipayAccountLoading: false,
    validateEarnipayAccountData: null,

    initiateEarnipayTransferLoading: false,
    initiateEarnipayTransferData: null,
    yieldSettingsLoading: false,
    yieldSettings: {},
    yieldReportLoading: false,
    yieldReport: {},
    yieldReportDownloadLoading: false,
    yieldReportDownload: {},
    yieldBalanceLoading: false,
    yieldBalance: {},
    accountStatementLoading: false,
    accountStatement: {},
    fundBusinessAccountLoading: false,
    fundBusinessAccount: {},
    paymentLinkToFundAccount: null,

    businessAccountsLoading: false,
    businessAccounts: {},

    additionalWalletLoading: false,
    additionalWallet: {},

    freezeAccountLoading: false,
    freezeAccount: {},

    unFreezeAccountLoading: false,
    unFreezeAccount: {},

    accountDetailsLoading: false,
    accountDetails: {},

    accountTransactionsLoading: false,
    accountTransactions: {},

    closeAccountLoading: false,
    closeAccount: {},

    businessCreditLoading: false,
    businessCredit: {},

    updatedWalletAccountLoading: false,
    updatedWalletAccount: {},

    moveMoneyLoading: false,
    moveMoney: {},

    optInOutYieldLoading: false,
    optInOutYield: {},

    sourceWalletId: null,
    multipleSourceWalletId: null,
    
    walletBalance: 0,
    walletBalanceLoading: false,
};


export default function reducer(state = initialState, action){
    switch (action.type){

        case actions.VALIDATE_EARNIPAY_ACCOUNT_START:
            state = {
                ...state,
                validateEarnipayAccountLoading: true,
                validateEarnipayAccountData: null,
            }
            return state;


        case actions.VALIDATE_EARNIPAY_ACCOUNT_DONE:
            state = {
                ...state,
                validateEarnipayAccountLoading: false,
                validateEarnipayAccountData: action.payload
            }
            return state;


        case actions.VALIDATE_EARNIPAY_ACCOUNT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                validateEarnipayAccountLoading: false,
                validateEarnipayAccountData: null,
            }
            return state;


        case actions.GET_DASHBOARD:
            state = {
                ...state,
                dashboardLoading: false,
                dashboard: JSON.parse(action.payload),
            }
            return state;

        
        case actions.GET_DASHBOARD_START:
            state = {
                ...state,
                dashboardLoading: true,
            }
            return state;


        case actions.GET_DASHBOARD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                dashboardLoading: false,
                dashboard: action.payload,
            }
            return state;


        case actions.GET_DASHBOARD_FAILED_SILENT:
            state = {
                ...state,
                dashboardLoading: false,
                dashboard: {},
            }
            return state;

            
        case actions.GET_TRANSACTIONS_COUNT:
            state = {
                ...state,
                transactionsCountLoading: false,
                transactionsCount: action.payload,
            }
            return state;

           
        case actions.GET_TRANSACTIONS_COUNT_START:
            state = {
                ...state,
                transactionsCountLoading: true,
            }
            return state;


        case actions.GET_TRANSACTIONS_COUNT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                transactionsCountLoading: false,
                transactionsCount: {},
            }
            return state;

            
        case actions.GET_TRANSACTIONS:
            state = {
                ...state,
                transactionsLoading: false,
                transactions: action.payload,
            }
            return state;

           
        case actions.GET_TRANSACTIONS_START:
            state = {
                ...state,
                transactionsLoading: true,
            }
            return state;


        case actions.GET_TRANSACTIONS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                transactionsLoading: false,
                transactions: {},
            }
            return state;


        case actions.GET_TRANSACTIONS_FAILED_SILENT:
            state = {
                ...state,
                transactionsLoading: false,
                transactions: {},
            }
            return state;

            
        case actions.GET_TRANSACTIONS_DOWNLOAD:
            state = {
                ...state,
                transactionsDownloadLoading: false,
                transactionsDownload: action.payload,
            }
            return state;

           
        case actions.GET_TRANSACTIONS_DOWNLOAD_START:
            state = {
                ...state,
                transactionsDownloadLoading: true,
            }
            return state;


        case actions.GET_TRANSACTIONS_DOWNLOAD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                transactionsDownloadLoading: false,
                transactionsDownload: {},
            }
            return state;

            
        case actions.CREATE_WALLET:
            state = {
                ...state,
                walletLoading: false,
                wallet: action.payload,
            }
            return state;

           
        case actions.CREATE_WALLET_START:
            state = {
                ...state,
                walletLoading: true,
            }
            return state;


        case actions.CREATE_WALLET_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                walletLoading: false,
                wallet: action.payload,
            }
            return state;


        case actions.CREATE_WALLET_RESET:
            state = {
                ...state,
                walletLoading: false,
                wallet: {},
            }
            return state;

            
        case actions.GET_BENEFICIARIES:
            state = {
                ...state,
                beneficiariesLoading: false,
                beneficiaries: action.payload,
            }
            return state;

           
        case actions.GET_BENEFICIARIES_START:
            state = {
                ...state,
                beneficiariesLoading: true,
            }
            return state;


        case actions.GET_BENEFICIARIES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                beneficiariesLoading: false,
                beneficiaries: {},
            }
            return state;

            
        case actions.GET_BENEFICIARIES_DOWNLOAD:
            state = {
                ...state,
                beneficiariesDownloadLoading: false,
                beneficiariesDownload: action.payload,
            }
            return state;

           
        case actions.GET_BENEFICIARIES_DOWNLOAD_START:
            state = {
                ...state,
                beneficiariesDownloadLoading: true,
            }
            return state;


        case actions.GET_BENEFICIARIES_DOWNLOAD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                beneficiariesDownloadLoading: false,
                beneficiariesDownload: {},
            }
            return state;

            
        case actions.DELETE_BENEFICIARY:
            state = {
                ...state,
                deleteBeneficiaryLoading: false,
                deleteBeneficiary: action.payload,
            }
            return state;

           
        case actions.DELETE_BENEFICIARY_START:
            state = {
                ...state,
                deleteBeneficiaryLoading: true,
            }
            return state;


        case actions.DELETE_BENEFICIARY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                deleteBeneficiaryLoading: false,
                deleteBeneficiary: action.payload,
            }
            return state;


        case actions.DELETE_BENEFICIARY_RESET:
            state = {
                ...state,
                deleteBeneficiaryLoading: false,
                deleteBeneficiary: {},
            }
            return state;

            
        case actions.GET_BANKS:
            state = {
                ...state,
                banksLoading: false,
                banks: action.payload,
            }
            return state;

           
        case actions.GET_BANKS_START:
            state = {
                ...state,
                banksLoading: true,
            }
            return state;


        case actions.GET_BANKS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                banksLoading: false,
                banks: {},
            }
            return state;

            
        case actions.GENERATE_OTP:
            state = {
                ...state,
                otpGenerationLoading: false,
                otpGeneration: action.payload,
            }
            return state;

           
        case actions.GENERATE_OTP_START:
            state = {
                ...state,
                otpGenerationLoading: true,
            }
            return state;


        case actions.GENERATE_OTP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                otpGenerationLoading: false,
                otpGeneration: {},
            }
            return state;


        case actions.GENERATE_OTP_RESET:
            state = {
                ...state,
                otpGenerationLoading: false,
                otpGeneration: {},
            }
            return state;
            
        case actions.GENERATE_OTP_RESET_PIN:
            state = {
                ...state,
                otpPinResetGenerationLoading: false,
                otpPinResetGeneration: action.payload,
            }
            return state;

           
        case actions.GENERATE_OTP_RESET_PIN_START:
            state = {
                ...state,
                otpPinResetGenerationLoading: true,
            }
            return state;


        case actions.GENERATE_OTP_RESET_PIN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                otpPinResetGenerationLoading: false,
                otpPinResetGeneration: {},
            }
            return state;


        case actions.GENERATE_OTP_RESET_PIN_RESET:
            state = {
                ...state,
                otpPinResetGenerationLoading: false,
                otpPinResetGeneration: {},
            }
            return state;

            
        case actions.CREATE_ACCESS_PIN:
            state = {
                ...state,
                accessPinLoading: false,
                accessPin: action.payload,
            }
            return state;

           
        case actions.CREATE_ACCESS_PIN_START:
            state = {
                ...state,
                accessPinLoading: true,
            }
            return state;


        case actions.CREATE_ACCESS_PIN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                accessPinLoading: false,
                accessPin: {},
            }
            return state;

            
        case actions.CREATE_ACCESS_PIN_RESET:
            state = {
                ...state,
                accessPinLoading: false,
                accessPin: {},
            }
            return state;

            
        case actions.RESET_ACCESS_PIN:
            state = {
                ...state,
                resetPinLoading: false,
                resetPin: action.payload,
            }
            return state;

           
        case actions.RESET_ACCESS_PIN_START:
            state = {
                ...state,
                resetPinLoading: true,
            }
            return state;


        case actions.RESET_ACCESS_PIN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                resetPinLoading: false,
                resetPin: {},
            }
            return state;

            
        case actions.RESET_ACCESS_PIN_RESET:
            state = {
                ...state,
                resetPinLoading: false,
                resetPin: {},
            }
            return state;

            
        case actions.VALIDATE_ACCOUNT:
            state = {
                ...state,
                accountValidationLoading: false,
                accountValidation: action.payload,
            }
            return state;
            
        case actions.VALIDATE_ACCOUNT_RESET:
            state = {
                ...state,
                accountValidation: {},
            }
            return state;
           
        case actions.VALIDATE_ACCOUNT_START:
            state = {
                ...state,
                accountValidationLoading: true,
            }
            return state;


        case actions.VALIDATE_ACCOUNT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                accountValidationLoading: false,
                accountValidation: {},
                accountValidationError: action.payload
            }
            return state;

            
        case actions.CREATE_BENEFICIARY:
            state = {
                ...state,
                beneficiaryCreateLoading: false,
                beneficiaryCreate: action.payload,
            }
            return state;

           
        case actions.CREATE_BENEFICIARY_START:
            state = {
                ...state,
                beneficiaryCreateLoading: true,
            }
            return state;


        case actions.CREATE_BENEFICIARY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                beneficiaryCreateLoading: false,
                beneficiaryCreate: {},
            }
            return state;


        case actions.CREATE_BENEFICIARY_RESET:
            state = {
                ...state,
                otpGenerationLoading: false,
                otpGeneration: {},
                accountValidationLoading: false,
                accountValidation: {},
                beneficiaryCreateLoading: false,
                beneficiaryCreate: {},
            }
            return state;

            
        case actions.CREATE_BULK_BENEFICIARIES_START:
            state = {
                ...state,
                bulkBeneficiaryCreateLoading: true,
            }
            return state;


        case actions.CREATE_BULK_BENEFICIARIES_DONE:
            state = {
                ...state,
                bulkBeneficiaryCreateLoading: false,
                bulkBeneficiaryCreateData: action.payload,
            }
            return state;
            
            
        case actions.CREATE_BULK_BENEFICIARIES_FAILED:
                if(action.payload?.message?.includes("Error: Otp does not match")) {
                    state = {
                        ...state,
                        bulkBeneficiaryOtpCount: state.bulkBeneficiaryOtpCount + 1,
                        bulkBeneficiaryCreateLoading: false,
                    }
                    
                } else {
                    errorReducer(action.payload);
                    state = {
                        ...state,
                        bulkBeneficiaryCreateLoading: false,
                    }
                }
            return state;
            
            
        case actions.RESET_BULK_BENEFICIARY_OTP_COUNT:
            state = {
                ...state,
                bulkBeneficiaryOtpCount: 0,
            }
            return state;

            
        case actions.GENERATE_BULK_BENEFICIARY_OTP_START:
            state = {
                ...state,
                bulkBeneficiaryOtpLoading: true,
            }
            return state;


        case actions.GENERATE_BULK_BENEFICIARY_OTP_DONE:
            state = {
                ...state,
                bulkBeneficiaryOtpLoading: false,
            }
            return state;
            
            
            case actions.GENERATE_BULK_BENEFICIARY_OTP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                bulkBeneficiaryOtpLoading: false,
            }
            return state;


            
        case actions.WALLET_TRANSFER:
            state = {
                ...state,
                walletTransferLoading: false,
                walletTransfer: action.payload,
            }
            return state;

           
        case actions.WALLET_TRANSFER_START:
            state = {
                ...state,
                walletTransferLoading: true,
            }
            return state;


        case actions.WALLET_TRANSFER_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                walletTransferLoading: false,
                walletTransfer: action.payload,
            }
            return state;

            
        case actions.WALLET_TRANSFER_UPLOAD:
            const responseWalletTransferUpload1 = JSON.parse(decryptPayload(action.payload));
            state = {
                ...state,
                walletTransferLoading: false,
                walletTransfer: responseWalletTransferUpload1,
            }
            return state;

            
        case actions.WALLET_TRANSFER_UPLOAD_FAILED:
            const responseWalletTransferUpload2 = JSON.parse(decryptPayload(action.payload));
            errorReducer(responseWalletTransferUpload2);
            state = {
                ...state,
                walletTransferLoading: false,
                walletTransfer: responseWalletTransferUpload2,
            }
            return state;


        case actions.WALLET_TRANSFER_RESET:
            state = {
                ...state,
                walletTransferLoading: false,
                walletTransfer: {},
            }
            return state;

            
        case actions.GET_YIELD_SETTINGS:
            state = {
                ...state,
                yieldSettingsLoading: false,
                yieldSettings: action.payload,
            }
            return state;

           
        case actions.GET_YIELD_SETTINGS_START:
            state = {
                ...state,
                yieldSettingsLoading: true,
            }
            return state;


        case actions.GET_YIELD_SETTINGS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                yieldSettingsLoading: false,
                yieldSettings: {},
            }
            return state;

            
        case actions.GET_YIELD_REPORT:
            state = {
                ...state,
                yieldReportLoading: false,
                yieldReport: action.payload,
            }
            return state;

           
        case actions.GET_YIELD_REPORT_START:
            state = {
                ...state,
                yieldReportLoading: true,
            }
            return state;


        case actions.GET_YIELD_REPORT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                yieldReportLoading: false,
                yieldReport: {},
            }
            return state;

            
        case actions.DOWNLOAD_YIELD_REPORT:
            state = {
                ...state,
                yieldReportDownloadLoading: false,
                yieldReportDownload: action.payload,
            }
            return state;

           
        case actions.DOWNLOAD_YIELD_REPORT_START:
            state = {
                ...state,
                yieldReportDownloadLoading: true,
            }
            return state;


        case actions.DOWNLOAD_YIELD_REPORT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                yieldReportDownloadLoading: false,
                yieldReportDownload: {},
            }
            return state;

            
        case actions.GENERATE_ACCOUNT_STATEMENT:
            state = {
                ...state,
                accountStatementLoading: false,
                accountStatement: {
                    status: "success",
                    message: action.payload?.message || ""
                },
            }
            return state;

           
        case actions.GENERATE_ACCOUNT_STATEMENT_START:
            state = {
                ...state,
                accountStatementLoading: true,
            }
            return state;


        case actions.GENERATE_ACCOUNT_STATEMENT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                accountStatementLoading: false,
                accountStatement: {},
            }
            return state;


        case actions.GENERATE_ACCOUNT_STATEMENT_RESET:
            state = {
                ...state,
                accountStatementLoading: false,
                accountStatement: {},
            }
            return state;

        case actions.FUND_BUSINESS_ACCOUNT:
            state = {
                ...state,
                fundBusinessAccountLoading: false,
                fundBusinessAccount: action.payload,
                paymentLinkToFundAccount: action?.payload?.paymentLink?.data?.paymentLinkUrl,
            }
            return state;

        case actions.FUND_BUSINESS_ACCOUNT_START:
            state = {
                ...state,
                fundBusinessAccountLoading: true,
            }
            return state;

        case actions.FUND_BUSINESS_ACCOUNT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                fundBusinessAccountLoading: false,
                fundBusinessAccount: {},
            }
            return state;

        case actions.VERIFY_BUSINESS_ACCOUNT_FUNDING:
            state = {
                ...state,
                verifyFundBusinessAccountStatusLoading: false,
                verifyFundBusinessAccountStatus: action.payload,
            }
            return state;

        case actions.VERIFY_BUSINESS_ACCOUNT_FUNDING_START:
            state = {
                ...state,
                verifyFundBusinessAccountStatusLoading: true,
            }
            return state;

        case actions.VERIFY_BUSINESS_ACCOUNT_FUNDING_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                verifyFundBusinessAccountStatusLoading: false,
                verifyFundBusinessAccountStatus: {},
            }
            return state;

        case actions.GET_BUSINESS_ACCOUNTS:
            state = {
                ...state,
                businessAccountsLoading: false,
                businessAccounts: action.payload,
            }
            return state;

        case actions.GET_BUSINESS_ACCOUNTS_START:
            state = {
                ...state,
                businessAccountsLoading: true,
            }
            return state;

        case actions.GET_BUSINESS_ACCOUNTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                businessAccountsLoading: false,
                businessAccounts: {},
            }
            return state;

        case actions.CREATE_ADDITIONAL_WALLET:
            state = {
                ...state,
                additionalWalletLoading: false,
                additionalWallet: action.payload,
            }
            return state;

        case actions.CREATE_ADDITIONAL_WALLET_START:
            state = {
                ...state,
                additionalWalletLoading: true,
            }
            return state;

        case actions.CREATE_ADDITIONAL_WALLET_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                additionalWalletLoading: false,
                additionalWallet: {},
            }
            return state;
            
        case actions.CREATE_ADDITIONAL_WALLET_RESET:
            // errorReducer(action.payload);
            state = {
                ...state,
                additionalWalletLoading: false,
                additionalWallet: {},
            }
            return state;

        case actions.FREEZE_ACCOUNT:
            state = {
                ...state,
                freezeAccountLoading: false,
                freezeAccount: action.payload,
            }
            return state;

        case actions.FREEZE_ACCOUNT_START:
            state = {
                ...state,
                freezeAccountLoading: true,
            }
            return state;

        case actions.FREEZE_ACCOUNT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                freezeAccountLoading: false,
                freezeAccount: {},
            }
            return state;
            
        case actions.FREEZE_ACCOUNT_RESET:
            state = {
                ...state,
                freezeAccount: {},
            }
            return state;

        case actions.UNFREEZE_ACCOUNT:
            state = {
                ...state,
                unFreezeAccountLoading: false,
                unFreezeAccount: action.payload,
            }
            return state;

        case actions.UNFREEZE_ACCOUNT_START:
            state = {
                ...state,
                unFreezeAccountLoading: true,
            }
            return state;

        case actions.UNFREEZE_ACCOUNT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                unFreezeAccountLoading: false,
                unFreezeAccount: {},
            }
            return state;

        case actions.UNFREEZE_ACCOUNT_RESET:
            state = {
                ...state,
                unFreezeAccount: {},
            }
            return state;

        case actions.CLOSE_ACCOUNT:
            state = {
                ...state,
                closeAccountLoading: false,
                closeAccount: action.payload,
            }
            return state;

        case actions.CLOSE_ACCOUNT_START:
            state = {
                ...state,
                closeAccountLoading: true,
            }
            return state;

        case actions.CLOSE_ACCOUNT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                closeAccountLoading: false,
                closeAccount: {},
            }
            return state;
            
        case actions.CLOSE_ACCOUNT_RESET:
            state = {
                ...state,
                closeAccount: {},
            }
            return state;

        case actions.GET_ACCOUNT_DETAILS:
            state = {
                ...state,
                accountDetailsLoading: false,
                accountDetails: action.payload,
            }
            return state;

        case actions.GET_ACCOUNT_DETAILS_START:
            state = {
                ...state,
                accountDetailsLoading: true,
            }
            return state;

        case actions.GET_ACCOUNT_DETAILS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                accountDetailsLoading: false,
                accountDetails: {},
            }
            return state;

        case actions.GET_ACCOUNT_TRANSACTIONS:
            state = {
                ...state,
                accountTransactionsLoading: false,
                accountTransactions: action.payload,
            }
            return state;

        case actions.GET_ACCOUNT_TRANSACTIONS_START:
            state = {
                ...state,
                accountTransactionsLoading: true,
            }
            return state;

        case actions.GET_ACCOUNT_TRANSACTIONS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                accountTransactionsLoading: false,
                accountTransactions: {},
            }
            return state;

        case actions.CREATE_BUSINESS_CREDIT:
            state = {
                ...state,
                businessCreditLoading: false,
                businessCredit: action.payload,
            }
            return state;

        case actions.CREATE_BUSINESS_CREDIT_START:
            state = {
                ...state,
                businessCreditLoading: true,
            }
            return state;

        case actions.CREATE_BUSINESS_CREDIT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                businessCreditLoading: false,
                businessCredit: {},
            }
            return state;
            
        case actions.CREATE_BUSINESS_CREDIT_RESET:
            state = {
                ...state,
                businessCredit: {},
            }
            return state;
            
        case actions.GET_BUSINESS_CREDIT:
            state = {
                ...state,
                businessCreditLoading: false,
                businessCredit: action.payload,
            }
            return state;

        case actions.GET_BUSINESS_CREDIT_START:
            state = {
                ...state,
                businessCreditLoading: true,
            }
            return state;

        case actions.GET_BUSINESS_CREDIT_FAILED:
            // errorReducer(action.payload);
            state = {
                ...state,
                businessCreditLoading: false,
                businessCredit: action.payload,
            }
            return state;

        case actions.UPDATE_WALLET_ACCOUNT:
            state = {
                ...state,
                updatedWalletAccountLoading: false,
                updatedWalletAccount: action.payload,
            }
            return state;

        case actions.UPDATE_WALLET_ACCOUNT_START:
            state = {
                ...state,
                updatedWalletAccountLoading: true,
            }
            return state;

        case actions.UPDATE_WALLET_ACCOUNT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                updatedWalletAccountLoading: false,
                updatedWalletAccount: {},
            }
            return state;

        case actions.UPDATE_WALLET_ACCOUNT_RESET:
            state = {
                ...state,
                updatedWalletAccount: {},
            }
            return state;

        case actions.MOVE_MONEY:
            state = {
                ...state,
                moveMoneyLoading: false,
                moveMoney: action.payload,
            }
            return state;

        case actions.MOVE_MONEY_START:
            state = {
                ...state,
                moveMoneyLoading: true,
            }
            return state;

        case actions.MOVE_MONEY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                moveMoneyLoading: false,
                moveMoney: {},
            }
            return state;

        case actions.MOVE_MONEY_RESET:
            state = {
                ...state,
                moveMoney: {},
            }
            return state;
            
        case actions.OPT_IN_OUT_YIELD:
            state = {
                ...state,
                optInOutYieldLoading: false,
                optInOutYield: action.payload,
            }
            return state;

        case actions.OPT_IN_OUT_YIELD_START:
            state = {
                ...state,
                optInOutYieldLoading: true,
            }
            return state;

        case actions.OPT_IN_OUT_YIELD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                optInOutYieldLoading: false,
                optInOutYield: {},
            }
            return state;

        case actions.OPT_IN_OUT_YIELD_RESET:
            state = {
                ...state,
                optInOutYield: {},
            }
            return state;

        case actions.SET_SOURCE_WALLET_ID:
            return {
                ...state,
                sourceWalletId: action.payload,
            };

        case actions.SET_MULTIPLE_SOURCE_WALLET_ID:
            return {
                ...state,
                multipleSourceWalletId: action.payload,
            };

        case actions.GET_YIELD_BALANCE:
            state = {
                ...state,
                yieldBalanceLoading: false,
                yieldBalance: action.payload,
            }
            return state;

        case actions.GET_YIELD_BALANCE_START:
            state = {
                ...state,
                yieldBalanceLoading: true,
            }
            return state;

        case actions.GET_YIELD_BALANCE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                yieldBalanceLoading: false,
                yieldBalance: {},
            }
            return state;
            
        case actions.GET_WALLET_BALANCE:
            state = {
                ...state,
                walletBalanceLoading: false,
                walletBalance: action.payload?.data?.totalWalletBalance || 0,
            }
            return state;

        case actions.GET_WALLET_BALANCE_START:
            state = {
                ...state,
                walletBalanceLoading: true,
            }
            return state;

        case actions.GET_WALLET_BALANCE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                walletBalanceLoading: false,
            }
            return state;

        default:
            return state;
    }
}