import * as actions from "./actionTypes";
// import { toast } from 'react-toastify';

const initialState = {
    companiesLoading: false,
    companies: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.SEARCH_COMPANIES:
            state = {
                ...state,
                companiesLoading: false,
                companies: action.payload,
            }
            return state;


        case actions.SEARCH_COMPANIES_START:
            state = {
                ...state,
                companiesLoading: true,
            }
            return state;


        case actions.SEARCH_COMPANIES_FAILED:
            // errorReducer(action.payload);
            state = {
                ...state,
                companiesLoading: false,
                companies: action.payload,
            }
            return state;


        case actions.SEARCH_COMPANIES_RESET:
            state = {
                ...state,
                companiesLoading: false,
                companies: {},
            }
            return state;


        default:
            return state;
    }
}