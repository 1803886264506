import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customSuccessToast } from "utils/customToast";

const initialState = {
    getAllGroupsLoading: false,
    getAllGroupsSuccess: false,
    allGroups: {},

    getAllGroupEmployeesLoading: false,
    getAllGroupEmployeesSuccess: false,
    allGroupEmployees: [],

    createGroupLoading: false,
    createGroupSuccess: false,
    updateGroupLoading: false,
    updateGroupSuccess: false,
    deleteGroupLoading: false,
    deleteGroupSuccess: false,
    addEmployeesFromGroupLoading: false,
    addEmployeesFromGroupSuccess: false,
    removeEmployeesFromGroupLoading: false,
    removeEmployeesFromGroupSuccess: false,

    getGroupAssignedEmployeesLoading: false,
    getGroupAssignedEmployeesSuccess: false,
    assignedGroupEmployees: null,
    
    assignEmployeeToGroupLoading: false,
    assignEmployeeToGroupSuccess: false,
    assignSelectionToGroupLoading: false,
    assignSelectionToGroupSuccess: [],
    assignSelectionToGroupFailed: [],
    selectedGroup: null,

    groupsDownloadLoading: false,
    groupsDownloadData: {},

    reloadPage: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.SELECT_GROUP:
            state = {
                ...state,
                selectedGroup: action.payload
            }
            return state;

        case actions.DOWNLOAD_GROUPS_FAILED:
            state = {
                ...state,
                groupsDownloadLoading: false,
                groupsDownloadData: {}
            }
            return state;

        case actions.DOWNLOAD_GROUPS_DONE:
            state = {
                ...state,
                groupsDownloadLoading: false,
                groupsDownloadData: action.payload.policies
            }
            return state;

        case actions.DOWNLOAD_GROUPS_START:
            state = {
                ...state,
                groupsDownloadLoading: true,
            }
            return state;

        case actions.GET_ALL_GROUPS:

            state = {
                ...state,
                getAllGroupsLoading: false,
                getAllGroupsSuccess: true,
                allGroups: action.payload,
            }
            return state;


        case actions.GET_ALL_GROUPS_START:
            state = {
                ...state,
                getAllGroupsLoading: true,
                getAllGroupsSuccess: false,
            }
            return state;


        case actions.GET_ALL_GROUPS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                getAllGroupsLoading: false,
                getAllGroupsSuccess: false,
                allGroups: [],
            }
            return state;

        case actions.GET_GROUPS_B2B:

            state = {
                ...state,
                getAllGroupsLoading: false,
                getAllGroupsSuccess: true,
                allGroups: action.payload,
            }
            return state;


        case actions.GET_GROUPS_B2B_START:
            state = {
                ...state,
                getAllGroupsLoading: true,
                getAllGroupsSuccess: false,
            }
            return state;


        case actions.GET_GROUPS_B2B_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                getAllGroupsLoading: false,
                getAllGroupsSuccess: false,
                allGroups: [],
            }
            return state;


        case actions.GET_ALL_GROUP_EMPLOYEES:
            state = {
                ...state,
                getAllGroupEmployeesLoading: false,
                getAllGroupEmployeesSuccess: true,
                allGroupEmployees: {
                    employees: [...action.payload.employees],
                    policy: action.payload.policy,
                },
            }
            return state;


        case actions.GET_ALL_GROUP_EMPLOYEES_START:
            state = {
                ...state,
                getAllGroupEmployeesLoading: true,
                getAllGroupEmployeesSuccess: false,
            }
            return state;


        case actions.GET_ALL_GROUP_EMPLOYEES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                getAllGroupEmployeesLoading: false,
                getAllGroupEmployeesSuccess: false,
                allGroupEmployees: [],
            }
            return state;


        case actions.CREATE_GROUP:
            customSuccessToast({ message: "Group Created Successfully" });
            state = {
                ...state,
                createGroupLoading: false,
                createGroupSuccess: true,
            }
            return state;


        case actions.CREATE_GROUP_START:
            state = {
                ...state,
                createGroupLoading: true,
                createGroupSuccess: false,
            }
            return state;


        case actions.CREATE_GROUP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createGroupLoading: false,
                createGroupSuccess: false,
            }
            return state;

        case actions.CREATE_GROUP_B2B:
            state = {
                ...state,
                createGroupLoading: false,
                createGroupSuccess: true,
            }
            return state;


        case actions.CREATE_GROUP_B2B_START:
            state = {
                ...state,
                createGroupLoading: true,
                createGroupSuccess: false,
            }
            return state;


        case actions.CREATE_GROUP_B2B_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createGroupLoading: false,
                createGroupSuccess: false,
            }
            return state;

            
        case actions.GET_GROUP_ASSIGNED_EMPLOYEES:
            state = {
                ...state,
                getGroupAssignedEmployeesLoading: false,
                getGroupAssignedEmployeesSuccess: true,
                assignedGroupEmployees: action.payload,
            }
            return state;


        case actions.GET_GROUP_ASSIGNED_EMPLOYEES_START:
            state = {
                ...state,
                getGroupAssignedEmployeesLoading: true,
                getGroupAssignedEmployeesSuccess: false,
            }
            return state;


        case actions.GET_GROUP_ASSIGNED_EMPLOYEES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                getGroupAssignedEmployeesLoading: false,
                getGroupAssignedEmployeesSuccess: false,
                assignedGroupEmployees: null,
            }
            return state;


        case actions.ASSIGN_EMPLOYEE_TO_GROUP:
            customSuccessToast({ message: "Assigned to Group" });
            state = {
                ...state,
                assignEmployeeToGroupLoading: false,
                assignEmployeeToGroupSuccess: true,
            }
            return state;


        case actions.ASSIGN_EMPLOYEE_TO_GROUP_START:
            state = {
                ...state,
                assignEmployeeToGroupLoading: true,
                assignEmployeeToGroupSuccess: false,
            }
            return state;


        case actions.ASSIGN_EMPLOYEE_TO_GROUP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                assignEmployeeToGroupLoading: false,
                assignEmployeeToGroupSuccess: false,
            }
            return state;


        case actions.ASSIGN_SELECTION_TO_GROUP_START:
            // alert("start here");
            state = {
                ...state,
                assignSelectionToGroupLoading: true,
                assignSelectionToGroupSuccess: [],
                assignSelectionToGroupFailed: [],
            }
            return state;


        case actions.ASSIGN_SELECTION_TO_GROUP_SUCCESS:
            // alert("success");
            state = {
                ...state,
                assignSelectionToGroupSuccess: [
                    ...state.assignSelectionToGroupSuccess,
                    Date.now(),
                ],
            }
            return state;


        case actions.ASSIGN_SELECTION_TO_GROUP_FAILED:
            // alert("fail");
            state = {
                ...state,
                assignSelectionToGroupFailed: [
                    ...state.assignSelectionToGroupFailed,
                    Date.now(),
                ],
            }
            return state;


        case actions.ASSIGN_SELECTION_TO_GROUP_RESET:
            // alert("reset here");
            state = {
                ...state,
                assignSelectionToGroupLoading: false,
            }
            return state;


        case actions.UPDATE_GROUP:
            customSuccessToast({ message: action.payload.message });
            state = {
                ...state,
                updateGroupLoading: false,
                updateGroupSuccess: true,
            }
            return state;


        case actions.UPDATE_GROUP_START:
            state = {
                ...state,
                updateGroupLoading: true,
                updateGroupSuccess: false,
            }
            return state;


        case actions.UPDATE_GROUP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                updateGroupLoading: false,
                updateGroupSuccess: false,
            }
            return state;

        case actions.UPDATE_GROUP_B2B:
            state = {
                ...state,
                updateGroupLoading: false,
                updateGroupSuccess: true,
            }
            return state;


        case actions.UPDATE_GROUP_B2B_START:
            state = {
                ...state,
                updateGroupLoading: true,
                updateGroupSuccess: false,
            }
            return state;


        case actions.UPDATE_GROUP_B2B_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                updateGroupLoading: false,
                updateGroupSuccess: false,
            }
            return state;

        case actions.ADD_EMPLOYEES_FROM_GROUP_B2B:
            state = {
                ...state,
                addEmployeesFromGroupLoading: false,
                addEmployeesFromGroupSuccess: true,
            }
            return state;


        case actions.ADD_EMPLOYEES_FROM_GROUP_B2B_START:
            state = {
                ...state,
                addEmployeesFromGroupLoading: true,
                addEmployeesFromGroupSuccess: false,
            }
            return state;


        case actions.ADD_EMPLOYEES_FROM_GROUP_B2B_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                addEmployeesFromGroupLoading: false,
                addEmployeesFromGroupSuccess: false,
            }
            return state;

        case actions.REMOVE_EMPLOYEES_FROM_GROUP_B2B:
            state = {
                ...state,
                removeEmployeesFromGroupLoading: false,
                removeEmployeesFromGroupSuccess: true,
            }
            return state;


        case actions.REMOVE_EMPLOYEES_FROM_GROUP_B2B_START:
            state = {
                ...state,
                removeEmployeesFromGroupLoading: true,
                removeEmployeesFromGroupSuccess: false,
            }
            return state;


        case actions.REMOVE_EMPLOYEES_FROM_GROUP_B2B_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                removeEmployeesFromGroupLoading: false,
                removeEmployeesFromGroupSuccess: false,
            }
            return state;


        case actions.DELETE_GROUP:
            customSuccessToast({ message: "Group Deleted Successfully" });
            state = {
                ...state,
                deleteGroupLoading: false,
                deleteGroupSuccess: true,
            }
            return state;


        case actions.DELETE_GROUP_START:
            state = {
                ...state,
                deleteGroupLoading: true,
                deleteGroupSuccess: false,
            }
            return state;


        case actions.DELETE_GROUP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                deleteGroupLoading: false,
                deleteGroupSuccess: false,
            }
            return state;


        /*
        case actions.UPDATE_LEGAL_INFO:
            state = {
                ...state,
                updateLegalInfoLoading: false,
                reloadPage: true
            }
            return state;


        case actions.UPDATE_LEGAL_INFO_SILENT:
            state = {
                ...state,
                updateLegalInfoLoading: false,
                reloadPage: true
            }
            return state;


        case actions.UPDATE_LEGAL_INFO_START:
            state = {
                ...state,
                updateLegalInfoLoading: true,
            }
            return state;


        case actions.UPDATE_LEGAL_INFO_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                updateLegalInfoLoading: false,
            }
            return state;


        case actions.UPDATE_POLICY_INFO:
            state = {
                ...state,
                updatePolicyInfoLoading: false,
                reloadPage: true
            }
            return state;


        case actions.UPDATE_POLICY_INFO_SILENT:
            state = {
                ...state,
                updatePolicyInfoLoading: false,
                reloadPage: true
            }
            return state;


        case actions.UPDATE_POLICY_INFO_START:
            state = {
                ...state,
                updatePolicyInfoLoading: true,
            }
            return state;


        case actions.UPDATE_POLICY_INFO_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                updatePolicyInfoLoading: false,
            }
            return state;
        */


        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false
            }
            return state;


        default:
            return state;
    }
}