import React, { useEffect, useState, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { Slide as SlideComponent } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {ReactComponent as Logo} from "assets/images/icons/logo-color@svg.svg";
// import logo from "assets/images/icons/logo-color@svg.svg";
// import {ReactComponent as Banner}  from "assets/images/business-pro-wide.svg";
import banner from "assets/images/business-pro-wide-min.jpg";
import bannerWider from "assets/images/business-woman-min.jpg";
import sliderEfficiency from "assets/images/bgs/slider-efficiency.png";
import sliderFast from "assets/images/bgs/slider-fast.png";
import sliderOnDemandPay from "assets/images/bgs/slider-on-demand-pay.png";
import sliderCredit from "assets/images/bgs/slider-on-demand-pay.png";
import credit from "assets/images/icons/credit-on.png";
import payroll from "assets/images/icons/payroll-on.png";
import payment from "assets/images/icons/payments-on.png";
import odp from "assets/images/icons/odp-on.png";
import yieldOn from "assets/images/icons/yield-on.png";
import './authLayout.css';
import { useMediaQuery } from 'hooks/useMediaQuery';
// import LoaderFallback from "components/__new/common/LoaderFallback";


const AuthLayout = (props) => {
    const location = useLocation();
    const [maxWidth, setMaxWidth] = useState("max-w-md");
    const is4KLargerScreen = useMediaQuery('(min-width: 4441px)');
    
    const handleNavigateToWebsite = () => {
        window.open("https://earnipay.com", "_blank");
    }
    
    useEffect(() => {
        if(location?.pathname?.includes("/verify-email") || location?.pathname?.includes("/get-started/select-modules")) {
            setMaxWidth("max-w-md");
        } else {
            setMaxWidth("max-w-md");
        }
    }, [location])
    
    const valueProps = [
        {title: "Credit", icon: credit},
        {title: "Payments", icon: payment},
        {title: "Payroll", icon: payroll},
        {title: "High-Yield Accounts", icon: yieldOn},
        {title: "On-Demand Pay", icon: odp},
    ]
    
    return ( 
        <div className="overall-layout w-full min-h-screen md:flex md:justify-between relative bg-[#F9FAFB]">
            {/* wider */}
            {is4KLargerScreen ? <div className="responsive-ratio relative w-full md:w-1/2 h-screen overflow-hidden ">
                <div className="banner-slider overflow-hidden hidden lg:flex justify-end">
                    <img src={banner} alt="banner" style={{objectFit: "cover"}} className='w-full text-right h-full' />
                    <div style={{maxHeight: '100%', background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 42%, #000000 100%)'}} 
                    className="absolute left-0 w-full h-full flex items-center justify-center" />
                    <div style={{
                        boxShadow: '0px 4px 4px 0px #00000040',
                        border: '1px solid #FFFFFF1A',
                        background: '#FFFFFF1A',
                        maxWidth: 'calc(100% - 48px)',
                        left: '24px'
                        }} 
                        className=" absolute overflow-hidden rounded-xl bottom-[27px] w-full">
                                <div className="px-4 py-6">
                                    <div className="flex items-center gap-x-2 mb-[24px]">
                                        <h1 style={{
                                        fontSize: '24px',
                                        fontWeight: 500,
                                        lineHeight: '28.8px',
                                        }} className='text-white text-left capitalize whitespace-normal'>Power your business growth</h1>
                                        <div style={{border: '1px solid rgba(255, 255, 255, 0.3)'}} className="w-[310px]" />
                                    </div>
                                    <div className="flex flex-wrap gap-[12px]">
                                        {
                                            valueProps.map((item, ) => (
                                                <OnboardingPill key={item.title} title={item.title} icon={item.icon} />
                                            ))
                                        }
                                    </div>
                                </div>
                    </div>
                </div>
            </div>
            :
                <div className="banner-slider  w-full md:w-1/2 h-screen relative overflow-hidden hidden lg:flex justify-end">
            <img src={bannerWider} alt="banner" style={{objectFit: "cover"}} className='w-full text-right h-full' />
            <div style={{maxHeight: '100%', background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 42%, #000000 100%)'}} 
            className="absolute left-0 w-full h-full flex items-center justify-center" />
            <div style={{
                boxShadow: '0px 4px 4px 0px #00000040',
                border: '1px solid #FFFFFF1A',
                background: '#FFFFFF1A',
                maxWidth: 'calc(100% - 48px)',
                left: '24px'
                }} 
                className=" absolute overflow-hidden rounded-xl bottom-[27px] w-full">
                        <div className="px-4 py-6">
                            <div className="flex items-center gap-x-2 mb-[24px]">
                                <h1 style={{
                                fontSize: '24px',
                                fontWeight: 500,
                                lineHeight: '28.8px',
                                }} className='text-white text-left capitalize whitespace-normal'>Power your business growth</h1>
                                <div style={{border: '1px solid rgba(255, 255, 255, 0.3)'}} className="w-[310px]" />
                            </div>
                            <div className="flex flex-wrap gap-[12px]">
                                {
                                    valueProps.map((item, ) => (
                                        <OnboardingPill key={item.title} title={item.title} icon={item.icon} />
                                    ))
                                }
                            </div>
                        </div>
            </div>
                </div>
            }
            {/* wide */}
            {/* <div className="banner-slider responsive-ratio aspect-w-16 aspect-h-9  w-full md:w-1/2 h-screen relative overflow-hidden hidden lg:flex justify-end">
                <img src={banner} alt="banner" style={{objectFit: "cover"}} className='w-full text-right h-full' />
                <div style={{maxHeight: '100%', background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 42%, #000000 100%)'}} 
                className="absolute left-0 w-full h-full flex items-center justify-center" />
                <div style={{
                    boxShadow: '0px 4px 4px 0px #00000040',
                    border: '1px solid #FFFFFF1A',
                    background: '#FFFFFF1A',
                    maxWidth: 'calc(100% - 48px)',
                    left: '24px'
                    }} 
                    className=" absolute overflow-hidden rounded-xl bottom-[27px] w-full">
                            <div className="px-4 py-6">
                                <div className="flex items-center gap-x-2 mb-[24px]">
                                    <h1 style={{
                                    fontSize: '24px',
                                    fontWeight: 500,
                                    lineHeight: '28.8px',
                                    }} className='text-white text-left capitalize whitespace-normal'>Power your business growth</h1>
                                    <div style={{border: '1px solid rgba(255, 255, 255, 0.3)'}} className="w-[310px]" />
                                </div>
                                <div className="flex flex-wrap gap-[12px]">
                                    {
                                        valueProps.map((item, ) => (
                                            <OnboardingPill key={item.title} title={item.title} icon={item.icon} />
                                        ))
                                    }
                                </div>
                            </div>
                </div>
            </div> */}
            {/* 4- 3 */}
            {/* <div className="banner-slider responsive-ratio aspect-w-4 aspect-h-3 w-full md:w-1/2 h-screen relative overflow-hidden hidden lg:flex justify-end">
                <img src={banner} alt="banner" style={{objectFit: "cover"}} className='w-full text-right h-full' />
                <div style={{maxHeight: '100%', background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 42%, #000000 100%)'}} 
                className="absolute left-0 w-full h-full flex items-center justify-center" />
                <div style={{
                    boxShadow: '0px 4px 4px 0px #00000040',
                    border: '1px solid #FFFFFF1A',
                    background: '#FFFFFF1A',
                    maxWidth: 'calc(100% - 48px)',
                    left: '24px'
                    }} 
                    className=" absolute overflow-hidden rounded-xl bottom-[27px] w-full">
                            <div className="px-4 py-6">
                                <div className="flex items-center gap-x-2 mb-[24px]">
                                    <h1 style={{
                                    fontSize: '24px',
                                    fontWeight: 500,
                                    lineHeight: '28.8px',
                                    }} className='text-white text-left capitalize whitespace-normal'>Power your business growth</h1>
                                    <div style={{border: '1px solid rgba(255, 255, 255, 0.3)'}} className="w-[310px]" />
                                </div>
                                <div className="flex flex-wrap gap-[12px]">
                                    {
                                        valueProps.map((item, ) => (
                                            <OnboardingPill key={item.title} title={item.title} icon={item.icon} />
                                        ))
                                    }
                                </div>
                            </div>
                </div>
            </div> */}
            {/* square */}
            {/* <div className="banner-slider responsive-ratio aspect-w-1 aspect-h-1 w-full md:w-1/2 h-screen relative overflow-hidden hidden lg:flex justify-end">
                <img src={banner} alt="banner" style={{objectFit: "cover"}} className='w-full text-right h-full' />
                <div style={{maxHeight: '100%', background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 42%, #000000 100%)'}} 
                className="absolute left-0 w-full h-full flex items-center justify-center" />
                <div style={{
                    boxShadow: '0px 4px 4px 0px #00000040',
                    border: '1px solid #FFFFFF1A',
                    background: '#FFFFFF1A',
                    maxWidth: 'calc(100% - 48px)',
                    left: '24px'
                    }} 
                    className=" absolute overflow-hidden rounded-xl bottom-[27px] w-full">
                            <div className="px-4 py-6">
                                <div className="flex items-center gap-x-2 mb-[24px]">
                                    <h1 style={{
                                    fontSize: '24px',
                                    fontWeight: 500,
                                    lineHeight: '28.8px',
                                    }} className='text-white text-left capitalize whitespace-normal'>Power your business growth</h1>
                                    <div style={{border: '1px solid rgba(255, 255, 255, 0.3)'}} className="w-[310px]" />
                                </div>
                                <div className="flex flex-wrap gap-[12px]">
                                    {
                                        valueProps.map((item, ) => (
                                            <OnboardingPill key={item.title} title={item.title} icon={item.icon} />
                                        ))
                                    }
                                </div>
                            </div>
                </div>
            </div> */}
            {/* Previous */}
            {/* <div className="banner-slider x4k:small-responsive-ratio w-full md:w-1/2 h-screen relative overflow-hidden hidden lg:flex justify-end">
                <img src={banner} alt="banner" style={{objectFit: "cover"}} className='w-full text-right h-full' />
                <div style={{maxHeight: '100%', background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 42%, #000000 100%)'}} 
                className="absolute left-0 w-full h-full flex items-center justify-center" />
                <div style={{
                    boxShadow: '0px 4px 4px 0px #00000040',
                    border: '1px solid #FFFFFF1A',
                    background: '#FFFFFF1A',
                    maxWidth: 'calc(100% - 48px)',
                    left: '24px'
                    }} 
                    className=" absolute overflow-hidden rounded-xl bottom-[27px] w-full">
                            <div className="px-4 py-6">
                                <div className="flex items-center gap-x-2 mb-[24px]">
                                    <h1 style={{
                                    fontSize: '24px',
                                    fontWeight: 500,
                                    lineHeight: '28.8px',
                                    }} className='text-white text-left capitalize whitespace-normal'>Power your business growth</h1>
                                    <div style={{border: '1px solid rgba(255, 255, 255, 0.3)'}} className="w-[310px]" />
                                </div>
                                <div className="flex flex-wrap gap-[12px]">
                                    {
                                        valueProps.map((item, ) => (
                                            <OnboardingPill key={item.title} title={item.title} icon={item.icon} />
                                        ))
                                    }
                                </div>
                            </div>
                </div>
            </div> */}
            <div className="main-box w-full md:w-1/2 min-h-full px-8 py-8 flex justify-center items-center">
                <div className={`w-full ${maxWidth} pb-0 z-[1] bg-white border rounded-xl overflow-hidden`}>
                    <Logo alt="logo" className="h-8 object-contain absolute top-[24px] right-[32px] sm:right-[53px] cursor-pointer" onClick={handleNavigateToWebsite} />
                    {/* <img src={logo} alt="logo" className="h-8 object-contain absolute top-[24px] right-[32px] sm:right-[53px] cursor-pointer" onClick={handleNavigateToWebsite} /> */}
                    {/* <div className="w-32 h-8 mx-auto text-center overflow-hidden">
                        <img src={logo} alt="logo" className="w-full h-full object-contain" />
                    </div> */}
                        {/* <Suspense fallback={<LoaderFallback />}> */}
                        <Suspense fallback={<></>}>
                            <div>
                                {props.children}
                            </div>
                        </Suspense>
                </div>
            </div>
        </div>
    );
}

const OnboardingPill = ({title, icon}) => {
    
    return (
        <div
            style={{
                height: '48px',
                // padding: '12px 16px 12px 16px',
                borderRadius: '36.24px',
                opacity: '0px',
                background: 'rgba(255, 255, 255, 0.1)',
                border: '1.25px solid #FFFFFF1A',
                fontWeight: '500',
            }}
            className='gap-[12px] px-[12px] py-[14.5px] flex items-center justify-center'
        >
            <h2 className="text-[16px] leading-[19.2px] tracking-[-1%]" style={{color: 'rgba(255, 255, 255, 0.7)'}}>{title}</h2>
            {/* {icon} */}
            <img src={icon} alt="icon" />
        </div>
    )
}


const Slider = () => {
    const slideshowProperties = {
        duration: 3000,
        transitionDuration: 300,
        pauseOnHover: true,
        indicators: true,
        autoplay: true,
        infinite: true,
        canSwipe: true,
        prevArrow: <div></div>,
        nextArrow: <div></div>,
    }
    
    return ( 
        <div className="auth-slideshow w-full h-full flex items-center justify-center relative z-[2]">
            {/* <div className="w-full max-w-md m-auto pb-14 md:pb-0"> */}
            <div className="w-full">
                <SlideComponent {...slideshowProperties} easing="ease">
                    <Slide 
                        image={sliderEfficiency}
                        title="Build Your Payroll on Auto"
                        description="Manage and settle your growing team easily and on time."
                    />
                    <Slide 
                        image={sliderFast}
                        title="Fast and Precise Payments, Every Time"
                        description="Send payments at lightning speed, whether to one person or multiple people, all at once."
                    />
                    <Slide 
                        image={sliderOnDemandPay}
                        title="Unlock Growth Without the Price Tag"
                        description="Empower your employees with On-demand Pay, giving them control over how they access their earned money as they earn it, at zero cost."
                    />
                    <Slide 
                        image={sliderCredit}
                        title="Loans to Keep Your Business Moving Forward"
                        description="Move at the pace of your business, not the limit of your cash."
                    />
                </SlideComponent>
            </div>
        </div>
    )
}


const Slide = (props) => {
    const {image, title, description} = props;
    return ( 
        <div className="slideshow-each">
            <div className="max-w-sm md:max-w-none mx-auto">
                <div className="w-full h-48 md:h-72 xl:h-96 flex justify-center items-center">
                    <img src={image} alt={title} className="object-contain h-full" />
                </div>
                <div className="mt-10 text-center">
                    <div className="font-semibold text-ep-primary-100 text-2xl md:text-3xl">
                        {title}
                    </div>
                    <div className="mt-4 font-light text-ep-primary-200 text-lg">
                        {description}
                    </div>
                </div>
            </div>
        </div>
    )
}
  

const BackgroundPatterns = () => {    
    return ( 
        <>
            <div className="auth-bg-1"></div>
            <div className="auth-bg-2"></div>
            <div className="auth-bg-3"></div>
        </>
    )
}
 

export default AuthLayout;
