
export const SELECT_RECURRING_TRANSFER = "SELECT_RECURRING_TRANSFER";
export const SAVE_RECURRING_TRANSFER_FORM = "SAVE_RECURRING_TRANSFER_FORM";
export const CLEAR_RECURRING_TRANSFER_FORM = "CLEAR_RECURRING_TRANSFER_FORM";

export const DELETE_RECURRING_TRANSFER_START = "DELETE_RECURRING_TRANSFER_START";
export const DELETE_RECURRING_TRANSFER_DONE = "DELETE_RECURRING_TRANSFER_DONE";
export const DELETE_RECURRING_TRANSFER_FAILED = "DELETE_RECURRING_TRANSFER_FAILED";

export const UPDATE_RECURRING_TRANSFER_START = "UPDATE_RECURRING_TRANSFER_START";
export const UPDATE_RECURRING_TRANSFER_DONE = "UPDATE_RECURRING_TRANSFER_DONE";
export const UPDATE_RECURRING_TRANSFER_FAILED = "UPDATE_RECURRING_TRANSFER_FAILED";

export const PAUSE_RECURRING_TRANSFER_START = "PAUSE_RECURRING_TRANSFER_START";
export const PAUSE_RECURRING_TRANSFER_DONE = "PAUSE_RECURRING_TRANSFER_DONE";
export const PAUSE_RECURRING_TRANSFER_FAILED = "PAUSE_RECURRING_TRANSFER_FAILED";

export const GET_RECURRING_TRANSFER_HISTORY_START = "GET_RECURRING_TRANSFER_HISTORY_START";
export const GET_RECURRING_TRANSFER_HISTORY_DONE = "GET_RECURRING_TRANSFER_HISTORY_DONE";
export const GET_RECURRING_TRANSFER_HISTORY_FAILED = "GET_RECURRING_TRANSFER_HISTORY_FAILED";

export const GET_RECURRING_TRANSFER_DETAILS_START = "GET_RECURRING_TRANSFER_DETAILS_START";
export const GET_RECURRING_TRANSFER_DETAILS_DONE = "GET_RECURRING_TRANSFER_DETAILS_DONE";
export const GET_RECURRING_TRANSFER_DETAILS_FAILED = "GET_RECURRING_TRANSFER_DETAILS_FAILED";

export const GET_ALL_RECURRING_TRANSFERS_START = "GET_ALL_RECURRING_TRANSFERS_START";
export const GET_ALL_RECURRING_TRANSFERS_DONE = "GET_ALL_RECURRING_TRANSFERS_DONE";
export const GET_ALL_RECURRING_TRANSFERS_FAILED = "GET_ALL_RECURRING_TRANSFERS_FAILED";

export const CREATE_RECURRING_TRANSFER_START = "CREATE_RECURRING_TRANSFER_START";
export const CREATE_RECURRING_TRANSFER_DONE = "CREATE_RECURRING_TRANSFER_DONE";
export const CREATE_RECURRING_TRANSFER_FAILED = "CREATE_RECURRING_TRANSFER_FAILED";

export const RESET_TRANSFER_PROPERTIES = "RESET_TRANSFER_PROPERTIES";

export const GET_EARNIPAY_BENEFICIARIES_START = "GET_EARNIPAY_BENEFICIARIES_START";
export const GET_EARNIPAY_BENEFICIARIES_DONE = "GET_EARNIPAY_BENEFICIARIES_DONE";
export const GET_EARNIPAY_BENEFICIARIES_FAILED = "GET_EARNIPAY_BENEFICIARIES_FAILED";

export const CREATE_TRANSFER_BENEFICIARY_START = "CREATE_TRANSFER_BENEFICIARY_START";
export const CREATE_TRANSFER_BENEFICIARY_DONE = "CREATE_TRANSFER_BENEFICIARY_DONE";
export const CREATE_TRANSFER_BENEFICIARY_FAILED = "CREATE_TRANSFER_BENEFICIARY_FAILED";
export const CREATE_TRANSFER_BENEFICIARY_RESET = "CREATE_TRANSFER_BENEFICIARY_RESET";


export const GET_TRANSFER_BENEFICIARIES_START = "GET_TRANSFER_BENEFICIARIES_START"
export const GET_TRANSFER_BENEFICIARIES = "GET_TRANSFER_BENEFICIARIES"
export const GET_TRANSFER_BENEFICIARIES_FAILED = "GET_TRANSFER_BENEFICIARIES_FAILED"

export const DELETE_TRANSFER_BENEFICIARY_START = "DELETE_TRANSFER_BENEFICIARY_START"
export const DELETE_TRANSFER_BENEFICIARY = "DELETE_TRANSFER_BENEFICIARY"
export const DELETE_TRANSFER_BENEFICIARY_FAILED = "DELETE_TRANSFER_BENEFICIARY_FAILED"
export const DELETE_TRANSFER_BENEFICIARY_RESET = "DELETE_TRANSFER_BENEFICIARY_RESET"

export const INITIATE_EARNIPAY_TRANSFER_START = "INITIATE_EARNIPAY_TRANSFER_START";
export const INITIATE_EARNIPAY_TRANSFER_DONE = "INITIATE_EARNIPAY_TRANSFER_DONE";
export const INITIATE_EARNIPAY_TRANSFER_FAILED = "INITIATE_EARNIPAY_TRANSFER_FAILED";
export const INITIATE_EARNIPAY_TRANSFER_RESET = "INITIATE_EARNIPAY_TRANSFER_RESET";

export const INITIATE_BANK_TRANSFER_START = "INITIATE_BANK_TRANSFER_START";
export const INITIATE_BANK_TRANSFER_DONE = "INITIATE_BANK_TRANSFER_DONE";
export const INITIATE_BANK_TRANSFER_FAILED = "INITIATE_BANK_TRANSFER_FAILED";
export const INITIATE_BANK_TRANSFER_RESET = "INITIATE_BANK_TRANSFER_RESET";

export const SAVE_EARNIPAY_TRANSFER_BULK_CSV = "SAVE_EARNIPAY_TRANSFER_BULK_CSV";
export const SAVE_EARNIPAY_TRANSFER_BULK_CSV_ERRORS = "SAVE_EARNIPAY_TRANSFER_BULK_CSV_ERRORS";
export const CLEAR_EARNIPAY_MULTIPLE_ITEMS = "CLEAR_EARNIPAY_MULTIPLE_ITEMS";
export const SAVE_EARNIPAY_MULTIPLE_ITEMS = "SAVE_EARNIPAY_MULTIPLE_ITEMS";

export const CLEAR_BANK_TRANSFER_MULTIPLE_ITEMS = "CLEAR_BANK_TRANSFER_MULTIPLE_ITEMS";
export const SAVE_BANK_TRANSFER_MULTIPLE_ITEMS = "SAVE_BANK_TRANSFER_MULTIPLE_ITEMS";
export const SAVE_BANK_TRANSFER_BULK_CSV = "SAVE_BANK_TRANSFER_BULK_CSV";
export const SAVE_BANK_TRANSFER_BULK_CSV_ERRORS = "SAVE_BANK_TRANSFER_BULK_CSV_ERRORS";
