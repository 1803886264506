import React, {Suspense} from 'react';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Header from 'components/__new/common/header.jsx';
import Sidebar from 'components/__new/common/sidebar.jsx';
import PayrollFeaturesModal from '../grossPayroll/PayrollFeaturesModal';
import Maybe from "../common/Maybe";
import IdleChecker from "hooks/useIdleChecker";
import Environment from "components/__new/common/Environment";
import LoaderFallback from "components/__new/common/LoaderFallback";
// import useLoginInfo from 'hooks/useLoginInfo';


const EmployeeAdminLayout = (props) => {
    const location = useLocation();
    const { isSidebarCollapsed } = useSelector((s) => s.ui.preferences);
    // const {userInfo} = useLoginInfo();
    
    
    /*
    const portfolio = `${userInfo?.employer?.firstName} ${userInfo?.employer?.lastName} | ${userInfo?.employer?.jobTitle} | ${userInfo?.company?.companyName}`;
    const app_id = process.env.REACT_APP_SIMPU_APP_ID;
    const public_key = process.env.REACT_APP_SIMPU_PUBLIC_KEY;

    
    useEffect(() => {
        var widget = window.Simpu.default.init({
            app_id: app_id,
            public_key: public_key
        });
        widget.render({
            name: portfolio,
            email: userInfo?.email,
            user_id: userInfo?._id,
        });
        // eslint-disable-next-line
    }, [])
    */
    

    return (
        <>
            <Environment local={false} develop={true} staging={true} production={true}>
                <IdleChecker />
            </Environment>
            
            <div className="flex min-h-screen" style={{backgroundColor: "#F6F6F6"}}>
                <Sidebar />
                <div className="h-screen flex-none hidden md:block" style={{width: (isSidebarCollapsed ? "60px":"250px")}}></div>
                <div className="flex-grow overflow-hidden">
                    <Header />
                    <div>
                        {/* <Maybe condition={!location.pathname.includes("/compliance")}>
                            <div className="pt-6 px-6 pb-24 sm:pt-10 sm:px-10">
                                {props.children}
                            </div>
                        </Maybe> */}
                        {/* <Maybe condition={location.pathname.includes("/compliance")}> */}
                            {/* <Suspense fallback={<LoaderFallback />}> */}
                            <Suspense fallback={<></>}>
                                <div className="pt-6 px-6 pb-24 sm:pt-5 sm:px-8">
                                    {props.children}
                                </div>
                            </Suspense>
                        {/* </Maybe> */}
                    </div>
                </div>
                <PayrollFeaturesModal />
            </div>
        </>
    );
}

export default EmployeeAdminLayout;
