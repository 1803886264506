import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getBaseUrl from 'utils/getBaseUrl';
import * as auth from "store/auth/user/action";
import { customErrorToast } from "utils/customToast";


const useSubAccounts = () => {
    const dispatch = useDispatch();
    const { loginSubAccountLoading, loginSubAccountResponse, subAccountsLoading, subAccounts } = useSelector((s) => s.auth.user);

    const [isSwitchAccountClicked, setIsSwitchAccountClicked] = useState(false);
    const [isOpenModalSwitchAccount, setIsOpenModalSwitchAccount] = useState(false);
    const [subAccountSwitchAccount, setSubAccountSwitchAccount] = useState({});


    const getSubAccounts = () => {
        const params = {
            page: 1,
            limit: 50,
            download: false
        }
        dispatch(auth.getSubAccounts(params));
    }


    const getSubAccountStatus = (subAccount) => {
        if (subAccount?.isEmailVerified === true && subAccount?.isCompanyInfo === true && subAccount?.isActive === true){
            return true;
        }
        return false;
    }


    const switchToSubAccount = (subAccount) => {
        if (subAccount && subAccount?._id && subAccount?._id !== ""){
            setIsSwitchAccountClicked(true);
            setIsOpenModalSwitchAccount(true);
            setSubAccountSwitchAccount(subAccount);

            if (getSubAccountStatus(subAccount) === true){
                const params = {
                    subAccountId: subAccount?._id,
                }
                dispatch(auth.loginSubAccount(params));
            }
        }
        else{
            customErrorToast({ message: "Invalid sub-account selected" });
        }
    }
    
    
    const switchToMainAccount = () => {
        setIsSwitchAccountClicked(true);
        setIsOpenModalSwitchAccount(true);
        setSubAccountSwitchAccount({
            isEmailVerified: true,
            isCompanyInfo: true,
            isActive: true,
        });

        setTimeout(() => {
            dispatch(auth.loginMainAccount()).then(() => {
                const baseUrl = getBaseUrl();
                window.location.href = baseUrl + "/dashboard";
            })
        }, 2000);
    }


    useEffect(() => {
        if (isSwitchAccountClicked === true){
            if (loginSubAccountLoading === false){
                if (loginSubAccountResponse?.status === "success"){
                    const baseUrl = getBaseUrl();
                    window.location.href = baseUrl + "/dashboard";
                }
                else{
                    setIsOpenModalSwitchAccount(false);
                }
                setIsSwitchAccountClicked(false);
            }
        }
        // eslint-disable-next-line
    }, [loginSubAccountResponse])
    
    
    return {
        isOpenModalSwitchAccount,
        setIsOpenModalSwitchAccount,

        switchToSubAccount,
        switchToMainAccount,
        getSubAccountStatus,
        subAccountSwitchAccount,

        loginSubAccountLoading,

        getSubAccounts,
        subAccountsLoading,
        subAccounts,
    };
}


export default useSubAccounts;
