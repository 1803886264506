import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDrawer from "components/__new/common/form-drawer";
import Maybe from "../common/Maybe";
import useSubAccounts from "hooks/useSubAccounts";


export const ModalSwitchingAccount = (props) => {
    const {
        subAccount,
        isOpenModalSwitchAccount,
        setIsOpenModalSwitchAccount
    } = props;


    const { getSubAccountStatus } = useSubAccounts();
    

    const closeDialog = () => {
        if (getSubAccountStatus(subAccount) === false){
            setIsOpenModalSwitchAccount(false);
        }
    }
    

    return (
        // <FormDrawer display="center" size="xl" isOpen={isOpenModalSwitchAccount} setIsOpen={setIsOpenModalSwitchAccount} closeDialog={() => {}}>
        <FormDrawer display="center" size="xl" isOpen={isOpenModalSwitchAccount} setIsOpen={setIsOpenModalSwitchAccount} closeDialog={closeDialog}>
            <div className="box max-w-xl border-none">


                <Maybe condition={getSubAccountStatus(subAccount) === true}>
                    <div className="py-5 text-center">
                        <div className="font-semibold text-xl">
                            Switching Account
                        </div>
                        <div className="text-fade">
                            Please wait while we log you in...
                        </div>
                        <div className="mt-10 flex justify-center">
                            <FontAwesomeIcon icon="circle-notch" spin className="text-4xl" />
                        </div>
                    </div>
                </Maybe>


                <Maybe condition={getSubAccountStatus(subAccount) === false}>
                    <div className="py-5 text-center">
                        <div className="font-semibold text-xl">
                            Incomplete Account Registration
                        </div>
                        <div className="text-fade">
                            Kindly complete the account registration to continue
                        </div>
                        <div className="mt-10">
                            
                            <div className="wizard-steps wizard-steps-switch-accounts h-12 mt-12 mb-20 mx-auto">
                                <div className="mx-10 flex">
                                    <div className={(subAccount?.isCompanyInfo === true ?"active":"") + " w-12 h-12 flex-shrink-0 mx-auto relative z-20 rounded-full bg-white border border-black flex"}>
                                        <FontAwesomeIcon icon="check" className="hidden label-icon m-auto text-sm font-bold" />
                                        <span className="label-text m-auto text-base">1</span>
                                    </div>
                                    <div className="flex-grow">
                                        <div className={(subAccount?.isCompanyInfo === true ?"bg-ep-primary":"bg-gray-400") + " h-2 mt-5 mx-5 rounded-full"}></div>
                                    </div>

                                    <div className={(subAccount?.isActive === true ?"active":"") + " w-12 h-12 flex-shrink-0 mx-auto relative z-20 rounded-full bg-white border border-black flex"}>
                                        <FontAwesomeIcon icon="check" className="hidden label-icon m-auto text-sm font-bold" />
                                        <span className="label-text m-auto text-base">2</span>
                                    </div>
                                    <div className="flex-grow">
                                        <div className={(subAccount?.isActive === true ?"bg-ep-primary":"bg-gray-400") + " h-2 mt-5 mx-5 rounded-full"}></div>
                                    </div>

                                    <div className={(subAccount?.isEmailVerified === true ?"active":"") + " w-12 h-12 flex-shrink-0 mx-auto relative z-20 rounded-full bg-white border border-black flex"}>
                                        <FontAwesomeIcon icon="check" className="hidden label-icon m-auto text-sm font-bold" />
                                        <span className="label-text m-auto text-base">3</span>
                                    </div>
                                </div>

                                <div className="w-full mt-3 flex justify-between space-x-2 text-center text-sm">
                                    <div>
                                        Fill Company Info
                                    </div>
                                    <div>
                                        Admin Approval
                                    </div>
                                    <div>
                                        Accept Email Invite
                                    </div>
                                </div>
                            </div>

                            <div className="mt-10 flex space-x-2 justify-center">
                                <button onClick={() => setIsOpenModalSwitchAccount(false)} className="btn btn-transparent-primary">Close</button>
                            </div>

                        </div>
                    </div>
                </Maybe>


            </div>
        </FormDrawer>
    )
}


export default ModalSwitchingAccount;