// Main
export const projectName = 'Earnipay';
export const repoName = 'earnipay-app';

// API - middleware
export const apiRequestStart = "API/REQUEST_START";
export const apiRequestSuccess = "API/REQUEST_SUCCESS";     // for logging purpose only
export const apiRequestFailed = "API/REQUEST_FAILED";       // for logging purpose only

// API - base url
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const apiDummyBaseUrl = "https://jsonplaceholder.typicode.com/posts";
export const apiPayrollBaseUrl = process.env.REACT_APP_API_PAYROLL_SERVICE_BASE_URL;
export const apiWalletBaseUrl = process.env.REACT_APP_API_WALLET_SERVICE_BASE_URL;
export const apiWalletToken = process.env.REACT_APP_API_WALLET_SERVICE_TOKEN;
export const apiGrossPayrollBaseUrl = process.env.REACT_APP_GROSS_PAYROLL_BASE_URL;
export const apiBillPaymentBaseUrl = process.env.REACT_APP_API_BILL_PAYMENT_SERVICE_BASE_URL;
export const apiBillPaymentToken = process.env.REACT_APP_API_BILL_PAYMENT_SERVICE_TOKEN;

// API - user auth
export const apiRequestNewUserOtp = "/b2b/employer/auth/send-otp";
export const apiVerifyNewUserOtp = "/b2b/employer/auth/verify-otp";
export const apiCreateNewUser = "/b2b/employer/auth/signup";
export const apiVerifyOtpAndCreateEmployer = "/b2b/employer/auth/verify-otp-and-create-employer";
export const apiLoginUser = "/b2b/employer/auth/login";
export const apiResendTwoFaOtp = "/b2b/employer/auth/send-2fa-otp";
// export const apiLoginUser = "/user-management/employer/auth/login";
// export const apiLoginUser = "/user-management/auth/login-user";
export const apiRegisterUser = "/user-management/create-user";
export const apiVerifyEmail = "/user-management/employer/verify-mail";
export const apiResendVerifyEmail = "/user-management/employer/resend-verification-mail";
// export const apiForgotPassword = "/user-management/employer/send-reset-pin";
export const apiForgotPassword = "/b2b/employer/setting/company/send-forget-password";
// export const apiResetPassword = "/user-management/employer/change-password";
export const apiResetPassword = "/b2b/employer/setting/company/reset-forgot-password";
export const apiGetSubAccounts = "/employer-operations/employer/sub-account/get";
export const apiAddSubAccount = "/employer-operations/employer/sub-account/create";
export const apiLoginSubAccount = "/employer-operations/employer/sub-account/login";
export const apiGetReferralPoints = "/setting/referral-point";
export const apiLogoutUser = "/user-management/auth/logout-user";

// API - onboarding
export const apiAddSocials = "/user-management/employer/add-socials";
export const apiAddDirector = "/user-management/employer/add-director";
export const apiUpdateDirector = "/user-management/employer/update-director/";
export const apiDeleteDirector = "/user-management/employer/remove-director/";

// API - user profile
export const apiGetEmployerInfo = "/user-management/employer";
export const apiUpdateCompanyInfo = "/user-management/employer/company-information";
export const apiUpdateProfile = "/user-management/profile/update";
export const apiUpdateProfilePassword = "/user-management/employer/update-password";

// API - dashboard
export const apiGetBusinessDashboard = "/b2b/employer/dashboard";

// API - employees
export const apiGetEmployees = "/user-management/employees/all";
export const apiGetEmployeesRole = "/user-management/employees/role";
export const apiDeleteAnEmployee = "/user-management/employees/delete";
export const apiGetCRMEmployees = "/crm/synced-employees";
export const apiGetEmployee = "/user-management/employee/";
export const apiResendEmployeeInvite = "/miscellaneous/resend-employee-invite";
export const apiResendEmployeeODPcode = "/user-management/employee/resend-access-code";
export const apiCreateEmployee = "/user-management/employee/create";
export const apiUploadEmployeeCsv = "/user-management/employee/upload";
export const apiUploadEmployeeExcelPreview = "/employees/upload-preview";
export const apiUploadEmployeeExcel = "/employees/upload-to-queue";
export const apiUploadEmployeeExcelStatus = "/employees/bulk-create/job/";
// export const apiUploadEmployeeExcel = "/employees/upload-to-queue";
// export const apiUploadEmployeeExcelStatus = "/employees/bulk-create/job/";
export const apiUpdateEmployee = "/user-management/employee/";
export const apiStopEmployeeEarning = "/user-management/employee/stop-earning/";
export const apiResumeEmployeeEarning = "/user-management/employee/restart-earning/";
export const apiEnableEmployee = "/user-management/employee/enable/";
export const apiBulkActivateEmployee = "/user-management/employee/enable-bulk";
export const apiDisableEmployee = "/user-management/employee/disable/";
export const apiBulkDeactivateEmployee = "/user-management/employee/disable-bulk";
export const apiArchiveEmployee = "/employees/";
export const apiBulkArchiveEmployee = "/employees/archive-bulk";
export const apiGenerateEraEmployee = "/miscellaneous/employer/create-eva";
export const apiGetEmployeeMetrics = "/user-management/employees/metrics";
export const apiInviteEmployees = "/user-management/employee/invite-employees";
export const apiInviteGroups = "/user-management/employee/invite-groups";

// API - ERA transactions
export const apiGetTransactions = "/miscellaneous/eva-transactions";

// API - groups
export const apiCreateGroupB2b = "/groups/create";
export const apiUpdateGroupB2b = "/groups/update/";
// export const apiGetGroupsB2b = "/groups/all";
export const apiAddEmployeesFromGroupB2b = "/groups/update/";
export const apiRemoveEmployeesFromGroupB2b = "/groups/update/";
export const apiDeleteGroupB2b = "/groups/delete/";
export const apiRemoveEmployeeFromGroup = "/groups/unassign";
export const apiAssignEmployeeToGroupB2b = "payroll/pay-group/";


// API - groups policy
// export const apiGetAllGroups = "/user-management/group-policy/get-all-policies";
export const apiGetAllGroups = "/payroll/pay-group";
export const apiGetAllGroupEmployees = "/user-management/group-policy/";
export const apiAssignEmployeeToGroup = "/user-management/group-policy/assign";
export const apiCreateGroup = "/user-management/group-policy/create";
export const apiUpdateGroup = "/user-management/group-policy/update/";
export const apiDeleteGroup = "/user-management/group-policy/delete/";

// API - withdrawals
export const apiGetAllWithdrawals = "/user-management/employees/withdrawals";
export const apiGetAllWithdrawalsMetrics = "/user-management/employees/withdrawals/metrics";
export const apiApproveWithdrawal = "/user-management/employee/withdrawal/approve/";
export const apiRejectWithdrawal = "/user-management/employee/withdrawal/cancel/";
export const apiGetODPConfiguration = "/user-management/odp-configuration";
export const apiUpdateODPConfiguration = "/user-management/odp-configuration";
export const apiGetWithdrawalReport = "/user-management/employees/withdrawals/report";
export const apiResumeWithdrawal = "/user-management/employee/resume-withdrawal/";
export const apiPauseWithdrawal = "/user-management/employee/pause-withdrawal/";

// API - savings
export const apiSavingsRequests = "/wallet/employer/savings-requests";
export const apiSavingsRequestMetrics = "/wallet/employer/savings-request-metrics";

// API - payroll (version 1)
export const apiGetPayrollMetrics = "/employer-operations/payroll/metrics";
export const apiGetAllPayroll = "/employer-operations/payroll/all";
export const apiFetchPayroll = "/payroll";
export const apiGetPayroll = "/payroll/";
export const apiUpdatePayrollTransaction = "/payroll/transactions/";
export const apiRetryPayroll = "/payroll/transactions/";

// API - run payroll
export const apiGetPayrolls = "/payroll";
export const apiCreatePayroll = "/payroll";
export const apiPayrollTransaction = "/payroll/transaction/";

// API - gross payroll routes
export const apiPaySchedule = "/payroll/pay-schedule";
export const apiPayGroup = "/payroll/pay-group";
export const apiPayStructure = "/payroll/pay-structure";
export const apiBenefitsInKind = "/payroll/benefits-in-kind";
export const apiAllowances = "/payroll/allowances-and-bonuses";
export const apiRemittance = "/payroll/remittance-party";
export const apiVerifyBankAccount = "/third-party/verify-bank-account";
export const apiGetPensionProviders = "/miscellaneous/get-pension-providers";

// API - invoice
export const apiGetAllInvoice = "/employer-operations/invoice/all";
export const apiGetInvoice = "/employer-operations/invoice/";
export const apiDownloadInvoicePDF = "/miscellaneous/employer/invoice/fetch";

// API - payment methods
export const apiGetAllPaymentMethod = "/employer-operations/payment-method/all";
export const apiCreatePaymentMethod = "/employer-operations/payment-method/create";
export const apiUpdatePaymentMethod = "/employer-operations/payment-method/";
export const apiDeletePaymentMethod = "/employer-operations/payment-method/";

// API - payment history
export const apiGetAllHistory = "/employer-operations/payment-history/all";

// API - payments / transfers
export const apiDeleteUpdateRecurringTransfer = "/wallet/transfers/recurring/";
export const apiToggleRecurringTransfer = "/wallet/transfers/recurring/toggle";
export const apiCreateGetRecurringTransfers = "/wallet/transfers/recurring";
export const apiInitiateEarnipaySingleTransfer = "/accounts/transactions/business/intra-single-transfer";
export const apiInitiateEarnipayMultipleTransfer = "/accounts/transactions/business/intra-multiple-transfer";
export const apiInitiateEarnipayBulkTransfer = "/wallet/wallet-transfer/bulk";
export const apiInitiateBankBulkTransfer = "/wallet/b2b/bank-transfer/bulk";
export const apiInitiateSingleBankTransfer = "/accounts/transactions/business/single-transfer";
export const apiInitiateMultipleBankTransfer = "/accounts/transactions/business/multiple-transfer";

// TO BE REMOVED
export const apiCreateSingleEarnipayBeneficiary = "/wallet/beneficiaries/earnipay";
export const apiCreateMultipleEarnipayBeneficiaries = "/wallet/beneficiaries/earnipay/multiple";
export const apiGetEarnipayBeneficiaries = "/wallet/beneficiaries/earnipay";

// API - wallet
export const apiValidateEarnipayAccount = "/wallet/search";
export const apiGetWalletDashboard = "/wallet/employer/dashboard";
export const apiGetBulkPayMetrics = "/wallet/employer/dashboard/bulk_pay";
export const apiGetBulkPayAggregates = "/bulk-pay/aggregate";
export const apiGetWalletTransactionsCount = "/wallet/employer/transactions/counts";
export const apiGetWalletTransactions = "/wallet/employer/transactions";
export const apiGetWalletBalance = "/b2b/employer/dashboard/wallet-balance";

// TO BE REMOVED
export const apiGetWalletBeneficiaries = "/wallet/employer/beneficiaries";

export const apiWalletValidateAccount = "/accounts/name-enquiries";
// export const apiWalletGenerateOtp = "/wallet/employer/otp/generate";
export const apiWalletGenerateOtp = "/b2b/employer/setting/security/send-access-pin-otp";
export const apiWalletGenerateOtpEmployerAdmin = "/b2b/employer/setting/security/send-access-pin-otp";
// export const apiWalletGenerateOtpEmployerAdmin = "/wallet/employer/otp/generate-employer-admin";
export const apiWalletGenerateBulkBeneficiaryOtp = "/wallet/employer/beneficiary/otp/generate";
export const apiWalletCreateAccessPin = "/b2b/employer/setting/security/change-transaction-pin";
// export const apiWalletCreateAccessPin = "/wallet/employer/access-pin/create";
export const apiWalletCreateAccessPinEmployerAdmin = "/b2b/employer/setting/security/change-transaction-pin";
// export const apiWalletCreateAccessPinEmployerAdmin = "/wallet/employer/set-employer-admin-access-pin";

// API - Transfer Beneficiary
export const apiGetTransferBeneficiaries = "/wallet/employer/beneficiaries";
export const apiCreateTransferBeneficiary = "/wallet/employer/beneficiary";
export const apiDeleteTransferBeneficiaries = "/wallet/employer/beneficiary";

export const apiWalletCreateBulkBeneficiary = "/wallet/employer/beneficiaries/upload-bulk";

// TO BE REMOVED
export const apiDeleteWalletBeneficiaries = "/wallet/employer/beneficiary";
export const apiGetBeneficiaries = "/wallet/employer/beneficiary";
export const apiCreateBeneficiaries = "/wallet/employer/beneficiary";

export const apiWalletTransferSingle = "/wallet/employer/beneficiaries/transfer/single";
export const apiWalletTransferMultiple = "/wallet/employer/beneficiaries/transfer/multiple";
export const apiWalletTransferUpload = "/wallet/employer/beneficiaries/transfer/uploads";
export const apiCreateWallet = "/miscellaneous/employer/wallet/create";
export const apiGenerateAccountStatement = "/accounts/transactions/generate-statement";
export const apiFundBusinessAccount = "/wallet/employer/accounts/fund/initiate";
export const apiVerifyFundBusinessAccountStatus = "wallet/accounts/fund/webhook"
export const apiGetBusinessAccounts = "/wallet/employer/accounts"
export const apiCreateAdditionalWallet = "/wallet/employer/create-additional-wallet"
export const apiFreezeAccount = "/wallet/employer/accounts/freeze"
export const apiUnFreezeAccount = "/wallet/employer/accounts/unfreeze"
export const apiGetAccountDetails = "/wallet/employer/accounts/single"
export const apiGetAccountTransactions = "/wallet/employer/employer-wallet-transactions"
export const apiCloseAccount = "/wallet/employer/accounts"
export const apiUpdateAccount = "/wallet/employer/accounts"
export const apiMoveMoney = "/wallet/employer/accounts/move"

// API - Credit
// export const apiCreateBusinessCredit = "/credit-engine/business/credit-check"
export const apiCreateBusinessCredit = "/credit-engine/business/credit-request"
export const apiGetBusinessCredit = "/credit-engine/business/credit"

// API - bill payment
export const apiParseCableFile = "/extract-cabletv-data";
export const apiParseCsvFile = "/extract-data-from-excel";
export const apiGetCablePurchaseTransactions = "/pay-bills/business/transactions";
// export const apiGetCablePurchaseTransactions = "/cabletv-transactions/recent";
export const apiSearchFilterCablePurchaseTransactions = "/cabletv-transactions/search-and-filter";
export const apiGetPowerPurchaseTransactions = "/pay-bills/business/transactions";
// export const apiGetPowerPurchaseTransactions = "/electricity-transactions/recent";
export const apiSearchFilterPowerTransactions = "/electricity-transactions/search-and-filter";
export const apiGetDataSubTransactions = "/pay-bills/business/transactions";
// export const apiGetDataSubTransactions = "/data-transactions/recent";
export const apiSearchFilterDataSubTransactions = "/data-transactions/search-and-filter";
export const apiGetAirtimeTransactions = "/pay-bills/business/transactions";
// export const apiGetAirtimeTransactions = "/airtime-transactions/recent";
export const apiSearchFilterAirtimeTransactions = "/airtime-transactions/search-and-filter";
export const apiGetBillPaymentBeneficiaries = "/beneficiaries/business/all";
export const apiGetBillPaymentProviders = "/all-providers";
export const apiGetBillPaymentServiceCodes = "/pay-bills/service-codes";
export const apiAirtimePurchaseSingle = "/pay-bills/business/buy-airtime";
// export const apiAirtimePurchaseSingle = "/airtime/purchase-for-business";
export const apiAirtimePurchaseMultiple = "/airtime/purchase/multiple";
export const apiAirtimePurchaseUpload = "/airtime/purchase/uploads";
export const apiGetDataBundles = "/pay-bills/data-plans/";
// export const apiGetDataBundles = "/data/bundles";
export const apiDataPurchaseSingle = "/pay-bills/business/buy-data";
// export const apiDataPurchaseSingle = "/data/purchase-for-business";
export const apiDataPurchaseMultiple = "/data/purchase/multiple";
export const apiDataPurchaseUpload = "/data/purchase/uploads";
export const apiGetElectricityValidation = "/pay-bills/electricity/validate-meter-number";
export const apiElectricityPurchaseSingle = "/pay-bills/business/buy-power";
// export const apiElectricityPurchaseSingle = "/electricity/purchase-for-business";
export const apiElectricityPurchaseMultiple = "/electricity/purchase/multiple";
export const apiRenewMultichoicePlan = "/pay-bills/business/subscribe-cable-tv/multichoice";
export const apiGetMultichoiceCodes = "/pay-bills/cable-tv/multichoice/product-codes/";
export const apiMultichoiceProductAddons = "/pay-bills/cable-tv/multichoice/product-codes/add-ons";
export const apiGetCableTvBouquets = "/pay-bills/cable-tv/";
// export const apiGetCableTvBouquets = "/cabletv/bouquets";
export const apiGetCableTvValidation = "/pay-bills/cable-tv/";
// export const apiGetCableTvValidation = "/cabletv/validation";
export const apiCableTvPurchaseSingle = "/pay-bills/business/subscribe-cable-tv/";
// export const apiCableTvPurchaseSingle = "/cabletv/purchase-for-business";
export const apiCableTvPurchaseMultiple = "/cabletv/purchase/multiple";
export const apiGetBulkAirtimeStatus = "/jobs";

// API - Bills payment Beneficiary
export const apiCreateBeneficiary = '/beneficiaries/business/new';
export const apiEditBeneficiary = '/beneficiaries/business/update/';
export const apiDeleteBeneficiary = '/beneficiaries/business/delete/';

// API - dashboard - yield
export const apiGetYieldSettings = "/wallet/employer/yield-settings";
export const apiGetYieldBalance = "/wallet/employer/get-yield-balance"
export const apiGetYieldReport = "/wallet/employer/dashboard/get-yield-report";
export const apiOptInOutYield = "/employer-operations/employer/yield/opt-in-out"

// API - settings - admin
export const apiGetAllAdmin = "/employer-operations/employer-admin/all";
export const apiGetAdmin = "/employer-operations/employer-admin/";
export const apiCreateAdmin = "/employer-operations/employer-admin/create";
export const apiUpdateAdmin = "/employer-operations/employer-admin/";
export const apiUpdateAdminStatus = "/employer-operations/employer-admin/";
export const apiDeleteAdmin = "/employer-operations/employer-admin/";
export const apiAdminResendInvite = "/employer-operations/employer-admin/resend-verification-mail/";
export const apiAdminOnboard = "/miscellaneous/employer-admin/account-confirmation";
export const apiSubAccountAdminOnboard = "/miscellaneous/sub-account/verify";
export const apiAdminAssignBusiness = "/employer-operations/employer/";

// API - settings - policy & legal info
export const apiGetCompanyInfo = "/user-management/company-information";
export const apiGetCompanyFees = "/user-management/company-fees";
export const apiUpdateLegalInfo = "/user-management/company-information";
export const apiUpdatePolicyInfo = "/user-management/company-policy/update";
export const apiUpdateCompanySettings = "/user-management/company-settings";
export const apiUploadBankStatement = "/user-management/company-information/bank-statement";
export const apiCreateBulkEmployeeEVA = "/employer-operations/employer/employees/eva/create";
export const apiTogglePayroll = "/toggle-payroll";
export const apiToggleOffEmployeeEva = "/employer-operations/employer/employees/eva/off";

// API - settings info (Profile, Company Details, etc)
export const apiUploadProfileImage = "/b2b/employer/setting/profile/update-profile-image";
export const apiRemoveProfileImage = "/b2b/employer/setting/profile/remove-profile-image";
export const apiUpdatePersonalInfo = "/b2b/employer/setting/profile/update-profile-info";
export const apiUploadCompanyLogo = "/b2b/employer/setting/company/update-company-logo";
export const apiUpdateCompanyInfoSettings = "/b2b/employer/setting/company/update-company-info";
export const apiGetMembers ='/b2b/employer/setting/company/members/';
export const apiGetAllMembers ='/b2b/employer/setting/company/members';
export const apiInviteMember ='/b2b/employer/setting/company/teams/invite-member';
export const apiAcceptInvite = '/b2b/employer/setting/company/members/';
export const apiResendMemberInvite = '/b2b/employer/setting/company/members/resend-invite/';
export const apiTransferOwnership = '/b2b/employer/setting/company/members/';
export const apiEditTeamMember = '/b2b/employer/setting/company/members/';
export const apiDeactivateMember = '/b2b/employer/setting/company/members/';
export const apiForgotPasswordMember = '/b2b/employer/setting/company/forget-password';
export const apiResetPasswordMember = '/b2b/employer/setting/company/reset-password';
export const apiGetRoles = "/b2b/employer/setting/company/roles";
export const apiCreateCustomRole = "/b2b/employer/setting/company/roles";
export const apiEditRole = "/b2b/employer/setting/company/roles/";
export const apiDeleteRole = "/b2b/employer/setting/company/roles/";
export const apiGetPermission = "/b2b/employer/setting/company/permissions";
export const apiGetRolePermission = "/b2b/employer/setting/company/roles/permissions/";
export const apiChangePassword = "/b2b/employer/setting/security/change-password";
export const apiChangeTransactionPin = "/b2b/employer/setting/security/change-transaction-pin";
export const apiChangeTwoFactorAuthStatus = "/b2b/employer/setting/security/update-2fa";
// export const apiEnterTransactionPinOtp = "/b2b/employer/setting/security/change-transaction-pin";
export const apiUpdateDailyTransactionLimit = "/wallet/employer/transaction-limit/daily";
export const apiUpdateSingleTransactionLimit = "/wallet/employer/transaction-limit/single";
export const apiGetTransactionLimit = "/wallet/employer/transaction-limit";
export const apiGetDefaultTransactionLimit = "/wallet/employer/transaction-limit/default"


// API - general
export const apiSetWithdrawalLimit = "/employers/update-withdrawal-limit";

// API - audit trail
// export const apiGetAuditTrail = "/audits/employer";
export const apiGetAuditTrail = "/b2b/employer/setting/company/audit-logs";

// API - misc
export const apiGetBanks = "/accounts/transactions/bank-list";

export const apiBentoLogin = "/miscellaneous/integration/login";
export const apiFinchSaveCode = "/crm/access-code";
export const apiFinchCheckStatus = "/crm/status";
export const apiRcSearchCompanies = "https://postapp.cac.gov.ng/postapp/api/front-office/search/company-business-name-it";
export const apiRcSearchCompaniesBackend = "/miscellaneous/search-rc-number";
export const apiFileUpload = "/user-management/employer/upload-employer-document";
export const apiValidateBvn = "employer/bvn/validate";
export const apiSendBvnOtp = "employer/bvn/send-otp";
export const apiValidateBvnOtp = "employer/bvn/validate-otp";
export const apiValidateBvnAndNin = "/wallet/employer/bvn/validate";
export const apiSendBvnOtpWhatsApp = "/wallet/bvn/resend-otp";
// export const apiSendOtp = "/wallet/employer/beneficiary/otp/generate";
export const apiSendOtp = "/b2b/employer/setting/security/send-access-pin-otp";
// export const apiSendOtp = "/wallet/employer/otp/generate";

// API - Approvals
export const apiCreateApprovalWorkflow = "/b2b/employer/setting/company/workflows";
export const apiGetApprovalWorkflows = "/b2b/employer/setting/company/workflows";
export const apiGetApprovalWorkflowsType = "/b2b/employer/setting/company/workflows/types";
export const apiUpdateApprovalWorkflow = "/b2b/employer/setting/company/workflows";
export const apiSetApprovalWorkflowStatus = "/b2b/employer/setting/company/workflows/status";
export const apiUpdateApprovalWorkflowStage = "/b2b/employer/setting/company/workflows/stages";
export const apiCreateRequest = "/b2b/employer/setting/company/requests";
export const apiGetRequestAnalytics = "/b2b/employer/setting/company/requests/analytics";
export const apiGetRequests = "/b2b/employer/setting/company/requests";
export const apiApproveRequest = "/b2b/employer/setting/company/requests/approve";
export const apiRejectRequest = "/b2b/employer/setting/company/requests/reject";
export const apiGetRequestApprovals = "/b2b/employer/setting/company/requests/approvals";
export const apiPostRequestComment = "/b2b/employer/setting/company/requests/comments";
export const apiGetRequestComments = "/b2b/employer/setting/company/requests/comments"
export const apiUpdateRequestDisbursement = "/b2b/employer/setting/company/requests/disbursed";
export const apiCancelRequest = "/b2b/employer/setting/company/requests/cancel";

// API - Notifications
export const apiGetAllNotifications = "/employer-operations/notifications";
export const apiMarkAllNotificationAsRead = "/employer-operations/notifications/mark-all-as-read"
export const apiMarkSingleNotificationAsRead = "/employer-operations/notifications/mark-single-as-read"
