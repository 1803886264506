import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customSuccessToast } from "utils/customToast";

const initialState = {
    bentoLoginLoading: false,
    bentoLoginResponse: {},

    finchSaveCodeLoading: false,
    finchSaveCodeStatus: false,
    finchSaveCodeResponse: {},

    finchCheckStatusLoading: false,
    finchCheckStatusResponse: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.BENTO_LOGIN:
            customSuccessToast({ message: action.payload.message });

            state = {
                ...state,
                bentoLoginLoading: false,
                bentoLoginResponse: { ...action.payload }
            }
            return state;


        case actions.BENTO_LOGIN_START:
            state = {
                ...state,
                bentoLoginLoading: true,
            }
            return state;


        case actions.BENTO_LOGIN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                bentoLoginLoading: false,
                bentoLoginResponse: {}
            }
            return state;


        case actions.FINCH_SAVE_CODE:
            customSuccessToast({ message: action.payload.message });
            state = {
                ...state,
                finchSaveCodeLoading: false,
                finchSaveCodeStatus: true,
                finchSaveCodeResponse: { ...action.payload }
            }
            return state;


        case actions.FINCH_SAVE_CODE_START:
            state = {
                ...state,
                finchSaveCodeLoading: true,
                finchSaveCodeStatus: false,
            }
            return state;


        case actions.FINCH_SAVE_CODE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                finchSaveCodeLoading: false,
                finchSaveCodeStatus: false,
                finchSaveCodeResponse: {}
            }
            return state;


        case actions.FINCH_CHECK_STATUS:
            state = {
                ...state,
                finchCheckStatusLoading: false,
                finchCheckStatusResponse: { ...action.payload }
            }
            return state;


        case actions.FINCH_CHECK_STATUS_START:
            state = {
                ...state,
                finchCheckStatusLoading: true,
            }
            return state;


        case actions.FINCH_CHECK_STATUS_FAILED:
            // errorReducer(action.payload);
            state = {
                ...state,
                finchCheckStatusLoading: false,
                finchCheckStatusResponse: {}
            }
            return state;


        default:
            return state;
    }
}