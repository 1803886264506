import Joi from "joi-browser";


const schemas = {
	// generics
	genericStringRequired: Joi.string()
		.required()
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	// specifics
	firstName: Joi.string()
		.regex(/^([A-Za-z]+)([-]{0,1})([A-Za-z]+)$/)
		.required()
		.label("First Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "First name is a required field";
						break;
					case "string.regex.base":
						err.message = `First name is invalid`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	directorFirstName: Joi.string()
		.regex(/^([A-Za-z]+)([-]{0,1})([A-Za-z]+)$/)
		.required()
		.label("Director First Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Director first name is a required field";
						break;
					case "string.regex.base":
						err.message = `Director first name is invalid`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	lastName: Joi.string()
		.regex(/^([A-Za-z]+)([-]{0,1})([A-Za-z]+)$/)
		.required()
		.label("Last Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Last name is a required field";
						break;
					case "string.regex.base":
						err.message = `Last name is invalid`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	directorLastName: Joi.string()
		.regex(/^([A-Za-z]+)([-]{0,1})([A-Za-z]+)$/)
		.required()
		.label("Director Last Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Director last name is a required field";
						break;
					case "string.regex.base":
						err.message = `Director last name is invalid`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	fullName: Joi.string()
		.min(2)
		.required()
		.label("Full Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Fullname is a required field";
						break;
					case "string.min":
						err.message = "Fullname must have minimum 2 characters";
						break;
					case "string.regex.base":
						err.message = `Full name is invalid`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	name: Joi.string()
		.min(2)
		.required()
		.label("Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Name is a required field";
						break;
					case "string.min":
						err.message = "Name must have a minimum of 2 characters";
						break;
					case "string.regex.base":
						err.message = `Name is invalid`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	alias: Joi.string()
		.min(2)
		.required()
		.label("Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Name is a required field";
						break;
					case "string.min":
						err.message = "Name must have a minimum of 2 characters";
						break;
					case "string.regex.base":
						err.message = `Name is invalid`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	jobTitle: Joi.string()
		.required()
		.label("Job Title")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Job title is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	role: Joi.string()
		.required()
		.label("Role")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Role is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	status: Joi.string()
		.required()
		.label("Status")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Status is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	email: Joi.string()
		.email()
		.required()
		.label("Email Address")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Email address is a required field";
						break;
					case "string.email":
						err.message = "Email address is invalid";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	businessEmail: Joi.string()
		.email()
		.required()
		.label("Business Email Address")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Business email address is a required field";
						break;
					case "string.email":
						err.message = "Business email address is invalid";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	phoneNumber: Joi.string()
		.regex(/^[0-9]{11}$/)
		.required()
		.label("Phone Number")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Phone number is a required field";
						break;
					case "string.min":
					case "string.max":
					case "string.regex.base":
						err.message = `Phone number must contain 11 digits`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	// phoneNumber: Joi.string().required().label("Phone Number").error(errors => {
	//     errors.forEach(err => {
	//         switch (err.type) {
	//             case "any.empty":
	//             case "any.required":
	//                 err.message = "Please enter the phone number";
	//                 break;
	//             default:
	//                 break;
	//         }
	//     });
	//     return errors;
	// }),
	currentPassword: Joi.string()
		.required()
		.label("Current Password")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Please enter your current password";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	password: Joi.string()
		.required()
		.label("Password")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter your password";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	confirmPassword: Joi.any()
		.valid(Joi.ref("password"))
		.label("Confirm Password")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.allowOnly":
						err.message = "Your passwords does not match";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	password_confirmation: Joi.any()
		.valid(Joi.ref("password"))
		.label("Confirm Password")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.allowOnly":
						err.message = "Your password does not match";
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	companyName: Joi.string()
		.required()
		.label("Company Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter the company name";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	companyNameRegisterForm: Joi.string()
		.required()
		.label("Company Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						// err.message = "Please enter your company name";
						err.message = " ";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	companySize: Joi.number()
		.integer()
		.required()
		.label("Company Size")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "number.base":
						err.message = "Please select number of employees";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	Logo: Joi.string()
		.required()
		.label("Logo")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please upload the company logo";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	businessName: Joi.string()
		.required()
		.label("Business name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Business name is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	businessSize: Joi.string()
		.required()
		.label("Business size")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Business size is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	businessType: Joi.string()
		.required()
		.label("Business type")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Business type is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	industryType: Joi.string()
		.required()
		.label("Industry type")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Industry type is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	otherIndustryType: Joi.string()
		.required()
		.label("Other industry type")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Other industry type is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	businessDescription: Joi.string()
		.min(10)
		.required()
		.label("Business description")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Business description is a required field";
						break;
					case "string.min":
						err.message = "Business description must have a minimum of 10 characters";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	description: Joi.string()
		.min(10)
		.required()
		.label("Description")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Description is a required field";
						break;
					case "string.min":
						err.message = "Description must have a minimum of 10 characters";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	registrationType: Joi.string()
		.required()
		.label("Registration type")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Registration type is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	registrationPrefix: Joi.string()
		.required()
		.label("Registration prefix")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Registration prefix is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	registrationNumber: Joi.string()
		.required()
		.label("Registration number")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Registration number is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	taxIdentificationNumber: Joi.string()
		.required()
		.label("Tax identification number")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Tax identification number is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	tradingName: Joi.string()
		.required()
		.label("Trading name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Trading name is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	businessAddress: Joi.string()
		.required()
		.label("Business address")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Business address is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	invoiceEmail: Joi.string()
		.email()
		.required()
		.label("Payment Email Address")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter your payment email address";
						break;
					case "string.email":
						err.message = "Please enter your valid payment email address";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	rcNumber: Joi.string()
		.required()
		.label("RC Number")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Please enter the company's Registration Number";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	tinNumber: Joi.string()
		.regex(/^[0-9]+[0-9\\-]*$/)
		.required()
		.label("TIN Number")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Please enter your company's TIN number";
						break;
					case "string.regex.base":
						err.message = `Please enter a valid TIN number`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	referredFrom: Joi.string()
		.allow(null, "")
		.label("Referred From")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Please select a referral";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	bvn: Joi.string()
		.regex(/^[0-9]{11}$/)
		.required()
		.label("BVN")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "BVN is a required field";
						break;
					case "string.min":
					case "string.max":
					case "string.regex.base":
						err.message = `BVN must contain 11 digits`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	nin: Joi.string()
		.regex(/^[0-9]{11}$/)
		.required()
		.label("NIN")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "BVN is a required field";
						break;
					case "string.min":
					case "string.max":
					case "string.regex.base":
						err.message = `NIN must contain 11 digits`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	employeeNumber: Joi.string()
		.required()
		.label("Employee ID Number")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter the employee ID number";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	bankId: Joi.string()
		.required()
		.label("Bank Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please select the bank name";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	accountNumber: Joi.string()
		.regex(/^[0-9]{10}$/)
		.required()
		.label("Account Number")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter the account number";
						break;
					case "string.min":
					case "string.max":
					case "string.regex.base":
						err.message = `Please enter 10 digits for the account number`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	netMonthlySalary: Joi.number()
		.required()
		.min(1000)
		.label("Net Monthly Salary")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "number.base":
						err.message = `Please enter the net monthly salary`;
						break;
					case "number.min":
						err.message = `Employee net monthly salary must be greater than ₦1,000`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	grossAnnualPay: Joi.number()
		.required()
		.min(1000)
		.label("Gross Yearly Salary")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "number.base":
						err.message = `Please enter the gross yearly salary`;
						break;
					case "number.min":
						err.message = `Employee gross yearly salary must be greater than ₦1,000`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	bankName: Joi.allow(null, ""),
	// cacDocument: Joi.allow(null,''),
	// addressVerificationDocument: Joi.allow(null,''),
	cacDocument: Joi.alternatives(Joi.string(), Joi.object())
		.required()
		.label("CAC Document")
		.error((errors) => {
			errors.forEach((err) => {
				err.context.reason?.forEach((err2) => {
					switch (err2.type) {
						case "any.empty":
						case "any.required":
							err2.message = "CAC document is required for verification";
							break;
						case "object.base":
							err2.message = "CAC document is required for verification";
							break;
						default:
							break;
					}
				});
			});
			return errors;
		}),
	particularsOfDirectorsDocument: Joi.alternatives(Joi.string(), Joi.object())
		.required()
		.label("Particulars of Directors Document")
		.error((errors) => {
			errors.forEach((err) => {
				err.context.reason?.forEach((err2) => {
					switch (err2.type) {
						case "any.empty":
						case "any.required":
							err2.message = "Particulars of directors document is required for verification";
							break;
						case "object.base":
							err2.message = "Particulars of directors document is required for verification";
							break;
						default:
							break;
					}
				});
			});
			return errors;
		}),
	bankStatementDocument: Joi.alternatives(Joi.string(), Joi.object())
		.required()
		.label("Bank Statement Document")
		.error((errors) => {
			errors.forEach((err) => {
				err.context.reason?.forEach((err2) => {
					switch (err2.type) {
						case "any.empty":
						case "any.required":
							err2.message = "Bank statement document is required for verification";
							break;
						case "object.base":
							err2.message = "Bank statement document is required for verification";
							break;
						default:
							break;
					}
				});
			});
			return errors;
		}),
	identityVerificationType: Joi.string()
		.required()
		.label("Identity verification type")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Identity verification type is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	identityVerificationDocument: Joi.alternatives(Joi.string(), Joi.object())
		.required()
		.label("Identity Verification Document")
		.error((errors) => {
			errors.forEach((err) => {
				err.context.reason?.forEach((err2) => {
					switch (err2.type) {
						case "any.empty":
						case "any.required":
							err2.message = "Identity document is required for verification";
							break;
						case "object.base":
							err2.message = "Identity document is required for verification";
							break;
						default:
							break;
					}
				});
			});
			return errors;
		}),
	addressVerificationType: Joi.string()
		.required()
		.label("Address verification type")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Address verification type is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	addressVerificationDocument: Joi.alternatives(Joi.string(), Joi.object())
		.required()
		.label("Address Verification Document")
		.error((errors) => {
			errors.forEach((err) => {
				err.context.reason?.forEach((err2) => {
					switch (err2.type) {
						case "any.empty":
						case "any.required":
							err2.message = "Address document is required for verification";
							break;
						case "object.base":
							err2.message = "Address document is required for verification";
							break;
						default:
							break;
					}
				});
			});
			return errors;
		}),
	documentType: Joi.string()
		.required()
		.label("Document type")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Document type is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	documentFile: Joi.alternatives(Joi.string(), Joi.object())
		.required()
		.label("Document upload")
		.error((errors) => {
			errors.forEach((err) => {
				err.context.reason?.forEach((err2) => {
					switch (err2.type) {
						case "any.empty":
						case "any.required":
							err2.message = "Identity document is required for verification";
							// err2.message = "Document upload is required";
							break;
						case "object.base":
							err2.message = "Identity document is required for verification";
							// err2.message = "Document upload is required";
							break;
						default:
							break;
					}
				});
			});
			return errors;
		}),
	bankCode: Joi.string()
		.required()
		.label("Bank Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please select the bank";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	bankAccountNumber: Joi.string()
		.regex(/^[0-9]{10}$/)
		.required()
		.label("Bank Account Number")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter the account number";
						break;
					case "string.min":
					case "string.max":
					case "string.regex.base":
						err.message = `Please enter 10 digits for the account number`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	pin: Joi.number()
		.integer()
		.required()
		.label("Reset Pin")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "number.base":
						err.message = `Please enter your password reset pin, sent to your email address`;
						break;
					case "number.integer":
						err.message = `Please enter a valid password reset pin, sent to your email address`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	address: Joi.string()
		.required()
		.label("Registered Address")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter your company's registered address";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	estimatedEmployees: Joi.number()
		.required()
		.label("Number of Employees")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = `Please enter the number of employees`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	estimatedSalary: Joi.string()
		.required()
		.label("Total Monthly Payroll")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = `Please enter the total monthly payroll`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	payrollCutOff: Joi.number()
		.required()
		.label("Payroll cutoff date")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please select the salary date";
						break;
					case "number.base":
						err.message = "Please select a valid salary date";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	resumedAt: Joi.string()
		.required()
		.label("Resumption date")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please select the resumption date";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	withdrawalPercent: Joi.number()
		.required()
		.label("Salary Withdrawal Percentage")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please select the salary withdrawal percentage";
						break;
					case "number.base":
						err.message = "Please select a valid salary withdrawal percentage";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	autoWithdrawals: Joi.boolean()
		.required()
		.label("Auto-Withdrawals")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "boolean.empty":
					case "boolean.required":
						err.message = "Please select the salary auto-withdrawal option";
						break;
					case "boolean.base":
						err.message = "Please select a valid salary auto-withdrawal option";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	paymentFee: Joi.string()
		.required()
		.label("Payment Fee")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = `Please select a payment fee type`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	payrollType: Joi.string()
		.required()
		.label("Payroll Type")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = `Please select a payroll style`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	date: Joi.string()
		.required()
		.label("Date")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = `Please enter a valid date`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	dob: Joi.string()
		.required()
		.label("Date of Birth")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = `Date of birth is a required field`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	dateOfBirth: Joi.string()
		.required()
		.label("Date of Birth")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = `Date of birth is a required field`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	directorDob: Joi.string()
		.required()
		.label("Director Date of Birth")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = `Director date of birth is a required field`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	time: Joi.string()
		.required()
		.label("time")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = `Please enter a valid time`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	startDate: Joi.string()
		.required()
		.label("Start Date")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = `Start date is a required field`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	endDate: Joi.string()
		.required()
		.label("End Date")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = `End date is a required field`;
						break;
					default:
						break;
				}
			});
			return errors;
		}),
		memberId: Joi.string()
		.required()
		.label("Member Identity")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please select the member's name";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	policyId: Joi.string()
		.required()
		.label("Group Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.required":
						err.message = "Please select a group";
						break;
					case "any.empty":
						err.message = "Please select a group";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	employeeId: Joi.string()
		.required()
		.label("Employee ID Number")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.required":
						err.message = "Unable to select employee";
						break;
					case "any.empty":
						err.message = "Unable to select employee";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	groupId: Joi.string()
		.required()
		.label("Employee Group")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Please select an employee group";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	groupPolicyId: Joi.string()
		.required()
		.label("Employee Group")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Please select an employee group";
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	amount: Joi.number()
		.integer()
		.required()
		.label("Amount")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "number.base":
					case "any.empty":
					case "any.required":
						err.message = "Please enter a valid amount";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	subject: Joi.string()
		.required()
		.label("Subject")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter the subject";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	message: Joi.string()
		.required()
		.label("Message")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter the message";
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	prorateSalary: Joi.any()
		.required()
		.label("Salary Proration")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please select a salary proration";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	prorateSalarySettings: Joi.string()
		.required()
		.label("Salary Proration Type")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please select a salary proration type";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	industry: Joi.string()
		.required()
		.label("Industry")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please select the industry";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	country: Joi.string()
		.required()
		.label("Country")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Country is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	state: Joi.string()
		.required()
		.label("State")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "State is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	city: Joi.string()
		.required()
		.label("City")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "City is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	nationality: Joi.string()
		.required()
		.label("Nationality")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Nationality is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	payGroup: Joi.array().items(
			Joi.object({
				value: Joi.string().required(),
				label: Joi.string().required()
			})
		)
		.min(1)
		.required()
		.label("Pay Group")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "array.includesRequiredUnknowns":
					case "array.base":
					case "object.base":
					case "any.required":
						err.message = "Please select a pay group";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	paySelection: Joi.string()
		.required()
		.label("Pay Selection")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please select a pay selection";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	payScheduleId: Joi.string()
		.required()
		.label("Pay Schedule")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please select a pay schedule";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	// website: Joi.string().regex(/^[.]+[\.]{1}[.]+$/).required().label("Website").error(errors => {
	// website: Joi.string().regex(/^((https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/).required().label("Website").error(errors => {
	// eslint-disable-next-line
	website: Joi.string()
		.regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
		.required()
		.label("Website")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Please enter the website url";
						break;
					case "string.min":
					case "string.max":
					case "string.regex.base":
						err.message = "Please enter a valid website url";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	websiteOptional: Joi.string()
		.allow(null, "")
		.regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g)
		.label("Website")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "string.min":
					case "string.max":
					case "string.regex.base":
						err.message = "Please enter a valid website URL";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	facebook: Joi.string()
		.allow(null, "")
		.regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g)
		.label("Facebook")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "string.min":
					case "string.max":
					case "string.regex.base":
						err.message = "Facebook url must be valid or blank";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	twitter: Joi.string()
		.allow(null, "")
		.regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g)
		.label("Twitter")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "string.min":
					case "string.max":
					case "string.regex.base":
						err.message = "Twitter url must be valid or blank";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	otherUrl: Joi.string()
		.allow(null, "")
		.regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g)
		.label("Other url")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "string.min":
					case "string.max":
					case "string.regex.base":
						err.message = "Other url must be valid or blank";
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	onDemandPayrollCutOff: Joi.string()
		.required()
		.label("Salary Date")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Please select a salary date";
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	onDemandPaymentFee: Joi.string()
		.required()
		.label("Withdrawal Fee")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Please select who pays withdrawal fee";
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	walletName: Joi.string()
		.required()
		.label("Account Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Wallet name is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	walletType: Joi.string()
		.required()
		.label("Account Type")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Wallet type is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	payrollName: Joi.string()
		.required()
		.label("Payroll Name")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
					case "any.required":
						err.message = "Payroll name is a required field";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
	bankAcctNo: Joi.string()
		.min(10)
		.required()
		.label("Account Number")
		.error((errors) => {
		  errors.forEach((err) => {
			switch (err.type) {
			  case "string.min":
				err.message = "Account Number must be 11 characters long";
				break;
			  default:
				break;
			}
		  });
		  return errors;
		}),

	fullDate: Joi.string()
		.required()
		.label("Payroll Date")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Please select a payroll date";
						break;
					default:
						break;
				}
			});
			return errors;
		}),

	monthYear: Joi.object()
		.required()
		.label("Payroll Date")
		.error((errors) => {
			errors.forEach((err) => {
				switch (err.type) {
					case "any.empty":
						err.message = "Please select a payroll date";
						break;
					default:
						break;
				}
			});
			return errors;
		}),
        
        primaryContactPerson: Joi.string().allow('').optional().label('Primary Contact Person')
        .error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.empty":
                        err.message = "Please enter a valid data";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    primaryContactEmail: Joi.string().email({ tlds: { allow: false } }).allow('').optional().label('Primary Contact Email')
        .error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.email":
                        err.message = "Please enter a valid email address";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        secondaryContactPerson: Joi.string().allow('').optional().label('Secondary Contact Person')
        .error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.empty":
                        err.message = "Please enter a valid data";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    secondaryContactEmail: Joi.string().email({ tlds: { allow: false } }).allow('').optional().label('Secondary Contact Email')
        .error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.email":
                        err.message = "Please enter a valid email address";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
};


const validate = (data) => {
    if (data){
        const dataKeys = Object.keys(data);
        let schema = {};
        for (let key in dataKeys){
            if (schemas[dataKeys[key]]){
                schema = {
                    ...schema,
                    [dataKeys[key]]: schemas[dataKeys[key]],
                }
            }
            else{
                return {
                    [dataKeys[key]]: "Validation for " + dataKeys[key] + " has not been configured",
                };
            }
        }

        const options = { abortEarly: false, allowUnknown: true };
        const result = Joi.validate(data, schema, options);
        const error = result.error;
        if (!error) return null;

        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    }
    else{
        return {
            0: "Please parse the form to validate",
        };
    }
};


export default validate;
