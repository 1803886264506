import { customErrorToast, customInfoToast, customSuccessToast } from "utils/customToast";
import * as actions from "./actionTypes";

const initialState = {
    salaryBreakdown: null,
    isDeductionModalOpen: false,
    isPfaModalOpen: false,
    customDeductions: [],
    selectedDeduction: null,
    selectedStructure: null,
    isEditDeductionOpen: false,
    payStructurePayload: null,
    payStructureFormAction: {},
    getPayStructureLoading: false,
    editPayStructureLoading: false,
    getPayStructureData: null,
    deletePayStructureLoading: false,
    deletePayStructureResponse: null,
    deleteMultipleStructuresLoading: false,
    createPayStructureLoading: false,
    createPayStructureResponse: null,
    isPayStructureGroupsModalOpen: false,
    structurePayGroupsLoading: false,
    structurePayGroupsData: null,
    assignStructureGroupsLoading: false,
    unassignStructureGroupsLoading: false,
    assignStructurePayGroup: null,

    getPayScheduleLoading: false,
    getPayScheduleData: null,
    selectedPaySchedule: null,
    createPayScheduleLoading: false,
    editPayScheduleLoading: false,
    payScheduleFormActionLoading: false,
    payScheduleFormAction: {},
    deletePayScheduleLoading: false,
    deletePayScheduleResponse: null,
    deleteMultipleSchedulesLoading: false,

    getPayGroupLoading: false,
    getPayGroupData: null,
    selectedPayGroup: null,
    payGroupDetails: null,
    updatePayGroupEmployeesLoading: false,
    paygroupEmployeeUpdateSuccess: false,
    createPayGroupLoading: false,
    editPayGroupLoading: false,
    deleteMultiplePayGroupsLoading: false,
    deletePayGroupLoading: false,
    deletePayGroupResponse: null,
    deletePayGroupStaffLoading: false,
    createdPayGroup: null,
    payGroupStaffLoading: false,
    payGroupStaffData: null,
    isPayGroupQuickAssignModalOpen: false,
    isSinglePayGroupLoading: false,
    singlePayGroupData: null,
    isRemovingSinglePayGroupLoading: false,
    removeSinglePaygroupResponse: null,

    getBenefitsLoading: false,
    getBenefitsData: null,
    createBenefitsLoading: false,
    deleteBenefitsLoading: false,
    deleteBenefitsResponse: null,
    deleteBatchBenefitsLoading: false,
    selectedBenefit: null,
    isEditBenefitModalOopen: false,
    editBenefitLoading: false,
    isBenefitsModalOpen: false,
    benefitDetails: null,
    benefitPayGroupsLoading: false,
    benefitPayGroupsData: null,
    assignBenefitGroupsLoading: false,
    unassignBenefitGroupsLoading: false,
    isBenefitQuickAssignModalOpen: false,
    assignBenefitsPayGroup: null,

    allowanceDetails: null,
    getAllowanceLoading: false,
    getAllowanceData: null,
    createAllowanceLoading: false,
    deleteAllowanceLoading: false,
    deleteAllowanceResponse: null,
    deleteBatchAllowanceLoading: false,
    editAllowanceLoading: false,
    isAllowanceModalOpen: false,
    isEditAllowanceModalOpen: false,
    selectedAllowance: null,
    allowancePayGroupsLoading: false,
    allowancePayGroupsData: null,
    assignAllowanceGroupsLoading: false,
    unassignAllowanceGroupsLoading: false,
    isAllowanceQuickAssignModalOpen: false,
    assignAllowancePayGroup: null,

    isRemittanceModalOpen: false,
    remittanceDetails: null,
    getRemittanceLoading: false,
    getRemittanceData: null,
    createRemittanceLoading: false,
    createRemittancePayload: null,
    deleteRemittanceLoading: false,
    deleteRemittanceResponse: null,
    deleteMultipleRemittanceLoading: false,
    editRemittanceLoading: false,
    selectedRemittance: null,
    verifyRemittanceBankAccountLoading: false,
    verifyRemittanceBankAccountData: null,
    
    isFreeTrialModalOpen: false,
    isPayrollFeaturesModalOpen: false,
    
    pensionProvidersLoading: false,
    pensionProvidersData: null,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {
        
            // payroll free trial
            case actions.TOGGLE_PAYROLL_FEATURES_MODAL:
                return {
                    ...state,
                    isPayrollFeaturesModalOpen: !state.isPayrollFeaturesModalOpen
                }
                
            case actions.TOGGLE_FREE_TRIAL_OFFER_MODAL:
                return {
                    ...state,
                    isFreeTrialModalOpen: !state.isFreeTrialModalOpen
                }
                

            // Pension providers
            case actions.GET_PENSION_PROVIDERS_START:
                return {
                    ...state,
                    pensionProvidersLoading: true,
                    pensionProvidersData: null,
                }

            case actions.GET_PENSION_PROVIDERS_DONE:
                return {
                    ...state,
                    pensionProvidersLoading: false,
                    pensionProvidersData: action.payload
                }

            case actions.GET_PENSION_PROVIDERS_FAILED:
                customErrorToast({ message: action.payload.message })
                return {
                    ...state,
                    pensionProvidersLoading: false,
                    pensionProvidersData: null,
                }

            // Remittance parties
            case actions.VERIFY_REMITTANCE_BANK_DETAILS_FAILED:
            customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    verifyRemittanceBankAccountLoading: false,
                    verifyRemittanceBankAccountData: null,
                }
                return state;

            case actions.VERIFY_REMITTANCE_BANK_DETAILS_DONE:
                customSuccessToast({ message: action.payload.message })
                state = {
                    ...state,
                    verifyRemittanceBankAccountLoading: false,
                    verifyRemittanceBankAccountData: action.payload,
                }
                return state;

            case actions.VERIFY_REMITTANCE_BANK_DETAILS_START:
                state = {
                    ...state,
                    verifyRemittanceBankAccountLoading: true,
                }
                return state;

            case actions.TOGGLE_REMITTANCE_MODAL:
                return {
                    ...state,
                    isRemittanceModalOpen: !state.isRemittanceModalOpen
                }

            case actions.SELECT_REMITTANCE:
                return {
                    ...state,
                    selectedRemittance: action.payload
                }

            case actions.SAVE_REMITTANCE_DETAILS:
                return {
                    ...state,
                    remittanceDetails: action.payload
                }

            case actions.GET_REMITTANCE_FAILED:
                customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    getRemittanceLoading: false,
                    getRemittanceData: null,
                }
                return state;

            case actions.GET_REMITTANCE_DONE:
                state = {
                    ...state,
                    getRemittanceLoading: false,
                    getRemittanceData: action.payload,
                }
                return state;

            case actions.GET_REMITTANCE_START:
                state = {
                    ...state,
                    getRemittanceLoading: true,
                }
                return state;

            case actions.CREATE_REMITTANCE_FAILED:
                customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    createRemittanceLoading: false,
                    createRemittancePayload: action.payload,
                }
                return state;

                case actions.CREATE_REMITTANCE_DONE:
                state = {
                    ...state,
                    createRemittanceLoading: false,
                    createRemittancePayload: action.payload,
                }
                return state;

            case actions.CREATE_REMITTANCE_START:
                state = {
                    ...state,
                    createRemittanceLoading: true,
                    createRemittancePayload: null,
                }
                return state;

            case actions.DELETE_REMITTANCE_FAILED:
                customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    deleteRemittanceResponse: null,
                    deleteRemittanceLoading: false,
                }
                return state;

                case actions.DELETE_REMITTANCE_DONE:
                 customSuccessToast({ message: "Remittance Party deleted" })
                state = {
                    ...state,
                    deleteRemittanceResponse: action.payload,
                    deleteRemittanceLoading: false,
                }
                return state;

            case actions.DELETE_REMITTANCE_START:
                    customInfoToast({ message: "Deleting Remittance Party" })
                state = {
                    ...state,
                    deleteRemittanceResponse: null,
                    deleteRemittanceLoading: true
                }
                return state;

            case actions.DELETE_MULTIPLE_REMITTANCE_FAILED:
                customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    deleteRemittanceResponse: null,
                    deleteMultipleRemittanceLoading: false,
                }
                return state;

                case actions.DELETE_MULTIPLE_REMITTANCE_DONE:
                   customSuccessToast({ message: "Remittance Parties deleted" })
                state = {
                    ...state,
                    deleteRemittanceResponse: action.payload,
                    deleteMultipleRemittanceLoading: false,
                }
                return state;

            case actions.DELETE_MULTIPLE_REMITTANCE_START:
                state = {
                    ...state,
                    deleteRemittanceResponse: null,
                    deleteMultipleRemittanceLoading: true
                }
                return state;

            case actions.EDIT_REMITTANCE_FAILED:
                customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    editRemittanceLoading: false,
                    createRemittancePayload: action.payload,
                }
                return state;

                case actions.EDIT_REMITTANCE_DONE:
                state = {
                    ...state,
                    editRemittanceLoading: false,
                    createRemittancePayload: action.payload,
                }
                return state;

            case actions.EDIT_REMITTANCE_START:
                state = {
                    ...state,
                    editRemittanceLoading: true,
                    createRemittancePayload: null,
                }
                return state;

            // Allowances
            case actions.TOGGLE_ALLOWANCE_GROUP_ASSIGNMENT:
                state = {
                    ...state,
                    isAllowanceQuickAssignModalOpen: !state.isAllowanceQuickAssignModalOpen,
                    assignAllowancePayGroup: null,
                    selectedAllowance: null,
                }
                return state;
            
            case actions.SELECT_ALLOWANCE:
                state = {
                    ...state,
                    selectedAllowance: action.payload,
                }
                return state;

            case actions.TOGGLE_EDIT_ALLOWANCE_MODAL:
                state = {
                    ...state,
                    isEditAllowanceModalOpen: !state.isEditAllowanceModalOpen,
                }
                return state;

            case actions.GET_ALLOWANCE_FAILED:
                state = {
                    ...state,
                    getAllowanceLoading: false,
                    getAllowanceData: null,
                }
                return state;

                case actions.GET_ALLOWANCE_DONE:
                state = {
                    ...state,
                    getAllowanceLoading: false,
                    getAllowanceData: action.payload,
                }
                return state;

            case actions.GET_ALLOWANCE_START:
                state = {
                    ...state,
                    getAllowanceLoading: true,
                }
                return state;

            case actions.GET_ALLOWANCE_PAY_GROUPS_FAILED:
                state = {
                    ...state,
                    allowancePayGroupsLoading: false,
                    allowancePayGroupsData: null,
                }
                return state;

                case actions.GET_ALLOWANCE_PAY_GROUPS_DONE:
                state = {
                    ...state,
                    allowancePayGroupsLoading: false,
                    allowancePayGroupsData: action.payload,
                }
                return state;

            case actions.GET_ALLOWANCE_PAY_GROUPS_START:
                state = {
                    ...state,
                    allowancePayGroupsLoading: true,
                }
                return state;

                case actions.ASSIGN_ALLOWANCE_PAY_GROUPS_FAILED:
                    customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    assignAllowanceGroupsLoading: false,
                    assignAllowancePayGroup: null,
                }
                return state;

                case actions.ASSIGN_ALLOWANCE_PAY_GROUPS_DONE:
                state = {
                    ...state,
                    assignAllowanceGroupsLoading: false,
                    assignAllowancePayGroup: action.payload,
                }
                return state;

                case actions.ASSIGN_ALLOWANCE_PAY_GROUPS_START:
                state = {
                    ...state,
                    assignAllowanceGroupsLoading: true,
                    assignAllowancePayGroup: null,
                }
                return state;

                case actions.UNASSIGN_ALLOWANCE_PAY_GROUPS_FAILED:
                    customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    unassignAllowanceGroupsLoading: false,
                }
                return state;

                case actions.UNASSIGN_ALLOWANCE_PAY_GROUPS_DONE:
                customSuccessToast({ message: 'Allowance Pay groups updated' })
                state = {
                    ...state,
                    unassignAllowanceGroupsLoading: false,
                }
                return state;

                case actions.UNASSIGN_ALLOWANCE_PAY_GROUPS_START:
                customInfoToast({ message: 'Updating Pay groups' })
                state = {
                    ...state,
                    unassignAllowanceGroupsLoading: true,
                }
                return state;

            case actions.CREATE_ALLOWANCE_FAILED:
                customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    isAllowanceQuickAssignModalOpen: false,
                    selectedAllowance: null,
                    createAllowanceLoading: false,
                }
                return state;

                case actions.CREATE_ALLOWANCE_DONE:
                    customSuccessToast({ message: "Allowance created" })
                state = {
                    ...state,
                    selectedAllowance: action.payload,
                    isAllowanceQuickAssignModalOpen: true,
                    createAllowanceLoading: false,
                }
                return state;

            case actions.CREATE_ALLOWANCE_START:
                state = {
                    ...state,
                    isAllowanceQuickAssignModalOpen: false,
                    selectedAllowance: null,
                    createAllowanceLoading: true,
                }
                return state;

            case actions.DELETE_ALLOWANCE_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    deleteAllowanceLoading: false,
                    deleteAllowanceResponse: null,
                }
                return state;

                case actions.DELETE_ALLOWANCE_DONE:
                    customSuccessToast({ message: "Allowance deleted" })
                state = {
                    ...state,
                    deleteAllowanceResponse: action.payload,
                    deleteAllowanceLoading: false,
                }
                return state;

            case actions.DELETE_ALLOWANCE_START:
                state = {
                    ...state,
                    deleteAllowanceResponse: null,
                    deleteAllowanceLoading: true
                }
                return state;

            case actions.DELETE_MULTIPLE_ALLOWANCE_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    deleteAllowanceResponse: null,
                    deleteBatchAllowanceLoading: false,
                }
                return state;

                case actions.DELETE_MULTIPLE_ALLOWANCE_DONE:
                    customSuccessToast({ message: "Allowances deleted" })
                state = {
                    ...state,
                    deleteAllowanceResponse: action.payload,
                    deleteBatchAllowanceLoading: false,
                }
                return state;

            case actions.DELETE_MULTIPLE_ALLOWANCE_START:
                    customInfoToast({ message: "Deleting Allowances" })
                state = {
                    ...state,
                    deleteAllowanceResponse: null,
                    deleteBatchAllowanceLoading: true,
                }
                return state;

            case actions.TOGGLE_ALLOWANCES_MODAL:
                    return {
                        ...state,
                        isAllowanceModalOpen: !state.isAllowanceModalOpen
                    }

            case actions.SAVE_ALLOWANCE_DETAILS:
                return {
                    ...state,
                    allowanceDetails: action.payload
                }

                case actions.EDIT_ALLOWANCE_FAILED:
                    
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    editAllowanceLoading: false,
                }
                return state;

                case actions.EDIT_ALLOWANCE_DONE:
                    customSuccessToast({ message: "Allowance updated" })
                state = {
                    ...state,
                    editAllowanceLoading: false,
                }
                return state;

            case actions.EDIT_ALLOWANCE_START:
                state = {
                    ...state,
                    editAllowanceLoading: true
                }
                return state;

                // Benefits
            case actions.TOGGLE_BENEFIT_GROUP_ASSIGNMENT:
                state = {
                    ...state,
                    isBenefitQuickAssignModalOpen: !state.isBenefitQuickAssignModalOpen,
                    assignBenefitsPayGroup: null,
                    selectedBenefit: null,
                }
                return state;
                
            case actions.SELECT_BENEFIT:
                state = {
                    ...state,
                    selectedBenefit: action.payload,
                }
                return state;

            case actions.TOGGLE_EDIT_BENEFIT_MODAL:
                state = {
                    ...state,
                    isEditBenefitModalOopen: !state.isEditBenefitModalOopen
                }
                return state;

            case actions.EDIT_BENEFIT_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    editBenefitLoading: false,
                }
                return state;

                case actions.EDIT_BENEFIT_DONE:
                    customSuccessToast({ message: "Benefit updated" })
                state = {
                    ...state,
                    editBenefitLoading: false,
                }
                return state;

            case actions.EDIT_BENEFIT_START:
                state = {
                    ...state,
                    editBenefitLoading: true
                }
                return state;

            case actions.DELETE_BENEFITS_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    deleteBenefitsResponse: null,
                    deleteBenefitsLoading: false,
                }
                return state;

            case actions.DELETE_BENEFITS_DONE:
                customSuccessToast({ message: "Benefit deleted" })
            state = {
                ...state,
                deleteBenefitsResponse: action.payload,
                deleteBenefitsLoading: false,
            }
            return state;

            case actions.DELETE_BENEFITS_START:
                state = {
                    ...state,
                    deleteBenefitsResponse: null,
                    deleteBenefitsLoading: true,
                }
                return state;

            case actions.DELETE_MULTIPLE_BENEFITS_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    deleteBatchBenefitsLoading: false,
                    deleteBenefitsResponse: null,
                }
                return state;

                case actions.DELETE_MULTIPLE_BENEFITS_DONE:
                    customSuccessToast({ message: "Benefits deleted" })
                state = {
                    ...state,
                    deleteBenefitsResponse: action.payload,
                    deleteBatchBenefitsLoading: false,
                }
                return state;

            case actions.DELETE_MULTIPLE_BENEFITS_START:
                    customInfoToast({ message: "Deleting Benefits" })
                state = {
                    ...state,
                    deleteBatchBenefitsLoading: true,
                    deleteBenefitsResponse: null,
                }
                return state;

            case actions.CREATE_BENEFITS_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    isBenefitQuickAssignModalOpen: false,
                    createBenefitsLoading: false,
                }
                return state;

                case actions.CREATE_BENEFITS_DONE:
                    customSuccessToast({ message: "Benefit created" })
                state = {
                    ...state,
                    selectedBenefit: action.payload,
                    isBenefitQuickAssignModalOpen: true,
                    createBenefitsLoading: false,
                }
                return state;

                case actions.CREATE_BENEFITS_START:
                state = {
                    ...state,
                    isBenefitQuickAssignModalOpen: false,
                    createBenefitsLoading: true,
                }
                return state;

                case actions.GET_BENEFITS_FAILED:
                    
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    getBenefitsLoading: false,
                    getBenefitsData: null,
                }
                return state;

                case actions.GET_BENEFITS_DONE:
                state = {
                    ...state,
                    getBenefitsLoading: false,
                    getBenefitsData: action.payload,
                }
                return state;

                case actions.GET_BENEFITS_START:
                state = {
                    ...state,
                    getBenefitsLoading: true,
                }
                return state;

                case actions.GET_BENEFIT_PAY_GROUPS_FAILED:
                    
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    benefitPayGroupsLoading: false,
                    benefitPayGroupsData: null,
                }
                return state;

                case actions.GET_BENEFIT_PAY_GROUPS_DONE:
                state = {
                    ...state,
                    benefitPayGroupsLoading: false,
                    benefitPayGroupsData: action.payload,
                }
                return state;

                case actions.GET_BENEFIT_PAY_GROUPS_START:
                state = {
                    ...state,
                    benefitPayGroupsLoading: true,
                }
                return state;

                case actions.ASSIGN_BENEFIT_PAY_GROUPS_FAILED:
                    
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    assignBenefitGroupsLoading: false,
                    assignBenefitsPayGroup: null,
                }
                return state;

                case actions.ASSIGN_BENEFIT_PAY_GROUPS_DONE:
                state = {
                    ...state,
                    assignBenefitGroupsLoading: false,
                    assignBenefitsPayGroup: action.payload,
                }
                return state;

                case actions.ASSIGN_BENEFIT_PAY_GROUPS_START:
                state = {
                    ...state,
                    assignBenefitGroupsLoading: true,
                    assignBenefitsPayGroup: null,
                }
                return state;

                case actions.UNASSIGN_BENEFIT_PAY_GROUPS_FAILED:
                    
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    unassignBenefitGroupsLoading: false,
                }
                return state;

                case actions.UNASSIGN_BENEFIT_PAY_GROUPS_DONE:
                customSuccessToast({ message: 'Benefit Pay groups updated' })
                state = {
                    ...state,
                    unassignBenefitGroupsLoading: false,
                }
                return state;

                    case actions.UNASSIGN_BENEFIT_PAY_GROUPS_START:
                    customInfoToast({ message: 'Removing Pay groups' })
                state = {
                    ...state,
                    unassignBenefitGroupsLoading: true,
                }
                return state;

            case actions.TOGGLE_BENEFITS_MODAL:
                    return {
                        ...state,
                        isBenefitsModalOpen: !state.isBenefitsModalOpen
                    }

            case actions.SAVE_BENEFIT_DETAILS:
                return {
                    ...state,
                    benefitDetails: action.payload
                }

            // Pay Structure
            case actions.CLEAR_PAY_STRUCTURE_ITEMS:
                return {
                ...state,
                payStructurePayload: null,
                customDeductions: [],
                selectedDeduction: null,
                salaryBreakdown: null,
                selectedStructure: null,
                payStructureFormAction: {},
            }

            case actions.SAVE_PAY_STRUCTURE_PAYLOAD:
                return {
                ...state,
                payStructurePayload: action.payload
            }

            case actions.GET_PAY_STRUCTURE_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    getPayStructureLoading: false,
                    getPayStructureData: null,
                }
                return state;

                case actions.GET_PAY_STRUCTURE_DONE:
                state = {
                    ...state,
                    getPayStructureLoading: false,
                    getPayStructureData: action.payload,
                }
                return state;

            case actions.GET_PAY_STRUCTURE_START:
                state = {
                    ...state,
                    getPayStructureLoading: true,
                }
                return state;

            case actions.GET_STRUCTURE_PAY_GROUPS_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    structurePayGroupsLoading: false,
                    structurePayGroupsData: null,
                }
                return state;

                case actions.GET_STRUCTURE_PAY_GROUPS_DONE:
                state = {
                    ...state,
                    structurePayGroupsLoading: false,
                    structurePayGroupsData: action.payload,
                }
                return state;

            case actions.GET_STRUCTURE_PAY_GROUPS_START:
                state = {
                    ...state,
                    structurePayGroupsLoading: true,
                }
                return state;

            case actions.ASSIGN_STRUCTURE_PAY_GROUPS_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    assignStructureGroupsLoading: false,
                    assignStructurePayGroup: null,
                }
                return state;

            case actions.ASSIGN_STRUCTURE_PAY_GROUPS_DONE:
            state = {
                ...state,
                assignStructureGroupsLoading: false,
                assignStructurePayGroup: action.payload,
            }
            return state;

            case actions.ASSIGN_STRUCTURE_PAY_GROUPS_START:
                state = {
                    ...state,
                    assignStructureGroupsLoading: true,
                    assignStructurePayGroup: null,
                }
                return state;

            case actions.UNASSIGN_STRUCTURE_PAY_GROUPS_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    unassignStructureGroupsLoading: false,
                }
                return state;

            case actions.UNASSIGN_STRUCTURE_PAY_GROUPS_DONE:
                customSuccessToast({ message: "Pay Groups deleted" })
            state = {
                ...state,
                unassignStructureGroupsLoading: false,
            }
            return state;

            case actions.UNASSIGN_STRUCTURE_PAY_GROUPS_START:
                customInfoToast({ message: "Deleting" })
                state = {
                    ...state,
                    unassignStructureGroupsLoading: true,
                }
                return state;

            case actions.DELETE_PAY_STRUCTURE_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    deletePayStructureLoading: false,
                    deletePayStructureResponse: null,
                    payStructureFormAction: action.payload,
                }
                return state;

                case actions.DELETE_PAY_STRUCTURE_DONE:
                    customSuccessToast({ message: "Pay structure deleted" })
                state = {
                    ...state,
                    deletePayStructureLoading: false,
                    deletePayStructureResponse: action.payload,
                    payStructureFormAction: action.payload,
                }
                return state;

            case actions.DELETE_PAY_STRUCTURE_START:
                state = {
                    ...state,
                    deletePayStructureLoading: true,
                    deletePayStructureResponse: null,
                    payStructureFormAction: {},
                }
                return state;

            case actions.DELETE_MULTIPLE_STRUCTURES_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    deletePayStructureResponse: null,
                    deleteMultipleStructuresLoading: false,
                    payStructureFormAction: action.payload,
                }
                return state;

                case actions.DELETE_MULTIPLE_STRUCTURES_DONE:
                    customSuccessToast({ message: "Pay structures deleted" })
                state = {
                    ...state,
                    deleteMultipleStructuresLoading: false,
                    deletePayStructureResponse: action.payload,
                    payStructureFormAction: action.payload,
                }
                return state;

            case actions.DELETE_MULTIPLE_STRUCTURES_START:
                customInfoToast({ message: "Deleting Pay structures" })
                state = {
                    ...state,
                    deletePayStructureResponse: null,
                    deleteMultipleStructuresLoading: true,
                    payStructureFormAction: action.payload,
                }
                return state;

            case actions.CREATE_PAY_STRUCTURE_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    createPayStructureResponse: null,
                    isPayStructureGroupsModalOpen: false,
                    createPayStructureLoading: false,
                    payStructureFormAction: {},
                }
                return state;

                case actions.CREATE_PAY_STRUCTURE_DONE:
                    customSuccessToast({ message: "Pay structure created" })
                state = {
                    ...state,
                    createPayStructureResponse: action.payload,
                    isPayStructureGroupsModalOpen: true,
                    createPayStructureLoading: false,
                    payStructureFormAction: action.payload,
                }
                return state;

            case actions.CREATE_PAY_STRUCTURE_START:
                state = {
                    ...state,
                    isPayStructureGroupsModalOpen: false,
                    createPayStructureResponse: null,
                    createPayStructureLoading: true,
                    payStructureFormAction: action.payload,
                }
                return state;

                case actions.SELECT_PAY_STRUCTURE_RESPONSE:
                    state = {
                        ...state,
                    createPayStructureResponse: action.payload,
                }
                return state;

                case actions.TOGGLE_PAY_STRUCTURE_GROUPS_MODAL:
                    state = {
                        ...state,
                    isPayStructureGroupsModalOpen: !state.isPayStructureGroupsModalOpen,
                    createPayStructureResponse: null,
                    assignStructurePayGroup: null,
                }
                return state;

            case actions.UPDATE_PAY_STRUCTURE_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    editPayStructureLoading: false,
                    payStructureFormAction: action.payload,
                }
                return state;

                case actions.UPDATE_PAY_STRUCTURE_DONE:
                    customSuccessToast({ message: "Pay structure updated" })
                state = {
                    ...state,
                    editPayStructureLoading: false,
                    payStructureFormAction: action.payload,
                }
                return state;

            case actions.UPDATE_PAY_STRUCTURE_START:
                customInfoToast({ message: "Saving edits" })
                state = {
                    ...state,
                    editPayStructureLoading: true,
                    payStructureFormAction: {},
                }
                return state;

            case actions.SELECT_PAY_STRUCTURE:
                state = {
                    ...state,
                    selectedStructure: action.payload,
                    customDeductions: action.payload.deductions.custom_deductions || []
                }
                return state;

            case actions.SAVE_BREAKDOWN_PAYLOAD:
                state = {
                    ...state,
                    salaryBreakdown: action.payload
                }
                return state;

            case actions.ADD_CUSTOM_DEDUCTION:
                customSuccessToast({ message: 'Custom deduction saved' })
                state = {
                    ...state,
                    customDeductions: [...state.customDeductions, action.payload]
                }
                return state;


            case actions.TOGGLE_EDIT_DEDUCTION_MODAL:
                return {
                    ...state,
                    isEditDeductionOpen: !state.isEditDeductionOpen
                }

            case actions.SAVE_EDITED_CUSTOM_DEDUCTION:
                const uniqueItems = state.customDeductions.filter((item) => item.id !== action.payload.id)
                customSuccessToast({ message: 'Changes saved' })
                state = {
                    ...state,
                    customDeductions: [...uniqueItems, action.payload]
                }
                return state;

            case actions.SELECT_CUSTOM_DEDUCTION:
                state = {
                    ...state,
                    selectedDeduction: action.payload
                }
                return state;

            case actions.DELETE_CUSTOM_DEDUCTION:
                const filteredDeductions = state.customDeductions.filter((item) => item.id !== action.payload)
                state = {
                    ...state,
                    customDeductions: filteredDeductions
                }
                return state;

            case actions.TOGGLE_PFA_SETUP_MODAL:
                return {
                    ...state,
                    isPfaModalOpen: !state.isPfaModalOpen
                }

            // Pay Schedule
            case actions.SELECT_PAY_SCHEDULE:
                return {
                    ...state,
                    selectedPaySchedule: action.payload
                }

            case actions.EDIT_PAY_SCHEDULE_FAILED:
                    
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    editPayScheduleLoading: false,
                    payScheduleFormActionLoading: false,
                    payScheduleFormAction: action.payload,
                }
                return state;

            case actions.EDIT_PAY_SCHEDULE_DONE:
                state = {
                    ...state,
                    editPayScheduleLoading: false,
                    payScheduleFormActionLoading: false,
                    payScheduleFormAction: action.payload,
                }
                return state;

            case actions.EDIT_PAY_SCHEDULE_START:
                state = {
                    ...state,
                    editPayScheduleLoading: true,
                    payScheduleFormActionLoading: true,
                    payScheduleFormAction: {},
                }
                return state;

            case actions.GET_PAY_SCHEDULE_FAILED:
                    
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    getPayScheduleLoading: false,
                    getPayScheduleData: null,
                }
                return state;

            case actions.GET_PAY_SCHEDULE_DONE:
                state = {
                    ...state,
                    getPayScheduleLoading: false,
                    getPayScheduleData: action.payload,
                }
                return state;

            case actions.GET_PAY_SCHEDULE_START:
                state = {
                    ...state,
                    getPayScheduleLoading: true
                }
                return state;

            case actions.CREATE_PAY_SCHEDULE_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    createPayScheduleLoading: false,
                    payScheduleFormActionLoading: false,
                    payScheduleFormAction: {},
                }
                return state;

                case actions.CREATE_PAY_SCHEDULE_DONE:
                state = {
                    ...state,
                    createPayScheduleLoading: false,
                    payScheduleFormActionLoading: false,
                    payScheduleFormAction: action.payload,
                }
                return state;

            case actions.CREATE_PAY_SCHEDULE_START:
                state = {
                    ...state,
                    createPayScheduleLoading: true,
                    payScheduleFormActionLoading: true,
                    payScheduleFormAction: action.payload,
                }
                return state;

            case actions.DELETE_PAY_SCHEDULE_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    deletePayScheduleResponse: null,
                    deletePayScheduleLoading: false,
                }
                return state;

            case actions.DELETE_PAY_SCHEDULE_DONE:
                customSuccessToast({ message: "Pay schedule deleted"})
                state = {
                    ...state,
                    deletePayScheduleResponse: action.payload,
                    deletePayScheduleLoading: false,
                }
                return state;

            case actions.DELETE_PAY_SCHEDULE_START:
            customInfoToast({ message: "Deleting Pay schedule" })
            state = {
                    ...state,
                    deletePayScheduleResponse: null,
                    deletePayScheduleLoading: true
            }
            return state;

            case actions.DELETE_MULTIPLE_SCHEDULES_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    deletePayScheduleResponse: null,
                    deleteMultipleSchedulesLoading: false,
                }
                return state;

            case actions.DELETE_MULTIPLE_SCHEDULES_DONE:
                customSuccessToast({ message: "Pay schedules deleted"})
                state = {
                    ...state,
                    deletePayScheduleResponse: action.payload,
                    deleteMultipleSchedulesLoading: false,
                }
                return state;

            case actions.DELETE_MULTIPLE_SCHEDULES_START:
            customInfoToast({ message: "Deleting Pay schedules" })
                state = {
                    ...state,
                    deletePayScheduleResponse: null,
                    deleteMultipleSchedulesLoading: true
                }
                return state;

            // Pay groups
            case actions.TOGGLE_PAY_GROUP_EMPLOYEES_ASSIGNMENT:
                state = {
                    ...state,
                    isPayGroupQuickAssignModalOpen: !state.isPayGroupQuickAssignModalOpen,
                }
                return state;
            
            case actions.SELECT_PAY_GROUP:
                state = {
                    ...state,
                    selectedPayGroup: action.payload
                }
                return state;

            case actions.EDIT_PAY_GROUP_FAILED:
                    
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    editPayGroupLoading: false,
                }
                return state;

            case actions.EDIT_PAY_GROUP_DONE:
                customSuccessToast({ message: "Pay Group updated" })
                state = {
                    ...state,
                    editPayGroupLoading: false,
                }
                return state;

            case actions.EDIT_PAY_GROUP_START:
                state = {
                    ...state,
                    editPayGroupLoading: true
                }
                return state;

            case actions.GET_PAY_GROUP_FAILED:
                    
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    getPayGroupLoading: false,
                    getPayGroupData: null,
                }
                return state;

            case actions.GET_PAY_GROUP_DONE:
                state = {
                    ...state,
                    getPayGroupLoading: false,
                    getPayGroupData: action.payload,
                }
                return state;

            case actions.GET_PAY_GROUP_START:
                state = {
                    ...state,
                    getPayGroupLoading: true
                }
                return state;
            case actions.GET_A_PAY_GROUP_FAILED:
                    
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    isSinglePayGroupLoading: false,
                    singlePayGroupData: null,
                }
                return state;

            case actions.GET_A_PAY_GROUP_DONE:
                state = {
                    ...state,
                    isSinglePayGroupLoading: false,
                    singlePayGroupData: action.payload,
                }
                return state;

            case actions.GET_A_PAY_GROUP_START:
                state = {
                    ...state,
                    isSinglePayGroupLoading: true
                }
                return state;
            case actions.REMOVE_EMPLOYEE_FROM_GROUP_FAILED:
                    
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    isRemovingSinglePayGroupLoading: false,
                    removeSinglePaygroupResponse: null,
                }
                return state;

            case actions.REMOVE_EMPLOYEE_FROM_GROUP_DONE:
                state = {
                    ...state,
                    isRemovingSinglePayGroupLoading: false,
                    removeSinglePaygroupResponse: action.payload,
                }
                return state;

            case actions.REMOVE_EMPLOYEE_FROM_GROUP_START:
                state = {
                    ...state,
                    isRemovingSinglePayGroupLoading: true,
                    removeSinglePaygroupResponse: null,
                }
                return state;

            case actions.GET_PAY_GROUP_EMPLOYEES_FAILED:
                    
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    payGroupStaffLoading: false,
                    payGroupStaffData: null,
                }
                return state;

            case actions.GET_PAY_GROUP_EMPLOYEES_DONE:
                state = {
                    ...state,
                    payGroupStaffLoading: false,
                    payGroupStaffData: action.payload,
                }
                return state;

            case actions.GET_PAY_GROUP_EMPLOYEES_START:
                state = {
                    ...state,
                    payGroupStaffLoading: true
                }
                return state;

            case actions.UPDATE_PAY_GROUP_EMPLOYEES_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    updatePayGroupEmployeesLoading: false,
                }
                return state;

                case actions.UPDATE_PAY_GROUP_EMPLOYEES_DONE:
                    customSuccessToast({ message: "Pay group employees updated" })
                state = {
                    ...state,
                    updatePayGroupEmployeesLoading: false,
                    paygroupEmployeeUpdateSuccess: true,
                }
                return state;
            case actions.RESET_PAY_GROUP_EMPLOYEES_ASSIGNMENT:
                state = {
                    ...state,
                    paygroupEmployeeUpdateSuccess: false,
                }
                return state;

            case actions.UPDATE_PAY_GROUP_EMPLOYEES_START:
                state = {
                    ...state,
                    updatePayGroupEmployeesLoading: true
                }
                return state;

            case actions.CREATE_PAY_GROUP_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    createPayGroupLoading: false,
                }
                return state;

                case actions.CREATE_PAY_GROUP_DONE:
                customSuccessToast({ message: "Pay group created" })
                state = {
                    ...state,
                    createPayGroupLoading: false,
                    createdPayGroup: action.payload,
                }
                return state;

            case actions.CREATE_PAY_GROUP_START:
                state = {
                    ...state,
                    createPayGroupLoading: true
                }
                return state;

            case actions.DELETE_PAY_GROUP_EMPLOYEE_FAILED:
                
customErrorToast({ message: action.payload.message })
            state = {
                ...state,
                deletePayGroupStaffLoading: false,
            }
            return state;

            case actions.DELETE_PAY_GROUP_EMPLOYEE_DONE:
            customSuccessToast({ message: "Employee deleted" })
                state = {
                    ...state,
                    deletePayGroupStaffLoading: false,
                }
                return state;

            case actions.DELETE_PAY_GROUP_EMPLOYEE_START:
                state = {
                    ...state,
                    deletePayGroupStaffLoading: true
                }
                return state;

            case actions.DELETE_PAY_GROUP_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    deletePayGroupResponse: null,
                    deletePayGroupLoading: false,
                }
                return state;

                case actions.DELETE_PAY_GROUP_DONE:
                    customSuccessToast({ message: "Pay group deleted" })
                state = {
                    ...state,
                    deletePayGroupResponse: action.payload,
                    deletePayGroupLoading: false,
                }
                return state;

            case actions.DELETE_PAY_GROUP_START:
                state = {
                    ...state,
                    deletePayGroupResponse: null,
                    deletePayGroupLoading: true
                }
                return state;

            case actions.DELETE_MULTIPLE_PAY_GROUPS_FAILED:
                
customErrorToast({ message: action.payload.message })
                state = {
                    ...state,
                    deletePayGroupResponse: null,
                    deleteMultiplePayGroupsLoading: false,
                }
                return state;

                case actions.DELETE_MULTIPLE_PAY_GROUPS_DONE:
                    customSuccessToast({ message: "Pay groups deleted" })
                state = {
                    ...state,
                    deletePayGroupResponse: action.payload,
                    deleteMultiplePayGroupsLoading: false,
                }
                return state;

            case actions.DELETE_MULTIPLE_PAY_GROUPS_START:
                state = {
                    ...state,
                    deletePayGroupResponse: null,
                    deleteMultiplePayGroupsLoading: true
                }
                return state;

            case actions.SAVE_PAY_GROUP_DETAILS:
                return {
                    ...state,
                    payGroupDetails: action.payload
                }

            case actions.CLEAR_PAY_GROUP_INFO:
                return {
                    ...state,
                    payGroupDetails: null
                }

        default:
            return state;

    }
}