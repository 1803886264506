import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customInfoToast } from "utils/customToast";

const initialState = {
    auditTrailLoading: false,
    auditTrail: {},
    auditTrailDownloadLoading: false,
    auditTrailDownload: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_AUDIT_TRAIL:
            state = {
                ...state,
                auditTrailLoading: false,
                auditTrail: action.payload,
            }
            return state;


        case actions.GET_AUDIT_TRAIL_START:
            state = {
                ...state,
                auditTrailLoading: true,
                auditTrail: {},
            }
            return state;


        case actions.GET_AUDIT_TRAIL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                auditTrailLoading: false,
                auditTrail: {},
            }
            return state;


        case actions.DOWNLOAD_GET_AUDIT_TRAIL:
            state = {
                ...state,
                auditTrailDownloadLoading: false,
                auditTrailDownload: action.payload,
            }
            return state;


        case actions.DOWNLOAD_GET_AUDIT_TRAIL_START:
            customInfoToast({ message: "Downloading Data..." });
            state = {
                ...state,
                auditTrailDownloadLoading: true,
                auditTrailDownload: {},
            }
            return state;


        case actions.DOWNLOAD_GET_AUDIT_TRAIL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                auditTrailDownloadLoading: false,
                auditTrailDownload: {},
            }
            return state;


        default:
            return state;
    }
}