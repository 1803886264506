export const GET_WITHDRAWALS_METRICS = "GET_WITHDRAWALS_METRICS";
export const GET_WITHDRAWALS_METRICS_START = "GET_WITHDRAWALS_METRICS_START";
export const GET_WITHDRAWALS_METRICS_FAILED = "GET_WITHDRAWALS_METRICS_FAILED";

export const GET_PREV_MONTH_WITHDRAWALS_START = "GET_PREV_MONTH_WITHDRAWALS_START";
export const GET_PREV_MONTH_WITHDRAWALS_DONE = "GET_PREV_MONTH_WITHDRAWALS_DONE";
export const GET_PREV_MONTH_WITHDRAWALS_FAILED = "GET_PREV_MONTH_WITHDRAWALS_FAILED";

export const GET_ALL_WITHDRAWALS = "GET_ALL_WITHDRAWALS";
export const GET_ALL_WITHDRAWALS_START = "GET_ALL_WITHDRAWALS_START";
export const GET_ALL_WITHDRAWALS_FAILED = "GET_ALL_WITHDRAWALS_FAILED";

export const DOWNLOAD_GET_ALL_WITHDRAWALS = "DOWNLOAD_GET_ALL_WITHDRAWALS";
export const DOWNLOAD_GET_ALL_WITHDRAWALS_START = "DOWNLOAD_GET_ALL_WITHDRAWALS_START";
export const DOWNLOAD_GET_ALL_WITHDRAWALS_FAILED = "DOWNLOAD_GET_ALL_WITHDRAWALS_FAILED";

export const APPROVE_WITHDRAWAL = "APPROVE_WITHDRAWAL";
export const APPROVE_WITHDRAWAL_START = "APPROVE_WITHDRAWAL_START";
export const APPROVE_WITHDRAWAL_FAILED = "APPROVE_WITHDRAWAL_FAILED";

export const REJECT_WITHDRAWAL = "REJECT_WITHDRAWAL";
export const REJECT_WITHDRAWAL_START = "REJECT_WITHDRAWAL_START";
export const REJECT_WITHDRAWAL_FAILED = "REJECT_WITHDRAWAL_FAILED";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";

export const GET_WITHDRAWAL_REPORT = "GET_WITHDRAWAL_REPORT";
export const GET_WITHDRAWAL_REPORT_START = "GET_WITHDRAWAL_REPORT_START";
export const GET_WITHDRAWAL_REPORT_FAILED = "GET_WITHDRAWAL_REPORT_FAILED";
