
export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const GET_ALL_GROUPS_START = "GET_ALL_GROUPS_START";
export const GET_ALL_GROUPS_FAILED = "GET_ALL_GROUPS_FAILED";

export const GET_GROUPS_B2B = "GET_GROUPS_B2B";
export const GET_GROUPS_B2B_START = "GET_GROUPS_B2B_START";
export const GET_GROUPS_B2B_FAILED = "GET_GROUPS_B2B_FAILED";

export const GET_ALL_GROUP_EMPLOYEES = "GET_ALL_GROUP_EMPLOYEES";
export const GET_ALL_GROUP_EMPLOYEES_START = "GET_ALL_GROUP_EMPLOYEES_START";
export const GET_ALL_GROUP_EMPLOYEES_FAILED = "GET_ALL_GROUP_EMPLOYEES_FAILED";

export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_START = "CREATE_GROUP_START";
export const CREATE_GROUP_FAILED = "CREATE_GROUP_FAILED";

export const CREATE_GROUP_B2B = "CREATE_GROUP_B2B";
export const CREATE_GROUP_B2B_START = "CREATE_GROUP_B2B_START";
export const CREATE_GROUP_B2B_FAILED = "CREATE_GROUP_B2B_FAILED";

export const GET_GROUP_ASSIGNED_EMPLOYEES = "GET_GROUP_ASSIGNED_EMPLOYEES";
export const GET_GROUP_ASSIGNED_EMPLOYEES_START = "GET_GROUP_ASSIGNED_EMPLOYEES_START";
export const GET_GROUP_ASSIGNED_EMPLOYEES_FAILED = "GET_GROUP_ASSIGNED_EMPLOYEES_FAILED";

export const ASSIGN_EMPLOYEE_TO_GROUP = "ASSIGN_EMPLOYEE_TO_GROUP";
export const ASSIGN_EMPLOYEE_TO_GROUP_START = "ASSIGN_EMPLOYEE_TO_GROUP_START";
export const ASSIGN_EMPLOYEE_TO_GROUP_FAILED = "ASSIGN_EMPLOYEE_TO_GROUP_FAILED";

export const ASSIGN_SELECTION_TO_GROUP_START = "ASSIGN_SELECTION_TO_GROUP_START";
export const ASSIGN_SELECTION_TO_GROUP_SUCCESS = "ASSIGN_SELECTION_TO_GROUP_SUCCESS";
export const ASSIGN_SELECTION_TO_GROUP_FAILED = "ASSIGN_SELECTION_TO_GROUP_FAILED";
export const ASSIGN_SELECTION_TO_GROUP_RESET = "ASSIGN_SELECTION_TO_GROUP_RESET";

export const DOWNLOAD_GROUPS_START = "DOWNLOAD_GROUPS_START";
export const DOWNLOAD_GROUPS_DONE = "DOWNLOAD_GROUPS_DONE";
export const DOWNLOAD_GROUPS_FAILED = "DOWNLOAD_GROUPS_FAILED";
export const SELECT_GROUP = "SELECT_GROUP";

export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_START = "UPDATE_GROUP_START";
export const UPDATE_GROUP_FAILED = "UPDATE_GROUP_FAILED";

export const UPDATE_GROUP_B2B = "UPDATE_GROUP_B2B";
export const UPDATE_GROUP_B2B_START = "UPDATE_GROUP_B2B_START";
export const UPDATE_GROUP_B2B_FAILED = "UPDATE_GROUP_B2B_FAILED";

export const ADD_EMPLOYEES_FROM_GROUP_B2B = "ADD_EMPLOYEES_FROM_GROUP_B2B";
export const ADD_EMPLOYEES_FROM_GROUP_B2B_START = "ADD_EMPLOYEES_FROM_GROUP_B2B_START";
export const ADD_EMPLOYEES_FROM_GROUP_B2B_FAILED = "ADD_EMPLOYEES_FROM_GROUP_B2B_FAILED";

export const REMOVE_EMPLOYEES_FROM_GROUP_B2B = "REMOVE_EMPLOYEES_FROM_GROUP_B2B";
export const REMOVE_EMPLOYEES_FROM_GROUP_B2B_START = "REMOVE_EMPLOYEES_FROM_GROUP_B2B_START";
export const REMOVE_EMPLOYEES_FROM_GROUP_B2B_FAILED = "REMOVE_EMPLOYEES_FROM_GROUP_B2B_FAILED";

export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_START = "DELETE_GROUP_START";
export const DELETE_GROUP_FAILED = "DELETE_GROUP_FAILED";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";