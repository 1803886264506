import React from 'react';

const CustomToastIconMap = {
    success: (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect opacity="0.3" width="24" height="24" rx="6" fill="#93D3B6" />
            <path
                d="M8.5 12.5L10.5 14.5L15.5 9.5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    warning: (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                opacity="0.3"
                y="0.5"
                width="24"
                height="24"
                rx="6"
                fill="#FFECCA"
            />
            <path
                d="M12 9.5V14.5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <circle cx="12" cy="17.5" r="1" fill="white" />
        </svg>
    ),
    error: (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                opacity="0.3"
                y="0.5"
                width="24"
                height="24"
                rx="6"
                fill="#F6CDC9"
            />
            <path
                d="M15 9.50002L9 15.5M8.99997 9.5L14.9999 15.5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    ),
    info: (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                opacity="0.3"
                y="0.5"
                width="24"
                height="24"
                rx="6"
                fill="#C7D5EE"
            />
            <path
                d="M12 16.5V11.5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <circle
                cx="1"
                cy="1"
                r="1"
                transform="matrix(1 0 0 -1 11 9.5)"
                fill="white"
            />
        </svg>
    ),
};

const CustomToastSpinnerIconMap = {
    success: (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM4.2 12C4.2 16.3078 7.69218 19.8 12 19.8C16.3078 19.8 19.8 16.3078 19.8 12C19.8 7.69218 16.3078 4.2 12 4.2C7.69218 4.2 4.2 7.69218 4.2 12Z"
                fill="#D9D9D9"
                fillOpacity="0.2"
            />
            <path
                className="custom-toast-spinner"
                d="M12 21.9C12 23.0598 12.9473 24.0187 14.0894 23.8168C16.496 23.3912 18.7336 22.237 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.7336 1.763 16.496 0.608785 14.0894 0.183243C12.9473 -0.018698 12 0.940203 12 2.1C12 3.2598 12.9568 4.17245 14.0748 4.48099C15.3644 4.83689 16.5528 5.52193 17.5154 6.48457C18.9782 7.94735 19.8 9.93131 19.8 12C19.8 14.0687 18.9782 16.0527 17.5154 17.5154C16.5528 18.4781 15.3644 19.1631 14.0748 19.519C12.9568 19.8275 12 20.7402 12 21.9Z"
                fill="#20F07B"
            />
        </svg>
    ),
    warning: (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5ZM4.2 12.5C4.2 16.8078 7.69218 20.3 12 20.3C16.3078 20.3 19.8 16.8078 19.8 12.5C19.8 8.19218 16.3078 4.7 12 4.7C7.69218 4.7 4.2 8.19218 4.2 12.5Z"
                fill="#D9D9D9"
                fillOpacity="0.2"
            />
            <path
                className="custom-toast-spinner"
                d="M12 2.6C12 1.4402 12.9472 0.481352 14.0893 0.683284C14.9456 0.834687 15.785 1.07908 16.5922 1.41345C18.0481 2.0165 19.371 2.90042 20.4853 4.01472C21.5996 5.12902 22.4835 6.45189 23.0866 7.9078C23.4209 8.71503 23.6653 9.55439 23.8167 10.4107C24.0186 11.5528 23.0598 12.5 21.9 12.5C20.7402 12.5 19.8275 11.5433 19.519 10.4253C19.4338 10.1164 19.3294 9.81236 19.2063 9.51507C18.8143 8.56873 18.2397 7.70886 17.5154 6.98457C16.7911 6.26027 15.9313 5.68573 14.9849 5.29374C14.6876 5.1706 14.3836 5.06623 14.0747 4.98099C12.9567 4.6725 12 3.7598 12 2.6Z"
                fill="#FFDDAA"
            />
        </svg>
    ),
    error: (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5ZM4.2 12.5C4.2 16.8078 7.69218 20.3 12 20.3C16.3078 20.3 19.8 16.8078 19.8 12.5C19.8 8.19218 16.3078 4.7 12 4.7C7.69218 4.7 4.2 8.19218 4.2 12.5Z"
                fill="#D9D9D9"
                fillOpacity="0.2"
            />
            <path
                className="custom-toast-spinner"
                d="M12 22.4C12 23.5598 12.9473 24.5187 14.0894 24.3168C16.496 23.8912 18.7336 22.737 20.4853 20.9853C22.7357 18.7348 24 15.6826 24 12.5C24 9.3174 22.7357 6.26516 20.4853 4.01472C18.7336 2.263 16.496 1.10879 14.0894 0.683243C12.9473 0.481302 12 1.4402 12 2.6C12 3.7598 12.9568 4.67245 14.0748 4.98099C15.3644 5.33689 16.5528 6.02193 17.5154 6.98457C18.9782 8.44735 19.8 10.4313 19.8 12.5C19.8 14.5687 18.9782 16.5527 17.5154 18.0154C16.5528 18.9781 15.3644 19.6631 14.0748 20.019C12.9568 20.3275 12 21.2402 12 22.4Z"
                fill="#FFACAC"
            />
        </svg>
    ),
    info: (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5ZM4.2 12.5C4.2 16.8078 7.69218 20.3 12 20.3C16.3078 20.3 19.8 16.8078 19.8 12.5C19.8 8.19218 16.3078 4.7 12 4.7C7.69218 4.7 4.2 8.19218 4.2 12.5Z"
                fill="#D9D9D9"
                fillOpacity="0.2"
            />
            <path
                className="custom-toast-spinner"
                d="M12 22.4C12 23.5598 12.9473 24.5187 14.0894 24.3168C16.496 23.8912 18.7336 22.737 20.4853 20.9853C22.7357 18.7348 24 15.6826 24 12.5C24 9.3174 22.7357 6.26516 20.4853 4.01472C18.7336 2.263 16.496 1.10879 14.0894 0.683243C12.9473 0.481302 12 1.4402 12 2.6C12 3.7598 12.9568 4.67245 14.0748 4.98099C15.3644 5.33689 16.5528 6.02193 17.5154 6.98457C18.9782 8.44735 19.8 10.4313 19.8 12.5C19.8 14.5687 18.9782 16.5527 17.5154 18.0154C16.5528 18.9781 15.3644 19.6631 14.0748 20.019C12.9568 20.3275 12 21.2402 12 22.4Z"
                fill="#72B3B8"
            />
        </svg>
    ),
};

const CustomToastTitleMap = {
    success: 'Success Message',
    warning: 'Warning Message',
    error: 'Error Message',
    info: 'System Notification',
};

const CustomToastClassNameMap = {
    success: 'custom-toast-success-wrapper ',
    warning: 'custom-toast-warning-wrapper ',
    error: 'custom-toast-error-wrapper ',
    info: 'custom-toast-info-wrapper ',
};

export const CustomToast = ({
    type,
    title,
    message,
    isLoading,
    closeToast,
}) => {
    return (
        <div
            className={`custom-toast-wrapper ${CustomToastClassNameMap[type]}`}
        >
            <div className="custom-toast-content-wrapper">
                <div className="custom-toast-icon-wrapper">
                    {CustomToastIconMap[type]}
                </div>
                <div className="custom-toast-content">
                    <h6 className="custom-toast-title">
                        {title ?? CustomToastTitleMap[type]}
                    </h6>
                    <p className="custom-toast-message">{message}</p>
                </div>
            </div>
            <div
                className="custom-toast-loader"
                onClick={isLoading ? undefined : closeToast}
            >
                <div className="cursor-pointer">
                    {isLoading ? (
                        <>{CustomToastSpinnerIconMap[type]}</>
                    ) : (
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 2.5C6.5 2.5 2 7 2 12.5C2 18 6.5 22.5 12 22.5C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5ZM15.7 14.8C16.1 15.2 16.1 15.8 15.7 16.2C15.3 16.6 14.7 16.6 14.3 16.2L12 13.9L9.7 16.2C9.3 16.6 8.7 16.6 8.3 16.2C7.9 15.8 7.9 15.2 8.3 14.8L10.6 12.5L8.3 10.2C7.9 9.8 7.9 9.2 8.3 8.8C8.7 8.4 9.3 8.4 9.7 8.8L12 11.1L14.3 8.8C14.7 8.4 15.3 8.4 15.7 8.8C16.1 9.2 16.1 9.8 15.7 10.2L13.4 12.5L15.7 14.8Z"
                                fill="white"
                            />
                        </svg>
                    )}
                </div>
            </div>
        </div>
    );
};
