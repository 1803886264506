// import http, {useLoginJwt, ignoreLoginJwt} from "services/httpService"
import axios from "axios";
import * as config from "config";
import { encryptPayload, decryptPayload } from "utils/encrypt_decrypt";
import * as userActions from "store/auth/user/actionTypes";
import { customErrorToast } from "utils/customToast";

const api = store => next => async action => {

    const httpService = (useLoginJwt, token, additionalHeaders, mainAccountId) => {
        let http;
        if ((typeof useLoginJwt !== "undefined") && (useLoginJwt === false)) {
            http = axios.create();
        }
        else {
            let headers = {
                Authorization: token,
                ...additionalHeaders,
            };

            if (mainAccountId){
                headers = {
                    ...headers,
                    x_default_request: mainAccountId,
                };
            }

            http = axios.create({
                headers: headers,
            });
        }
        return http;
    }

    const getBaseUrl = (baseUrl) => {
        let apiBaseUrl;
        if (typeof baseUrl !== "undefined") {
            // console.log("use custom", baseUrl);
            apiBaseUrl = baseUrl;
        }
        else {
            // console.log("use default", config.apiBaseUrl);
            apiBaseUrl = config.apiBaseUrl;
        }
        return apiBaseUrl;
    }


    const getToken = (customToken) => {
        let apiToken;
        if (typeof customToken !== "undefined" && customToken !== "") {
            // console.log("use custom", customToken);
            apiToken = customToken;
        }
        else {
            const loginJwt = store.getState().auth.userPersist.data.token;
            // console.log("use loginJwt", loginJwt);
            apiToken = loginJwt;
        }
        return apiToken;
    }

    
    const isUserTokenActive = () => {
        const tokenExpiryValue = store.getState().auth.userPersist.data.tokenExpiry;
        if (tokenExpiryValue !== undefined && tokenExpiryValue !== null){
            const tokenExpiryTime = (parseInt(tokenExpiryValue) * 1000);
            const currentTime = Date.now();
            
            if (currentTime > tokenExpiryTime){
                customErrorToast({ message: "Expired Session, Please Login Again" }, {
                    toastId: "expired",
                });
                setTimeout(() => {
                    store.dispatch({ type: userActions.LOGOUT_USER });
                    return false;
                }, 1000)
            }
            else{
                // console.log("jwt hasn't expired");
                return true;
            }
        }
        // console.log("user isn't loggedin yet");
        return true;
    }
    
    
    let encryptedUrls = [];
    const getEncryptedPayload = (data, url, useEncryption) => {
        let params = data;
        if (useEncryption && useEncryption === true){
            // console.log("encryption here");
            encryptedUrls.push(url);
            const encryptedData = encryptPayload(data);
            return encryptedData;
        }
        return params;
    }

    
    const getDecryptedResponse = (response) => {
        const {config, data} = response;
        const endpointUrl = config.baseURL.toString() + config.url.toString();
        const endpointUrlIndex = encryptedUrls.indexOf(endpointUrl);
        if (endpointUrlIndex > -1 && data){
            encryptedUrls.splice(endpointUrlIndex, 1);
            const decryptedData = decryptPayload(data);
            return decryptedData ?? {};
        }
        else{
            return data;
        }
    }


    const getMainAccountId = () => {
        const user = store.getState().auth.userPersist.data;
        if (user.mainAccount && user.mainAccount._id) {
            return user.mainAccount._id;
        }
        else {
            return null;
        }
    }


    // eslint-disable-next-line no-unused-vars
    const expiredTokenResponses = [
        "Expired Session, Please Login Again",
        "Invalid or Expired Token",
    ]

    if (action.type !== config.apiRequestStart) {
        next(action);
    }
    else {
        
        if (isUserTokenActive() === true){

        const { baseUrl, url, method, data, onStart, onSuccess, onError, useLoginJwt, customToken, additionalHeaders, useEncryption} = action.payload;
        if (onStart) {
            store.dispatch({ type: onStart });
        }

        const apiBaseUrl = getBaseUrl(baseUrl);
        const token = getToken(customToken);
        const mainAccountId = getMainAccountId();
        const params = getEncryptedPayload(data, (apiBaseUrl.toString() + url.toString()), useEncryption);
        const http = httpService(useLoginJwt, token, additionalHeaders, mainAccountId);

        

        next(action);                                                                           // for debugger logging purpose (in chrome Redux dev tools)



        await http.request({
            baseURL: apiBaseUrl,
            url,
            method,
            data: params
        }).then((response) => {
            if (!response.data) {
                throw new Error("response.data is not defined!");
            }
            store.dispatch({ type: config.apiRequestSuccess, payload: response });                // for debugger logging purpose
            if (onSuccess) {
                // store.dispatch({ type: onSuccess, payload: response.data });
                store.dispatch({ type: onSuccess, payload: getDecryptedResponse(response) });
            }
        }).catch((error) => {
            store.dispatch({ type: config.apiRequestFailed, payload: error });                    // for debugger logging purpose
            if (onError) {
                if (error.response) {
                    // if (expiredTokenResponses.includes(error.response.data.message)) {
                        // store.dispatch({ type: userActions.LOGOUT_USER });
                    // }
                    // else{
                        // store.dispatch({ type: onError, payload: error.response.data });
                    // }
                    try {
                        const decryptedResponse = getDecryptedResponse(error.response);
                        store.dispatch({ type: onError, payload: decryptedResponse });
                    } catch (error) {
                        store.dispatch({
                            type: onError,
                            payload: {
                                message: "Invalid response data."
                            }
                        });
                    }
                }
                else if (error.request) {
                    if (navigator.onLine) {
                        store.dispatch({
                            type: onError,
                            payload: {
                                message: "Error: Invalid request. Please try again later."
                            }
                        });
                    }
                    else {
                        store.dispatch({
                            type: onError,
                            payload: {
                                message: "No Internet Connection"
                            }
                        });
                    }
                }
                else {
                    store.dispatch({
                        type: onError,
                        payload: {
                            message: "An error occurred. Please try again later."
                        }
                    });
                }
            }
        });

        }
    }

}

export default api;