
export const ObjectToQueryString = (params, paramsToQs = "?") => {

    if (params){
        if (Object.keys(params).length > 0) {
            Object.keys(params).forEach((param) => {
                if (params[param] !== "") {
                    paramsToQs += param + "=" + params[param] + "&";
                }
            });
            paramsToQs = paramsToQs.substring(0, paramsToQs.length - 1);
            // console.log("paramsToQs", paramsToQs);

            return paramsToQs;
        }
    }
    
    return "";

};

export const customQueryJoiner = (query) => {
    if (query === ""){
        return "?";
    }
    else{
        return "&";
    }
}

export default ObjectToQueryString;