/*
export const nameInitials = (fullName) => {
    
    const names = fullName.split(' ');
    var initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;

};
*/

// if((firstName === undefined) && (lastName === undefined)){
//     return 'XY';
// } else 

export const nameInitials = (firstName, lastName) => {   
    if (((firstName !== undefined) && (lastName !== undefined)) && ((firstName !== null) && (lastName !== null))){
        var initials = (firstName?.substring(0, 1) + "" + lastName?.substring(0, 1)).toUpperCase();
        return initials;
    }
    else{
        return null;
    }
    
};

export function getBusinessInitials(sentence) {
    if (!sentence) {
        return null;
    }
    const words = sentence.trim().split(/\s+/);

    if (words.length === 1) {
        return words[0].slice(0, 2).toUpperCase();
    } else if (words.length >= 2) {
        return (words[0][0] + words[1][0]).toUpperCase();
    }
}
