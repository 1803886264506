// import Maybe from "components/__new/common/Maybe";

export const Checkbox = (props) => {
    const { type, label, checked, onChange, onBlur, required, readonly, disabled, defaultProps, checkboxClass } = props;

    return (
        <div className="checkbox">
            <label className="max-w-max flex space-x-3 items-center cursor-pointer">
                <input
                    type={type}
                    className={`
                        w-5 h-5
                        ${type === "radio" ? "form-radio rounded-full" : "form-checkbox"}
                        ${(readonly || disabled) === true && "disabled"}
                        ${checkboxClass ? checkboxClass : ""}
                    `}
                    
                    checked={checked}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={required || false}

                    readOnly={readonly || disabled}
                    {...defaultProps}
                />
                <div className="overflow-hidden">
                    {label}

                    {/* 
                    <Maybe condition={required === true}>
                        <span className="form-input-required">*</span>
                    </Maybe>
                     */}
                </div>
            </label>
        </div>
    )
}

export default Checkbox
