
export const VALIDATE_BVN = "VALIDATE_BVN";
export const VALIDATE_BVN_START = "VALIDATE_BVN_START";
export const VALIDATE_BVN_FAILED = "VALIDATE_BVN_FAILED";
export const VALIDATE_BVN_RESET = "VALIDATE_BVN_RESET";

export const SEND_BVN_OTP = "SEND_BVN_OTP";
export const SEND_BVN_OTP_START = "SEND_BVN_OTP_START";
export const SEND_BVN_OTP_FAILED = "SEND_BVN_OTP_FAILED";
export const SEND_BVN_OTP_RESET = "SEND_BVN_OTP_RESET";

export const SEND_BVN_OTP_WHATS_APP = "SEND_BVN_OTP_WHATS_APP";
export const SEND_BVN_OTP_WHATS_APP_START = "SEND_BVN_OTP_WHATS_APP_START";
export const SEND_BVN_OTP_WHATS_APP_FAILED = "SEND_BVN_OTP_WHATS_APP_FAILED";
export const SEND_BVN_OTP_WHATS_APP_RESET = "SEND_BVN_OTP_WHATS_APP_RESET";

export const VALIDATE_BVN_OTP = "VALIDATE_BVN_OTP";
export const VALIDATE_BVN_OTP_START = "VALIDATE_BVN_OTP_START";
export const VALIDATE_BVN_OTP_FAILED = "VALIDATE_BVN_OTP_FAILED";
export const VALIDATE_BVN_OTP_RESET = "VALIDATE_BVN_OTP_RESET";
