
export const pluralize = (count, wordSingular, wordPlural) => {   
    if (isNaN(count)){
        return wordSingular;
    }

    if (count > 1){
        return wordPlural;
    }
    else{
        return wordSingular;
    }
};

export default pluralize;