import * as actions from "./actionTypes";
import * as config from "config";
import store from "store/store";
import ObjectToQueryString from 'utils/ObjectToQueryString';


export function validateEarnipayAccount(accountNumber){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: `${config.apiValidateEarnipayAccount}?q=${accountNumber}`,
            method: "get",
            data: {},
            onStart: actions.VALIDATE_EARNIPAY_ACCOUNT_START,
            onSuccess: actions.VALIDATE_EARNIPAY_ACCOUNT_DONE,
            onError: actions.VALIDATE_EARNIPAY_ACCOUNT_FAILED,
        },
    }
}

function getMainAccountToken(){
    const user = store.getState().auth.userPersist.data;
    const mainAccountToken = Object.keys(user.mainAccount).length > 0 ? user.mainAccount.token : "";
    return mainAccountToken ?? "";
}

export function getDashboard(userInfo){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetWalletDashboard,
            // additionalHeaders: {token},
            method: "get",
            data: {},
            onStart: actions.GET_DASHBOARD_START,
            onSuccess: actions.GET_DASHBOARD,
            onError: actions.GET_DASHBOARD_FAILED,
            customToken: getMainAccountToken(),
            useEncryption: true,
        },
    }
}

export function getTransactionsCount(token){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetWalletTransactionsCount,
            additionalHeaders: {token},
            method: "get",
            data: {},
            onStart: actions.GET_TRANSACTIONS_COUNT_START,
            onSuccess: actions.GET_TRANSACTIONS_COUNT,
            onError: actions.GET_TRANSACTIONS_COUNT_FAILED,
        },
    }
}

export function getTransactions(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetWalletTransactions + ObjectToQueryString(params),
            additionalHeaders: {token},
            method: "get",
            data: {},
            onStart: actions.GET_TRANSACTIONS_START,
            onSuccess: actions.GET_TRANSACTIONS,
            onError: actions.GET_TRANSACTIONS_FAILED,
        },
    }
}

export function getTransactionsDownload(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetWalletTransactions + ObjectToQueryString(params),
            additionalHeaders: {token},
            method: "get",
            data: {},
            onStart: actions.GET_TRANSACTIONS_DOWNLOAD_START,
            onSuccess: actions.GET_TRANSACTIONS_DOWNLOAD,
            onError: actions.GET_TRANSACTIONS_DOWNLOAD_FAILED,
        },
    }
}

export function getBeneficiaries(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetWalletBeneficiaries + ObjectToQueryString(params),
            additionalHeaders: {token},
            method: "get",
            data: {},
            onStart: actions.GET_BENEFICIARIES_START,
            onSuccess: actions.GET_BENEFICIARIES,
            onError: actions.GET_BENEFICIARIES_FAILED,
            useEncryption: true,
        },
    }
}

export function getBeneficiariesDownload(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetWalletBeneficiaries + ObjectToQueryString(params),
            additionalHeaders: {token},
            method: "get",
            data: {},
            onStart: actions.GET_BENEFICIARIES_DOWNLOAD_START,
            onSuccess: actions.GET_BENEFICIARIES_DOWNLOAD,
            onError: actions.GET_BENEFICIARIES_DOWNLOAD_FAILED,
            useEncryption: true,
        },
    }
}

export function deleteBeneficiary(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiDeleteWalletBeneficiaries,
            additionalHeaders: {token},
            method: "delete",
            data: params,
            onStart: actions.DELETE_BENEFICIARY_START,
            onSuccess: actions.DELETE_BENEFICIARY,
            onError: actions.DELETE_BENEFICIARY_FAILED,
        },
    }
}

export function deleteBeneficiaryReset(){
    return {
        type: actions.DELETE_BENEFICIARY_RESET,
        payload: {},
    }
}

export function generateOtp(token, params, type){
    return {
        type: config.apiRequestStart,
        payload: {
            // baseUrl: config.apiWalletBaseUrl,
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiWalletGenerateOtp,
            additionalHeaders: {token},
            method: "post",
            data: { otpType: params },
            onStart: type === "reset" ? actions.GENERATE_OTP_RESET_PIN_START : actions.GENERATE_OTP_START,
            onSuccess: type === "reset" ? actions.GENERATE_OTP_RESET_PIN : actions.GENERATE_OTP,
            onError: type === "reset" ? actions.GENERATE_OTP_RESET_PIN_FAILED : actions.GENERATE_OTP_FAILED,
        },
    }
}

export function generateOtpWithId(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            // baseUrl: config.apiWalletBaseUrl,
            url: config.apiWalletGenerateOtp,
            additionalHeaders: {token},
            method: "post",
            data: params,
            onStart: actions.GENERATE_OTP_START,
            onSuccess: actions.GENERATE_OTP,
            onError: actions.GENERATE_OTP_FAILED,
        },
    }
}

export function generateOtpEmployerAdmin(type, otpType){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            // baseUrl: config.apiWalletBaseUrl,
            url: config.apiWalletGenerateOtpEmployerAdmin,
            method: "post",
            data: {
                otpType,
            },
            onStart: type === "reset" ? actions.GENERATE_OTP_RESET_PIN_START : actions.GENERATE_OTP_START,
            onSuccess: type === "reset" ? actions.GENERATE_OTP_RESET_PIN : actions.GENERATE_OTP,
            onError: type === "reset" ? actions.GENERATE_OTP_RESET_PIN_FAILED : actions.GENERATE_OTP_FAILED,
        },
    }
}

export function generateBulkBeneficiaryOtp(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            // baseUrl: config.apiWalletBaseUrl,
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiWalletGenerateOtp,
            additionalHeaders: {token},
            method: "post",
            data: {purpose: params},
            onStart: actions.GENERATE_BULK_BENEFICIARY_OTP_START,
            onSuccess: actions.GENERATE_BULK_BENEFICIARY_OTP_DONE,
            onError: actions.GENERATE_BULK_BENEFICIARY_OTP_FAILED,
        },
    }
}

export function resetBulkBeneficiaryOtpCount(){
    return {
        type: actions.RESET_BULK_BENEFICIARY_OTP_COUNT,
        payload: {},
    }
}

export function generateOtpReset(type){
    return {
        type: type === "reset" ? actions.GENERATE_OTP_RESET_PIN_RESET : actions.GENERATE_OTP_RESET,
        payload: {},
    }
}

export function createAccessPin(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiWalletCreateAccessPin,
            additionalHeaders: {token},
            method: "post",
            data: params,
            onStart: actions.CREATE_ACCESS_PIN_START,
            onSuccess: actions.CREATE_ACCESS_PIN,
            onError: actions.CREATE_ACCESS_PIN_FAILED,
            useEncryption: true,
        },
    }
}

export function createAccessPinEmployerAdmin(params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiWalletCreateAccessPinEmployerAdmin,
            method: "post",
            data: params,
            onStart: actions.CREATE_ACCESS_PIN_START,
            onSuccess: actions.CREATE_ACCESS_PIN,
            onError: actions.CREATE_ACCESS_PIN_FAILED,
            useEncryption: true,
        },
    }
}

export function createAccessPinReset(){
    return {
        type: actions.CREATE_ACCESS_PIN_RESET,
        payload: {},
    }
}

export function resetAccessPin(params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiWalletCreateAccessPinEmployerAdmin,
            method: "post",
            data: params,
            onStart: actions.RESET_ACCESS_PIN_START,
            onSuccess: actions.RESET_ACCESS_PIN,
            onError: actions.RESET_ACCESS_PIN_FAILED,
            useEncryption: true,
        },
    }
}

export function resetAccessPinReset(){
    return {
        type: actions.RESET_ACCESS_PIN_RESET,
        payload: {},
    }
}

export function validateAccount(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiWalletValidateAccount,
            additionalHeaders: {token},
            method: "post",
            data: params,
            onStart: actions.VALIDATE_ACCOUNT_START,
            onSuccess: actions.VALIDATE_ACCOUNT,
            onError: actions.VALIDATE_ACCOUNT_FAILED,
        },
    }
}

export function validateAccountReset(){
    return {
        type: actions.VALIDATE_ACCOUNT_RESET,
        payload: {},
    }
}

export function createBeneficiary(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiCreateTransferBeneficiary,
            additionalHeaders: {token},
            method: "post",
            data: params,
            onStart: actions.CREATE_BENEFICIARY_START,
            onSuccess: actions.CREATE_BENEFICIARY,
            onError: actions.CREATE_BENEFICIARY_FAILED,
            useEncryption: true,
        },
    }
}

export function createBulkBeneficiaries(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiWalletCreateBulkBeneficiary,
            additionalHeaders: {token},
            method: "post",
            data: params,
            onStart: actions.CREATE_BULK_BENEFICIARIES_START,
            onSuccess: actions.CREATE_BULK_BENEFICIARIES_DONE,
            onError: actions.CREATE_BULK_BENEFICIARIES_FAILED,
            useEncryption: true,
        },
    }
}

export function createBeneficiaryReset(){
    return {
        type: actions.CREATE_BENEFICIARY_RESET,
        payload: {},
    }
}

export function walletTransferSingle(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiWalletTransferSingle,
            additionalHeaders: {token},
            method: "post",
            data: params,
            onStart: actions.WALLET_TRANSFER_START,
            onSuccess: actions.WALLET_TRANSFER,
            onError: actions.WALLET_TRANSFER_FAILED,
            useEncryption: true,
        },
    }
}

export function walletTransferMultiple(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiWalletTransferMultiple,
            additionalHeaders: {token},
            method: "post",
            data: params,
            onStart: actions.WALLET_TRANSFER_START,
            onSuccess: actions.WALLET_TRANSFER,
            onError: actions.WALLET_TRANSFER_FAILED,
            useEncryption: true,
        },
    }
}

export function walletTransferUpload(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiWalletTransferUpload,
            additionalHeaders: {token},
            method: "post",
            data: params,
            onStart: actions.WALLET_TRANSFER_START,
            onSuccess: actions.WALLET_TRANSFER_UPLOAD,
            onError: actions.WALLET_TRANSFER_UPLOAD_FAILED,
        },
    }
}

export function walletTransferReset(){
    return {
        type: actions.WALLET_TRANSFER_RESET,
        payload: {},
    }
}

export function getDashboardSilent(userInfo){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetWalletDashboard,
            // additionalHeaders: {token},
            method: "get",
            data: {},
            onStart: actions.GET_DASHBOARD_START,
            onSuccess: actions.GET_DASHBOARD,
            onError: actions.GET_DASHBOARD_FAILED_SILENT,
            customToken: getMainAccountToken(),
            useEncryption: true,
        },
    }
}

export function getTransactionsSilent(token, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetWalletTransactions + ObjectToQueryString(params),
            additionalHeaders: {token},
            method: "get",
            data: {},
            onStart: actions.GET_TRANSACTIONS_START,
            onSuccess: actions.GET_TRANSACTIONS,
            onError: actions.GET_TRANSACTIONS_FAILED_SILENT,
        },
    }
}

export function getYieldSettings(token){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetYieldSettings,
            additionalHeaders: {token},
            method: "get",
            data: {},
            onStart: actions.GET_YIELD_SETTINGS_START,
            onSuccess: actions.GET_YIELD_SETTINGS,
            onError: actions.GET_YIELD_SETTINGS_FAILED,
        },
    }
}

export function getYieldBalance(){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetYieldBalance,
            method: "get",
            data: {},
            onStart: actions.GET_YIELD_BALANCE_START,
            onSuccess: actions.GET_YIELD_BALANCE,
            onError: actions.GET_YIELD_BALANCE_FAILED,
        },
    }
}

export function getYieldReport(token, walletId, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: `${config.apiGetYieldReport}/${walletId}?page=${params?.page}&limit=${params?.limit}`,
            additionalHeaders: {token},
            method: "get",
            data: {},
            onStart: actions.GET_YIELD_REPORT_START,
            onSuccess: actions.GET_YIELD_REPORT,
            onError: actions.GET_YIELD_REPORT_FAILED,
        },
    }
}

export function downloadYieldReport(token, walletId){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetYieldReport + walletId,
            additionalHeaders: {token},
            method: "get",
            data: {},
            onStart: actions.DOWNLOAD_YIELD_REPORT_START,
            onSuccess: actions.DOWNLOAD_YIELD_REPORT,
            onError: actions.DOWNLOAD_YIELD_REPORT_FAILED,
        },
    }
}

export function optInOutYield(){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiBaseUrl,
            url: config.apiOptInOutYield,
            // additionalHeaders: {token},
            method: "patch",
            data: {},
            onStart: actions.OPT_IN_OUT_YIELD_START,
            onSuccess: actions.OPT_IN_OUT_YIELD,
            onError: actions.OPT_IN_OUT_YIELD_FAILED,
        },
    }
}

export function resetOptInOutYield(){
    return {
        type: actions.OPT_IN_OUT_YIELD_RESET,
    }
}

export function createWallet(){
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCreateWallet,
            method: "post",
            data: {},
            onStart: actions.CREATE_WALLET_START,
            onSuccess: actions.CREATE_WALLET,
            onError: actions.CREATE_WALLET_FAILED,
        },
    }
}

export function createAdditionalWallet(params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiCreateAdditionalWallet,
            method: "post",
            data: params,
            onStart: actions.CREATE_ADDITIONAL_WALLET_START,
            onSuccess: actions.CREATE_ADDITIONAL_WALLET,
            onError: actions.CREATE_ADDITIONAL_WALLET_FAILED,
        },
    }
}
export function resetCreateAdditionalWallet(){
    return {
        type: actions.CREATE_ADDITIONAL_WALLET_RESET,
        payload: {},
    }
}

export function createWalletReset(){
    return {
        type: actions.CREATE_WALLET_RESET,
        payload: {},
    }
}

export function generateAccountStatement(params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiGenerateAccountStatement,
            method: "post",
            data: params,
            onStart: actions.GENERATE_ACCOUNT_STATEMENT_START,
            onSuccess: actions.GENERATE_ACCOUNT_STATEMENT,
            onError: actions.GENERATE_ACCOUNT_STATEMENT_FAILED,
            useEncryption: true,
        },
    }
}

export function resetGenerateAccountStatement(){
    return {
        type: actions.GENERATE_ACCOUNT_STATEMENT_RESET,
        payload: {},
    }
}

export function apiCreateAdditionalWallet(params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiCreateAdditionalWallet,
            method: "post",
            data: params,
            onStart: actions.CREATE_ADDITIONAL_WALLET_START,
            onSuccess: actions.CREATE_ADDITIONAL_WALLET,
            onError: actions.CREATE_ADDITIONAL_WALLET_FAILED,
        },
    }
}

export function fundBusinessAccount(params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiFundBusinessAccount,
            method: "post",
            data: params,
            onStart: actions.FUND_BUSINESS_ACCOUNT_START,
            onSuccess: actions.FUND_BUSINESS_ACCOUNT,
            onError: actions.FUND_BUSINESS_ACCOUNT_FAILED,
        },
    }
}

export function verifyFundBusinessAccountStatus(params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiVerifyFundBusinessAccountStatus + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.VERIFY_BUSINESS_ACCOUNT_FUNDING_START,
            onSuccess: actions.VERIFY_BUSINESS_ACCOUNT_FUNDING,
            onError: actions.VERIFY_BUSINESS_ACCOUNT_FUNDING_FAILED,
        },
    }
}

export function getBusinessAccounts(params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetBusinessAccounts + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_BUSINESS_ACCOUNTS_START,
            onSuccess: actions.GET_BUSINESS_ACCOUNTS,
            onError: actions.GET_BUSINESS_ACCOUNTS_FAILED,
        },
    }
}

export function freezeAccount(walletId){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiFreezeAccount + "/" + walletId,
            method: "patch",
            data: {},
            onStart: actions.FREEZE_ACCOUNT_START,
            onSuccess: actions.FREEZE_ACCOUNT,
            onError: actions.FREEZE_ACCOUNT_FAILED,
        },
    }
}

export function resetFreezeAccount(){
    return {
        type: actions.FREEZE_ACCOUNT_RESET,
    }
}

export function unFreezeAccount(walletId){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiUnFreezeAccount + "/" + walletId,
            method: "patch",
            data: {},
            onStart: actions.UNFREEZE_ACCOUNT_START,
            onSuccess: actions.UNFREEZE_ACCOUNT,
            onError: actions.UNFREEZE_ACCOUNT_FAILED,
        },
    }
}

export function resetUnFreezeAccount(){
    return {
        type: actions.UNFREEZE_ACCOUNT_RESET,
    }
}

export function closeAccount(walletId){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiCloseAccount + "/" + walletId,
            method: "delete",
            data: {},
            onStart: actions.CLOSE_ACCOUNT_START,
            onSuccess: actions.CLOSE_ACCOUNT,
            onError: actions.CLOSE_ACCOUNT_FAILED,
        },
    }
}

export function resetCloseAccount(){
    return {
        type: actions.CLOSE_ACCOUNT_RESET,
    }
}

export function getAccountDetails(walletId){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetAccountDetails + "/" + walletId,
            method: "get",
            data: {},
            onStart: actions.GET_ACCOUNT_DETAILS_START,
            onSuccess: actions.GET_ACCOUNT_DETAILS,
            onError: actions.GET_ACCOUNT_DETAILS_FAILED,
        },
    }
}

export function getAccountTransactions(walletId, params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetAccountTransactions + "/" + walletId + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_ACCOUNT_TRANSACTIONS_START,
            onSuccess: actions.GET_ACCOUNT_TRANSACTIONS,
            onError: actions.GET_ACCOUNT_TRANSACTIONS_FAILED,
        },
    }
}

export function createBusinessCredit(params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiCreateBusinessCredit,
            method: "post",
            data: params,
            onStart: actions.CREATE_BUSINESS_CREDIT_START,
            onSuccess: actions.CREATE_BUSINESS_CREDIT,
            onError: actions.CREATE_BUSINESS_CREDIT_FAILED,
        },
    }
}

export function resetBusinessCredit(){
    return {
        type: actions.CREATE_BUSINESS_CREDIT_RESET,
    }
}

export function getBusinessCredit(employerId){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiGetBusinessCredit + "/" + employerId,
            method: "get",
            data: {},
            onStart: actions.GET_BUSINESS_CREDIT_START,
            onSuccess: actions.GET_BUSINESS_CREDIT,
            onError: actions.GET_BUSINESS_CREDIT_FAILED,
        },
    }
}

export function updateAccount(params, walletId){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiUpdateAccount + "/" + walletId,
            method: "patch",
            data: params,
            onStart: actions.UPDATE_WALLET_ACCOUNT_START,
            onSuccess: actions.UPDATE_WALLET_ACCOUNT,
            onError: actions.UPDATE_WALLET_ACCOUNT_FAILED,
        },
    }
}

export function resetUpdateWalletAccount(){
    return {
        type: actions.UPDATE_WALLET_ACCOUNT_RESET,
    }
}

export function moveMoney(params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiMoveMoney,
            method: "patch",
            data: params,
            onStart: actions.MOVE_MONEY_START,
            onSuccess: actions.MOVE_MONEY,
            onError: actions.MOVE_MONEY_FAILED,
        },
    }
}

export function resetMoveMoney(){
    return {
        type: actions.MOVE_MONEY_RESET,
    }
}

export function setSourceWalletId(sourceWalletId) {
    return {
        type: actions.SET_SOURCE_WALLET_ID,
        payload: sourceWalletId
    };
}

export function multipleSourceWalletId(multipleSourceWalletId) {
    return {
        type: actions.SET_MULTIPLE_SOURCE_WALLET_ID,
        payload: multipleSourceWalletId
    };
}


export function getWalletBalance(){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiGetWalletBalance,
            method: "get",
            data: {},
            onStart: actions.GET_WALLET_BALANCE_START,
            onSuccess: actions.GET_WALLET_BALANCE,
            onError: actions.GET_WALLET_BALANCE_FAILED,
            useEncryption: true,
        },
    }
}