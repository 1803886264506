import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip';
import { ReactComponent as InfoIcon } from "assets/images/icons/project-icons/information.svg";

export const Tooltip = ({id, place, effect, backgroundColor, textColor, tooltipClassName, children}) => {
    useEffect(() => {
        ReactTooltip.rebuild(); 
    }, []);
      

    return (
        <div className='pl-2'>
            <span className="pt-1 text-xl cursor-pointer">
                <InfoIcon width={17} data-tip data-for={id || "title-info"} />
            </span>

            <ReactTooltip 
                id={id || "title-info"} 
                className={`tooptip-size ${tooltipClassName}`} 
                place={place} 
                effect={effect}
                backgroundColor={backgroundColor}
                textColor={textColor}
            >
                {children}
            </ReactTooltip>
        </div>
    )
}
