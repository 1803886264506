import { combineReducers } from "redux";
import dashboardReducer from "./dashboard/reducer";
import employeesReducer from "./employees/reducer";
import groupsReducer from "./groups/reducer";
import paymentMethodReducer from "./paymentMethod/reducer";
import paymentHistoryReducer from "./paymentHistory/reducer";
import withdrawalsReducer from "./withdrawals/reducer";
import savingsReducer from "./savings/reducer";
import payrollReducer from "./payroll/payroll-version-1/reducer";
import runPayrollReducer from "./payroll/runPayroll/reducer"
import payrollSetupReducer from "./payroll/payrollSetup/reducer"
import invoiceReducer from "./invoice/reducer";
import walletReducer from "./wallet/reducer";
import billPaymentReducer from "./billPayment/reducer";
import settingsAdminReducer from "./settingsAdmin/reducer";
import settingsInfoReducer from "./settingsInfo/reducer";
import integrationsReducer from "./integrations/reducer";
import generalReducer from "./general/reducer";
import auditTrailReducer from "./auditTrail/reducer";
import complianceReducer from "./compliance/reducer";
import transfersReducer from ".//transfers/reducer";
import notificationsReducer from "./notification/reducer";
import approvalsReducer from "./approvals/reducer";

export default combineReducers({
    dashboard: dashboardReducer,
    employees: employeesReducer,
    groups: groupsReducer,
    paymentMethod: paymentMethodReducer,
    paymentHistory: paymentHistoryReducer,
    withdrawals: withdrawalsReducer,
    savings: savingsReducer,
    payroll: payrollReducer,
    runPayroll: runPayrollReducer,
    payrollSetup: payrollSetupReducer,
    invoice: invoiceReducer,
    wallet: walletReducer,
    billPayment: billPaymentReducer,
    settingsAdmin: settingsAdminReducer,
    settingsInfo: settingsInfoReducer,
    integrations: integrationsReducer,
    general: generalReducer,
    auditTrail: auditTrailReducer,
    compliance: complianceReducer,
    transfers: transfersReducer,
    notifications: notificationsReducer,
    approvals: approvalsReducer
});