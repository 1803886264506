export const SAVE_CABLE_CSV_DATA = "SAVE_CABLE_CSV_DATA"

export const RESET_PARSED_CSV_PROPS = "RESET_PARSED_CSV_PROPS"

export const PARSE_CSV_FILE_START = "PARSE_CSV_FILE_START"
export const PARSE_CSV_FILE_DONE = "PARSE_CSV_FILE_DONE"
export const PARSE_CSV_FILE_FAILED = "PARSE_CSV_FILE_FAILED"

export const SAVE_CABLE_PREVIEW_ITEMS = "SAVE_CABLE_PREVIEW_ITEMS"
export const CLEAR_CABLE_PREVIEW_ITEMS = "CLEAR_CABLE_PREVIEW_ITEMS"

export const GET_CABLE_TRANSACTIONS_START = "GET_CABLE_TRANSACTIONS_START"
export const GET_CABLE_TRANSACTIONS_DONE = "GET_CABLE_TRANSACTIONS_DONE"
export const GET_CABLE_TRANSACTIONS_FAILED = "GET_CABLE_TRANSACTIONS_FAILED"

export const SAVE_POWER_CSV_DATA = "SAVE_POWER_CSV_DATA"
export const SAVE_POWER_PREVIEW_ITEMS = "SAVE_POWER_PREVIEW_ITEMS"
export const CLEAR_POWER_PREVIEW_ITEMS = "CLEAR_POWER_PREVIEW_ITEMS"

export const GET_POWER_TRANSACTIONS_START = "GET_POWER_TRANSACTIONS_START"
export const GET_POWER_TRANSACTIONS_DONE = "GET_POWER_TRANSACTIONS_DONE"
export const GET_POWER_TRANSACTIONS_FAILED = "GET_POWER_TRANSACTIONS_FAILED"

export const SAVE_DATASUB_CSV_DATA = "SAVE_DATASUB_CSV_DATA"
export const SAVE_DATASUB_CSV_ERRORS = "SAVE_DATASUB_CSV_ERRORS"

export const GET_DATASUB_TRANSACTIONS_START = "GET_DATASUB_TRANSACTIONS_START"
export const GET_DATASUB_TRANSACTIONS_DONE = "GET_DATASUB_TRANSACTIONS_DONE"
export const GET_DATASUB_TRANSACTIONS_FAILED = "GET_DATASUB_TRANSACTIONS_FAILED"

export const SAVE_AIRTIME_CSV_DATA = "SAVE_AIRTIME_CSV_DATA"
export const SAVE_AIRTIME_CSV_ERRORS = "SAVE_AIRTIME_CSV_ERRORS"

export const SAVE_AIRTIME_PREVIEW_ITEMS = "SAVE_AIRTIME_PREVIEW_ITEMS"
export const CLEAR_AIRTIME_PREVIEW_ITEMS = "CLEAR_AIRTIME_PREVIEW_ITEMS"

export const GET_AIRTIME_TRANSACTIONS_START = "GET_AIRTIME_TRANSACTIONS_START"
export const GET_AIRTIME_TRANSACTIONS_DONE = "GET_AIRTIME_TRANSACTIONS_DONE"
export const GET_AIRTIME_TRANSACTIONS_FAILED = "GET_AIRTIME_TRANSACTIONS_FAILED"

export const GET_BENEFICIARIES = "GET_BENEFICIARIES";
export const GET_BENEFICIARIES_START = "GET_BENEFICIARIES_START";
export const GET_BENEFICIARIES_FAILED = "GET_BENEFICIARIES_FAILED";

export const GET_ALL_BENEFICIARIES = "GET_ALL_BENEFICIARIES";
export const GET_ALL_BENEFICIARIES_START = "GET_ALL_BENEFICIARIES_START";
export const GET_ALL_BENEFICIARIES_FAILED = "GET_ALL_BENEFICIARIES_FAILED";

export const CREATE_BENEFICIARY = "CREATE_BENEFICIARY";
export const CREATE_BENEFICIARY_START = "CREATE_BENEFICIARY_START";
export const CREATE_BENEFICIARY_FAILED = "CREATE_BENEFICIARY_FAILED";

export const EDIT_BENEFICIARY = "EDIT_BENEFICIARY";
export const EDIT_BENEFICIARY_START = "EDIT_BENEFICIARY_START";
export const EDIT_BENEFICIARY_FAILED = "EDIT_BENEFICIARY_FAILED";

export const DELETE_BENEFICIARY = "DELETE_BENEFICIARY";
export const DELETE_BENEFICIARY_START = "DELETE_BENEFICIARY_START";
export const DELETE_BENEFICIARY_FAILED = "DELETE_BENEFICIARY_FAILED";

export const GET_SERVICE_CODES = "GET_SERVICE_CODES";
export const GET_SERVICE_CODES_START = "GET_SERVICE_CODES_START";
export const GET_SERVICE_CODES_FAILED = "GET_SERVICE_CODES_FAILED";

export const GET_PROVIDERS = "GET_PROVIDERS";
export const GET_PROVIDERS_START = "GET_PROVIDERS_START";
export const GET_PROVIDERS_FAILED = "GET_PROVIDERS_FAILED";

export const AIRTIME_PURCHASE = "AIRTIME_PURCHASE";
export const AIRTIME_PURCHASE_START = "AIRTIME_PURCHASE_START";
export const AIRTIME_PURCHASE_FAILED = "AIRTIME_PURCHASE_FAILED";
export const AIRTIME_PURCHASE_RESET = "AIRTIME_PURCHASE_RESET";

export const AIRTIME_PURCHASE_UPLOAD = "AIRTIME_PURCHASE_UPLOAD";
export const AIRTIME_PURCHASE_UPLOAD_FAILED = "AIRTIME_PURCHASE_UPLOAD_FAILED";

export const GET_BULK_AIRTIME_STATUS_START = "GET_BULK_AIRTIME_STATUS_START";
export const GET_BULK_AIRTIME_STATUS_DONE = "GET_BULK_AIRTIME_STATUS_DONE";
export const GET_BULK_AIRTIME_STATUS_FAILED = "GET_BULK_AIRTIME_STATUS_FAILED";

export const GET_DATA_BUNDLES = "GET_DATA_BUNDLES";
export const GET_DATA_BUNDLES_START = "GET_DATA_BUNDLES_START";
export const GET_DATA_BUNDLES_FAILED = "GET_DATA_BUNDLES_FAILED";

export const DATA_PURCHASE = "DATA_PURCHASE";
export const DATA_PURCHASE_START = "DATA_PURCHASE_START";
export const DATA_PURCHASE_FAILED = "DATA_PURCHASE_FAILED";
export const DATA_PURCHASE_RESET = "DATA_PURCHASE_RESET";

export const DATA_PURCHASE_UPLOAD = "DATA_PURCHASE_UPLOAD";
export const DATA_PURCHASE_UPLOAD_FAILED = "DATA_PURCHASE_UPLOAD_FAILED";

export const GET_ELECTRICITY_VALIDATION = "GET_ELECTRICITY_VALIDATION";
export const GET_ELECTRICITY_VALIDATION_START = "GET_ELECTRICITY_VALIDATION_START";
export const GET_ELECTRICITY_VALIDATION_FAILED = "GET_ELECTRICITY_VALIDATION_FAILED";
export const RESET_ELECTRICITY_VALIDATION = "RESET_ELECTRICITY_VALIDATION";

export const ELECTRICITY_PURCHASE = "ELECTRICITY_PURCHASE";
export const ELECTRICITY_PURCHASE_START = "ELECTRICITY_PURCHASE_START";
export const ELECTRICITY_PURCHASE_FAILED = "ELECTRICITY_PURCHASE_FAILED";
export const ELECTRICITY_PURCHASE_RESET = "ELECTRICITY_PURCHASE_RESET";

export const RENEW_MULTICHOICE_PLAN = "RENEW_MULTICHOICE_PLAN";
export const RENEW_MULTICHOICE_PLAN_START = "RENEW_MULTICHOICE_PLAN_START";
export const RENEW_MULTICHOICE_PLAN_FAILED = "RENEW_MULTICHOICE_PLAN_FAILED";

export const GET_MULTICHOICE_CODES = "GET_MULTICHOICE_CODES";
export const GET_MULTICHOICE_CODES_START = "GET_MULTICHOICE_CODES_START";
export const GET_MULTICHOICE_CODES_FAILED = "GET_MULTICHOICE_CODES_FAILED";

export const GET_MULTICHOICE_PRODUCT_ADDONS = "GET_MULTICHOICE_PRODUCT_ADDONS";
export const GET_MULTICHOICE_PRODUCT_ADDONS_START = "GET_MULTICHOICE_PRODUCT_ADDONS_START";
export const GET_MULTICHOICE_PRODUCT_ADDONS_FAILED = "GET_MULTICHOICE_PRODUCT_ADDONS_FAILED";

export const GET_CABLETV_BOUQUETS = "GET_CABLETV_BOUQUETS";
export const GET_CABLETV_BOUQUETS_START = "GET_CABLETV_BOUQUETS_START";
export const GET_CABLETV_BOUQUETS_FAILED = "GET_CABLETV_BOUQUETS_FAILED";

export const GET_CABLETV_VALIDATION = "GET_CABLETV_VALIDATION";
export const GET_CABLETV_VALIDATION_START = "GET_CABLETV_VALIDATION_START";
export const GET_CABLETV_VALIDATION_FAILED = "GET_CABLETV_VALIDATION_FAILED";
export const RESET_CABLETV_VALIDATION = "RESET_CABLE_VALIDATION";

export const CABLETV_PURCHASE = "CABLETV_PURCHASE";
export const CABLETV_PURCHASE_START = "CABLETV_PURCHASE_START";
export const CABLETV_PURCHASE_FAILED = "CABLETV_PURCHASE_FAILED";
export const CABLETV_PURCHASE_RESET = "CABLETV_PURCHASE_RESET";
