import mixpanel from 'mixpanel-browser';

const logger = (event, user={}) => {    

    try {
        const _id = user?._id || "";
        const email = user?.email || "";
        const phoneNumber = user?.phoneNumber || "";

        const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
        // const mixpanelToken = "289c324d14f36949ac0b82e4a9c24682";

        if (mixpanelToken !== "00000000"){
            mixpanel.init(mixpanelToken, { debug: false, ignore_dnt: true });
            mixpanel.identify(_id);
            mixpanel.track(event, {
                distinct_id: _id,
                user_email: email,
                employeePhonenumber: phoneNumber,
            });
        }

        return true;
    }
    catch (e) {
        // console.log("logger error: ", e);
        return false;
    }

};

export default logger;
