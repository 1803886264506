import { Link } from "react-router-dom";
import errorIcon from "assets/images/icons/error.svg";
import DynamicHeadTag from "components/__new/common/DynamicHeadTag";

const Error404 = () => {
    return (
        <>
            <DynamicHeadTag
                headerText="404 - Page not found"
            />

            <div className="w-screen h-screen flex overflow-hidden">
                <div className="m-auto px-8 text-center max-w-md">

                    <div className="h-24 sm:h-32 md:h-56">
                        <img src={errorIcon} alt="EarniPay" className="h-full object-contain mx-auto" />
                    </div>
                    <div className="mt-8 font-semibold text-2xl sm:text-3xl">
                        404 Error
                    </div>
                    <div className="mt-4 text-gray-500">
                        Oops! It seems like the page you are looking for, is not available! Please try again or go back to home.
                    </div>

                    <Link to="/dashboard" className="btn btn-block btn-lg btn-ep-primary mt-8 w-full">
                        Exit
                    </Link>

                </div>
            </div>
            
        </>
    )
}
 
export default Error404;