import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customSuccessToast } from "utils/customToast";

const initialState = {
    // loading: false,
    
    loginLoading: false,
    loginUser: {},
    // loggedIn: false,     // moved to persist
    // data: {},            // moved to persist
    
    // signedUp: false,     // moved to persist
    signupLoading: false,
    signupForm: {},
    // signupUser: {},      // moved to persist


    forgotPasswordLoading: false,
    forgotPassword: false,
    forgotPasswordForm: {},
    
    resetPasswordLoading: false,
    resetPassword: false,
    
    verifyAccountLoading: false,
    verifyAccount: false,
    
    resendMailLoading: false,
    resendMail: false,

    referralPointsLoading: false,
    referralPoints: {},
    
    subAccountsLoading: false,
    subAccounts: {},

    addSubAccountLoading: false,
    subAccountForm: {},
    subAccount: {},

    loginSubAccountLoading: false,
    loginSubAccountResponse: {},
    
    userModules: null,
    
    newUserDetails: null,
    
    resendTwoFaOtpLoading: false,
    resendTwoFaOtp: null,
    
    newUserOtpLoading: false,
    newUserOtpData: null,
    
    verifyNewUserOtpLoading: false,
    verifyNewUserOtpData: null,
    
    verifyOtpAndCreateEmployerLoading: false,
    verifyOtpAndCreateEmployerData: null,
    
    createNewUserLoading: false,
    createNewUserData: null,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {
    
    
        case actions.CLEAR_SIGNUP_INFO:
            state = {
                ...state,
                newUserOtpData: null,
                verifyNewUserOtpData: null,
                verifyOtpAndCreateEmployerData: null,
                newUserDetails: null
            }
            return state;
    
    
        case actions.SAVE_NEW_USER_DETAILS:
            state = {
                ...state,
                newUserDetails: action.payload
            }
            return state;
    
    
        case actions.CREATE_NEW_USER_START:
            state = {
                ...state,
                createNewUserData: null,
                createNewUserLoading: true
            }
            return state;
    
    
        case actions.CREATE_NEW_USER_DONE:
            customSuccessToast({ message: action.payload?.message });
            state = {
                ...state,
                createNewUserData: action.payload,
                createNewUserLoading: false,
                verifyNewUserOtpLoading: false,
            }
            return state;
    
    
        case actions.CREATE_NEW_USER_FAILED:
            errorReducer(JSON.parse(action?.payload));
            state = {
                ...state,
                createNewUserData: null,
                createNewUserLoading: false,
                verifyNewUserOtpLoading: false,
            }
            return state;
    
    
        case actions.VERIFY_NEW_USER_OTP_START:
            state = {
                ...state,
                verifyNewUserOtpData: null,
                verifyNewUserOtpLoading: true
            }
            return state;
    
    
        case actions.VERIFY_NEW_USER_OTP_DONE:
            customSuccessToast({ message: action.payload?.message });
            state = {
                ...state,
                verifyNewUserOtpData: action.payload,
                verifyNewUserOtpLoading: true,
            }
            return state;
    
    
        case actions.VERIFY_NEW_USER_OTP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                verifyNewUserOtpData: null,
                verifyNewUserOtpLoading: false,
            }
            return state;
    
        case actions.VERIFY_OTP_AND_CREATE_EMPLOYER_START:
            state = {
                ...state,
                verifyOtpAndCreateEmployerData: null,
                verifyOtpAndCreateEmployerLoading: true
            }
            return state;
    
    
        case actions.VERIFY_OTP_AND_CREATE_EMPLOYER:
            state = {
                ...state,
                verifyOtpAndCreateEmployerData: action.payload,
                verifyOtpAndCreateEmployerLoading: false,
            }
            return state;
    
    
        case actions.VERIFY_OTP_AND_CREATE_EMPLOYER_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                verifyOtpAndCreateEmployerData: null,
                verifyOtpAndCreateEmployerLoading: false,
            }
            return state;
    
        case actions.RESEND_TWO_FA_OTP_START:
            state = {
                ...state,
                resendTwoFaOtpLoading: true,
                resendTwoFaOtp: null,
            }
            return state;
            
            
            case actions.RESEND_TWO_FA_OTP:
                customSuccessToast({ message: action.payload?.message });
                state = {
                    ...state,
                    resendTwoFaOtp: action.payload,
                    resendTwoFaOtpLoading: false,
            }
            return state;
    
    
        case actions.RESEND_TWO_FA_OTP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                resendTwoFaOtpLoading: false,
                resendTwoFaOtp: null,
            }
            return state;
    
    
        case actions.REQUEST_NEW_USER_OTP_START:
            state = {
                ...state,
                newUserOtpLoading: true,
                newUserOtpData: null,
            }
            return state;
            
            
            case actions.REQUEST_NEW_USER_OTP_DONE:
                customSuccessToast({ message: action.payload?.message });
                state = {
                    ...state,
                    newUserOtpData: action.payload,
                    newUserOtpLoading: false,
            }
            return state;
    
    
        case actions.REQUEST_NEW_USER_OTP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                newUserOtpLoading: false,
                newUserOtpData: null,
            }
            return state;
            
        case actions.CLEAR_NEW_USER_OTP_DATA:
            state = {
                ...state,
                newUserOtpData: null,
            }
            return state;


            case actions.LOGIN_USER:
                state = {
                    ...state,
                    loginLoading: false,
                    loginUser: action.payload,
                    signupForm: {},
                    // signedUp: false,
                }
                return state;


        case actions.LOGIN_USER_START:
            state = {
                ...state,
                loginLoading: true,
                loginUser: {},
                
            }
            return state;


        case actions.LOGIN_USER_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                loginLoading: false,
                loginUser: {},
                // loggedIn: false,
                // data: {}
            }
            return state;


        case actions.UPDATE_LOGIN_USER:
            state = {
                ...state,
            }
            return state;



        case actions.LOGOUT_USER:
            state = {
                ...state,
                loginUser: {},
                // loggedIn: false,
                // data: {}
            }
            // window.location.href = "/login";
            return state;


        case actions.SIGNUP_1_USER:
            state = {
                ...state,
                // loggedIn: false,
                // data: {},
                signupForm: { ...action.payload.form },
                // signedUp: false,
            }
            return state;


        case actions.SIGNUP_2_USER:
            state = {
                ...state,
                signupLoading: false,
                // signedUp: true,
                signupForm: {},
                // signupUser: {
                //     name: action.payload.user.name,
                //     userId: action.payload.user.userId,
                //     email: action.payload.user.email,
                // },
            }
            return state;


        case actions.SIGNUP_USER_START:
            state = {
                ...state,
                signupLoading: true,
                // loggedIn: false,
                // data: {},
                // signupUser: {}
            }
            return state;


        case actions.SIGNUP_USER_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                signupLoading: false,
                // signupUser: {}
            }
            return state;


        case actions.VERIFY_ACCOUNT:
            state = {
                ...state,
                verifyAccountLoading: false,
                verifyAccount: true,
            }
            return state;


        case actions.VERIFY_ACCOUNT_START:
            state = {
                ...state,
                verifyAccountLoading: true,
                verifyAccount: false,
            }
            return state;


        case actions.VERIFY_ACCOUNT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                verifyAccountLoading: false,
                verifyAccount: false,
            }
            return state;


        case actions.RESEND_VERIFY_EMAIL:
            customSuccessToast({ message: "A new confirmation email has been sent" });
            state = {
                ...state,
                resendMailLoading: false,
                resendMail: true,
            }
            return state;


        case actions.RESEND_VERIFY_EMAIL_START:
            state = {
                ...state,
                resendMailLoading: true,
                resendMail: false,
            }
            return state;


        case actions.RESEND_VERIFY_EMAIL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                resendMailLoading: false,
                resendMail: false,
            }
            return state;


        case actions.RESEND_VERIFY_EMAIL_RESET:
            state = {
                ...state,
                resendMail: false,
            }
            return state;


        case actions.FORGOT_PASSWORD:
            customSuccessToast({ message: "Password reset pin has been sent to your email address" });
            state = {
                ...state,
                forgotPasswordLoading: false,
                forgotPassword: true,
                forgotPasswordForm: {
                    email: action.payload.data.email
                }
            }
            return state;


        case actions.FORGOT_PASSWORD_START:
            state = {
                ...state,
                forgotPasswordLoading: true,
                forgotPassword: false,
            }
            return state;


        case actions.FORGOT_PASSWORD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                forgotPasswordLoading: false,
                forgotPassword: false,
            }
            return state;


        case actions.FORGOT_PASSWORD_RESET:
            state = {
                ...state,
                forgotPasswordLoading: false,
                forgotPassword: false,
            }
            return state;


        case actions.RESET_PASSWORD:
            state = {
                ...state,
                resetPasswordLoading: false,
                resetPassword: true,
            }
            return state;


        case actions.RESET_PASSWORD_START:
            state = {
                ...state,
                resetPasswordLoading: true,
                resetPassword: false,
            }
            return state;


        case actions.RESET_PASSWORD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                resetPasswordLoading: false,
                resetPassword: false,
            }
            return state;


        case actions.RESET_PASSWORD_RESET:
            state = {
                ...state,
                resetPassword: false,
            }
            return state;




        case actions.GET_REFERRAL_POINTS:
            state = {
                ...state,
                referralPointsLoading: false,
                referralPoints: {...action.payload.data},
            }
            return state;


        case actions.GET_REFERRAL_POINTS_START:
            state = {
                ...state,
                referralPointsLoading: true,
                referralPoints: {},
            }
            return state;


        case actions.GET_REFERRAL_POINTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                referralPointsLoading: false,
                referralPoints: {},
            }
            return state;




        case actions.GET_SUB_ACCOUNTS:
            state = {
                ...state,
                subAccountsLoading: false,
                subAccounts: action.payload,
            }
            return state;


        case actions.GET_SUB_ACCOUNTS_START:
            state = {
                ...state,
                subAccountsLoading: true,
                subAccounts: {},
            }
            return state;


        case actions.GET_SUB_ACCOUNTS_FAILED:
            if (action.payload?.message !== "You cannot access On Demand Pay"){
                errorReducer(action.payload);
            }
            state = {
                ...state,
                subAccountsLoading: false,
                subAccounts: {},
            }
            return state;




        case actions.ADD_SUB_ACCOUNTS:
            state = {
                ...state,
                addSubAccountLoading: false,
                subAccount: action.payload,
            }
            return state;


        case actions.ADD_SUB_ACCOUNTS_START:
            state = {
                ...state,
                addSubAccountLoading: true,
                subAccount: {},
            }
            return state;


        case actions.ADD_SUB_ACCOUNTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                addSubAccountLoading: false,
                subAccount: {},
            }
            return state;
        

        case actions.SAVE_SUB_ACCOUNT_FORM:
            state = {
                ...state,
                subAccountForm: action.payload,
            }
            return state;



        case actions.LOGIN_SUB_ACCOUNTS:
            if (action.payload?.status === "failed"){
                errorReducer(action.payload);
            }

            state = {
                ...state,
                loginSubAccountLoading: false,
                loginSubAccountResponse: action.payload,
            }
            return state;


        case actions.LOGIN_SUB_ACCOUNTS_START:
            state = {
                ...state,
                loginSubAccountLoading: true,
                loginSubAccountResponse: {},
            }
            return state;


        case actions.LOGIN_SUB_ACCOUNTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                loginSubAccountLoading: false,
                loginSubAccountResponse: {},
            }
            return state;


        case actions.SAVE_USER_MODULES:
            state = {
                ...state,
                userModules: action.payload,
            }
            return state;

    
        default:
            return state;
    }
}