import React, { Component } from 'react';


class XFrameOptions extends Component {
    state = {  }
    render() { 
        return (
            <div className="w-screen h-screen p-20 bg-gray-200 overflow-hidden">
                <div className="w-full h-full border rounded-md overflow-hidden">
                    <iframe src="https://onome-dev.earnipay.com/login" title="iframe check" className="w-full h-full"></iframe>
                </div>
            </div>
         );
    }
}
 
export default XFrameOptions;