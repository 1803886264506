import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import useLoginInfo from "hooks/useLoginInfo";
import Maybe from "components/__new/common/Maybe";
import Environment from "components/__new/common/Environment";
// import SoftLaunch from "components/__new/common/SoftLaunch";
import { togglePayrollFeaturesModal } from 'store/entities/payroll/payrollSetup/action';
import { ReactComponent as IconVerification } from "assets/images/icons/project-icons/icon-verification.svg";
import { ReactComponent as IconHome } from "assets/images/icons/project-icons/icon-dashboard.svg";
import { ReactComponent as IconPeople } from "assets/images/icons/project-icons/icon-people.svg";
import { ReactComponent as IconOnDemandPay } from "assets/images/icons/project-icons/icon-on-demand-pay.svg";
import { ReactComponent as IconPayments } from "assets/images/icons/project-icons/icon-payment.svg";
import { ReactComponent as IconPayroll } from "assets/images/icons/project-icons/icon-payroll.svg";
import { ReactComponent as IconAuditTrail } from "assets/images/icons/project-icons/icon-audit.svg";
import { ReactComponent as IconApprovals } from "assets/images/icons/project-icons/approvals.svg";
// import { ReactComponent as IconSettings } from "assets/images/icons/project-icons/settings-3.svg";
import { ReactComponent as IconPaymentNote } from "assets/images/icons/project-icons/payment-note-2.svg";
import { ReactComponent as IconChevronUp } from "assets/images/icons/project-icons/chevron-up.svg";
import { ReactComponent as IconCredit } from "assets/images/icons/project-icons/Icon-credit.svg";
import { ReactComponent as IconTrade } from "assets/images/icons/project-icons/trade-icon.svg";
import { ReactComponent as IconAccount } from "assets/images/icons/project-icons/IconAccount.svg";
import { ReactComponent as IconApproval } from "assets/images/icons/project-icons/icon-approvals.svg";
import { ReactComponent as IconCompliance } from "assets/images/icons/icon-finance.svg";
import { ReactComponent as IconLogout } from "assets/images/icons/project-icons/icon-logout.svg";
import { ReactComponent as IconSettings } from "assets/images/icons/project-icons/u_setting.svg";
import useRouteAccess from 'hooks/useRouteAccess';
import * as info from "store/entities/settingsInfo/action";
import * as complianceActions from "store/entities/compliance/action";
import LogoutConfirmModal from '../common/LogoutConfirmModal';
import { CreditStatus } from 'pages/credit/CreditInfo';
import { ALLOWED_BUSINESS_NAMES_FOR_PAYROLL } from 'utils/constants';


export const NavigationLinks = (props) => {
    const { isSidebarCollapsed, toggleSidebarSize } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const {userInfo, logoutUser} = useLoginInfo();
    const {isOnboardingRequired, isOnDemandOnboardingRequired, isPayrollOnboardingRequired} = useRouteAccess();
    const { companyInfoLoading, companyInfo } = useSelector((s) => s.entities.settingsInfo);
    const { businessCredit } = useSelector(s => s.entities.wallet);
    const { employerInfo } = useSelector((s) => s.auth.account);
    const employer = employerInfo?.data?.employer;
    const [isOpenPeople, setIsOpenPeople] = useState(false);
    const [isOpenOnDemandPay, setIsOpenOnDemandPay] = useState(false);
    const [isOpenBulkPay, setIsOpenBulkPay] = useState(false);
    const [isOpenPayroll, setIsOpenPayroll] = useState(false);
    const [isOpenAccount, setIsOpenAccount] = useState(false);
    const [isSidebarBottomSticky, setIsSidebarBottomSticky] = useState(true);
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const checkSidebarAccess = (isOnDemandRoute = false, enforceActication = undefined, isPayrollOdpCreditTradeRoute, isPayrollRoute, isOnboardingLandingPage) => {
        if (enforceActication === true || isOnboardingLandingPage) return "opacity-100";
        
        if ((isOnboardingRequired === true) ||
            (isOnDemandRoute === true && isOnDemandOnboardingRequired === true) ||
            !(userInfo.employer.adminVerify || employer?.adminVerify) || (isPayrollOdpCreditTradeRoute && userInfo?.company?.companyType !== "registered business") || (isPayrollRoute && isPayrollOnboardingRequired))
        {
            return "opacity-40";
        }
        else{
            return "opacity-100";
        }
    }

    const addDashboardIndicator = () => {
        if (location.pathname === "/"){
            return "active";
        }
        return "";
    }


    // eslint-disable-next-line no-unused-vars
    const addEmployeesIndicator = () => {
        if (location.pathname.includes("/employees/add") || location.pathname.includes("/employees/edit")){
            // add this manual sidebar indicator only if user is @ the onboarding screen
            if (!userInfo.employer.adminVerify){
                return "active";
            }
        }
        return "";
    }


    const addPaymentNotesIndicator = () => {
        if (location.pathname.includes("/payment-history")){
            return "active";
        }
    }


    const addWalletDashboardIndicator = () => {
        if (
            location.pathname.includes("/wallet/balance") ||
            location.pathname.includes("/payments/account") ||
            location.pathname.includes("/wallet/fund")
        ){
            return "active";
        }
    }
    
    
    const autoOpenSidebarDropdown = () => {
        var activeLink = document.querySelectorAll(".sidebar-links.active")[0];
        if (activeLink){
            // aa.parentElement.parentElement.firstChild.style.backgroundColor = "red";
            const parentId = activeLink.parentElement.parentElement.firstChild.getAttribute("id");
            if (parentId === "sidebar-group-people"){
                setIsOpenPeople(true);
            }
            else if (parentId === "sidebar-group-ondemand"){
                setIsOpenOnDemandPay(true);
            }
            else if (parentId === "sidebar-group-bulkpay"){
                setIsOpenBulkPay(true);
            }
            else if (parentId === "sidebar-group-payroll"){
                setIsOpenPayroll(true);
            }
            else if (parentId === "sidebar-group-account"){
                setIsOpenAccount(true)
            }
        }
    }
    
    
    const autoActiveSidebarParentDropdown = () => {
        var activeLink = document.querySelectorAll(".sidebar-links.active")[0];
        if (activeLink){
            const parentGroup = activeLink.parentElement.parentElement;
            if (parentGroup.classList.contains("sidebar-links-parent")){
                const parent = parentGroup.firstChild;
                if (isSidebarCollapsed === true){
                    // parent.classList.add("active");
                    parent.style.backgroundColor = "#002B30";
                }
                else{
                    // parent.classList.remove("active");
                    parent.style.backgroundColor = "transparent";
                }
            }
        }
    }



    const toggleSidebarUISeparation = () => {
        if (props.view && props.view === "mobile"){
            setIsSidebarBottomSticky(false);
        }
        else{
            const sidebarTopHeight = document.querySelector(".sidebar-top-section").offsetHeight;
            const sidebarBottomHeight = document.querySelector(".sidebar-bottom-section").offsetHeight;
            const screenHeight = window.innerHeight;
            const sidebarTopMaxHeight = screenHeight - sidebarBottomHeight - 100;
            
            if (sidebarTopHeight > sidebarTopMaxHeight){
                if (isSidebarBottomSticky === true){
                    setIsSidebarBottomSticky(false);
                }
            }
            else{
                if (isSidebarBottomSticky === false){
                    setIsSidebarBottomSticky(true);
                }
            }
        }
    }
    
    
    const toggleSidebarDropdown = (e) => {
        if (isSidebarCollapsed === true){
            toggleSidebarSize("expand")
        }
        else{
            if (e === "people"){
                setIsOpenPeople(data => !data);
                setIsOpenOnDemandPay(false);
                setIsOpenBulkPay(false);
                setIsOpenPayroll(false);
                setIsOpenAccount(false);
            }
            else if (e === "ondemand"){
                setIsOpenOnDemandPay(data => !data);
                setIsOpenBulkPay(false);
                setIsOpenPeople(false);
                setIsOpenPayroll(false);
                setIsOpenAccount(false);
            }
            else if (e === "bulkpay"){
                setIsOpenBulkPay(data => !data);
                setIsOpenOnDemandPay(false);
                setIsOpenPeople(false);
                setIsOpenPayroll(false);
                setIsOpenAccount(false);
            }
            else if (e === "payroll"){
                setIsOpenPayroll(data => !data);
                setIsOpenOnDemandPay(false);
                setIsOpenBulkPay(false);
                setIsOpenPeople(false);
                setIsOpenAccount(false);
            }
            else if (e === "account"){
                setIsOpenAccount(data => !data);
                setIsOpenOnDemandPay(false);
                setIsOpenBulkPay(false);
                setIsOpenPayroll(false);
                setIsOpenPeople(false);
            }
        }
    }
    
    
    const handlePayrollToggle = () => { 
        toggleSidebarDropdown("payroll");
        if(isSidebarCollapsed === false) {
            // dispatch(togglePayrollFeaturesModal());
        }
        
    }
    
    
    useEffect(() => {
        if (companyInfoLoading === false && Object.keys(companyInfo).length === 0){
            dispatch(info.getInfo(userInfo?.company?.selectedCompanyId));
        }
        
        autoOpenSidebarDropdown();
        window.addEventListener('resize', toggleSidebarUISeparation);
        return () => {
            window.removeEventListener('resize', toggleSidebarUISeparation);
        };
        // eslint-disable-next-line
    }, [])
    
    
    useEffect(() => {
        toggleSidebarUISeparation();
        // eslint-disable-next-line
    }, [isOpenPeople, isOpenBulkPay, isOpenOnDemandPay, isOpenPayroll])

    
    useEffect(() => {
        autoActiveSidebarParentDropdown();
        // eslint-disable-next-line
    }, [isSidebarCollapsed])


    return (
      <div className={isSidebarCollapsed === true ? 'sidebar-collapsed' : ''}>
        <div className="sidebar-top-section">
          {/* <ComplianceSetupProgress
                    isSidebarCollapsed={isSidebarCollapsed}
                    toggleSidebarSize={toggleSidebarSize}
                /> */}

          <LogoutConfirmModal
            isOpen={showLogoutModal}
            setIsOpen={setShowLogoutModal}
          />
          <Maybe
            condition={
              userInfo?.employer?.isCompliant === null ||
              !(userInfo?.employer?.isCompliant || employer?.isCompliant)
            }
          >
            <NavLink
              to="/compliance"
              className={'sidebar-links ' + checkSidebarAccess(false, true)}
            >
              <IconVerification className="sidebar-links-icon" />
              <div>Verification</div>
            </NavLink>
          </Maybe>
          {/* <NavLink to="/compliance" className={"sidebar-links " + checkSidebarAccess()}>
                    <IconVerification className="sidebar-links-icon" />
                    <div>
                        Verification
                    </div>
                </NavLink> */}

          <NavLink
            to="/dashboard"
            className={`sidebar-links ${addDashboardIndicator()} ${checkSidebarAccess()}`}
          >
            <IconHome className="sidebar-links-icon" />
            <div>Dashboard</div>
          </NavLink>

          {/* Account Module */}
          <div className="sidebar-links-parent">
            <div
              onClick={() => toggleSidebarDropdown('account')}
            //   className={'sidebar-links ' + checkSidebarAccess()}
            className={'sidebar-links ' + checkSidebarAccess(undefined, true)}
              id="sidebar-group-account"
            >
              <IconAccount className="sidebar-links-icon -top-0.5" />
              <div>
                Account
                <IconChevronUp
                  className={`sidebar-angle-down transition ease-in-out ${isOpenAccount ? '' : 'rotate-180'}`}
                />
              </div>
            </div>

            <div className={isOpenAccount === false ? 'hidden' : ''}>
              <NavLink
                to="/account/all-accounts"
                className={
                  'sidebar-links ' + 
                  checkSidebarAccess(false, false, false, false, true)
                  // checkSidebarAccess(undefined, true)
                }
              >
                <div className="sidebar-links-icon"></div>
                <div>Sub-accounts</div>
              </NavLink>

              <NavLink
                to="/account/yield"
                className={
                  'sidebar-links ' +
                  checkSidebarAccess(false, false, false, false, true)
                  // checkSidebarAccess()
                }
              >
                <div className="sidebar-links-icon"></div>
                <div>Yield</div>
              </NavLink>
            </div>
          </div>

          <Environment develop={true} staging={true} production={true}>
            <div className="sidebar-links-parent">
              <div
                onClick={() => toggleSidebarDropdown('bulkpay')}
                // className={'sidebar-links ' + checkSidebarAccess()}
                className={'sidebar-links ' + checkSidebarAccess(undefined, true)}
                id="sidebar-group-bulkpay"
              >
                <IconPayments className="sidebar-links-icon -top-0.5" />
                <div>
                  Payments
                  <IconChevronUp
                    className={`sidebar-angle-down transition ease-in-out ${isOpenBulkPay ? '' : 'rotate-180'}`}
                  />
                </div>
              </div>

              <div className={isOpenBulkPay === false ? 'hidden' : ''}>
                {/* <Link to="/payments/account" className={`sidebar-links ${checkSidebarAccess()} + ${addWalletDashboardIndicator()}`}>
                                <div className="sidebar-links-icon"></div>
                                <div>
                                    Account
                                </div>
                            </Link> */}

                {/* <NavLink to="/payments/transfer" className={"sidebar-links " + checkSidebarAccess()}>
                                <div className="sidebar-links-icon"></div>
                                <div>
                                    Transfers
                                </div>
                            </NavLink> */}

                <NavLink
                  to="/payments/transfers"
                  className={'sidebar-links ' + 
                    checkSidebarAccess(false, false, false, false, true)
                    // checkSidebarAccess()
                }
                >
                  <div className="sidebar-links-icon"></div>
                  <div>Transfers</div>
                </NavLink>

                {/* <NavLink to="/bill-payment" className={"sidebar-links " + checkSidebarAccess()}>
                                <div className="sidebar-links-icon"></div>
                                <div>
                                    Bill Payments
                                </div>
                            </NavLink> */}

                <NavLink
                  to="/payments/bills"
                  className={
                    'sidebar-links ' +
                    checkSidebarAccess(false, false, false, false, true)
                    // checkSidebarAccess()
                  }
                >
                  <div className="sidebar-links-icon"></div>
                  <div>Bill Payments</div>
                </NavLink>

                {/* <NavLink to="/payments/transactions" className={"sidebar-links " + checkSidebarAccess()}>
                                <div className="sidebar-links-icon"></div>
                                <div>
                                    Transaction History
                                </div>
                            </NavLink> */}

                <NavLink
                  to="/payments/transaction-history"
                  className={'sidebar-links ' + 
                    checkSidebarAccess(false, false, false, false, true)
                    // checkSidebarAccess()
                }
                >
                  <div className="sidebar-links-icon"></div>
                  <div>Transaction History</div>
                </NavLink>

                <NavLink
                  to="/payments/beneficiaries"
                  className={'sidebar-links ' + 
                    checkSidebarAccess(false, false, false, false, true)}
                    // checkSidebarAccess()
                >
                  <div className="sidebar-links-icon"></div>
                  <div>Beneficiaries</div>
                </NavLink>
              </div>
            </div>
          </Environment>

          <div className="sidebar-links-parent">
            <div
              onClick={() => toggleSidebarDropdown('people')}
              className={'sidebar-links ' + checkSidebarAccess(undefined, true)}
              id="sidebar-group-people"
            >
              <IconPeople className="sidebar-links-icon -top-0.5" />
              <div>
                People
                <IconChevronUp
                  className={`sidebar-angle-down transition ease-in-out ${isOpenPeople ? '' : 'rotate-180'}`}
                />
              </div>
            </div>

            {/* <Maybe condition={isOpenPeople === true}> */}
            <div className={isOpenPeople === false ? 'hidden' : ''}>
              <NavLink
                exact
                to="/people/employees"
                className={
                  'sidebar-links ' +
                  checkSidebarAccess(undefined, true)
                }
              >
                <div className="sidebar-links-icon"></div>
                <div>Employees</div>
              </NavLink>

              <NavLink
                to="/people/groups"
                className={'sidebar-links ' + 
                    checkSidebarAccess(undefined, true)
                    // checkSidebarAccess()
                }
              >
                <div className="sidebar-links-icon"></div>
                <div>Groups</div>
              </NavLink>
            </div>
          </div>
          <Maybe
            condition={
              userInfo?.employer?.accountType !== 'sub' &&
              ALLOWED_BUSINESS_NAMES_FOR_PAYROLL.map((companyBusinessName) =>
                companyBusinessName.toLowerCase()
              ).includes(userInfo?.company?.businessName?.toLowerCase())
            }
          >
            <div className="sidebar-links-parent">
              <div
                onClick={handlePayrollToggle}
                className={
                  'sidebar-links ' +
                  checkSidebarAccess(false, undefined, true, true)
                }
                id="sidebar-group-payroll"
              >
                <IconPayroll className="sidebar-links-icon" />
                <div>
                  <div className="flex items-center">
                    <div>Payroll</div>

                    {/* <div className="ml-3 px-2 py-1 rounded-md bg-[#548174] border border-ep-primary-300 text-ep-primary-100 text-xs">
                                        Beta
                                    </div> */}
                  </div>
                  <IconChevronUp
                    className={`sidebar-angle-down transition ease-in-out ${isOpenPayroll ? '' : 'rotate-180'}`}
                  />
                </div>
              </div>

              {/* <Maybe condition={isOpenPayroll === true}> */}
              <div className={isOpenPayroll === false ? 'hidden' : ''}>
                <NavLink
                  to="/payroll"
                  className={
                    'sidebar-links ' +
                    checkSidebarAccess(false, undefined, true, true)
                  }
                >
                  <div className="sidebar-links-icon"></div>
                  <div>All Payroll</div>
                </NavLink>

                <NavLink
                  to="/payroll-configuration"
                  className={
                    'sidebar-links ' +
                    checkSidebarAccess(false, undefined, true, true)
                  }
                >
                  <div className="sidebar-links-icon"></div>
                  <div>Payroll Configuration</div>
                </NavLink>
              </div>
            </div>
          </Maybe>

          <div className="sidebar-links-parent">
            <div
              onClick={() => toggleSidebarDropdown('ondemand')}
            //   className={'sidebar-links ' + checkSidebarAccess(true, undefined, true)}
              className={'sidebar-links ' + checkSidebarAccess(true, true, true)}
              id="sidebar-group-ondemand"
            >
              <IconOnDemandPay className="sidebar-links-icon -top-0.5" />
              <div>
                On-Demand Pay
                <IconChevronUp
                  className={`sidebar-angle-down transition ease-in-out ${isOpenOnDemandPay ? '' : 'rotate-180'}`}
                />
              </div>
            </div>

            {/* <Maybe condition={isOpenOnDemandPay === true}> */}
            <div className={isOpenOnDemandPay === false ? 'hidden' : ''}>
              <NavLink
                to="/withdrawals"
                className={
                  'sidebar-links ' +
                  checkSidebarAccess(true, undefined, true, false, true)
                  // checkSidebarAccess(true, undefined, true)
                }
              >
                <div className="sidebar-links-icon"></div>
                <div>Withdrawals</div>
              </NavLink>

              <NavLink
                to="/invited-employees"
                className={
                  'sidebar-links ' +
                  checkSidebarAccess(true, undefined, true, false, true)
                //   checkSidebarAccess(true, undefined, true)
                }
              >
                <div className="sidebar-links-icon"></div>
                <div>Invited Employees</div>
              </NavLink>

              <Maybe condition={companyInfo?.company?.employeeEva === true}>
                <NavLink
                  to="/employees/era"
                  className={
                    'sidebar-links ' + checkSidebarAccess(true, undefined, true)
                  }
                >
                  <div className="sidebar-links-icon"></div>
                  <div>Employees ERAs</div>
                </NavLink>
              </Maybe>

              {/* 
                        <NavLink to="/savings" className={"sidebar-links " + checkSidebarAccess(true)}>
                            <div className="sidebar-links-icon"></div>
                            <div>
                                Savings Requests
                            </div>
                        </NavLink>
                        */}

              <NavLink
                to="/reconciliation"
                className={
                  'sidebar-links ' +
                  checkSidebarAccess(true, undefined, true, false, true)
                //   checkSidebarAccess(true, undefined, true)
                }
              >
                <div className="sidebar-links-icon"></div>
                <div>Reconciliation</div>
              </NavLink>

              {/* <NavLink to="/payment-note" className={`sidebar-links ${checkSidebarAccess(true)} ${addPaymentNotesIndicator()}`}>
                            <div className="sidebar-links-icon"></div>
                            <div>
                                Payment Notes
                            </div>
                        </NavLink> */}
            </div>
          </div>

          <NavLink
            to={`${businessCredit?.status === CreditStatus.PENDING ? '/credit/application-submitted' : '/credit'}`}
            className={`sidebar-links ${
              checkSidebarAccess(false, undefined, true, false, true)
              // checkSidebarAccess(false, undefined, true)
            }`}
          >
            <IconCredit className="sidebar-links-icon sidebar-links-icon-white" />
            <div>Credit</div>
          </NavLink>

          <NavLink
            to="/trade"
            className={`sidebar-links ${
              checkSidebarAccess(false, undefined, true, false, true)
              // checkSidebarAccess(false, undefined, true)
            }`}
          >
            <IconTrade className="sidebar-links-icon sidebar-links-icon-white" />
            <div>Trade</div>
          </NavLink>

          <Maybe condition={companyInfo?.company?.employeeEva === true}>
            <div className="hidden">
              <NavLink
                to="/transaction-history"
                className={'sidebar-links ' + checkSidebarAccess()}
              >
                <IconPaymentNote className="sidebar-links-icon" />
                <div>Transaction History</div>
              </NavLink>
            </div>
          </Maybe>
        </div>

        <div
          className={`sidebar-bottom-section ${isSidebarBottomSticky === true ? 'w-full absolute bottom-4' : ''}`}
        >
          <NavLink
            to="/approvals/dashboard"
            className={
              'sidebar-links ' +
              checkSidebarAccess(false, false, false, false, true)
              // checkSidebarAccess()
            }
          >
            <IconApproval className="sidebar-links-icon sidebar-links-icon-white" />
            <div>Approvals</div>
          </NavLink>

          <NavLink
            to="/audit-logs"
            className={'sidebar-links ' + checkSidebarAccess()}
          >
            <IconAuditTrail className="sidebar-links-icon sidebar-links-icon-white" />
            <div>Audit Logs</div>
          </NavLink>

          {/* <Maybe condition={userInfo.employer.userType === 'employer'}> */}
          <NavLink
            to="/settings"
            className={'sidebar-links ' + 
                // checkSidebarAccess(false, false, false, false, false)
                checkSidebarAccess()
                // ''
            }
          >
            <IconSettings className="sidebar-links-icon svg-fill-4A5564" />
            <div>Settings</div>
          </NavLink>
          {/* </Maybe> */}

          {/* <div onClick={() => setShowLogoutModal(true)} className="sidebar-links hidden md:flex">
                    <IconLogout className="sidebar-links-icon" />
                    <div>
                        Logout
                    </div>
                </div> */}
        </div>
      </div>
    );
}


const ComplianceSetupProgress = (props) => {
    const { isSidebarCollapsed, toggleSidebarSize } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const {userInfo} = useLoginInfo();
    const { complianceProgress } = useSelector((s) => s.entities.compliance);
    
    
    const startCompliance = () => {
        toggleSidebarSize("expand");
        history.push("/compliance");
    }
    
    
    useEffect(() => {
        if (userInfo.employer.status === "review"){
            dispatch(complianceActions.updateComplianceProgress(100));
        }
        // eslint-disable-next-line
    }, [])
    
    
    return (
        <>
            <Maybe condition={userInfo?.employer?.isCompliant === false}>
                <div onClick={startCompliance} className="m-4 mt-0 block border border-gray-100 rounded-md cursor-pointer hover:bg-gray-100">
                    
                    <Maybe condition={isSidebarCollapsed !== true}>
                        <div className="p-4">
                            <div>
                                Compliance setup
                            </div>
                            <div className="mt-3">
                                <div className="text-sm flex justify-between">
                                    <div>
                                        <Maybe condition={complianceProgress === 0}>
                                            Not started
                                        </Maybe>
                                        <Maybe condition={complianceProgress > 0 && complianceProgress < 100}>
                                            In progress
                                        </Maybe>
                                        <Maybe condition={complianceProgress === 100}>
                                            <Maybe condition={userInfo?.employer?.isCompliant === true}>
                                                Verified
                                            </Maybe>
                                            <Maybe condition={userInfo?.employer?.isCompliant === false}>
                                                <Maybe condition={userInfo?.employer?.status === "review"}>
                                                    Verifying
                                                </Maybe>
                                                <Maybe condition={userInfo?.employer?.status === "rejected"}>
                                                    Unverified
                                                </Maybe>
                                            </Maybe>
                                        </Maybe>
                                    </div>
                                    <div>
                                        <Maybe condition={complianceProgress > 0 && userInfo?.employer?.isCompliant === false && userInfo?.employer?.status !== "rejected"}>
                                            {complianceProgress}% Complete
                                        </Maybe>
                                    </div>
                                </div>
                                <div className="w-full h-2 mt-1 bg-[#D9D9D9] rounded-md overflow-hidden">
                                    <div
                                        style={{width: complianceProgress+"%"}}
                                        className={`h-full ${userInfo?.employer?.isCompliant ? "bg-ep-primary-300":"bg-ep-secondary-400"}`}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </Maybe>
                    
                    <Maybe condition={isSidebarCollapsed === true}>
                        <div className="py-2 flex justify-center">
                            <IconCompliance className="sidebar-links-icon svg-fill-white" />
                        </div>
                    </Maybe>
                    
                </div>
            </Maybe>
        </>
    )
}


export default NavigationLinks;