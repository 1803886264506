function capitalizeFirstLetter(str) {
    if (!str) {
        return ''; // Return an empty string if the input is empty or falsy
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
}


function convertToTitleCase(str) {
    if (!str) {
        return '';  // Return an empty string if the input is empty or falsy
    }
    
    return str
        .split(/[_ ]/)                  // Split the string by underscores or spaces
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' ');                  // Join the words with a space
}

function capitalizeEachWord(sentence) {
    if (!sentence) {
        return '';  // Return an empty string if the input is empty or falsy
    }
    return sentence
        .toLowerCase() // Converts the entire sentence to lowercase first
        .split(' ')    // Splits the sentence into an array of words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizes the first letter of each word
        .join(' ');    // Joins the words back into a single string
}


export { capitalizeFirstLetter, capitalizeEachWord, convertToTitleCase };
