import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import localStorage from "redux-persist/lib/storage";
// import sessionStorage from 'redux-persist/lib/storage/session';  // accessible in tabs only

import authReducer from "./auth/auth.reducer";
import entitiesReducer from "./entities/entities.reducer";
import uiReducer from "./ui/ui.reducer";

const rootPersistConfig = {
    key: "root",
    storage: localStorage,
    whitelist: [""],
}

const authPersistConfig = {
    key: "auth",
    storage: localStorage,
    whitelist: ["userPersist"],
}

const preferencesPersistConfig = {
    key: "ui",
    storage: localStorage,
    whitelist: ["preferences"],
}

const rootReducer = combineReducers({
    entities: entitiesReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    ui: persistReducer(preferencesPersistConfig, uiReducer),
});

export default persistReducer(rootPersistConfig, rootReducer);