export const queryOptions = [
//   {
//     title: 'Dashboard',
//     path: '/dashboard',
//     subRoute: '',
//   },
  {
    title: 'Profile Information',
    path: '/settings/profile',
    subRoute: 'Profile',
  },
  {
    title: 'Company Details',
    path: '/settings/info',
    subRoute: 'Company Details',
  },
  {
    title: 'Teams',
    path: '/settings/team-management',
    subRoute: 'Team Management',
  },
  {
    title: 'Role Management',
    path: '/settings/team-management/roles',
    subRoute: 'Role Management',
  },
  {
    title: 'On-Demand Pay',
    path: '/settings/ondemand-pay',
    subRoute: 'On-Demand Pay',
  },
  {
    title: 'Login & Security',
    path: '/settings/login-security',
    subRoute: 'Login & Security',
  },
  // {
  //     title: "Notifications",
  //     path: "/settings/notifications",
  // },
];
