import * as actions from "./actionTypes";
import * as config from "config";
import ObjectToQueryString from 'utils/ObjectToQueryString';

export function getBusinessDashboard(){
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetBusinessDashboard,
            method: "get",
            data: {},
            onStart: actions.GET_BUSINESS_DASHBOARD_START,
            onSuccess: actions.GET_BUSINESS_DASHBOARD,
            onError: actions.GET_BUSINESS_DASHBOARD_START,
        },
    }
}

export function getDashboard(){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiGetBusinessDashboard,
            method: "get",
            data: {},
            onStart: actions.GET_BUSINESS_DASHBOARD_START,
            onSuccess: actions.GET_BUSINESS_DASHBOARD,
            onError: actions.GET_BUSINESS_DASHBOARD_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function filterDashboard(params){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiGrossPayrollBaseUrl,
            url: config.apiGetBusinessDashboard + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.FILTER_BUSINESS_DASHBOARD_START,
            onSuccess: actions.FILTER_BUSINESS_DASHBOARD,
            onError: actions.FILTER_BUSINESS_DASHBOARD_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getBulkPayMetrics(token){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetBulkPayMetrics,
            additionalHeaders: {token},
            method: "get",
            data: {},
            onStart: actions.GET_BULKPAY_METRICS_START,
            onSuccess: actions.GET_BULKPAY_METRICS_DONE,
            onError: actions.GET_BULKPAY_METRICS_FAILED,
        },
    }
}

export function getBulkPayAggregates(userId){
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiBillPaymentBaseUrl,
            url: `${config.apiGetBulkPayAggregates}?userId=${userId}`,
            additionalHeaders: {"Authorization": config.apiBillPaymentToken},
            method: "get",
            data: {},
            onStart: actions.GET_BULKPAY_AGGREGATES_START,
            onSuccess: actions.GET_BULKPAY_AGGREGATES_DONE,
            onError: actions.GET_BULKPAY_AGGREGATES_FAILED,
        },
    }
}

export function saveOnDemandOnboarding(payload) {
    return {
        type: actions.SAVE_ON_DEMAND_ONBOARDING,
        payload: payload
    }
}


export function submitOnboardingForm(params){
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdatePolicyInfo,
            method: "post",
            data: params,
            onStart: actions.SUBMIT_ONBOARDING_FORM_START,
            onSuccess: actions.SUBMIT_ONBOARDING_FORM_DONE,
            onError: actions.SUBMIT_ONBOARDING_FORM_FAILED,
        },
    }
}