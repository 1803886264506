export const GET_PAYROLL_METRICS = "GET_PAYROLL_METRICS";
export const GET_PAYROLL_METRICS_START = "GET_PAYROLL_METRICS_START";
export const GET_PAYROLL_METRICS_FAILED = "GET_PAYROLL_METRICS_FAILED";

export const GET_ALL_PAYROLL = "GET_ALL_PAYROLL";
export const GET_ALL_PAYROLL_START = "GET_ALL_PAYROLL_START";
export const GET_ALL_PAYROLL_FAILED = "GET_ALL_PAYROLL_FAILED";

export const DOWNLOAD_GET_ALL_PAYROLL = "DOWNLOAD_GET_ALL_PAYROLL";
export const DOWNLOAD_GET_ALL_PAYROLL_START = "DOWNLOAD_GET_ALL_PAYROLL_START";
export const DOWNLOAD_GET_ALL_PAYROLL_FAILED = "DOWNLOAD_GET_ALL_PAYROLL_FAILED";

export const GET_CURRENT_PAYROLL = "GET_CURRENT_PAYROLL";
export const GET_CURRENT_PAYROLL_START = "GET_CURRENT_PAYROLL_START";
export const GET_CURRENT_PAYROLL_FAILED = "GET_CURRENT_PAYROLL_FAILED";

export const GET_CURRENT_PAYROLL_BULK = "GET_CURRENT_PAYROLL_BULK";
export const GET_CURRENT_PAYROLL_BULK_START = "GET_CURRENT_PAYROLL_BULK_START";
export const GET_CURRENT_PAYROLL_BULK_FAILED = "GET_CURRENT_PAYROLL_BULK_FAILED";

export const GET_PAYROLL_DASHBOARD_INFO = "GET_PAYROLL_DASHBOARD_INFO";
export const GET_PAYROLL_DASHBOARD_INFO_START = "GET_PAYROLL_DASHBOARD_INFO_START";
export const GET_PAYROLL_DASHBOARD_INFO_FAILED = "GET_PAYROLL_DASHBOARD_INFO_FAILED";

export const GET_PAYROLL_STATUS = "GET_PAYROLL_STATUS";
export const GET_PAYROLL_STATUS_START = "GET_PAYROLL_STATUS_START";
export const GET_PAYROLL_STATUS_FAILED = "GET_PAYROLL_STATUS_FAILED";

export const GET_PAYROLL_BY_ID = "GET_PAYROLL_BY_ID";
export const GET_PAYROLL_BY_ID_START = "GET_PAYROLL_BY_ID_START";
export const GET_PAYROLL_BY_ID_FAILED = "GET_PAYROLL_BY_ID_FAILED";

export const GET_PAYROLL_TRANSACTIONS = "GET_PAYROLL_TRANSACTIONS";
export const GET_PAYROLL_TRANSACTIONS_START = "GET_PAYROLL_TRANSACTIONS_START";
export const GET_PAYROLL_TRANSACTIONS_FAILED = "GET_PAYROLL_TRANSACTIONS_FAILED";

export const DOWNLOAD_PAYROLL_TRANSACTIONS = "DOWNLOAD_PAYROLL_TRANSACTIONS";
export const DOWNLOAD_PAYROLL_TRANSACTIONS_START = "DOWNLOAD_PAYROLL_TRANSACTIONS_START";
export const DOWNLOAD_PAYROLL_TRANSACTIONS_FAILED = "DOWNLOAD_PAYROLL_TRANSACTIONS_FAILED";

export const GET_COMPLETED_PAYROLL_TRANSACTIONS = "GET_COMPLETED_PAYROLL_TRANSACTIONS";
export const GET_COMPLETED_PAYROLL_TRANSACTIONS_START = "GET_COMPLETED_PAYROLL_TRANSACTIONS_START";
export const GET_COMPLETED_PAYROLL_TRANSACTIONS_FAILED = "GET_COMPLETED_PAYROLL_TRANSACTIONS_FAILED";

export const GET_FETCH_PAYROLLS = "GET_FETCH_PAYROLLS";
export const GET_FETCH_PAYROLLS_START = "GET_FETCH_PAYROLLS_START";
export const GET_FETCH_PAYROLLS_FAILED = "GET_FETCH_PAYROLLS_FAILED";

export const DOWNLOAD_FETCH_PAYROLLS = "DOWNLOAD_FETCH_PAYROLLS";
export const DOWNLOAD_FETCH_PAYROLLS_START = "DOWNLOAD_FETCH_PAYROLLS_START";
export const DOWNLOAD_FETCH_PAYROLLS_FAILED = "DOWNLOAD_FETCH_PAYROLLS_FAILED";

export const SCHEDULE_PAYROLL = "SCHEDULE_PAYROLL";
export const SCHEDULE_PAYROLL_START = "SCHEDULE_PAYROLL_START";
export const SCHEDULE_PAYROLL_FAILED = "SCHEDULE_PAYROLL_FAILED";

export const RUN_PAYROLL = "RUN_PAYROLL";
export const RUN_PAYROLL_START = "RUN_PAYROLL_START";
export const RUN_PAYROLL_FAILED = "RUN_PAYROLL_FAILED";

export const RETRY_PAYROLL = "RETRY_PAYROLL";
export const RETRY_PAYROLL_START = "RETRY_PAYROLL_START";
export const RETRY_PAYROLL_FAILED = "RETRY_PAYROLL_FAILED";

export const UPDATE_PAYROLL_TRANSACTION_START = "UPDATE_PAYROLL_TRANSACTION_START";
export const UPDATE_PAYROLL_TRANSACTION_SUCCESS = "UPDATE_PAYROLL_TRANSACTION_SUCCESS";
export const UPDATE_PAYROLL_TRANSACTION_FAILED = "UPDATE_PAYROLL_TRANSACTION_FAILED";
export const UPDATE_PAYROLL_TRANSACTION_RESET = "UPDATE_PAYROLL_TRANSACTION_RESET";
