import * as actions from "./actionTypes";
import * as actionsUser from "../user/actionTypes";
import * as config from "config";

export function updateProfile(params){
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateProfile,
            method: "post",
            data: params,
            onStart: actions.UPDATE_PROFILE_START,
            onSuccess: actions.UPDATE_PROFILE,
            onError: actions.UPDATE_PROFILE_FAILED,
        },
    }
}

export function resetUpdateProfile(){
    return {
        type: actions.UPDATE_PROFILE_RESET,
        payload: {},
    }
}

export function updateLoggedInPersistedStorage(params){
    return {
        type: actionsUser.UPDATE_LOGIN_USER,
        payload: {
            data: params,
        },
    }
}

export function updateLoginProfile(params){
    return {
        type: actionsUser.UPDATE_LOGIN_PROFILE,
        payload: params,
    }
}

export function updatePassword(params){
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateProfilePassword,
            method: "post",
            data: params,
            onStart: actions.UPDATE_PASSWORD_START,
            onSuccess: actions.UPDATE_PASSWORD,
            onError: actions.UPDATE_PASSWORD_FAILED,
        },
    }
}

export function resetUpdatePassword(){
    return {
        type: actions.UPDATE_PASSWORD_RESET,
        payload: {},
    }
}

export function getEmployerInfo() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetEmployerInfo,
            method: "get",
            data: {},
            onStart: actions.GET_EMPLOPYER_INFO_START,
            onSuccess: actions.GET_EMPLOPYER_INFO,
            onError: actions.GET_EMPLOPYER_INFO_FAILED,
            useEncryption: true,
        }
    }
}

export function updateEmployerProducts(id, data) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetEmployerInfo}/${id}`,
            method: "put",
            data,
            onStart: actions.UPDATE_EMPLOYER_PRODUCTS_START,
            onSuccess: actions.UPDATE_EMPLOYER_PRODUCTS,
            onError: actions.UPDATE_EMPLOYER_PRODUCTS_FAILED,
        }
    }
}

export function resetEmployerProductsAction() {
    return {
        type: actions.RESET_EMPLOYER_PRODUCTS_ACTION,
        payload: {}
    }
}

export function updateEmployerInfoFE(params) {
    return {
        type: actions.UPDATE_EMPLOPYER_INFO,
        payload: params,
    }
}

export function updateCompanyInfo(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateCompanyInfo,
            method: "patch",
            data: params,
            onStart: actions.UPDATE_COMPANY_INFO_START,
            onSuccess: actions.UPDATE_COMPANY_INFO,
            onError: actions.UPDATE_COMPANY_INFO_FAILED,
        }
    }
}

export function resetUpdateCompanyInfo() {
    return {
        type: actions.UPDATE_COMPANY_INFO_RESET,
        payload: {},
    }
}
