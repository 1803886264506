import { useState } from "react";
import { Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import Maybe from "components/__new/common/Maybe";
import getFileSize from "utils/getFileSize";
import validate from "utils/validate";
import { ReactComponent as IconFileUpload } from "assets/images/icons/project-icons/upload-2.svg";
import { ReactComponent as IconFile } from "assets/images/icons/project-icons/file.svg";
import { ReactComponent as IconTimes } from "assets/images/icons/project-icons/times.svg";


export const FileUploadForm = (props) => {
    const {data, onchange, filetype, maxFileSize, uploadUi, filename, validationName, validationGenerics, checkFormValidation, uploadedFileContainerClassName, disabled, isFileTypeHidden=false} = props;
    const types = filetype ?? ["jpg","jpeg","png","gif","pdf","doc","docx","xls","xlsx","csv"];
    const maxSize = maxFileSize ?? 3;
    const [errors, setErrors] = useState({});
    
    
    const doValidation = (e) => {
        if (validationName !== undefined){
            const data = {[validationName]: e};
            const vErrors = validate(data);
            if (vErrors){
                setErrors(vErrors);
            }
            else{
                setErrors({});
            }
            checkFormValidation(undefined, validationName, e);
        }
    }
    
    
    const displayError = (errorMessage) => {
        const errorObject = {
            "File type is not supported": "The file format is not supported. Acceptable formats: " + filetype.join(", "),
            "File size is too big": "The file size should not be greater than " + maxSize + "mb",
        }
        setErrors({ [validationName]: errorObject[errorMessage] ?? errorMessage });
    }
    
    const handleRemoveFile = () => {
        onchange(null); 
        doValidation(null);
    }
    
    
    return (
        <div className="mb-4">
            
            
            <Maybe condition={!data}>
                <FileUploader handleChange={(e) => {onchange(e); doValidation(e)}} multiple={false} types={types} maxSize={maxSize} onTypeError={(err) => displayError(err)} onSizeError={(err) => displayError(err)} disabled={disabled}>
                    <Maybe condition={uploadUi === undefined}>
                        <div className="px-[24px] py-[16px] flex flex-col items-center justify-center border border-gray-200 cursor-pointer rounded-[8px]">
                            <div className="w-[40px] h-[40px] flex">
                                <IconFileUpload className="m-auto w-full h-full" />
                            </div>
                            <div className="mt-[12px] text-[14px] leading-[20px]">
                                <span className="text-ep-primary-600 font-medium">
                                    Click to upload&nbsp;
                                </span>
                                <span className="text-ep-gray-400 font-normal">
                                    or drag and drop
                                </span>
                            </div>
                            <Maybe condition={!isFileTypeHidden}>
                            <div className="text-fade text-[12px]">
                                (Formats: {filetype.join(", ")}. Maximum size: {maxSize}mb)
                            </div>
                            </Maybe>
                        </div>
                    </Maybe>
                    <Maybe condition={uploadUi !== undefined}>
                        {uploadUi}
                    </Maybe>
                </FileUploader>
            </Maybe>
            
            
            <Maybe condition={data !== null && data !== "" && data}>
                <Maybe condition={typeof data === "object" && data?.lastModified !== undefined}>
                    <div className={`p-4 flex items-center justify-between space-x-8 text-[14px] leading-[20px] font-medium rounded-[8px] box bg-[#F9FAFB] border border-ep-primary overflow-hidden ${uploadedFileContainerClassName || ""}`}>
                        <div className="flex items-center space-x-4 ellipsis-2-lines">
                            <div className="w-10 h-10 flex bg-ep-primary-100 rounded-full">
                                <IconFile />
                            </div>
                            <div className="text-ep-primary">
                                {data?.name}
                            </div>
                        </div>
                        <div className="flex items-center justify-end space-x-2">
                            <div className="text-ep-gray-400">
                                {getFileSize(data?.size)}
                            </div>
                            <div 
                                onClick={!disabled ? handleRemoveFile : undefined} 
                                className={`w-6 h-6 rounded flex group hover:bg-red-500 ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
                            >
                                <IconTimes 
                                    className="w-3 h-3 m-auto group-hover:svg-fill-white" 
                                />
                            </div>
                        </div>
                    </div>
                </Maybe>
                
                
                <Maybe condition={typeof data === "string" && data?.lastModified === undefined}>
                    <div className={`p-4 flex items-center justify-between space-x-8 box bg-[#F9FAFB] rounded-[8px] border border-ep-primary overflow-hidden ${uploadedFileContainerClassName || ""}`}>
                        <div className="flex items-center space-x-4 ellipsis-2-lines">
                            <div className="w-10 h-10 flex bg-ep-primary-100 rounded-full">
                                <IconFile />
                            </div>
                            <Link to={{pathname: data}} target="_blank" className="text-ep-primary ellipsis hover:underline">
                                {filename ?? data}
                            </Link>
                        </div>
                        <div className="flex-shrink-0 flex items-center justify-end space-x-2">
                            <Link to={{pathname: data}} target="_blank" className="text-ep-primary hover:underline">
                                View file
                            </Link>
                            <div 
                                onClick={!disabled ? handleRemoveFile : undefined} 
                                className={`w-6 h-6 rounded flex hover:bg-red-500 group ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
                            >
                                <IconTimes 
                                    className="w-3 h-3 m-auto group-hover:svg-fill-white" 
                                />
                            </div>
                        </div>
                    </div>
                </Maybe>
            </Maybe>
            
            
            <Maybe condition={errors[validationName] !== undefined}>
                <div className="form-input-message">
                    <Maybe condition={validationGenerics?.includes(validationName) === true}>
                        {filename?.toLowerCase() ?? ""}&nbsp;
                    </Maybe>
                    {errors[validationName]}
                </div>
            </Maybe>
            
            
        </div>
    )
}

export default FileUploadForm
