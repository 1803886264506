import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customInfoToast, customSuccessToast } from "utils/customToast";

const initialState = {
    savingsRequestsLoading: false,
    savingsRequests: {},
    
    savingsRequestsDownloadLoading: false,
    savingsRequestsDownload: {},
    
    savingsRequestMetricsLoading: false,
    savingsRequestMetrics: {},
    
    savingsRequestsApproveLoading: false,
    savingsRequestsRejectLoading: false,
    savingsRequestsRejected: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {


        case actions.GET_SAVINGS_REQUESTS:
            state = {
                ...state,
                savingsRequestsLoading: false,
                savingsRequests: action.payload
            }
            return state;


        case actions.GET_SAVINGS_REQUESTS_START:
            state = {
                ...state,
                savingsRequestsLoading: true,
            }
            return state;


        case actions.GET_SAVINGS_REQUESTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                savingsRequestsLoading: false,
                savingsRequests: {},
            }
            return state;


        case actions.DOWNLOAD_GET_SAVINGS_REQUESTS:
            state = {
                ...state,
                savingsRequestsDownloadLoading: false,
                savingsRequestsDownload: action.payload
            }
            return state;


        case actions.DOWNLOAD_GET_SAVINGS_REQUESTS_START:
            customInfoToast({ message: "Downloading Data..." });
            state = {
                ...state,
                savingsRequestsDownloadLoading: true,
                savingsRequestsDownload: {},
            }
            return state;


        case actions.DOWNLOAD_GET_SAVINGS_REQUESTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                savingsRequestsDownloadLoading: false,
                savingsRequestsDownload: {},
            }
            return state;


        case actions.GET_SAVINGS_REQUEST_METRICS:
            state = {
                ...state,
                savingsRequestMetricsLoading: false,
                savingsRequestMetrics: action.payload
            }
            return state;


        case actions.GET_SAVINGS_REQUEST_METRICS_START:
            state = {
                ...state,
                savingsRequestMetricsLoading: true,
            }
            return state;


        case actions.GET_SAVINGS_REQUEST_METRICS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                savingsRequestMetricsLoading: false,
                savingsRequestMetrics: {},
            }
            return state;
    

        case actions.APPROVE_SAVINGS_REQUEST:
            customSuccessToast({ message: "Savings request approved" })
            state = {
                ...state,
                savingsRequestsApproveLoading: false,
            }
            return state;


        case actions.APPROVE_SAVINGS_REQUEST_START:
            customInfoToast({ message: "Approving savings request" });
            state = {
                ...state,
                savingsRequestsApproveLoading: true,
            }
            return state;


        case actions.APPROVE_SAVINGS_REQUEST_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                savingsRequestsApproveLoading: false,
            }
            return state;


        case actions.REJECT_SAVINGS_REQUEST:
            customSuccessToast({ message: "Savings request rejected" });
            state = {
                ...state,
                savingsRequestsRejectLoading: false,
                savingsRequestsRejected: true,
            }
            return state;


        case actions.REJECT_SAVINGS_REQUEST_START:
            state = {
                ...state,
                savingsRequestsRejectLoading: true,
                savingsRequestsRejected: false,
            }
            return state;


        case actions.REJECT_SAVINGS_REQUEST_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                savingsRequestsRejectLoading: false,
                savingsRequestsRejected: false,
            }
            return state;


        case actions.REJECT_SAVINGS_REQUEST_RESET:
            state = {
                ...state,
                savingsRequestsRejectLoading: false,
                savingsRequestsRejected: false,
            }
            return state;


        default:
            return state;
    }
}