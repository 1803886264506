import React, { useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import FormDrawer from "components/__new/common/form-drawer";
import useLoginInfo from "hooks/useLoginInfo";
import { default as ReactSelect, components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Maybe from "components/__new/common/Maybe";
import useSubAccounts from "hooks/useSubAccounts";
import ModalSwitchingAccount from "./ModalSwitchingAccount";
import * as info from "store/entities/settingsInfo/action";
import * as auth from "store/auth/user/action";
// import useEmployerProducts from "hooks/useEmployerProducts";


const SubAccountSwitch = React.forwardRef((props, ref) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {userInfo} = useLoginInfo();
    // const {isOnDemandUser} = useEmployerProducts()
    const {
        subAccountsLoading, subAccounts, getSubAccounts,
        isOpenModalSwitchAccount, setIsOpenModalSwitchAccount,
        switchToSubAccount, switchToMainAccount, subAccountSwitchAccount
    } = useSubAccounts();
    const { companyInfoLoading, companyInfo } = useSelector((s) => s.entities.settingsInfo);


    const [hasSubAccountAccess, setHasSubAccountAccess] = useState(false);
    const [isOpenModalSwitch, setIsOpenModalSwitch] = useState(false);
    const [subAccountList, setSubAccountList] = useState([]);
    const [subAccountListSelected, setSubAccountListSelected] = useState({});
    
    
    const getSubAccountsFilteredList = (data) => {
        let currentUser = userInfo;
        let subAccountsDisplayed = data;
        if (userInfo?.mainAccount && Object.keys(userInfo?.mainAccount)?.length > 0){
            currentUser = userInfo?.mainAccount;
        }
        if (currentUser.alias === true){
            const userEmployerList = currentUser?.employerList?.map(employer => employer?._id);
            subAccountsDisplayed = data?.filter((account) => userEmployerList?.includes(account?._id));
        }
        return subAccountsDisplayed;
    }

    
    const populateSubAccountList = () => {
        let list = [];
        let listSelected = {};

        if (Object.keys(userInfo.employer).length > 0){
            const data = {
                label: userInfo?.employer?.companyName,
                value: userInfo.employer?.company,
                ...userInfo,
                ...{accountType: "main"},
            };
            list.push(data);
        }
        // else{
        //     const data = {
        //         label: companyInfo?.company?.companyName,
        //         value: companyInfo?.company?._id,
        //         ...userInfo,
        //         companyInfo: {...companyInfo},
        //     };
        //     list.push(data);
        //     listSelected = data;
        // }
        
        // console.log("list 1", list);

        if (subAccounts?.accounts?.docs){
            let subAccountsDisplayed = getSubAccountsFilteredList(subAccounts?.accounts?.docs);
            subAccountsDisplayed.map((subAccount) => {
                const data = {
                    label: subAccount.companyName,
                    value: subAccount.companyInfoId,
                    ...subAccount,
                };
                list.push(data);
                if (companyInfo?.company?._id === subAccount.companyInfoId){
                    listSelected = data;
                }
                return null;
            });
            setSubAccountList(list);
        }
        
        // console.log("list 2", list);

        setSubAccountListSelected(listSelected);
    }


    const switchAccount = (account) => {
        if (account?.accountType === "main"){
            if (Object.keys(userInfo.mainAccount).length > 0){
                // console.log("main 1");
                dispatch(auth.resetUserInitialState()).then(() => {
                    switchToMainAccount();
                })
            }
        }
        else{            
            dispatch(auth.resetUserMainAccount()).then(() => {
                if (companyInfo._id !== account.companyInfoId) {
                    switchToSubAccount(account);
                } 
            })
        }
    }
    

    const addNewBusiness = () => {
        setIsOpenModalSwitch(false);
        history.push("/account/add");
    }
    

    useEffect(() => {
        // if(isOnDemandUser) {
            // if (
            //     (userInfo.employer.accountType && userInfo.employer.accountType === "main") ||
            //     (userInfo.employer.accountType && userInfo.employer.accountType === "sub" && userInfo.mainAccount && Object.keys(userInfo.mainAccount).length > 0) ||
            //     (userInfo.employer.alias && userInfo.employer.alias === true)
            //     ){
            //         getSubAccounts();
            //         setHasSubAccountAccess(true);
            // }
        // }
        getSubAccounts();
        setHasSubAccountAccess(true);
        dispatch(info.getInfo(userInfo?.company?.selectedCompanyId));
        // eslint-disable-next-line
    }, [])

    
    useEffect(() => {
        if (subAccounts?.status === "success" && companyInfo?.status === "success"){
            populateSubAccountList();
        }
        // eslint-disable-next-line
    }, [subAccounts, companyInfo])
    
    
    useImperativeHandle(ref, () => ({
        hasSubAccountAccess,
        setIsOpenModalSwitch,
        addNewBusiness,
    }));

    
    // console.log("userInfo", userInfo);
    // console.log("subAccountList", subAccountList);
    // console.log("subAccountListSelected", subAccountListSelected);
    // console.log("-------------");
    

    return (
        <div>


            <FormDrawer display="center" size="md" isOpen={isOpenModalSwitch} setIsOpen={setIsOpenModalSwitch}>
                <div className="px-0 py-4 max-w-md">
                    <div>
                        <div className="" style={{height: "438px"}}>
                            <ReactSelect
                                options={subAccountList}
                                value={subAccountListSelected}
                                onChange={(e) => switchAccount(e)}
                                placeholder="Search business"
                                noOptionsMessage={() => (subAccountsLoading || companyInfoLoading ? "Loading businesses..." : "No businesss yet")}
                                styles={ReactSelectStyles}
                                closeMenuOnSelect={false}
                                menuIsOpen={true}
                                hideSelectedOptions={false}
                                components={{Option, ValueContainer}}
                                allowSelectAll={false}
                            />
                        </div>

                        {/* <Maybe condition={hasSubAccountAccess === true}>
                            <div onClick={addNewBusiness} className="px-8 py-3 flex space-x-2 items-center justify-start text-ep-primary bg-ep-primary bg-opacity-5 hover:bg-opacity-20 cursor-pointer">
                                <FontAwesomeIcon icon="plus" className="w-2 h-2 mr-1 inline" />
                                Create new business
                            </div>
                        </Maybe> */}
                    </div>
                </div>
            </FormDrawer>
            

            <ModalSwitchingAccount
                subAccount={subAccountSwitchAccount}
                isOpenModalSwitchAccount={isOpenModalSwitchAccount}
                setIsOpenModalSwitchAccount={setIsOpenModalSwitchAccount}
            />


            <Maybe condition={props.isSelectorVisible === undefined || props.isSelectorVisible === true}>
                <Maybe condition={hasSubAccountAccess === true}>
                    <div onClick={() => setIsOpenModalSwitch(true)} className="h-10 px-4 flex space-x-2 justify-between items-center text-white bg-white bg-opacity-20 rounded-sm cursor-pointer overflow-hidden">
                        <div className="flex-grow ellipsis">
                            <Maybe condition={subAccountsLoading === true || companyInfoLoading === true}>
                                <FontAwesomeIcon icon="circle-notch" spin className="mr-2 inline" />
                                Loading...
                            </Maybe>
                            <Maybe condition={subAccountsLoading === false && companyInfoLoading === false}>
                                <Maybe condition={userInfo?.employer?.accountType === "main"}>
                                    {subAccountListSelected?.company?.companyName}
                                </Maybe>
                                <Maybe condition={userInfo?.employer?.accountType === "sub"}>
                                    {companyInfo?.company?.companyName}
                                </Maybe>
                            </Maybe>
                        </div>
                        <FontAwesomeIcon icon="angle-down" className="text-white" />
                    </div>
                </Maybe>


                <Maybe condition={hasSubAccountAccess === false}>
                    <div className="h-10 px-4 flex space-x-2 justify-between items-center text-white bg-white bg-opacity-20 rounded-sm overflow-hidden">
                        <div className="flex-grow ellipsis">
                            <Maybe condition={companyInfoLoading === true}>
                                <FontAwesomeIcon icon="circle-notch" spin className="mr-2 inline" />
                                Loading...
                            </Maybe>
                            <Maybe condition={companyInfoLoading === false}>
                                {companyInfo?.company?.companyName}
                            </Maybe>
                        </div>
                    </div>
                </Maybe>
            </Maybe>


        </div>
    )
})


const useSubAccountStatus = (subAccount) => {
    const {userInfo} = useLoginInfo();
    const {companyInfo} = useSelector(state => state.entities.settingsInfo);
    let status;

    // console.log("userInfo <>", userInfo);
    // console.log("subAccount <>", subAccount);
    
    if (subAccount){
        // (!subAccount.accountType)    when logging in as an employerAdmin, accountType is not returned
        if (!subAccount?.accountType || subAccount?.accountType === "main"){
            // console.log("main account");
            if (userInfo.mainAccount && Object.keys(userInfo.mainAccount).length > 0){
                status = "active";
            }
            else{
                status = "selected";
            }
        }
        else{
            // console.log("sub account");
            if (subAccount?.isEmailVerified === true && subAccount?.isCompanyInfo === true && subAccount?.isActive === true){
                status = "active";
            }
            else{
                status = "inactive";
            }
            
            if (companyInfo?.company?._id === subAccount.companyInfoId){
                status = "selected";
            }
        }
    }

    return { status };
}


const ColorIndicator = (subAccount, activeColor) => {
    const { status } = useSubAccountStatus(subAccount);
    let color;

    if (status === "inactive"){
        color = "text-[#dee0e4]";
    }
    else{
        color = activeColor;
    }

    return color;
}


const SwitchButtonIndicator = (subAccount) => {
    const { status } = useSubAccountStatus(subAccount);
    
    if (status === "selected"){
        return <span className="label label-green px-3">Logged in</span>;
    }
    else{
        return <span className="text-ep-primary border-b border-ep-primary">Switch</span>;
    }
}


const Option = (props) => {
    return (
        <div className="border-t">
            <components.Option {...props}>
                <div className="max-w-sm mx-auto pt-2 pb-3 text-sm flex space-x-2 justify-between">
                    <div>
                        <div className="text-base flex space-x-2">
                            <div className={ColorIndicator(props.data, "")}>
                                {props.label}
                            </div>
                            {props?.data?.accountType === "main" &&
                                <label className="text-ep-secondary">(Main)</label>
                            }
                        </div>
                        <div className={`mt-1 text-xs capitalize ${ColorIndicator(props.data, "text-fade")}`}>
                            <Maybe condition={typeof props.data?.employer === "object"}>
                                {props.data?.employer?.firstName ?? "-"} {props.data?.employer?.lastName} ({props.data?.employer?.jobTitle ?? "-"})
                            </Maybe>
                            <Maybe condition={typeof props.data?.employer !== "object"}>
                                {props.data?.firstName ?? "-"} {props.data?.lastName} ({props.data?.jobTitle ?? "-"})
                            </Maybe>
                        </div>
                    </div>
                    <div>
                        <div>
                            {SwitchButtonIndicator(props.data)}
                        </div>
                        {/* 
                        <input
                            type="radio"
                            checked={props.isSelected}
                            onChange={() => null}
                            className="w-5 h-5 form-checkbox rounded-full"
                        />
                         */}
                    </div>
                </div>
            </components.Option>
        </div>
    );
};


const ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
            {!!children && (
                <span
                    className="fa fa-search"
                    aria-hidden="true"
                    style={{ position: "absolute", left: 10, color: "#aaa" }}
                />
            )}
            {children}
        </components.ValueContainer>
      )
    );
};


const ReactSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        color: "#333",
        background: "#fff",
        cursor: "pointer",
        "&:hover": {
            background: "#eee",
        }
    }),
    valueContainer: base => ({
        ...base,
        paddingLeft: 10,
    }),
    menuList: (base) => ({
        ...base,
        minHeight: "390px",
    }),
    menu: (base) => ({
        ...base,
        width: "max-content",
        padding: "0",
        margin: "0",
        boxShadow: "none",
        minWidth: "100%",
   }),
   control: (provided, state) => ({
        ...provided,
        margin: "0 16px",
        border: "1px solid #ddd",
        boxShadow: "none",
        outline: "none",
        "&:hover": {
            border: "1px solid #bbb",
            outline: "none",
        },
    }),
};


export default SubAccountSwitch;