import React from "react";
import { Helmet } from "react-helmet";

const DynamicHeadTag = ({ headerText, headerDescription }) => {

	const title = headerText ? headerText + " | Business Dashboard | Earnipay" : "Business Dashboard | Earnipay | Finance, People Operations and Employee Benefits";
	const description = headerDescription || "Access and manage all your finance and people operations activities in one place";
	
	return (
		<Helmet>
			<title>{title}</title>

			<meta name="description" content={description} />
			<meta itemprop="description" content={description} />

			<meta property="og:url" content="https://employer.earnipay.com/" />
			<meta property="og:type" content="article" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />

			<meta property="og:image" content="https://media-files-staging.s3.eu-west-1.amazonaws.com/_1674819583413.png" />
			<meta property="og:image:url" content="https://media-files-staging.s3.eu-west-1.amazonaws.com/_1674819583413.png" />
			<meta property="og:image:secure_url" content="https://media-files-staging.s3.eu-west-1.amazonaws.com/_1674819583413.png" />
		</Helmet>
	);
};

export default DynamicHeadTag;

