const PAYROLL_ACCESS_WHITE_LIST_PROD = [
  'EARNIPAY LIMITED',
  'Cmq media Nigeria Ltd',
  'Earnipay Dev Test',
  'CAREERBUDDY TECHNOLOGIES LTD',
];

const PAYROLL_ACCESS_WHITE_LIST_DEV = ['SMILE IDENTITY NIGERIA LIMITED'];

export const ALLOWED_BUSINESS_NAMES_FOR_PAYROLL =
  process.env.REACT_APP_B2B_ENVIRONMENT === 'prod'
    ? PAYROLL_ACCESS_WHITE_LIST_PROD
    : PAYROLL_ACCESS_WHITE_LIST_DEV;

export const SETTINGS_TAB_OPTIONS = [
  {
    title: 'Profile Information',
    path: '/settings/profile',
  },
  {
    title: 'Company Details',
    path: '/settings/info',
  },
  {
    title: 'Teams',
    path: '/settings/team-management',
  },
  {
    title: 'Role Management',
    path: '/settings/team-management/roles',
  },
  {
    title: 'On-Demand Pay',
    path: '/settings/ondemand-pay',
  },
  {
    title: 'Login & Security',
    path: '/settings/login-security',
  },
  {
    title: 'Transaction Limits',
    path: '/settings/transaction-limits',
  },
  /* {
    title: 'Notifications',
    path: '/settings/notifications',
    id: 'settingsNotificationTab',
  }, */
];
