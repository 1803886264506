import { useHistory } from 'react-router-dom';


const useBackButton = () => {
    const history = useHistory();



    const goBack = () => {
        history.goBack();
        /*
        if (prevPageUrl && isSiteDomain(prevPageUrl)){
            navigate(-1);
        }
        else{
            navigate("/");
        }
        */
    }

    return {goBack};
}


export default useBackButton;
