
export const BENTO_LOGIN = "BENTO_LOGIN";
export const BENTO_LOGIN_START = "BENTO_LOGIN_START";
export const BENTO_LOGIN_FAILED = "BENTO_LOGIN_FAILED";

export const FINCH_SAVE_CODE = "FINCH_SAVE_CODE";
export const FINCH_SAVE_CODE_START = "FINCH_SAVE_CODE_START";
export const FINCH_SAVE_CODE_FAILED = "FINCH_SAVE_CODE_FAILED";

export const FINCH_CHECK_STATUS = "FINCH_CHECK_STATUS";
export const FINCH_CHECK_STATUS_START = "FINCH_CHECK_STATUS_START";
export const FINCH_CHECK_STATUS_FAILED = "FINCH_CHECK_STATUS_FAILED";
