import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { customInfoToast, customSuccessToast } from "utils/customToast";

const initialState = {
    withdrawalsMetricsLoading: false,
    withdrawalsMetrics: {},
    
    prevMonthWithdrawalsLoading: false,
    prevMonthWithdrawalsData: {},
    
    withdrawalsLoading: false,
    withdrawals: {},
    
    withdrawalsDownloadLoading: false,
    withdrawalsDownload: {},
    
    withdrawalApproveLoading: false,
    withdrawalRejectLoading: false,
    
    reloadPage: false,

    withdrawalReportLoading: false,
    withdrawalReport: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {
        
        case actions.GET_PREV_MONTH_WITHDRAWALS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                prevMonthWithdrawalsLoading: false,
                prevMonthWithdrawalsData: {},
            }
            return state;


        case actions.GET_PREV_MONTH_WITHDRAWALS_DONE:
            state = {
                ...state,
                prevMonthWithdrawalsLoading: false,
                prevMonthWithdrawalsData: action.payload
            }
            return state;


        case actions.GET_PREV_MONTH_WITHDRAWALS_START:
            state = {
                ...state,
                prevMonthWithdrawalsLoading: true,
            }
            return state;


        case actions.GET_WITHDRAWALS_METRICS:
            state = {
                ...state,
                withdrawalsMetricsLoading: false,
                withdrawalsMetrics: action.payload
            }
            return state;


        case actions.GET_WITHDRAWALS_METRICS_START:
            state = {
                ...state,
                withdrawalsMetricsLoading: true,
            }
            return state;


        case actions.GET_WITHDRAWALS_METRICS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                withdrawalsMetricsLoading: false,
                withdrawalsMetrics: {},
            }
            return state;


        case actions.GET_ALL_WITHDRAWALS:
            state = {
                ...state,
                withdrawalsLoading: false,
                withdrawals: action.payload
            }
            return state;


        case actions.GET_ALL_WITHDRAWALS_START:
            state = {
                ...state,
                withdrawalsLoading: true,
            }
            return state;


        case actions.GET_ALL_WITHDRAWALS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                withdrawalsLoading: false,
                withdrawals: {},
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_WITHDRAWALS:
            state = {
                ...state,
                withdrawalsDownloadLoading: false,
                withdrawalsDownload: action.payload
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_WITHDRAWALS_START:
            customInfoToast({ message: "Downloading Data..." });
            state = {
                ...state,
                withdrawalsDownloadLoading: true,
                withdrawalsDownload: {},
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_WITHDRAWALS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                withdrawalsDownloadLoading: false,
                withdrawalsDownload: {},
            }
            return state;


        case actions.APPROVE_WITHDRAWAL:
            customSuccessToast({ message: "Withdrawal Approved" })
            state = {
                ...state,
                withdrawalApproveLoading: false,
                reloadPage: true,
            }
            return state;


        case actions.APPROVE_WITHDRAWAL_START:
            customInfoToast({ message: "Approving Withdrawal" });
            state = {
                ...state,
                withdrawalApproveLoading: true,
            }
            return state;


        case actions.APPROVE_WITHDRAWAL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                withdrawalApproveLoading: false,
            }
            return state;


        case actions.REJECT_WITHDRAWAL:
            customSuccessToast({ message: "Withdrawal Rejected" })
            state = {
                ...state,
                withdrawalRejectLoading: false,
                reloadPage: true,
            }
            return state;


        case actions.REJECT_WITHDRAWAL_START:
            customInfoToast({ message: "Rejecting Withdrawal" });
            state = {
                ...state,
                withdrawalRejectLoading: true,
            }
            return state;


        case actions.REJECT_WITHDRAWAL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                withdrawalRejectLoading: false,
            }
            return state;


        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false
            }
            return state;


        case actions.GET_WITHDRAWAL_REPORT:
            state = {
                ...state,
                withdrawalReportLoading: false,
                withdrawalReport: action.payload,
            }
            return state;


        case actions.GET_WITHDRAWAL_REPORT_START:
            state = {
                ...state,
                withdrawalReportLoading: true,
            }
            return state;


        case actions.GET_WITHDRAWAL_REPORT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                withdrawalReportLoading: false,
            }
            return state;
    

        default:
            return state;
    }
}