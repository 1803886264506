export const GET_ALL_NOTIFICATIONS_START = "GET_ALL_NOTIFICATIONS_START";
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const GET_ALL_NOTIFICATIONS_FAILED = "GET_ALL_NOTIFICATIONS_FAILED";

export const MARK_ALL_NOTIFICATION_AS_READ_START = "MARK_ALL_NOTIFICATION_AS_READ_START";
export const MARK_ALL_NOTIFICATION_AS_READ = "MARK_ALL_NOTIFICATION_AS_READ";
export const MARK_ALL_NOTIFICATION_AS_READ_FAILED = "MARK_ALL_NOTIFICATION_AS_READ_FAILED";

export const MARK_SINGLE_NOTIFICATION_AS_READ_START = "MARK_SINGLE_NOTIFICATION_AS_READ_START";
export const MARK_SINGLE_NOTIFICATION_AS_READ = "MARK_SINGLE_NOTIFICATION_AS_READ";
export const MARK_SINGLE_NOTIFICATION_AS_READ_FAILED = "MARK_SINGLE_NOTIFICATION_AS_READ_FAILED";
export const RESET_MARK_SINGLE_NOTIFICATION_AS_READ = "RESET_MARK_SINGLE_NOTIFICATION_AS_READ";
