import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { isValidJSON } from "utils";
import { customInfoToast, customSuccessToast } from "utils/customToast";

const initialState = {
    earnipayBulkTransferCsvData: null,
    earnipayBulkTransferCsvErrors: null,
    
    bankBulkTransferCsvData: null,
    bankBulkTransferCsvErrors: null,
    
    earnipayTransferPreviewItems: [],
    
    earnipayTransferLoading: false,
    earnipayTransferResponse: null,
    earnipayTransferFailed: null,
    
    bankTransferPreviewItems: [],
    bankTransferLoading: false,
    bankTransferResponse: null,
    bankTransferFailed: null,
    
    transferBeneficiaryLoading: false,
    transferBeneficiary: {},
    
    transferBeneficiariesLoading: false,
    transferBeneficiaries: {},
    
    deleteTransferBeneficiaryLoading: false,
    deleteTransferBeneficiary: {},
    
    allRecurringTransfersLoading: false,
    allRecurringTransfersData: null,
    
    recurringDetailsLoading: false,
    recurringDetailsData: null,
    
    recurringHistoryLoading: false,
    recurringHistoryData: null,
    
    pauseRecurringLoading: false,
    deleteRecurringLoading: false,
    updateRecurringLoading: false,
    updateRecurringData: null,
    
    recurringTransferForm: null,
    
    createRecurringLoading: false,
    createRecurringData: null,
    selectedRecurringTransfer: null
};


export default function reducer(state = initialState, {type, payload}) {
    switch (type) {
        
        case actions.SELECT_RECURRING_TRANSFER:
            state = {
                ...state,
                selectedRecurringTransfer: payload,
            }
            return state;
        
        case actions.CLEAR_RECURRING_TRANSFER_FORM:
            state = {
                ...state,
                recurringTransferForm: null,
            }
            return state;
        
        case actions.SAVE_RECURRING_TRANSFER_FORM:
            state = {
                ...state,
                recurringTransferForm: payload,
            }
            return state;
        
        case actions.UPDATE_RECURRING_TRANSFER_START:
            state = {
                ...state,
                updateRecurringLoading: true,
                updateRecurringData: null,
            }
            return state;

        case actions.UPDATE_RECURRING_TRANSFER_DONE:
            state = {
                ...state,
                updateRecurringLoading: false,
                updateRecurringData: payload,
            }
            return state;

        case actions.UPDATE_RECURRING_TRANSFER_FAILED:
            errorReducer(payload)
            state = {
                ...state,
                updateRecurringLoading: false,
                updateRecurringData: null,
            }
            return state;
        
        case actions.DELETE_RECURRING_TRANSFER_START:
            state = {
                ...state,
                deleteRecurringLoading: true,
            }
            return state;

        case actions.DELETE_RECURRING_TRANSFER_DONE:
            customInfoToast({ message: payload?.message });
            state = {
                ...state,
                deleteRecurringLoading: false,
            }
            return state;

        case actions.DELETE_RECURRING_TRANSFER_FAILED:
            errorReducer(payload)
            state = {
                ...state,
                deleteRecurringLoading: false,
            }
            return state;
        
        case actions.PAUSE_RECURRING_TRANSFER_START:
            state = {
                ...state,
                pauseRecurringLoading: true,
            }
            return state;

        case actions.PAUSE_RECURRING_TRANSFER_DONE:
            customInfoToast({ message: payload?.message });
            state = {
                ...state,
                pauseRecurringLoading: false,
            }
            return state;

        case actions.PAUSE_RECURRING_TRANSFER_FAILED:
            errorReducer(payload)
            state = {
                ...state,
                pauseRecurringLoading: false,
            }
            return state;
        
        case actions.GET_RECURRING_TRANSFER_HISTORY_START:
            state = {
                ...state,
                recurringHistoryLoading: true,
                recurringHistoryData: null
            }
            return state;

        case actions.GET_RECURRING_TRANSFER_HISTORY_DONE:
            state = {
                ...state,
                recurringHistoryLoading: false,
                recurringHistoryData: payload
            }
            return state;

        case actions.GET_RECURRING_TRANSFER_HISTORY_FAILED:
            errorReducer(payload)
            state = {
                ...state,
                recurringHistoryLoading: false,
                recurringHistoryData: null
            }
            return state;
        
        case actions.GET_RECURRING_TRANSFER_DETAILS_START:
            state = {
                ...state,
                recurringDetailsLoading: true,
                recurringDetailsData: null
            }
            return state;

        case actions.GET_RECURRING_TRANSFER_DETAILS_DONE:
            state = {
                ...state,
                recurringDetailsLoading: false,
                recurringDetailsData: payload
            }
            return state;

        case actions.GET_RECURRING_TRANSFER_DETAILS_FAILED:
            errorReducer(payload)
            state = {
                ...state,
                recurringDetailsLoading: false,
                recurringDetailsData: null
            }
            return state;
        
        case actions.CREATE_RECURRING_TRANSFER_START:
            state = {
                ...state,
                createRecurringLoading: true,
                createRecurringData: null,
            }
            return state;

        case actions.CREATE_RECURRING_TRANSFER_DONE:
            customSuccessToast({ message: payload?.message });
            state = {
                ...state,
                createRecurringLoading: false,
                createRecurringData: payload,
            }
            return state;

        case actions.CREATE_RECURRING_TRANSFER_FAILED:
            errorReducer(payload)
            state = {
                ...state,
                createRecurringLoading: false,
                createRecurringData: null,
            }
            return state;
        
        case actions.GET_ALL_RECURRING_TRANSFERS_START:
            state = {
                ...state,
                allRecurringTransfersLoading: true,
                allRecurringTransfersData: null
            }
            return state;

        case actions.GET_ALL_RECURRING_TRANSFERS_DONE:
            state = {
                ...state,
                allRecurringTransfersLoading: false,
                allRecurringTransfersData: payload
            }
            return state;

        case actions.GET_ALL_RECURRING_TRANSFERS_FAILED:
            errorReducer(payload)
            state = {
                ...state,
                allRecurringTransfersLoading: false,
                allRecurringTransfersData: null
            }
            return state;
        
        case actions.RESET_TRANSFER_PROPERTIES:
            state = {
                ...state,
                earnipayTransferResponse: null,
                bankTransferResponse: null,
            }
            return state;

        case actions.GET_EARNIPAY_BENEFICIARIES_START:
            state = {
                ...state,
                transferBeneficiariesLoading: true,
                transferBeneficiaries: null
            }
            return state;

        case actions.GET_EARNIPAY_BENEFICIARIES_DONE:
            state = {
                ...state,
                transferBeneficiariesLoading: false,
                transferBeneficiaries: payload
            }
            return state;

        case actions.GET_EARNIPAY_BENEFICIARIES_FAILED:
            errorReducer(payload)
            state = {
                ...state,
                transferBeneficiariesLoading: false,
                transferBeneficiaries: null
            }
            return state;

        case actions.CREATE_TRANSFER_BENEFICIARY_START:
            state = {
                ...state,
                transferBeneficiaryLoading: true,
                transferBeneficiary: {}
            }
            return state;

        case actions.CREATE_TRANSFER_BENEFICIARY_DONE:
            state = {
                ...state,
                transferBeneficiaryLoading: false,
                transferBeneficiary: JSON.parse(payload)
            }
            return state;

        case actions.CREATE_TRANSFER_BENEFICIARY_FAILED:
            errorReducer(payload)
            state = {
                ...state,
                transferBeneficiaryLoading: false,
                transferBeneficiary: {}
            }
            return state;

        case actions.INITIATE_BANK_TRANSFER_START:
            state = {
                ...state,
                bankTransferLoading: true,
                bankTransferResponse: null
            }
            return state;

        case actions.INITIATE_BANK_TRANSFER_DONE:
            state = {
                ...state,
                bankTransferLoading: false,
                bankTransferResponse: payload
            }
            return state;

        case actions.INITIATE_BANK_TRANSFER_FAILED:
            errorReducer(payload)
            state = {
                ...state,
                bankTransferLoading: false,
                bankTransferResponse: null,
                bankTransferFailed: payload,
            }
            return state;

        case actions.INITIATE_BANK_TRANSFER_RESET:
            state = {
                ...state,
                bankTransferResponse: null,
                bankTransferFailed: null
            }
            return state;

        case actions.SAVE_BANK_TRANSFER_MULTIPLE_ITEMS:
            state = {
                ...state,
                bankTransferPreviewItems: payload
            }
            return state;
            
        case actions.CLEAR_BANK_TRANSFER_MULTIPLE_ITEMS:
            state = {
                ...state,
                bankTransferPreviewItems: [],
            }
            return state;
            
        case actions.SAVE_EARNIPAY_MULTIPLE_ITEMS:
            state = {
                ...state,
                earnipayTransferPreviewItems: payload
            }
            return state;
            
        case actions.CLEAR_EARNIPAY_MULTIPLE_ITEMS:
            state = {
                ...state,
                earnipayTransferPreviewItems: [],
            }
            return state;

        case actions.INITIATE_EARNIPAY_TRANSFER_START:
            state = {
                ...state,
                earnipayTransferLoading: true,
                earnipayTransferResponse: null
            }
            return state;

        case actions.INITIATE_EARNIPAY_TRANSFER_DONE:
            const responseData = isValidJSON(payload) ? JSON.parse(payload) : payload;
            state = {
                ...state,
                earnipayTransferLoading: false,
                earnipayTransferResponse: responseData,
            }
            return state;

        case actions.INITIATE_EARNIPAY_TRANSFER_FAILED:
            errorReducer(JSON.parse(payload))
            state = {
                ...state,
                earnipayTransferLoading: false,
                earnipayTransferResponse: null,
                earnipayTransferFailed: JSON.parse(payload)
            }
            return state;

        case actions.INITIATE_EARNIPAY_TRANSFER_RESET:
            state = {
                ...state,
                earnipayTransferResponse: null,
                earnipayTransferFailed: null,
            }
            return state;

        case actions.SAVE_EARNIPAY_TRANSFER_BULK_CSV:
            state = {
                ...state,
                earnipayBulkTransferCsvData: payload,
            }
            return state;
            
        case actions.SAVE_EARNIPAY_TRANSFER_BULK_CSV_ERRORS:
            state = {
                ...state,
                earnipayBulkTransferCsvErrors: payload,
            }
            return state;

        case actions.SAVE_BANK_TRANSFER_BULK_CSV:
            state = {
                ...state,
                bankBulkTransferCsvData: payload,
            }
            return state;

        case actions.SAVE_BANK_TRANSFER_BULK_CSV_ERRORS:
            state = {
                ...state,
                bankBulkTransferCsvErrors: payload,
            }
            return state;

        case actions.GET_TRANSFER_BENEFICIARIES_START:
            state = {
                ...state,
                transferBeneficiariesLoading: true,
                transferBeneficiaries: {}
            }
            return state;

        case actions.GET_TRANSFER_BENEFICIARIES:
            state = {
                ...state,
                transferBeneficiariesLoading: false,
                transferBeneficiaries: JSON.parse(payload),
            }
            return state;

        case actions.GET_TRANSFER_BENEFICIARIES_FAILED:
            errorReducer(payload)
            state = {
                ...state,
                transferBeneficiariesLoading: false,
                transferBeneficiaries: {}
            }
            return state;

        case actions.DELETE_TRANSFER_BENEFICIARY_START:
            state = {
                ...state,
                deleteTransferBeneficiaryLoading: true,
                deleteTransferBeneficiary: {}
            }
            return state;

        case actions.DELETE_TRANSFER_BENEFICIARY:
            state = {
                ...state,
                deleteTransferBeneficiaryLoading: false,
                deleteTransferBeneficiary: JSON.parse(payload),
            }
            return state;

        case actions.DELETE_TRANSFER_BENEFICIARY_FAILED:
            errorReducer(payload)
            state = {
                ...state,
                deleteTransferBeneficiaryLoading: false,
                deleteTransferBeneficiary: {}
            }
            return state;

        case actions.DELETE_TRANSFER_BENEFICIARY_RESET:
            state = {
                ...state,
                deleteTransferBeneficiary: {},
            }
            return state;

        case actions.CREATE_TRANSFER_BENEFICIARY_RESET:
            state = {
                ...state,
                transferBeneficiary: null,
            }
            return state;

        default:
            return state;
    }
}