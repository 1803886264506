import useLoginInfo from "hooks/useLoginInfo";



const Environment = (props) => {

    const checkUrl = () => {
        const url = window.location.href;
        const { hostname } = new URL(url);
        
        // console.log("url", url);
        // console.log("hostname", hostname);

        if (
            (props.local === undefined || (props.local && props.local === true && hostname === "localhost")) ||
            (props.develop && props.develop === true && hostname === "employer-dev.earnipay.com") ||
            (props.staging && props.staging === true && hostname === "employer-stg.earnipay.com") ||
            (props.production && props.production === true && hostname === "business.earnipay.com")
        ){
            return true;
        }
        return false;
    }

    return (
        <>
            {checkUrl() === true &&
                props.children
            }
        </>
    )
}



/**
 * [DEPRECATED] Pls use SoftLaunch.jsx
 * how to use
 * <SoftLaunch email={["@earnipay.com", "@edenlife.ng", "@tuteria.com", "@justbrandit.ng"]}>
 * ...
 * </SoftLaunch>
 */
export const SoftLaunch = (props) => {
    const {userInfo} = useLoginInfo();

    const checkStatus = () => {
        if (props.email){
            let status = false;
            props.email.map((email) => {
                if (userInfo?.email?.includes(email)){
                    status = true;
                }
                return null;
            })
            return status;
        }
        return false;
    }

    return (
        <>
            {checkStatus() === true &&
                props.children
            }
        </>
    )
}


export default Environment
